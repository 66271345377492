import React from 'react';

import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { useCropTechnology } from '../../../_hooks/useCropTechnology';

import TechnologyCircle from './TechnologyCircle';

const useStyles = makeStyles(() => ({
  technology: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'center'
  }
}));

const Technology = ({ varietyId }) => {
  const { technologyName, technologyColor, technologyTextColor } =
    useCropTechnology(varietyId);

  const styles = useStyles({
    color: technologyColor,
    textColor: technologyTextColor
  });

  return (
    <>
      {varietyId && (
        <Box className={styles.technology}>
          <TechnologyCircle varietyId={varietyId} />
          <Box component="span">{technologyName}</Box>
        </Box>
      )}
    </>
  );
};

export default Technology;
