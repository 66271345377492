import _ from 'lodash';
import moment from 'moment';

import { getErrorMessage } from '../../services/errors';
import {
  createGrowerActionTypes,
  deleteGrowerActionTypes,
  getConfigGrowersActionTypes,
  getGrowersActionTypes,
  updateGrowerActionTypes
} from '../actionTypes/growersActionTypes';
import { requestStatus } from '../helpers/requestStatus';

export const growersReducer = (state = {}, action) => {
  switch (action.type) {
    case getGrowersActionTypes.REQUEST:
      return {
        ...state,
        growers: {
          ..._.get(state, 'growers'),
          status: requestStatus.IN_PROGRESS
        }
      };
    case getGrowersActionTypes.SUCCEEDED:
      return {
        ...state,
        growers: {
          status: requestStatus.SUCCESS,
          growers: action.payload.response,
          updatedAt: moment().toISOString()
        }
      };
    case getGrowersActionTypes.FAILED:
      return {
        ...state,
        growers: {
          status: requestStatus.FAILURE,
          error: {
            message: getErrorMessage(action.payload.apiError)
          }
        }
      };
    case getConfigGrowersActionTypes.REQUEST:
      return {
        ...state,
        configGrowers: {
          ..._.get(state, 'configGrowers'),
          status: requestStatus.IN_PROGRESS
        }
      };
    case getConfigGrowersActionTypes.SUCCEEDED:
      return {
        ...state,
        configGrowers: {
          status: requestStatus.SUCCESS,
          response: action.payload.response
        }
      };
    case getConfigGrowersActionTypes.FAILED:
      return {
        ...state,
        configGrowers: {
          status: requestStatus.FAILURE,
          error: {
            message: getErrorMessage(action.payload.apiError)
          }
        }
      };
    case createGrowerActionTypes.REQUEST:
      return {
        ...state,
        createGrower: {
          status: requestStatus.IN_PROGRESS
        }
      };
    case createGrowerActionTypes.SUCCEEDED:
      return {
        ...state,
        createGrower: {
          status: requestStatus.SUCCESS,
          grower: action.payload.response,
          updatedAt: moment().toISOString()
        }
      };
    case createGrowerActionTypes.FAILED:
      return {
        ...state,
        createGrower: {
          status: requestStatus.FAILURE,
          error: {
            message: getErrorMessage(action.payload.apiError)
          }
        }
      };
    case createGrowerActionTypes.CLEAR:
      return {
        ...state,
        createGrower: {}
      };
    case updateGrowerActionTypes.REQUEST:
      return {
        ...state,
        updateGrower: {
          status: requestStatus.IN_PROGRESS
        }
      };
    case updateGrowerActionTypes.SUCCEEDED:
      return {
        ...state,
        updateGrower: {
          status: requestStatus.SUCCESS,
          grower: action.payload.response,
          updatedAt: moment().toISOString()
        }
      };
    case updateGrowerActionTypes.FAILED:
      return {
        ...state,
        updateGrower: {
          status: requestStatus.FAILURE,
          error: {
            message: getErrorMessage(action.payload.apiError)
          }
        }
      };
    case updateGrowerActionTypes.CLEAR:
      return {
        ...state,
        updateGrower: {}
      };
    case deleteGrowerActionTypes.REQUEST:
      return {
        ...state,
        deleteGrower: {
          status: requestStatus.IN_PROGRESS,
          growerId: _.get(state, 'deleteGrower.growerId')
        }
      };
    case deleteGrowerActionTypes.SUCCEEDED:
      return {
        ...state,
        deleteGrower: {
          status: requestStatus.SUCCESS,
          growerId: _.get(state, 'deleteGrower.growerId'),
          updatedAt: moment().toISOString()
        }
      };
    case deleteGrowerActionTypes.FAILED:
      return {
        ...state,
        deleteGrower: {
          status: requestStatus.FAILURE,
          growerId: _.get(state, 'deleteGrower.growerId'),
          error: {
            message: getErrorMessage(action.payload.apiError)
          }
        }
      };
    case deleteGrowerActionTypes.CLEAR:
      return {
        ...state,
        deleteGrower: {}
      };
    case deleteGrowerActionTypes.SELECT:
      return {
        ...state,
        deleteGrower: {
          growerId: action.payload.growerId
        }
      };
    default:
      return state;
  }
};
