import { useMemo } from 'react';

import _ from 'lodash';
import { useSelector } from 'react-redux';

import { requestStatus } from '../helpers/requestStatus';

export const configFarmsStatusSelector = (growerId) => (state) =>
  _.get(state, ['farms', 'configFarms', growerId, 'status']);

export const useConfigFarmsSelector = (growerId) => {
  const status = useSelector((state) =>
    _.get(state, ['farms', 'configFarms', growerId, 'status'])
  );
  const isLoading = status === requestStatus.IN_PROGRESS;
  const success = status === requestStatus.SUCCESS;
  const error = useSelector((state) =>
    _.get(state, ['farms', 'configFarms', growerId, 'error', 'message'])
  );
  const farms = useSelector((state) =>
    _.get(state, ['farms', 'configFarms', growerId, 'farms'])
  );

  return useMemo(
    () => ({
      farms,
      isLoading,
      success,
      error
    }),
    [error, farms, isLoading, success]
  );
};

export const useConfigFarmSelectors = (growerId, farmId) => {
  const {
    farms,
    success,
    isLoading: inProgress,
    error
  } = useConfigFarmsSelector(growerId);

  const farm = useMemo(
    () => _.find(farms, (farm) => farm.id === farmId),
    [farmId, farms]
  );

  return useMemo(
    () => ({ farm, success, inProgress, error }),
    [farm, inProgress, success, error]
  );
};

export const useCreateFarmSelectors = () => {
  const status = useSelector((state) =>
    _.get(state, 'farms.createFarm.status')
  );
  const isLoading = status === requestStatus.IN_PROGRESS;
  const success = status === requestStatus.SUCCESS;
  const error = useSelector((state) =>
    _.get(state, 'farms.createFarm.error.message')
  );

  return {
    isLoading,
    success,
    error
  };
};

export const useUpdateFarmSelectors = () => {
  const status = useSelector((state) =>
    _.get(state, 'farms.updateFarm.status')
  );
  const isLoading = status === requestStatus.IN_PROGRESS;
  const success = status === requestStatus.SUCCESS;
  const error = useSelector((state) =>
    _.get(state, 'farms.updateFarm.error.message')
  );

  return {
    isLoading,
    success,
    error
  };
};

export const useDeleteFarmSelectors = () => {
  const farmToDelete = useSelector((state) =>
    _.get(state, 'farms.deleteFarm.farm')
  );

  const status = useSelector((state) =>
    _.get(state, 'farms.deleteFarm.status')
  );
  const isLoading = status === requestStatus.IN_PROGRESS;
  const success = status === requestStatus.SUCCESS;
  const error = useSelector((state) =>
    _.get(state, 'farms.deleteFarm.error.message')
  );

  return {
    farmToDelete,
    isLoading,
    success,
    error
  };
};
