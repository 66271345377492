import React, { forwardRef } from 'react';

import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles((theme) => ({
  dialog: {
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.dimensions.headerHeight
    }
  }
}));

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ResponsiveDialog = ({ maxWidth = 'sm', children, ...props }) => {
  const theme = useTheme();
  const styles = useStyles();
  const fullscreen = useMediaQuery(theme.breakpoints.down(maxWidth));

  return (
    <Dialog
      {...props}
      classes={{ root: styles.dialog }}
      fullScreen={fullscreen}
      TransitionComponent={fullscreen ? Transition : undefined}
      maxWidth={maxWidth}
    >
      {children}
    </Dialog>
  );
};

export default ResponsiveDialog;
