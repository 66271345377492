import { buildApiActionTypes } from './apiActionTypes';

export const weatherActionTypes = {
  UPDATE_FIELD_WEATHER: 'UPDATE_FIELD_WEATHER',
  FORCE_UPDATE_FIELD_WEATHER: 'FORCE_UPDATE_FIELD_WEATHER',
  UPDATE_LOCATION_WEATHER: 'UPDATE_LOCATION_WEATHER',
  FORCE_UPDATE_LOCATION_WEATHER: 'FORCE_UPDATE_LOCATION_WEATHER',
  UPDATE_WEATHER_STATION_WEATHER: 'UPDATE_WEATHER_STATION_WEATHER',
  FORCE_UPDATE_WEATHER_STATION_WEATHER: 'FORCE_UPDATE_WEATHER_STATION_WEATHER'
};

export const getFieldWeatherActionTypes =
  buildApiActionTypes('GET_FIELD_WEATHER');

export const getLocationWeatherActionTypes = buildApiActionTypes(
  'GET_LOCATION_WEATHER'
);
