import { useMemo } from 'react';

import _ from 'lodash';
import { useSelector } from 'react-redux';

export const growerIdSelector = (state) =>
  _.get(state, 'farmStructure.growerId');
export const seasonIdSelector = (state) =>
  _.get(state, 'farmStructure.seasonId');
export const farmIdSelector = (state) => _.get(state, 'farmStructure.farmId');
export const fieldIdSelector = (state) => _.get(state, 'farmStructure.fieldId');

export const useFarmStructureSelector = () => {
  const growerId = useSelector(growerIdSelector);
  const seasonId = useSelector(seasonIdSelector);
  const farmId = useSelector(farmIdSelector);
  const fieldId = useSelector(fieldIdSelector);

  return useMemo(
    () => ({
      growerId,
      seasonId,
      farmId,
      fieldId
    }),
    [farmId, fieldId, growerId, seasonId]
  );
};
