import React, { useCallback, useContext, useState } from 'react';

import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Box, IconButton } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import { AuthContext } from '../../../../../_context/AuthContext';
import { useMenuState } from '../../../../../_hooks/ui/useMenuState';
import EnvironmentNameBadge from '../../EnvironmentNameBadge';
import GrowerFarmFieldSelector from '../../GrowerFarmFieldSelector';
import HeaderIconButton from '../../HeaderIconButton';

import MobileHeaderMenu from './MobileHeaderMenu';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexGrow: '1',
    flexShrink: 1,
    minWidth: 0,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignContent: 'center'
  },
  buttonContainer: {
    borderLeft: '1px solid #ffffff50'
  },
  selectorContainer: {
    display: 'flex',
    flexGrow: '1',
    flexShrink: 1,
    minWidth: 0,
    flexDirection: 'row',
    alignContent: 'center',
    justifyContent: 'flex-end'
  },
  actionsButton: {
    color: '#ffffff'
  },
  environmentName: {
    alignSelf: 'center'
  }
}));

const MobileHeader = () => {
  const styles = useStyles();

  const { isActive, tosAccepted } = useContext(AuthContext);

  const showEnvironmentName = useMediaQuery((theme) =>
    theme.breakpoints.up('sm')
  );

  const { showMobileSideMenu, toggleMobileSideMenu } = useMenuState();
  const [showMobileHeaderMenu, setShowMobileHeaderMenu] = useState(false);

  const toggleMobileHeaderMenu = useCallback(() => {
    setShowMobileHeaderMenu((showMobileHeaderMenu) => !showMobileHeaderMenu);
  }, []);

  return (
    <>
      <Box className={styles.container}>
        {isActive && tosAccepted && (
          <HeaderIconButton
            onClick={toggleMobileSideMenu}
            icon={showMobileSideMenu ? faTimes : faBars}
          />
        )}
        {showEnvironmentName && (
          <EnvironmentNameBadge className={styles.environmentName} />
        )}
        <Box className={styles.selectorContainer}>
          {isActive && tosAccepted && <GrowerFarmFieldSelector />}
          <Box className={styles.buttonContainer}>
            <IconButton
              className={styles.actionsButton}
              onClick={toggleMobileHeaderMenu}
            >
              <MoreVertIcon fontSize="large" />
            </IconButton>
          </Box>
        </Box>
      </Box>
      <MobileHeaderMenu
        isOpen={showMobileHeaderMenu}
        toggleOpen={toggleMobileHeaderMenu}
      />
    </>
  );
};

export default MobileHeader;
