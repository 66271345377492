import React from 'react';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(() => ({
  container: {
    marginTop: '1rem',
    marginBottom: '1rem'
  }
}));

const HarvestAdvisorChartControls = ({ isZoomed, onZoomReset }) => {
  const styles = useStyles();

  return (
    <Grid
      container
      justifyContent="flex-end"
      alignContent="center"
      className={styles.container}
    >
      <Grid item>
        <Button
          size="small"
          color="primary"
          onClick={onZoomReset}
          disabled={!isZoomed}
        >
          Reset Zoom
        </Button>
      </Grid>
    </Grid>
  );
};

export default HarvestAdvisorChartControls;
