import localForage from 'localforage';
import _ from 'lodash';
import { applyMiddleware, createStore } from 'redux';
import { persistStore, persistReducer, createTransform } from 'redux-persist';
import createSagaMiddleware from 'redux-saga';

import { requestStatus } from './helpers/requestStatus';
import rootReducer from './reducers';
import rootSaga from './sagas';

const sagaMiddleware = createSagaMiddleware();
let middlewareEnhancer = applyMiddleware(sagaMiddleware);

if (process.env.NODE_ENV !== 'production') {
  const {
    composeWithDevTools
  } = require('../../node_modules/redux-devtools-extension'); // eslint-disable-line import/no-extraneous-dependencies, global-require

  middlewareEnhancer = composeWithDevTools(middlewareEnhancer);
}

function omitInProgress(obj) {
  if (
    !_.isPlainObject(obj) ||
    _.get(obj, 'status') === requestStatus.SUCCESS ||
    _.get(obj, 'status') === requestStatus.FAILURE
  ) {
    return obj;
  }

  return _.chain(obj)
    .omitBy((value) => _.get(value, 'status') === requestStatus.IN_PROGRESS)
    .mapValues((value) => omitInProgress(value))
    .value();
}

const inProgressTransform = createTransform(
  // transform state on its way to being serialized and persisted.
  (inboundState) => {
    return omitInProgress(inboundState);
  },
  // transform state being rehydrated
  (outboundState) => {
    return outboundState;
  },
  {}
);

const persistConfig = {
  key: 'reduxState',
  storage: localForage,
  transforms: [inProgressTransform],
  blacklist: ['ui', 'uiLegacy']
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const configureStore = () => {
  const store = createStore(persistedReducer, {}, middlewareEnhancer);

  if (process.env.NODE_ENV !== 'production' && module.hot) {
    module.hot.accept('./reducers', () => store.replaceReducer(rootReducer));
  }

  sagaMiddleware.run(rootSaga);

  const persistor = persistStore(store);

  return { store, persistor };
};

export default configureStore;
