import React, { PureComponent } from 'react';

const iconStyle = {
  cursor: 'pointer',
  stroke: 'none'
};

export default class FlowMeterIcon extends PureComponent {
  render() {
    const { size = 20, onClick, style } = this.props;

    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        id="svg"
        width={size}
        height={size}
        viewBox="0 0 100 100"
        style={{ ...iconStyle, ...style }}
        onClick={onClick}
      >
        <circle cx="50" cy="25" r="22" fill="gray" />
        <circle cx="50" cy="25" r="16" fill="#B0BEC5" />
        <path
          id="donut1"
          d="M38.68629150101523,36.31370849898476 A16,16,0,0,1,38.68629150101524,13.686291501015239 L44.34314575050762,19.34314575050762 A8,8,0,0,0,44.34314575050762,30.65685424949238 z"
          fill="limegreen"
        />
        <path
          id="donut2"
          d="M38.68629150101524,13.686291501015239 A16,16,0,0,1,61.31370849898476,13.68629150101524 L55.65685424949238,19.34314575050762 A8,8,0,0,0,44.34314575050762,19.34314575050762 z"
          fill="gold"
        />
        <path
          id="donut3"
          d="M61.31370849898476,13.68629150101524 A16,16,0,0,1,61.31370849898476,36.31370849898476 L55.65685424949238,30.65685424949238 A8,8,0,0,0,55.65685424949238,19.34314575050762 z"
          fill="#c9361c"
        />
        <rect
          x="33"
          y="41"
          rx="5"
          ry="5"
          width="34"
          height="20"
          fill="#607D8B"
        />
        <rect x="5" y="50" width="80" height="35" fill="#FFB300" />
        <rect x="5" y="45" rx="3" ry="3" width="15" height="45" fill="gold" />
        <rect x="80" y="45" rx="3" ry="3" width="15" height="45" fill="gold" />
        <path d="M30,59 a1,1 0 0,0 40,0" fill="gold" />
        <rect x="30" y="46" width="40" height="15" fill="gold" />
        <polygon
          className="needle"
          points="50,35 47,32 50,10 53,32"
          transform="rotate(15, 50, 30)"
          fill="black"
        />
      </svg>
    );
  }
}
