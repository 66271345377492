import { useMemo } from 'react';

import { seasonService } from '../../../../services';
import { createApiSlice } from '../../utils/createApiSlice';

const addFieldToSeasonSlice = createApiSlice({
  reducerPath: ['gff', 'seasons', 'addFieldToSeason'],
  apiFunction: seasonService.addFieldV2
});

const { actions, useApiSelectors } = addFieldToSeasonSlice;

export const addFieldToSeasonActions = actions;

export const useAddFieldToSeasonSelectors = () => {
  const { inProgress, errorMessage, response } = useApiSelectors();

  return useMemo(
    () => ({
      inProgress,
      errorMessage,
      response
    }),
    [inProgress, errorMessage, response]
  );
};

export default addFieldToSeasonSlice;
