import React from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(() => ({
  loadingIndicator: {
    margin: '2rem',
    display: 'flex',
    justifyContent: 'space-around'
  },
  spinner: {
    width: '3rem',
    height: '3rem'
  }
}));

const LoadingIndicator = () => {
  const styles = useStyles();
  return (
    <div className={styles.loadingIndicator}>
      <CircularProgress className={styles.spinner} />
    </div>
  );
};

export default LoadingIndicator;
