import React, { useCallback, useMemo, useState } from 'react';

import * as d3 from 'd3';
import _ from 'lodash';
import { v4 as uuid } from 'uuid';

import { useTimelineFieldNamesWidth } from '../Charts/_hooks/useTimelineFieldNamesWidth';
import ChartTimeAxis from '../Charts/ChartTimeAxis';
import ChartTodayLine from '../Charts/ChartTodayLine';
import TimelineFieldNames from '../Charts/TimelineFieldNames';
import { parseDate } from '../Growths/GrowthTimeline/_utils/growthTimelineUtils';
import GrowthStagePopover from '../Growths/GrowthTimeline/GrowthStagePopover';
import TechnologyPopover from '../Growths/GrowthTimeline/TechnologyPopover';

import ActivitiesGrowthStagesTimeline from './ActivitiesGrowthStagesTimeline';
import ActivitiesGrowthStagesTimelineLabels from './ActivitiesGrowthStagesTimelineLabels';
import ActivityPopover from './ActivityPopover';
import CounceStagesTimeline from './CounceStagesTimeline';
import FieldsDivider from './FieldsDivider';
import GrowthActivitiesTimeline from './GrowthActivitiesTimeline';
import GrowthActivitiesTimelineLabels from './GrowthActivitiesTimelineLabels';

const timelineBarHeight = 12;
const timelineGap = 6;
const fieldGap = 12;
const scaleMargin = 20;
const scaleHeight = 40;
const todayLineExcess = 10;
const categoriesWidth = 115;
const growthModelTimelineBarHeight = 16;
const growthModelTimelineGap = 10;

const ActivitiesTimelineChart = ({
  timelineActivities,
  containerWidth,
  minDate,
  maxDate,
  rangeSelection,
  showVStages,
  showModifiedDate,
  disableFarmName
}) => {
  const [clipPathId] = useState(`growthTimelineClipPath-${uuid()}`);

  const [hoverParams, setHoverParams] = useState();
  const [growthStageHoverParams, setGrowthStageHoverParams] = useState();
  const [technologyHoverParams, setTechnologyHoverParams] = useState();

  const vStagesHeight = showVStages ? 50 : 0;

  const { fieldNamesWidth } = useTimelineFieldNamesWidth(
    containerWidth,
    disableFarmName
  );

  const timelineActivitiesWithHeights = useMemo(() => {
    let startY = todayLineExcess;
    return _.map(timelineActivities, (growth) => {
      const activitiesTypesCount = _.chain(growth)
        .get('activitiesByType', {})
        .keys()
        .value().length;

      const height =
        vStagesHeight +
        activitiesTypesCount * (timelineBarHeight + timelineGap) +
        growthModelTimelineBarHeight +
        growthModelTimelineGap -
        timelineGap;
      const growthHeight = !!_.get(growth, 'irrigationType')
        ? height <= 50
          ? 50
          : height
        : height <= 25
        ? 25
        : height;
      const res = {
        ...growth,
        height: growthHeight,
        startY
      };

      startY += growthHeight + fieldGap * 2;

      return res;
    });
  }, [timelineActivities, vStagesHeight]);

  const fieldNames = useMemo(() => {
    return _.map(timelineActivitiesWithHeights, (growth) => {
      return {
        id: _.get(growth, 'id'),
        fieldName: _.get(growth, 'fieldName'),
        farmName: _.get(growth, 'farmName'),
        irrigationType: _.get(growth, 'irrigationType'),
        technology: _.get(growth, 'technology'),
        height: _.get(growth, 'height'),
        startY: _.get(growth, 'startY')
      };
    });
  }, [timelineActivitiesWithHeights]);

  const fieldsHeights = useMemo(
    () => _.map(fieldNames, (name) => _.get(name, 'height')),
    [fieldNames]
  );

  const timelinesHeight = useMemo(() => {
    const h = (fieldsHeights.length - 1) * fieldGap * 2 + _.sum(fieldsHeights);
    return h > 0 ? h : 0;
  }, [fieldsHeights]);

  const height = useMemo(
    () => timelinesHeight + 2 * todayLineExcess + scaleMargin + scaleHeight,
    [timelinesHeight]
  );

  const timeScale = useMemo(
    () =>
      d3
        .scaleTime()
        .domain([parseDate(minDate), parseDate(maxDate)])
        .range([fieldNamesWidth + categoriesWidth, containerWidth]),
    [minDate, maxDate, fieldNamesWidth, containerWidth]
  );

  const selectedTimeScale = useMemo(() => {
    if (
      rangeSelection &&
      rangeSelection.length === 2 &&
      !isNaN(rangeSelection[0]) &&
      !isNaN(rangeSelection[1])
    ) {
      return timeScale.copy().domain(rangeSelection);
    }
    return timeScale;
  }, [rangeSelection, timeScale]);

  const handleMouseOver = useCallback((params) => {
    setHoverParams(params);
  }, []);

  const handleMouseOut = useCallback(() => {
    setHoverParams((params) => ({ ...params, element: null }));
  }, []);

  const handleGrowthStageMouseOver = useCallback((params) => {
    setGrowthStageHoverParams(params);
  }, []);

  const handleGrowthStageMouseOut = useCallback(() => {
    setGrowthStageHoverParams((params) => ({ ...params, element: null }));
  }, []);

  const handleTechnologyMouseOver = useCallback((params) => {
    setTechnologyHoverParams(params);
  }, []);

  const handleTechnologyMouseOut = useCallback(() => {
    setTechnologyHoverParams((params) => ({ ...params, element: null }));
  }, []);

  return (
    <>
      <svg width={containerWidth} height={height}>
        <TimelineFieldNames
          fieldNames={fieldNames}
          paddingTop={todayLineExcess}
          barHeight={timelineBarHeight}
          barGap={timelineGap}
          fieldNamesWidth={fieldNamesWidth}
          showTechnology
          onMouseOver={handleTechnologyMouseOver}
          onMouseOut={handleTechnologyMouseOut}
          disableFarmName={disableFarmName}
        />
        <ChartTimeAxis
          paddingTop={height - scaleHeight}
          selectedTimeScale={selectedTimeScale}
        />
        <GrowthActivitiesTimelineLabels
          svgWidth={containerWidth}
          barHeight={timelineBarHeight}
          barGap={timelineGap}
          growthModelTimelineBarHeight={
            vStagesHeight + growthModelTimelineBarHeight
          }
          growthModelTimelineGap={growthModelTimelineGap}
          fieldNamesWidth={fieldNamesWidth}
          timelineActivities={timelineActivitiesWithHeights}
        />
        <ActivitiesGrowthStagesTimelineLabels
          svgWidth={containerWidth}
          paddingTop={vStagesHeight}
          barHeight={growthModelTimelineBarHeight}
          timelineGrowths={timelineActivitiesWithHeights}
          fieldNamesWidth={fieldNamesWidth}
        />
        <g clipPath={`url(#${clipPathId})`}>
          <GrowthActivitiesTimeline
            svgWidth={containerWidth}
            barHeight={timelineBarHeight}
            barGap={timelineGap}
            rectangleRadius={3}
            growthModelTimelineBarHeight={
              vStagesHeight + growthModelTimelineBarHeight
            }
            growthModelTimelineGap={growthModelTimelineGap}
            timelineActivities={timelineActivitiesWithHeights}
            selectedTimeScale={selectedTimeScale}
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
          />
          <ActivitiesGrowthStagesTimeline
            svgWidth={containerWidth}
            paddingTop={vStagesHeight}
            barHeight={growthModelTimelineBarHeight}
            barGap={growthModelTimelineGap}
            rectangleRadius={3}
            timelineGrowths={timelineActivitiesWithHeights}
            selectedTimeScale={selectedTimeScale}
            onMouseOver={handleGrowthStageMouseOver}
            onMouseOut={handleGrowthStageMouseOut}
          />
          {vStagesHeight > 0 && (
            <CounceStagesTimeline
              svgWidth={containerWidth}
              height={vStagesHeight}
              paddingTop={0}
              timelineActivities={timelineActivitiesWithHeights}
              selectedTimeScale={selectedTimeScale}
            />
          )}
          <ChartTodayLine
            paddingTop={0}
            height={timelinesHeight + 2 * todayLineExcess}
            selectedTimeScale={selectedTimeScale}
            lineWidth={2}
            roundToDay
          />
        </g>
        <FieldsDivider
          svgWidth={containerWidth}
          fields={fieldNames}
          fieldGap={fieldGap}
        />

        <clipPath id={clipPathId}>
          <rect
            x={fieldNamesWidth + categoriesWidth}
            y={0}
            width={containerWidth - fieldNamesWidth - categoriesWidth}
            height={timelinesHeight + 2 * todayLineExcess}
          />
        </clipPath>
      </svg>
      <ActivityPopover hoverParams={hoverParams} />
      <GrowthStagePopover
        hoverParams={growthStageHoverParams}
        showModifiedDate={showModifiedDate}
      />
      <TechnologyPopover hoverParams={technologyHoverParams} />
    </>
  );
};

export default ActivitiesTimelineChart;
