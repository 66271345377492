import addUserFeedbackSlice from './addUserFeedbackSlice';
import changeUserFeedbackSlice from './changeUserFeedbackStatusSlice';
import getUsersFeedbackSlice from './getUsersFeedbackSlice';

const feedbackSlices = [
  addUserFeedbackSlice,
  getUsersFeedbackSlice,
  changeUserFeedbackSlice
];

export default feedbackSlices;
