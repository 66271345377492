import { useMemo } from 'react';

import _ from 'lodash';

import { seasonService } from '../../../../services';
import { createApiSlice } from '../../utils/createApiSlice';

const getSeasonsSlice = createApiSlice({
  reducerPath: ['gff', 'seasons', 'seasons'],
  apiFunction: seasonService.getSeasonsV2,
  getStateUpdatePath: (action) => {
    const growerId = _.first(_.get(action, 'payload.apiArguments'));
    return [growerId];
  },
  getSelectorPath: (growerId) => {
    return [growerId];
  },
  responseMutationFn: (response) => {
    return _.chain(response)
      .map((season) => ({
        ...season,
        farms: _.chain(season)
          .get('farms')
          .map((farm) => ({
            ...farm,
            growerId: _.get(season, 'growerId'),
            seasonId: _.get(season, 'id'),
            fields: _.chain(farm)
              .get('fields')
              .map((field) => ({
                ...field,
                growerId: _.get(season, 'growerId'),
                seasonId: _.get(season, 'id'),
                farmId: _.get(farm, 'id')
              }))
              .keyBy((field) => _.get(field, 'id'))
              .value()
          }))
          .keyBy((farm) => _.get(farm, 'id'))
          .value()
      }))
      .keyBy((season) => _.get(season, 'id'))
      .value();
  }
});

const { actions, selectors, useApiSelectors, createResponseSelector } =
  getSeasonsSlice;

export const getSeasonsActions = actions;

export const getSeasonsSelectors = {
  ...selectors,
  season: (growerId, seasonId) => createResponseSelector([seasonId], growerId),
  farms: (growerId, seasonId) =>
    createResponseSelector([seasonId, 'farms'], growerId),
  farm: (growerId, seasonId, farmId) =>
    createResponseSelector([seasonId, 'farms', farmId], growerId),
  fields: (growerId, seasonId, farmId) =>
    createResponseSelector([seasonId, 'farms', farmId, 'fields'], growerId),
  field: (growerId, seasonId, farmId, fieldId) =>
    createResponseSelector(
      [seasonId, 'farms', farmId, 'fields', fieldId],
      growerId
    )
};

export const useGetSeasonsSelectors = (growerId) => {
  const {
    inProgress,
    errorMessage,
    success,
    response: seasons
  } = useApiSelectors(growerId);

  return useMemo(
    () => ({
      seasons,
      inProgress,
      errorMessage,
      success
    }),
    [errorMessage, inProgress, seasons, success]
  );
};

export default getSeasonsSlice;
