import React, { useCallback, useContext, useMemo, useState } from 'react';

import { Accordion } from '@material-ui/core';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import EditIcon from '@material-ui/icons/Edit';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import _ from 'lodash';
import moment from 'moment';
import { useDispatch } from 'react-redux';

import { growthStagesTypes } from '../../../_constants/growthsConstants';
import { AuthContext } from '../../../_context/AuthContext';
import { FarmStructureContext } from '../../../_context/FarmStructureContext';
import { useActiveGrowth } from '../../../_hooks/Growths/useActiveGrowth';
import { openModifyGrowthDialog } from '../../../_store/actions/uiActions';
import { useAllGrowerFieldsSelector } from '../../../_store/selectors/fieldsSelectors';
import { AccordionDataRow } from '../../../Components/Configuration/AccordionDataRow';
import FieldDetails from '../../../Components/Growths/FieldDetails';
import GrowthStageBadge from '../../../Components/Growths/GrowthStageBadge';
import SmallGrowthStageBadge from '../../../Components/Growths/SmallGrowthStageBadge';

import Variety from './Variety';

const useContentStyles = makeStyles(() => ({
  growthBadge: {
    marginTop: '0.25rem',
    marginBottom: '0.25rem'
  },
  editButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  seedProduct: {
    display: 'flex',
    justifyContent: 'flex-end'
  }
}));

const AccordionContent = ({ field }) => {
  const styles = useContentStyles();
  const dispatch = useDispatch();

  const { isUserView } = useContext(AuthContext);
  const { isHistoricalSeason } = useContext(FarmStructureContext);
  const showBadge = !useMediaQuery((theme) => theme.breakpoints.up('sm'));

  const growerId = useMemo(() => _.get(field, 'growerId'), [field]);
  const seasonId = useMemo(() => _.get(field, 'seasonId'), [field]);
  const farmId = useMemo(() => _.get(field, 'farmId'), [field]);
  const fieldId = useMemo(() => _.get(field, 'id'), [field]);

  const { activeGrowth } = useActiveGrowth(field);
  const growthId = useMemo(() => _.get(activeGrowth, 'id'), [activeGrowth]);

  const varietyId = _.get(activeGrowth, 'varietyId');

  const plantingDate = useMemo(() => {
    const date = _.get(activeGrowth, 'plantingDate');
    return !!date ? moment(date).format('ll') : null;
  }, [activeGrowth]);

  const growthStage = useMemo(
    () =>
      _.get(
        activeGrowth,
        'estimatedCurrentGrowthStage',
        growthStagesTypes.Unknown
      ),
    [activeGrowth]
  );

  const stageObserved = useMemo(
    () => _.get(activeGrowth, 'stageObserved', false),
    [activeGrowth]
  );
  const stagePending = useMemo(
    () => _.get(activeGrowth, 'stagePending', false),
    [activeGrowth]
  );

  const handleEditButtonClick = useCallback(() => {
    dispatch(
      openModifyGrowthDialog(growerId, seasonId, farmId, fieldId, growthId)
    );
  }, [dispatch, farmId, fieldId, growerId, growthId, seasonId]);

  return (
    <Grid container spacing={2}>
      {!isUserView && growthId && !isHistoricalSeason && (
        <Grid item xs={12} className={styles.editButtonContainer}>
          <IconButton size="small" onClick={handleEditButtonClick}>
            <EditIcon />
          </IconButton>
        </Grid>
      )}
      {!!varietyId && (
        <Grid item xs={12}>
          <AccordionDataRow title="Seed Product" inline>
            <Box className={styles.seedProduct}>
              <Variety varietyId={varietyId} />
            </Box>
          </AccordionDataRow>
        </Grid>
      )}
      {plantingDate && (
        <Grid item xs={12}>
          <AccordionDataRow title="Planting Date" text={plantingDate} inline />
        </Grid>
      )}
      {showBadge && (
        <Grid item xs={12}>
          <AccordionDataRow title="Growth Stage">
            <Box className={styles.growthBadge}>
              <GrowthStageBadge
                growthStage={growthStage}
                stageObserved={stageObserved}
                stagePending={stagePending}
              />
            </Box>
          </AccordionDataRow>
        </Grid>
      )}
      <Grid item xs={12}>
        <FieldDetails field={field} />
      </Grid>
    </Grid>
  );
};

const useHeaderStyles = makeStyles(() => ({
  header: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  headerBadge: {
    marginLeft: '0.25rem'
  }
}));

const AccordionHeader = ({ field }) => {
  const styles = useHeaderStyles();

  const showLargeBadge = useMediaQuery((theme) => theme.breakpoints.up('sm'));

  const { activeGrowth } = useActiveGrowth(field);

  const growthStage = useMemo(
    () =>
      _.get(
        activeGrowth,
        'estimatedCurrentGrowthStage',
        growthStagesTypes.Unknown
      ),
    [activeGrowth]
  );

  const stageObserved = useMemo(
    () => _.get(activeGrowth, 'stageObserved', false),
    [activeGrowth]
  );
  const stagePending = useMemo(
    () => _.get(activeGrowth, 'stagePending', false),
    [activeGrowth]
  );

  return (
    <Box className={styles.header}>
      <Typography>{_.get(field, 'name')}</Typography>
      <Box className={styles.headerBadge}>
        {showLargeBadge && (
          <GrowthStageBadge
            growthStage={growthStage}
            stageObserved={stageObserved}
            stagePending={stagePending}
          />
        )}
        {!showLargeBadge && (
          <SmallGrowthStageBadge
            growthStage={growthStage}
            stageObserved={stageObserved}
          />
        )}
      </Box>
    </Box>
  );
};

const FieldAccordion = ({ field }) => {
  const [expanded, setExpanded] = useState(false);

  const handleToggle = useCallback(() => {
    setExpanded((val) => !val);
  }, []);

  return (
    <Accordion expanded={expanded} onChange={handleToggle}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        id={`${_.get(field, 'id')}-header`}
      >
        <AccordionHeader field={field} />
      </AccordionSummary>
      <AccordionDetails>
        {expanded && <AccordionContent field={field} />}
      </AccordionDetails>
    </Accordion>
  );
};

const FieldsAccordion = () => {
  const { fields } = useAllGrowerFieldsSelector();

  return (
    <>
      {_.map(fields, (field) => (
        <FieldAccordion key={_.get(field, 'id')} field={field} />
      ))}
    </>
  );
};

export default FieldsAccordion;
