import React, { useCallback, useContext, useMemo } from 'react';

import { MenuItem, Select } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import _ from 'lodash';

import {
  LOCATION_SOURCES,
  WeatherContext
} from '../../../_context/WeatherContext';
import LastUpdatedHeaderDetails from '../../../Components/LastUpdatedHeaderDetails';
import ResponsiveToggleButtons from '../../../Components/ResponsiveToggleButtons';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      justifyContent: 'center'
    }
  },
  buttonGroup: {
    flexGrow: 0,
    marginLeft: '1rem',
    [theme.breakpoints.down('md')]: {
      marginLeft: '0',
      marginTop: '0.5rem'
    }
  },
  weatherStationSelect: {
    minWidth: '10rem',
    marginLeft: '1rem',
    [theme.breakpoints.down('md')]: {
      marginLeft: '0',
      marginTop: '0.5rem'
    }
  }
}));

const locationSourceSelectorItems = [
  { id: LOCATION_SOURCES.WEATHER_STATION, name: 'Weather Station' },
  { id: LOCATION_SOURCES.FIELD, name: 'Selected Field' },
  { id: LOCATION_SOURCES.BROWSER_LOCATION, name: 'Current Location' }
];

const TitleHeaderDetails = () => {
  const styles = useStyles();

  const {
    updatedAt,
    forceUpdate,
    locationSource,
    setLocationSource,
    inProgress,
    weatherStations,
    selectedWeatherStation,
    selectWeatherStation
  } = useContext(WeatherContext);

  const hasWeatherStations = !_.isEmpty(weatherStations);
  const hasGeolocation = !!navigator.geolocation;

  const availableLocationSources = useMemo(
    () =>
      _.filter(locationSourceSelectorItems, (item) => {
        const id = _.get(item, 'id');
        switch (id) {
          case LOCATION_SOURCES.WEATHER_STATION:
            return hasWeatherStations;
          case LOCATION_SOURCES.BROWSER_LOCATION:
            return hasGeolocation;
          case LOCATION_SOURCES.FIELD:
            return true;
          default:
            return false;
        }
      }),
    [hasGeolocation, hasWeatherStations]
  );

  const handleRefresh = useCallback(async () => {
    await forceUpdate();
  }, [forceUpdate]);

  const handleWeatherStationChange = useCallback(
    (e) => {
      selectWeatherStation(e.target.value);
    },
    [selectWeatherStation]
  );

  const handleSelectWeatherLocation = useCallback(
    (locationId) => {
      setLocationSource(locationId);
    },
    [setLocationSource]
  );

  return (
    <div className={styles.container}>
      <LastUpdatedHeaderDetails
        updatedAt={updatedAt}
        inProgress={inProgress}
        onRefresh={handleRefresh}
      />
      {hasWeatherStations &&
        locationSource === LOCATION_SOURCES.WEATHER_STATION && (
          <Select
            className={styles.weatherStationSelect}
            value={_.get(selectedWeatherStation, 'id')}
            onChange={handleWeatherStationChange}
          >
            {_.map(weatherStations, (station) => (
              <MenuItem key={_.get(station, 'id')} value={_.get(station, 'id')}>
                {_.get(station, 'name')}
              </MenuItem>
            ))}
          </Select>
        )}
      {(navigator.geolocation || hasWeatherStations) && (
        <ResponsiveToggleButtons
          className={styles.buttonGroup}
          items={availableLocationSources}
          onSelect={handleSelectWeatherLocation}
          selectedItemId={locationSource}
          size="small"
          variant="contained"
          disableElevation
          dropdownBreakpoint="sm"
        />
      )}
    </div>
  );
};

export default TitleHeaderDetails;
