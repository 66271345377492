import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import cx from 'classnames';

import { useMenuState } from '../../_hooks/ui/useMenuState';

import Menu from './Components/Menu';

const useStyles = makeStyles(() => ({
  heading: {
    textTransform: 'uppercase',
    fontSize: '0.8rem',
    margin: '0.75rem 0',
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
    position: 'relative',
    color: 'rgba(255, 255, 255, 0.6)',
    transition: 'opacity .3s ease',
    opacity: '1'
  },
  hideHeading: {
    opacity: '0'
  }
}));

const MenuSection = ({ title, menuItems, collapsedSideMenuHover }) => {
  const styles = useStyles();
  const { collapsedSideMenu } = useMenuState();

  return (
    <>
      <Typography
        variant="h5"
        className={cx(styles.heading, {
          [styles.hideHeading]: collapsedSideMenu && !collapsedSideMenuHover
        })}
      >
        {title}
      </Typography>
      <Menu
        menuItems={menuItems}
        collapsedSideMenuHover={collapsedSideMenuHover}
      />
    </>
  );
};

export default MenuSection;
