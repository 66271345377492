import { useMemo } from 'react';

import { configService } from '../../../services';
import { createApiSlice } from '../utils/createApiSlice';

const environmentSlice = createApiSlice({
  reducerPath: ['newConfig', 'environment'],
  apiFunction: configService.getEnvironmentV2,
  authenticate: false,
  apiArgumentsFn: () => []
});

const { actions, useApiSelectors } = environmentSlice;

export const environmentActions = actions;

export const useEnvironmentSelectors = () => {
  const { inProgress, errorMessage, success, response } = useApiSelectors();

  return useMemo(
    () => ({
      inProgress,
      errorMessage,
      success,
      response
    }),
    [errorMessage, inProgress, response, success]
  );
};

export default environmentSlice;
