import { useMemo } from 'react';

import { useSelector } from 'react-redux';

import { createDataSlice } from '../../utils/createDataSlice';

const shapefileUploadDialogSlice = createDataSlice({
  reducerPath: ['ui', 'dialogs', 'shapefileUpload'],
  initialState: { showDialog: false },
  reducers: {
    toggle(state) {
      state.open = !state.open;
    }
  }
});

const { actions, baseSelector: shapefileUploadDialogSelector } =
  shapefileUploadDialogSlice;

export const shapefileUploadDialogActions = actions;

export const useShapefileUploadDialogSelectors = () => {
  const { open } = useSelector(shapefileUploadDialogSelector);

  return useMemo(
    () => ({
      open: !!open
    }),
    [open]
  );
};

export default shapefileUploadDialogSlice;
