import React, { useCallback } from 'react';

import { Tooltip } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { saveAs } from 'file-saver';

const ChartDownloadButton = ({
  chartRef,
  fileName = 'chart.png',
  iconButton,
  ...props
}) => {
  const handleClick = useCallback(() => {
    if (
      chartRef &&
      chartRef.current &&
      chartRef.current.canvas &&
      chartRef.current.canvas.toBlob
    ) {
      chartRef.current.canvas.toBlob((blob) => saveAs(blob, fileName));
    }
  }, [chartRef, fileName]);

  return (
    <>
      {!iconButton && (
        <Button
          startIcon={<CloudDownloadIcon />}
          onClick={handleClick}
          {...props}
        >
          Download
        </Button>
      )}
      {!!iconButton && (
        <Tooltip title="Download Chart">
          <IconButton onClick={handleClick} {...props}>
            <CloudDownloadIcon />
          </IconButton>
        </Tooltip>
      )}
    </>
  );
};

export default ChartDownloadButton;
