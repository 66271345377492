import addSeasonDeviceSlice from './addSeasonDeviceSlice';
import removeSeasonDeviceSlice from './removeSeasonDeviceSlice';
import selectedPumpSlice from './selectedPumpsSlice';
import updateSeasonDeviceSlice from './updateSeasonDeviceSlice';

const seasonDevicesSlices = [
  addSeasonDeviceSlice,
  removeSeasonDeviceSlice,
  updateSeasonDeviceSlice,
  selectedPumpSlice
];

export default seasonDevicesSlices;
