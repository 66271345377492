import { addToken, client } from './client';

export const growthService = {
  getActiveGrowthV2,
  createGrowthV2,
  modifyGrowthV2,
  deleteGrowthV2,
  addGrowthFeedbackV2,
  updateGrowthFeedbackV2,
  deleteGrowthFeedbackV2,
  setTargetMoistureV2,
  addMoistureSampleV2,
  updateMoistureSampleV2,
  deleteMoistureSampleV2
};

async function getActiveGrowthV2(token, growerId, seasonId, farmId, fieldId) {
  const response = await client.get(
    `/v2/growers/${growerId}/seasons/${seasonId}/farms/${farmId}/fields/${fieldId}/growths/active`,
    addToken(token)
  );
  return response.data;
}

async function modifyGrowthV2(
  token,
  growerId,
  seasonId,
  farmId,
  fieldId,
  growthId,
  growth
) {
  const response = await client.put(
    `/v2/growers/${growerId}/seasons/${seasonId}/farms/${farmId}/fields/${fieldId}/growths/${growthId}`,
    growth,
    addToken(token)
  );
  return response.data;
}

async function deleteGrowthV2(
  token,
  growerId,
  seasonId,
  farmId,
  fieldId,
  growthId
) {
  const response = await client.delete(
    `/v2/growers/${growerId}/seasons/${seasonId}/farms/${farmId}/fields/${fieldId}/growths/${growthId}`,
    addToken(token)
  );
  return response.data;
}

async function createGrowthV2(
  token,
  growerId,
  seasonId,
  farmId,
  fieldId,
  growth
) {
  const response = await client.post(
    `/v2/growers/${growerId}/seasons/${seasonId}/farms/${farmId}/fields/${fieldId}/growths`,
    growth,
    addToken(token)
  );
  return response.data;
}

async function addGrowthFeedbackV2(
  token,
  growerId,
  seasonId,
  farmId,
  fieldId,
  growthId,
  feedback
) {
  const response = await client.post(
    `/v2/growers/${growerId}/seasons/${seasonId}/farms/${farmId}/fields/${fieldId}/growths/${growthId}/feedback`,
    feedback,
    addToken(token)
  );
  return response.data;
}

async function updateGrowthFeedbackV2(
  token,
  growerId,
  seasonId,
  farmId,
  fieldId,
  growthId,
  feedbackId,
  feedback
) {
  const response = await client.put(
    `/v2/growers/${growerId}/seasons/${seasonId}/farms/${farmId}/fields/${fieldId}/growths/${growthId}/feedback/${feedbackId}`,
    feedback,
    addToken(token)
  );
  return response.data;
}

async function deleteGrowthFeedbackV2(
  token,
  growerId,
  seasonId,
  farmId,
  fieldId,
  growthId,
  feedbackId
) {
  const response = await client.delete(
    `/v2/growers/${growerId}/seasons/${seasonId}/farms/${farmId}/fields/${fieldId}/growths/${growthId}/feedback/${feedbackId}`,
    addToken(token)
  );
  return response.data;
}

async function setTargetMoistureV2(
  token,
  growerId,
  seasonId,
  farmId,
  fieldId,
  growthId,
  targetMoisture
) {
  const response = await client.post(
    `/v2/growers/${growerId}/seasons/${seasonId}/farms/${farmId}/fields/${fieldId}/growths/${growthId}/grain-moisture`,
    targetMoisture,
    addToken(token)
  );
  return response.data;
}

async function addMoistureSampleV2(
  token,
  growerId,
  seasonId,
  farmId,
  fieldId,
  growthId,
  moistureSample
) {
  const response = await client.post(
    `/v2/growers/${growerId}/seasons/${seasonId}/farms/${farmId}/fields/${fieldId}/growths/${growthId}/moisture-samples`,
    moistureSample,
    addToken(token)
  );
  return response.data;
}

async function updateMoistureSampleV2(
  token,
  growerId,
  seasonId,
  farmId,
  fieldId,
  growthId,
  sampleId,
  moistureSample
) {
  const response = await client.patch(
    `/v2/growers/${growerId}/seasons/${seasonId}/farms/${farmId}/fields/${fieldId}/growths/${growthId}/moisture-samples/${sampleId}`,
    moistureSample,
    addToken(token)
  );
  return response.data;
}

async function deleteMoistureSampleV2(
  token,
  growerId,
  seasonId,
  farmId,
  fieldId,
  growthId,
  sampleId
) {
  const response = await client.delete(
    `/v2/growers/${growerId}/seasons/${seasonId}/farms/${farmId}/fields/${fieldId}/growths/${growthId}/moisture-samples/${sampleId}`,
    addToken(token)
  );
  return response.data;
}
