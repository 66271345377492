import { useMemo } from 'react';

import { useSelector } from 'react-redux';

import { requestStatus } from '../helpers/requestStatus';
import { getSeasonsSelectors } from '../slices/gff/seasons/getSeasonsSlice';
import { getSeasonWithGrowthsSelectors } from '../slices/gff/seasons/getSeasonsWithGrowthsSlice';

export const useSeasonSelector = (growerId, seasonId) => {
  const season = useSelector(getSeasonsSelectors.season(growerId, seasonId));

  return useMemo(() => ({ season }), [season]);
};

export const useSeasonWithGrowthsSelector = (growerId, seasonId) => {
  const status = useSelector(
    getSeasonWithGrowthsSelectors.status(growerId, seasonId)
  );

  const inProgress = status === requestStatus.IN_PROGRESS;

  return useMemo(() => ({ inProgress }), [inProgress]);
};
