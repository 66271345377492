import React, {
  forwardRef,
  useCallback,
  useMemo,
  useRef,
  useState
} from 'react';

import { ButtonGroup, Menu, MenuItem } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import cx from 'classnames';
import _ from 'lodash';

const ToggleButton = ({
  itemId,
  selected,
  selectedColor,
  color,
  children,
  onClick,
  ...props
}) => {
  const handleClick = useCallback(() => {
    onClick(itemId);
  }, [itemId, onClick]);

  return (
    <Button
      onClick={handleClick}
      color={selected ? selectedColor : color}
      {...props}
    >
      {children}
    </Button>
  );
};

const DropdownMenuItem = forwardRef(({ itemId, onClick, children }, ref) => {
  const handleClick = useCallback(() => {
    onClick(itemId);
  }, [itemId, onClick]);

  return (
    <MenuItem onClick={handleClick} dense ref={ref}>
      {children}
    </MenuItem>
  );
});

const ResponsiveToggleButtons = ({
  items,
  onSelect,
  selectedItemId,
  className,
  dropdownBreakpoint = 'md',
  selectedColor = 'primary',
  ...props
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const anchorRef = useRef();

  const showGroup = useMediaQuery((theme) =>
    theme.breakpoints.up(dropdownBreakpoint)
  );

  const selectedItem = useMemo(
    () => _.find(items, (item) => _.get(item, 'id') === selectedItemId),
    [items, selectedItemId]
  );

  const handleDropdownClose = useCallback(() => {
    setDropdownOpen(false);
  }, []);

  const handleSelect = useCallback(
    (itemId) => {
      onSelect(itemId);
      handleDropdownClose();
    },
    [handleDropdownClose, onSelect]
  );

  const toggleDropdown = useCallback(() => {
    setDropdownOpen((state) => !state);
  }, []);

  return (
    <>
      {showGroup && (
        <ButtonGroup className={cx(className)} {...props}>
          {_.map(items, (item) => (
            <ToggleButton
              key={_.get(item, 'id')}
              itemId={_.get(item, 'id')}
              selected={selectedItemId === _.get(item, 'id')}
              selectedColor={selectedColor}
              onClick={handleSelect}
            >
              {_.get(item, 'name')}
            </ToggleButton>
          ))}
        </ButtonGroup>
      )}
      {!showGroup && (
        <>
          <Button
            {...props}
            className={cx(className)}
            endIcon={<ArrowDropDownIcon />}
            color={selectedColor}
            onClick={toggleDropdown}
            ref={anchorRef}
          >
            {_.get(selectedItem, 'name')}
          </Button>
          <Menu
            anchorEl={anchorRef.current}
            open={dropdownOpen}
            onClose={handleDropdownClose}
            keepMounted
          >
            {_.map(items, (item) => (
              <DropdownMenuItem
                key={_.get(item, 'id')}
                itemId={_.get(item, 'id')}
                onClick={handleSelect}
              >
                {_.get(item, 'name')}
              </DropdownMenuItem>
            ))}
          </Menu>
        </>
      )}
    </>
  );
};

export default ResponsiveToggleButtons;
