import React, { useEffect, useRef } from 'react';

import * as d3 from 'd3';

import { parseDate } from '../Growths/GrowthTimeline/_utils/growthTimelineUtils';

const GrainMoistureLineChart = ({
  yScale,
  data,
  timeScale,
  valueSelector,
  color,
  lineWidth = 1
}) => {
  const chartPath = useRef();

  useEffect(() => {
    if (!chartPath.current || !data) {
      return;
    }
    const path = d3.select(chartPath.current);

    const line = d3
      .line()
      .curve(d3.curveBasis)
      .defined((d) => !isNaN(valueSelector(d)))
      .x((d) => timeScale(parseDate(d.date)))
      .y((d) => yScale(valueSelector(d)));

    path
      .datum(data)
      .attr('fill', 'none')
      .attr('stroke', color)
      .attr('stroke-width', lineWidth)
      .attr('stroke-linejoin', 'round')
      .attr('stroke-linecap', 'round')
      .attr('d', line);
  }, [color, data, lineWidth, timeScale, valueSelector, yScale]);

  return <path ref={chartPath} />;
};

export default GrainMoistureLineChart;
