import React from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormHelperText from '@material-ui/core/FormHelperText';

import ResponsiveDialogContent from '../../Components/ResponsiveDialogContent';

const DeleteDialog = ({
  open,
  title,
  text,
  onCancel,
  onDelete,
  inProgress,
  error
}) => {
  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <ResponsiveDialogContent>
        <DialogContentText id="alert-dialog-description">
          {text}
        </DialogContentText>
        {error && <FormHelperText error>{error}</FormHelperText>}
      </ResponsiveDialogContent>
      <DialogActions>
        <Button
          onClick={onCancel}
          color="primary"
          autoFocus
          disabled={inProgress}
        >
          Cancel
        </Button>
        <Button onClick={onDelete} color="primary" disabled={inProgress}>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteDialog;
