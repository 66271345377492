import React from 'react';

import _ from 'lodash';

import { useGrowthStagesConfig } from '../../_store/selectors/configSelectors';

import CustomBadge from './CustomBadge';
import { faObservedGrowthStageIcon } from './faObservedGrowthStageIcon';
import { faPendingGrowthStageIcon } from './faPendingGrowthStageIcon';

const GrowthStageBadge = ({
  className,
  growthStage,
  small = false,
  stageObserved,
  stagePending
}) => {
  const { growthStagesConfig } = useGrowthStagesConfig();

  const backgroundColor = _.get(growthStagesConfig, [
    growthStage,
    'outlineColor'
  ]);
  const textColor = _.get(growthStagesConfig, [growthStage, 'textColor']);
  const growthStageName = _.get(growthStagesConfig, [growthStage, 'name']);

  return (
    <CustomBadge
      className={className}
      backgroundColor={backgroundColor}
      textColor={textColor}
      title={growthStageName}
      small={small}
      faIcon={
        !!stagePending
          ? faPendingGrowthStageIcon
          : !!stageObserved
          ? faObservedGrowthStageIcon
          : undefined
      }
      iconTooltip={
        stagePending ? 'Update Pending' : stageObserved ? 'Observed' : ''
      }
    />
  );
};

export default GrowthStageBadge;
