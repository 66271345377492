import { useMemo } from 'react';

import _ from 'lodash';

import { useReportsGrowersWithSelection } from './useReportsGrowersWithSelection';

export function useSeasonSelectedFields() {
  const { growers } = useReportsGrowersWithSelection();

  const fieldsChecked = useMemo(
    () =>
      _.chain(growers)
        .filter((grower) => !!_.get(grower, 'seasonId'))
        .keyBy((grower) => _.get(grower, 'id'))
        .mapValues((grower) => ({
          [_.get(grower, 'seasonId')]: _.chain(grower)
            .get('farms')
            .keyBy((farm) => _.get(farm, 'id'))
            .mapValues((farm) =>
              _.chain(farm)
                .get('fields')
                .keyBy((field) => _.get(field, 'id'))
                .mapValues((field) => _.get(field, 'checked'))
                .value()
            )
            .value()
        }))
        .value(),
    [growers]
  );

  return useMemo(() => ({ fieldsChecked }), [fieldsChecked]);
}
