import React from 'react';

import { Chip } from '@material-ui/core';
import _ from 'lodash';

import { useEnvironmentSelectors } from '../../../_store/slices/config/environmentSlice';

const EnvironmentNameBadge = ({ className, size = 'small', ...props }) => {
  const { response: environmentResponse } = useEnvironmentSelectors();

  const environmentName = _.get(environmentResponse, 'name');

  return (
    <>
      {!_.isEmpty(environmentName) && (
        <Chip
          {...props}
          className={className}
          label={`${environmentName} Environment`}
          size={size}
        />
      )}
      {_.isEmpty(environmentName) && <div />}
    </>
  );
};

export default EnvironmentNameBadge;
