import { useMemo } from 'react';

import _ from 'lodash';

import { growerDevicesVendors } from '../../_constants/growerDevicesConstants';
import { useSeasonSelector } from '../../_store/selectors/seasonsSelectors';

export function useWeatherStations(growerId, seasonId) {
  const { season } = useSeasonSelector(growerId, seasonId);

  const weatherStations = useMemo(
    () =>
      _.chain(season)
        .get('devices')
        .filter(
          (device) =>
            _.get(device, 'vendor') === growerDevicesVendors.Ambient.type
        )
        .value(),
    [season]
  );

  return { weatherStations };
}
