import React, { useMemo } from 'react';

import _ from 'lodash';

import TimelineRectangle from '../Charts/TimelineRectangle';
import { parseDate } from '../Growths/GrowthTimeline/_utils/growthTimelineUtils';

const ActivitiesGrowthStagesTimeline = ({
  svgWidth,
  barHeight,
  paddingTop,
  rectangleRadius,
  timelineGrowths,
  selectedTimeScale,
  onMouseOver = _.noop,
  onMouseOut = _.noop,
  onClick = _.noop
}) => {
  const growthRectangles = useMemo(() => {
    return _.map(timelineGrowths, (growth) => {
      const growthId = _.get(growth, 'id');
      const startY = _.get(growth, 'startY');
      const rectangles = _.chain(growth)
        .get('stages')
        .map((stage) => {
          const x0 = selectedTimeScale(parseDate(stage.startDate));
          const rectWidth =
            selectedTimeScale(parseDate(stage.endDate)) -
            selectedTimeScale(parseDate(stage.startDate));
          const x1 = x0 + rectWidth;

          const rangeX0 = selectedTimeScale.range()[0];
          const rangeX1 = selectedTimeScale.range()[1];

          const visibleX0 = x0 < rangeX0 ? rangeX0 : x0;
          const visibleX1 = x1 > rangeX1 ? rangeX1 : x1;
          const visibleMidX =
            x0 >= rangeX0
              ? (visibleX1 - visibleX0) / 2
              : rangeX0 - x0 + (visibleX1 - visibleX0) / 2;

          const y = startY + paddingTop;

          const height = barHeight;
          const color = stage.backgroundColor;
          const textColor = stage.textColor;
          return {
            growthId,
            id: `${growthId}-${stage.growthStage}`,
            x: x0,
            y,
            width: rectWidth,
            height,
            color,
            textColor,
            startDate: stage.startDate,
            endDate: stage.endDate,
            growthStage: stage.growthStage,
            observed: stage.observed,
            dateModified: stage.dateModified,
            visibleMidX
          };
        })
        .filter((rectangle) => rectangle.width > 0)
        .value();
      return { growthId, rectangles };
    });
  }, [barHeight, paddingTop, selectedTimeScale, timelineGrowths]);

  return (
    <g>
      {svgWidth > 0 && (
        <>
          {_.map(growthRectangles, (growth) => (
            <g key={_.get(growth, 'growthId')}>
              {_.map(_.get(growth, 'rectangles'), (rectangle) => (
                <TimelineRectangle
                  key={_.get(rectangle, 'id')}
                  rectangleRadius={rectangleRadius}
                  rectangle={rectangle}
                  onMouseOver={onMouseOver}
                  onMouseOut={onMouseOut}
                  onClick={onClick}
                />
              ))}
            </g>
          ))}
        </>
      )}
    </g>
  );
};

export default ActivitiesGrowthStagesTimeline;
