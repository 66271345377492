import React, { useCallback, useContext, useState } from 'react';

import { DialogTitle } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import _ from 'lodash';
import { useForm } from 'react-hook-form';

import { UserContext } from '../../../_context/UserContext';
import { toaster, toastSuccess } from '../../../_utilities/toast-utils';
import ResponsiveDialog from '../../../Components/ResponsiveDialog';
import ResponsiveDialogContent from '../../../Components/ResponsiveDialogContent';

const ChangePasswordDialog = ({ isOpen, toggle }) => {
  const { changePassword } = useContext(UserContext);
  const [inProgress, setInProgress] = useState(false);
  const [submitError, setSubmitError] = useState();

  const { register, handleSubmit, errors, watch } = useForm();

  const password = watch('password');

  const successToast = useCallback(
    () => toaster('Password has been changed!', toastSuccess),
    []
  );

  const handleFormSubmit = useCallback(
    async (formData) => {
      setSubmitError(null);
      setInProgress(true);
      try {
        await changePassword(formData);
        toggle();
        successToast();
      } catch (e) {
        if (_.get(e, 'response.data.message') === 'PasswordNotValid') {
          setSubmitError('Invalid password.');
        } else {
          setSubmitError('Something went wrong.');
        }
      }
      setInProgress(false);
    },
    [changePassword, successToast, toggle]
  );

  return (
    <ResponsiveDialog open={isOpen} maxWidth="xs">
      <form noValidate onSubmit={handleSubmit(handleFormSubmit)}>
        <DialogTitle>Change password</DialogTitle>
        <ResponsiveDialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                inputRef={register({
                  required: 'Current Password is required'
                })}
                variant="outlined"
                required
                fullWidth
                id="existingPassword"
                label="Current Password"
                name="existingPassword"
                error={!!errors.existingPassword}
                helperText={_.get(errors, 'existingPassword.message')}
                autoComplete="current-password"
                type="password"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                inputRef={register({
                  required: 'New Password is required',
                  minLength: {
                    value: 5,
                    message: 'Min length is 5'
                  }
                })}
                variant="outlined"
                required
                fullWidth
                id="password"
                label="New Password"
                name="password"
                error={!!errors.password}
                helperText={_.get(errors, 'password.message')}
                type="password"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                inputRef={register({
                  required: 'Confirm Password is required',
                  validate: (value) =>
                    value === password ||
                    'New Password and Confirm Password do not match',
                  minLength: {
                    value: 5,
                    message: 'Min length is 5'
                  }
                })}
                variant="outlined"
                required
                fullWidth
                id="confirmPassword"
                label="Confirm Password"
                name="confirmPassword"
                error={!!errors.confirmPassword}
                helperText={_.get(errors, 'confirmPassword.message')}
                type="password"
              />
            </Grid>
            <Grid item xs={12}>
              {submitError && (
                <FormHelperText error>{submitError}</FormHelperText>
              )}
            </Grid>
          </Grid>
        </ResponsiveDialogContent>
        <DialogActions>
          <Button onClick={toggle} variant="contained" color="default">
            Cancel
          </Button>
          <Button
            disabled={inProgress}
            type="submit"
            variant="contained"
            color="primary"
          >
            Save
          </Button>
        </DialogActions>
      </form>
    </ResponsiveDialog>
  );
};

export default ChangePasswordDialog;
