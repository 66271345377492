import React, { useMemo } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { ToastContainer } from 'react-toastify';

import {
  environmentActions,
  useEnvironmentSelectors
} from '../../_store/slices/config/environmentSlice';

import Routes from './Components/Routes';

import 'react-toastify/dist/ReactToastify.min.css';
import 'react-perfect-scrollbar/dist/css/styles.css';

const useStyles = makeStyles(() => ({
  appContainer: {
    background: '#f1f4f6',
    display: 'flex',
    minHeight: '100vh',
    flexDirection: 'column',
    margin: 0
  }
}));

const AppMain = () => {
  const styles = useStyles();
  const dispatch = useDispatch();

  const { response: environmentResponse } = useEnvironmentSelectors();

  useMemo(() => {
    if (!environmentResponse) {
      dispatch(environmentActions.submit());
    }
  }, [dispatch, environmentResponse]);

  return (
    <>
      <div className={styles.appContainer}>
        <Routes />
      </div>
      <ToastContainer
        position="bottom-right"
        autoClose={10000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  );
};

export default AppMain;
