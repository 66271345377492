import React from 'react';

import Collapse from '@material-ui/core/Collapse';

import FieldDetails from '../../../Components/Growths/FieldDetails';

const FieldCollapse = ({ field, open }) => {
  return (
    <Collapse in={open} timeout="auto" unmountOnExit>
      <FieldDetails field={field} />
    </Collapse>
  );
};

export default FieldCollapse;
