import React, { useCallback } from 'react';

import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import _ from 'lodash';
import { useDispatch } from 'react-redux';

import { orderedGrowthModels } from '../../_constants/growthsConstants';
import { selectGrowthModel } from '../../_store/actions/uiActions';
import { useGrowthModelSelectors } from '../../_store/selectors/uiSelectors';

const useStyles = makeStyles(() => ({
  formControl: {
    minWidth: 120
  },
  select: (props) => ({
    fontSize: props.small ? '0.75rem' : '1rem'
  })
}));

const GrowthModelSelector = ({ showLabel, small }) => {
  const styles = useStyles({ small });
  const dispatch = useDispatch();

  const { selectedGrowthModel } = useGrowthModelSelectors();

  const handleChange = useCallback(
    (e) => {
      dispatch(selectGrowthModel(e.target.value));
    },
    [dispatch]
  );

  return (
    <FormControl className={styles.formControl}>
      {showLabel && (
        <InputLabel id="growth-model-select-label">Growth Model</InputLabel>
      )}
      <Select
        labelId="growth-model-select-label"
        value={selectedGrowthModel}
        onChange={handleChange}
        className={styles.select}
      >
        {_.map(orderedGrowthModels, (model) => (
          <MenuItem key={_.get(model, 'type')} value={_.get(model, 'type')}>
            {_.get(model, 'name')}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default GrowthModelSelector;
