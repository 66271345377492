import React, { useCallback, useState } from 'react';

import { Button, ButtonGroup, CircularProgress } from '@material-ui/core';

const ConfirmButton = ({
  children,
  onConfirm,
  onToggleShowConfirm,
  confirmText = 'Confirm',
  showConfirm: controlledShowConfirm,
  ...props
}) => {
  const [showConfirm, setShowConfirm] = useState(false);
  const [inProgress, setInProgress] = useState(false);

  const isControlled = !!onToggleShowConfirm;

  const hideConfirm = useCallback(() => {
    if (isControlled) {
      onToggleShowConfirm(false);
    } else {
      setShowConfirm(false);
    }
  }, [isControlled, onToggleShowConfirm]);

  const handleClick = useCallback(() => {
    if (isControlled) {
      onToggleShowConfirm(true);
    } else {
      setShowConfirm(true);
    }
  }, [isControlled, onToggleShowConfirm]);

  const handleConfirm = useCallback(async () => {
    setInProgress(true);
    await onConfirm();
    setInProgress(false);
    hideConfirm();
  }, [onConfirm, hideConfirm]);

  const handleCancel = useCallback(() => {
    hideConfirm();
  }, [hideConfirm]);

  return (
    <>
      {(isControlled && controlledShowConfirm) || showConfirm ? (
        inProgress ? (
          <CircularProgress />
        ) : (
          <ButtonGroup>
            <Button
              {...props}
              color="secondary"
              disabled={inProgress}
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button {...props} disabled={inProgress} onClick={handleConfirm}>
              {confirmText}
            </Button>
          </ButtonGroup>
        )
      ) : (
        <Button {...props} onClick={handleClick}>
          {children}
        </Button>
      )}
    </>
  );
};

export default ConfirmButton;
