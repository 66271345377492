import { growerService } from '../../services';
import {
  createGrowerActionTypes,
  deleteGrowerActionTypes,
  getConfigGrowersActionTypes,
  getGrowersActionTypes,
  updateGrowerActionTypes
} from '../actionTypes/growersActionTypes';

import { buildApiActions } from './apiActions';

export const getGrowersActions = buildApiActions(
  getGrowersActionTypes,
  growerService.getGrowersV2
);

export const getConfigGrowersActions = buildApiActions(
  getConfigGrowersActionTypes,
  growerService.getAllGrowersV2
);

export const createGrowerActions = buildApiActions(
  createGrowerActionTypes,
  growerService.createGrowerV2
);

export const updateGrowerActions = buildApiActions(
  updateGrowerActionTypes,
  growerService.updateGrowerV2
);

export const deleteGrowerActions = {
  ...buildApiActions(deleteGrowerActionTypes, growerService.deleteGrowerV2),
  select: (growerId) => ({
    type: deleteGrowerActionTypes.SELECT,
    payload: {
      growerId
    }
  })
};
