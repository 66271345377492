import { useMemo } from 'react';

import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { put, select, takeEvery } from 'redux-saga/effects';

import { getErrorMessage } from '../../../services/errors';
import { requestStatus } from '../../helpers/requestStatus';

export const submitActionName = 'apiSubmit';
export const requestActionName = 'apiRequest';
export const successActionName = 'apiSuccess';
export const errorActionName = 'apiError';
export const clearActionName = 'apiClear';

const flattenPath = (path) =>
  _.chain(path)
    .flattenDeep()
    .filter(
      (item) =>
        (_.isNumber(item) && !_.isNaN(item)) ||
        (_.isString(item) && !_.isEmpty(item))
    )
    .join('.')
    .value();

export function createApiSlice({
  reducerPath,
  apiFunction,
  initialState = {},
  customReducers,
  getStateUpdatePath,
  getSelectorPath,
  responseMutationFn = (response) => response,
  authenticate = true,
  apiArgumentsFn,
  updateIntervalSeconds
}) {
  const getPath = (action, path = []) => {
    if (!_.isFunction(getStateUpdatePath)) {
      return path;
    }

    return flattenPath([getStateUpdatePath(action), path]);
  };

  const apiSlice = createSlice({
    name: _.join(reducerPath, '/'),
    initialState,
    reducers: {
      [submitActionName]: () => {},
      [requestActionName]: (state, action) => {
        _.set(state, getPath(action, ['status']), requestStatus.IN_PROGRESS);
      },
      [successActionName]: (state, action) => {
        _.set(state, getPath(action, ['status']), requestStatus.SUCCESS);
        _.set(state, getPath(action, ['updatedAt']), moment().toISOString());
        _.set(
          state,
          getPath(action, ['response']),
          responseMutationFn
            ? responseMutationFn(
                action.payload.response,
                action.payload.apiArguments
              )
            : action.payload.response
        );
        _.unset(state, getPath(action, ['error']));
      },
      [errorActionName]: (state, action) => {
        _.set(state, getPath(action, ['status']), requestStatus.FAILURE);
        _.set(state, getPath(action, ['updatedAt']), moment().toISOString());
        _.set(state, getPath(action, ['error']), {
          message: getErrorMessage(action.payload.apiError),
          status: _.get(action, 'payload.apiError.response.status')
        });
        _.unset(state, getPath(action, ['response']));
      },
      [clearActionName]: (state, action) => {
        const path = getPath(action, []);
        if (_.isEmpty(path)) {
          return initialState;
        }
        _.set(state, path, initialState);
      },
      ...customReducers
    }
  });

  const createSelector = (args, path) => (state) => {
    const basePath = _.isFunction(getSelectorPath)
      ? getSelectorPath(...args)
      : null;
    const absolutePath = flattenPath([reducerPath, basePath, path]);
    return _.get(state, absolutePath);
  };

  const statusSelector = (...args) => createSelector(args, 'status');
  const updatedAtSelector = (...args) => createSelector(args, 'updatedAt');
  const errorSelector = (...args) => createSelector(args, 'error');
  const responseSelector = (...args) => createSelector(args, 'response');

  const createResponseSelector =
    (path, ...args) =>
    (state) => {
      return _.get(responseSelector(...args)(state), path);
    };

  const selectors = {
    status: statusSelector,
    updatedAt: updatedAtSelector,
    error: errorSelector,
    response: responseSelector
  };

  const useApiSelectors = (...args) => {
    const status = useSelector(statusSelector(...args));
    const updatedAt = useSelector(updatedAtSelector(...args));
    const error = useSelector(errorSelector(...args));
    const response = useSelector(responseSelector(...args));

    const inProgress = status === requestStatus.IN_PROGRESS;
    const success = status === requestStatus.SUCCESS;
    const errorMessage = _.get(error, 'message');

    return useMemo(
      () => ({
        inProgress,
        success,
        errorMessage,
        response,
        updatedAt
      }),
      [errorMessage, inProgress, response, success, updatedAt]
    );
  };

  const { actions, reducer } = apiSlice;
  const submitAction = _.get(actions, [submitActionName]);
  const originalRequestAction = _.get(actions, [requestActionName]);
  const successAction = _.get(actions, [successActionName]);
  const errorAction = _.get(actions, [errorActionName]);
  const clearAction = _.get(actions, [clearActionName]);
  const otherActions = _.omit(actions, [
    submitActionName,
    requestActionName,
    errorActionName,
    clearActionName
  ]);

  const requestAction = (...args) =>
    originalRequestAction({
      requestActions: {
        success: successAction,
        error: errorAction
      },
      apiFunction,
      apiArguments: args,
      authenticate
    });

  function* handleRequest(action) {
    if (!_.isFunction(apiArgumentsFn)) {
      return;
    }
    const apiArguments = apiArgumentsFn(action.payload);
    const stateUpdatePath = getPath({ payload: { apiArguments } });

    const status = yield select(selectors.status(stateUpdatePath));
    const updatedAt = yield select(selectors.updatedAt(stateUpdatePath));
    const error = yield select(selectors.error(stateUpdatePath));
    const errorStatus = _.get(error, 'status');

    const shouldUpdate =
      status !== requestStatus.IN_PROGRESS &&
      (!updateIntervalSeconds ||
        !updatedAt ||
        moment().diff(moment(updatedAt), 'seconds') > updateIntervalSeconds ||
        (status === requestStatus.FAILURE && errorStatus !== 404));

    if (shouldUpdate) {
      yield put(requestAction(...apiArguments));
    }
  }

  function* requestSaga() {
    yield takeEvery(submitAction.type, handleRequest);
  }

  return {
    actions: {
      ...otherActions,
      submit: submitAction,
      request: requestAction,
      success: successAction,
      error: errorAction,
      clear: clearAction
    },
    reducerDescriptor: {
      reducer,
      path: reducerPath
    },
    selectors,
    createResponseSelector,
    useApiSelectors,
    requestSaga: _.isFunction(apiArgumentsFn) ? requestSaga : undefined
  };
}
