import React, { useMemo } from 'react';

import _ from 'lodash';
import moment from 'moment';

import { getFieldAccessibilityColor } from '../../_utilities/FieldAccessibility/fieldAccessibilityColors';
import TimelineRectangle from '../Charts/TimelineRectangle';

const FieldAccessibilityTimeline = ({
  svgWidth,
  paddingTop,
  barHeight,
  barGap,
  rectangleRadius,
  fieldAccessibility,
  selectedTimeScale,
  onMouseOver = _.noop,
  onMouseOut = _.noop
}) => {
  const accessibilityRectangles = useMemo(() => {
    return _.map(fieldAccessibility, (accessibility, idx) => {
      const fieldId = _.get(accessibility, 'fieldId');
      const rectangles = _.chain(accessibility)
        .get('data')
        .map((item) => {
          const startDate = moment(_.get(item, 'date'));
          const endDate = startDate.clone().add(1, 'hours');

          const x0 = Math.round(selectedTimeScale(startDate.toDate()));
          const x1 = Math.round(selectedTimeScale(endDate.toDate()));
          const rectWidth = x1 - x0;

          const rangeX0 = selectedTimeScale.range()[0];
          const rangeX1 = selectedTimeScale.range()[1];

          const visibleX0 = x0 < rangeX0 ? rangeX0 : x0;
          const visibleX1 = x1 > rangeX1 ? rangeX1 : x1;
          const visibleMidX =
            x0 >= rangeX0
              ? (visibleX1 - visibleX0) / 2
              : rangeX0 - x0 + (visibleX1 - visibleX0) / 2;

          const y = idx * (barHeight + barGap) + paddingTop;

          const classificationValue = _.get(item, 'value');

          const height = barHeight;
          const color = getFieldAccessibilityColor(classificationValue);
          return {
            id: `${fieldId}-${_.get(item, 'date')}`,
            x: x0,
            y,
            width: rectWidth,
            height,
            color,
            startDate: startDate.toISOString(),
            endDate: endDate.toISOString(),
            classification: _.get(item, 'classification'),
            classificationValue: classificationValue,
            visibleMidX
          };
        })
        .filter((rectangle) => rectangle.width > 0)
        .value();
      return { fieldId, rectangles };
    });
  }, [barGap, barHeight, paddingTop, selectedTimeScale, fieldAccessibility]);

  return (
    <g>
      {svgWidth > 0 && (
        <>
          {_.map(accessibilityRectangles, (field) => (
            <g key={_.get(field, 'fieldId')}>
              {_.map(_.get(field, 'rectangles'), (rectangle) => (
                <TimelineRectangle
                  key={_.get(rectangle, 'id')}
                  rectangleRadius={rectangleRadius}
                  rectangle={rectangle}
                  onMouseOver={onMouseOver}
                  onMouseOut={onMouseOut}
                />
              ))}
            </g>
          ))}
        </>
      )}
    </g>
  );
};

export default FieldAccessibilityTimeline;
