import { useMemo } from 'react';

import _ from 'lodash';
import tinycolor from 'tinycolor2';

import {
  useTechnologiesConfig,
  useVarietiesConfig
} from '../_store/selectors/configSelectors';
import { defaultTechnologyColor } from '../Pages/Map/Components/hooks/useFieldsFeatureCollection';

export const getTechnology = (varieties, technologies, varietyId) => {
  const variety = _.find(
    varieties,
    (variety) => _.get(variety, 'id') === varietyId
  );
  const varietyName = _.get(variety, 'name', 'Unknown');
  const technologyId = _.get(variety, 'technologyId');
  const technology = _.find(
    technologies,
    (technology) => _.get(technology, 'id') === technologyId
  );
  const technologyName = _.get(technology, 'name', 'Unspecified');
  const technologyColor = _.get(technology, 'color', defaultTechnologyColor);

  const technologyTextColor = tinycolor
    .mostReadable(technologyColor, ['#FFFFFF', '#000000'], {
      includeFallbackColors: true
    })
    .toHexString();

  return {
    variety,
    varietyName,
    technologyId,
    technology,
    technologyName,
    technologyColor,
    technologyTextColor
  };
};

export const useCropTechnology = (varietyId) => {
  const { varieties } = useVarietiesConfig();
  const { technologies } = useTechnologiesConfig();

  const {
    varietyName,
    technologyId,
    technologyName,
    technologyColor,
    technologyTextColor
  } = useMemo(
    () => getTechnology(varieties, technologies, varietyId),
    [technologies, varieties, varietyId]
  );

  return useMemo(
    () => ({
      varietyId,
      varietyName,
      technologyId,
      technologyName,
      technologyColor,
      technologyTextColor
    }),
    [
      technologyColor,
      technologyId,
      technologyName,
      varietyId,
      varietyName,
      technologyTextColor
    ]
  );
};

export const useGrowthsCropTechnologies = (growths) => {
  const { varieties } = useVarietiesConfig();
  const { technologies } = useTechnologiesConfig();

  return useMemo(() => {
    return _.chain(growths)
      .map((growth) => ({
        id: _.get(growth, 'id'),
        ...getTechnology(varieties, technologies, _.get(growth, 'varietyId'))
      }))
      .keyBy((growth) => _.get(growth, 'id'))
      .value();
  }, [growths, technologies, varieties]);
};
