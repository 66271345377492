import { useMemo } from 'react';

import _ from 'lodash';

import { userService } from '../../../services';
import { createApiSlice } from '../utils/createApiSlice';

const getUserActionsSlice = createApiSlice({
  reducerPath: ['newUser', 'getUserActions'],
  apiFunction: userService.getUserActions,
  getStateUpdatePath: (action) => {
    const userId = _.get(action, 'payload.apiArguments.0');
    return [userId];
  },
  getSelectorPath: (userId) => {
    return [userId];
  },
  apiArgumentsFn: ({ userId }) => [userId]
});

const { actions, useApiSelectors } = getUserActionsSlice;

export const getUserActionsActions = actions;

export const useGetUserActionsSelectors = (userId) => {
  const { inProgress, errorMessage, success, response } =
    useApiSelectors(userId);

  return useMemo(
    () => ({
      inProgress,
      errorMessage,
      success,
      response
    }),
    [errorMessage, inProgress, response, success]
  );
};

export default getUserActionsSlice;
