import { growthService } from '../../services';
import {
  createGrowthActionTypes,
  deleteGrowthActionTypes,
  getActiveGrowthActionTypes,
  growthsActionTypes,
  modifyGrowthActionTypes,
  setTargetMoistureActionTypes
} from '../actionTypes/growthsActionTypes';

import { buildApiActions } from './apiActions';

export const updateActiveGrowthAction = (
  growerId,
  seasonId,
  farmId,
  fieldId
) => ({
  type: growthsActionTypes.UPDATE_ACTIVE_GROWTH,
  payload: {
    growerId,
    seasonId,
    farmId,
    fieldId
  }
});

export const updateAllActiveGrowthsForGrowerAction = (growerId, seasonId) => ({
  type: growthsActionTypes.UPDATE_ALL_ACTIVE_GROWTH_FOR_GROWER,
  payload: {
    growerId,
    seasonId
  }
});

export const updateAllActiveGrowthsForFarmAction = (
  growerId,
  seasonId,
  farmId
) => ({
  type: growthsActionTypes.UPDATE_ALL_ACTIVE_GROWTH_FOR_FARM,
  payload: {
    growerId,
    seasonId,
    farmId
  }
});

export const forceUpdateActiveGrowthAction = (
  growerId,
  seasonId,
  farmId,
  fieldId
) => ({
  type: growthsActionTypes.FORCE_UPDATE_ACTIVE_GROWTH,
  payload: {
    growerId,
    seasonId,
    farmId,
    fieldId
  }
});

export const getActiveGrowthsActions = buildApiActions(
  getActiveGrowthActionTypes,
  growthService.getActiveGrowthV2
);

export const createGrowthActions = buildApiActions(
  createGrowthActionTypes,
  growthService.createGrowthV2
);

export const modifyGrowthActions = buildApiActions(
  modifyGrowthActionTypes,
  growthService.modifyGrowthV2
);

export const deleteGrowthActions = buildApiActions(
  deleteGrowthActionTypes,
  growthService.deleteGrowthV2
);

export const setTargetMoistureActions = buildApiActions(
  setTargetMoistureActionTypes,
  growthService.setTargetMoistureV2
);
