import { useMemo } from 'react';

import { statisticsService } from '../../../services/statistics.service';
import { createApiSlice } from '../utils/createApiSlice';

const getGrowersStatisticsSlice = createApiSlice({
  reducerPath: ['statistics', 'getGrowersStatistics'],
  apiFunction: statisticsService.getGrowersStatistics,
  apiArgumentsFn: () => []
});

const { actions, useApiSelectors } = getGrowersStatisticsSlice;

export const getGrowersStatisticsActions = actions;

export const useGetGrowersStatisticsSelectors = () => {
  const { inProgress, errorMessage, success, response } = useApiSelectors();

  return useMemo(
    () => ({
      inProgress,
      errorMessage,
      success,
      response
    }),
    [errorMessage, inProgress, response, success]
  );
};

export default getGrowersStatisticsSlice;
