import { useMemo } from 'react';

import { useSelector } from 'react-redux';

import { createDataSlice } from '../utils/createDataSlice';

const growersConfigSlice = createDataSlice({
  reducerPath: ['ui', 'growersConfig'],
  initialState: { showAddress: undefined },
  reducers: {
    toggleShowAddress(state) {
      state.showAddress = !state.showAddress;
    },
    setShowAddress(state, action) {
      state.showAddress = action.payload;
    }
  }
});

const { actions, baseSelector: growersConfigSelector } = growersConfigSlice;

export const growersConfigActions = actions;

export const useGrowersConfigSelectors = () => {
  const { showAddress } = useSelector(growersConfigSelector);

  return useMemo(
    () => ({
      showAddress
    }),
    [showAddress]
  );
};

export default growersConfigSlice;
