import React, { useCallback } from 'react';

import { Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import cx from 'classnames';

import DrawerThumbnail from './DrawerThumbnail';

const useStyles = makeStyles(() => ({
  drawerItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    minHeight: '3.75rem',
    paddingLeft: '1.5rem',
    paddingRight: '1.5rem',
    color: '#D1D1D1',
    backgroundColor: '#28374A',
    '&:hover': {
      backgroundColor: '#445468',
      color: '#F8F8F8'
    },
    cursor: 'pointer'
  },
  drawerItemEven: {
    backgroundColor: '#2E4057'
  },
  drawerItemSelected: {
    borderLeft: '3px #44AF1E solid'
  },
  titlesContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    minWidth: 0
  },
  title: {
    fontWeight: '500',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  subtitle: {
    fontSize: '0.75rem',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
}));

const DrawerItem = ({
  item,
  title,
  subtitle,
  even,
  selected,
  onClick,
  shape
}) => {
  const styles = useStyles();

  const handleClick = useCallback(() => {
    onClick(item);
  }, [item, onClick]);

  return (
    <Box
      className={cx(styles.drawerItem, {
        [styles.drawerItemEven]: even,
        [styles.drawerItemSelected]: selected
      })}
      onClick={handleClick}
    >
      {shape && <DrawerThumbnail geometry={shape} selected={selected} />}
      <Box className={styles.titlesContainer}>
        <Typography className={styles.title}>{title}</Typography>
        <Typography className={styles.subtitle}>{subtitle}</Typography>
      </Box>
    </Box>
  );
};

export default DrawerItem;
