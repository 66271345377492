import React from 'react';

import { Popover, Typography } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import _ from 'lodash';
import moment from 'moment';

const useStyles = makeStyles(() => ({
  tooltip: {
    padding: '1rem'
  },
  dates: {
    marginTop: '0.5rem',
    whiteSpace: 'nowrap',
    fontWeight: '500'
  },
  fieldName: {
    marginTop: '0.5rem',
    whiteSpace: 'nowrap',
    fontWeight: '600'
  },
  description: {
    marginTop: '1rem'
  },
  popover: {
    pointerEvents: 'none'
  }
}));

const ActivityPopover = ({ hoverParams }) => {
  const styles = useStyles();

  const anchorEl = _.get(hoverParams, 'element');
  const fieldName = _.get(hoverParams, 'data.fieldName');
  const description = _.get(hoverParams, 'data.description');
  const displayTooltip = !!anchorEl;

  return (
    <Popover
      className={styles.popover}
      classes={{ paper: styles.tooltip }}
      open={displayTooltip}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: _.get(hoverParams, 'data.visibleMidX', 0)
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
      disableAutoFocus
      disableRestoreFocus
    >
      <Typography className={styles.fieldName} align="center">
        {fieldName}
      </Typography>
      <Typography className={styles.dates} align="center">
        {moment(_.get(hoverParams, 'data.startDate')).format('ll')}
        {!!_.get(hoverParams, 'data.endDate') &&
          ` - ${moment(_.get(hoverParams, 'data.endDate')).format('ll')}`}
      </Typography>
      <Typography className={styles.description}>{description}</Typography>
    </Popover>
  );
};

export default ActivityPopover;
