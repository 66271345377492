import _ from 'lodash';

export function getGrowthModel(growth, growthModelType) {}

export function getGrowthStages(growth, growthModelType) {
  return _.chain(growth)
    .get('growthModels')
    .find((model) => _.get(model, 'type') === growthModelType)
    .get('growthStages')
    .value();
}

export function getActivities(growth, growthModelType) {
  return _.chain(growth)
    .get('growthModels')
    .find((model) => _.get(model, 'type') === growthModelType)
    .get('activities')
    .value();
}

export function getGdd(growth, growthModelType) {
  return _.chain(growth)
    .get('growthModels')
    .find((model) => _.get(model, 'type') === growthModelType)
    .get('gdd')
    .value();
}

export function getPrecipitation(growth, growthModelType) {
  return _.chain(growth)
    .get('growthModels')
    .find((model) => _.get(model, 'type') === growthModelType)
    .get('precipitation')
    .value();
}

export function getInfieldDrydown(growth, growthModelType) {
  return _.chain(growth)
    .get('growthModels')
    .find((model) => _.get(model, 'type') === growthModelType)
    .get('infieldDrydown')
    .value();
}

export function getCounceModel(growth, growthModelType) {
  return _.chain(growth)
    .get('growthModels')
    .find((model) => _.get(model, 'type') === growthModelType)
    .get('counceModel')
    .value();
}
