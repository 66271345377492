import React from 'react';

import { Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(() => ({
  drawerError: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '3rem',
    paddingLeft: '1.5rem',
    paddingRight: '1.5rem',
    color: '#D1D1D1'
  }
}));

const DrawerError = ({ message }) => {
  const styles = useStyles();

  return (
    <Box className={styles.drawerError}>
      <Typography>{message}</Typography>
    </Box>
  );
};

export default DrawerError;
