import { useContext, useMemo } from 'react';

import _ from 'lodash';
import { useSelector } from 'react-redux';

import { FarmStructureContext } from '../../_context/FarmStructureContext';
import { requestStatus } from '../helpers/requestStatus';
import { getSeasonsSelectors } from '../slices/gff/seasons/getSeasonsSlice';
import { getSeasonWithGrowthsSelectors } from '../slices/gff/seasons/getSeasonsWithGrowthsSlice';
import { useCropDashboardSelectors } from '../slices/ui/cropDashboardSlice';

export const fieldsSelector = (growerId, seasonId, farmId) =>
  getSeasonsSelectors.fields(growerId, seasonId, farmId);

export const fieldSelector = (growerId, seasonId, farmId, fieldId) =>
  getSeasonsSelectors.field(growerId, seasonId, farmId, fieldId);

export const useFieldsWithGrowthSelector = (growerId, seasonId, farmId) => {
  const inProgress =
    useSelector(getSeasonWithGrowthsSelectors.status(growerId, seasonId)) ===
    requestStatus.IN_PROGRESS;
  const fieldsById = useSelector(
    getSeasonWithGrowthsSelectors.fields(growerId, seasonId, farmId)
  );

  const fields = useMemo(() => _.values(fieldsById), [fieldsById]);

  return useMemo(
    () => ({
      fields,
      inProgress
    }),
    [fields, inProgress]
  );
};

export const useFieldWithGrowthSelector = (
  growerId,
  seasonId,
  farmId,
  fieldId
) => {
  const field = useSelector(
    getSeasonWithGrowthsSelectors.field(growerId, seasonId, farmId, fieldId)
  );

  return useMemo(
    () => ({
      field
    }),
    [field]
  );
};

export const useAllGrowerFieldsSelector = (forceShowAllForGrower = false) => {
  const {
    selectedGrowerId: growerId,
    selectedSeasonId: seasonId,
    selectedFarmId: farmId
  } = useContext(FarmStructureContext);

  const { showAllForGrower } = useCropDashboardSelectors();

  const farmsWithGrowthsById = useSelector(
    getSeasonWithGrowthsSelectors.farms(growerId, seasonId)
  );
  const seasonFarmsById = useSelector(
    getSeasonsSelectors.farms(growerId, seasonId)
  );
  const farmsById = farmsWithGrowthsById || seasonFarmsById;

  const fields = useMemo(
    () =>
      _.chain(farmsById)
        .filter(
          (farm, id) =>
            forceShowAllForGrower || showAllForGrower || id === farmId
        )
        .mapValues((farm) =>
          _.chain(farm)
            .get('fields')
            .values()
            .map((field) => ({ ...field, farmName: _.get(farm, 'name') }))
            .value()
        )
        .flatMap((farm) => farm)
        .value(),
    [farmId, farmsById, forceShowAllForGrower, showAllForGrower]
  );

  return useMemo(
    () => ({
      fields
    }),
    [fields]
  );
};

export const useFieldsSelector = (growerId, seasonId, farmId) => {
  const fieldsById = useSelector(fieldsSelector(growerId, seasonId, farmId));
  const fields = useMemo(() => _.values(fieldsById), [fieldsById]);

  return useMemo(
    () => ({
      fields
    }),
    [fields]
  );
};

export const useFieldSelectors = (growerId, seasonId, farmId, fieldId) => {
  const field = useSelector(fieldSelector(growerId, seasonId, farmId, fieldId));

  return useMemo(
    () => ({
      field
    }),
    [field]
  );
};
