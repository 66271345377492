import _ from 'lodash';

export const commonLineDataSetParams = (hexColor) => ({
  type: 'line',
  fill: false,
  lineTension: 0.1,
  borderCapStyle: 'butt',
  borderDash: [],
  borderDashOffset: 0.0,
  borderJoinStyle: 'miter',
  pointBorderWidth: 1,
  pointHoverRadius: 5,
  pointHoverBorderWidth: 2,
  pointRadius: 3,
  pointHitRadius: 10,
  backgroundColor: `${hexColor}33`,
  borderColor: `${hexColor}`,
  pointBorderColor: `${hexColor}`,
  pointBackgroundColor: `${hexColor}`,
  pointHoverBackgroundColor: `${hexColor}`,
  pointHoverBorderColor: `${hexColor}`
});

const defaultOptions = {
  responsive: true,
  tooltips: {
    mode: 'label'
  },
  scales: {
    'x-axis': {
      type: 'time',
      time: {
        minUnit: 'day',
        tooltipFormat: 'LL',
        displayFormats: {
          hour: 'MMM DD, hhA'
        }
      },
      display: true,
      gridLines: {
        display: true
      },
      ticks: {
        autoSkip: true
      }
    }
  }
};

export function getDefaultOptions(timeScale) {
  const options = _.cloneDeep(defaultOptions);
  options.scales['x-axis'].time.tooltipFormat =
    timeScale === 'hour' ? 'LLL' : 'LL';
  options.scales['x-axis'].time.minUnit = timeScale === 'hour' ? 'hour' : 'day';
  return options;
}

export const getValue = (weatherData, field) => {
  if (
    _.every(
      _.values(weatherData),
      (val) => !_.isNumber(val[field]) || _.isNaN(val[field])
    )
  ) {
    return undefined;
  }
  return _.chain(weatherData)
    .values()
    .map((val) => {
      const value = _.get(val, [field]);
      return {
        x: _.get(val, 'date'),
        y: _.isNumber(value) && !_.isNaN(value) ? value : null
      };
    })
    .value();
};

export function getAggregatedValues(weatherData, field) {
  const values = getValue(weatherData, field);
  if (!values) {
    return [];
  }

  let aggregatedValue = 0;
  const aggregatedValues = [];

  for (let item of values) {
    const value = item.y;

    if (!_.isNumber(value) || _.isNaN(value)) {
      aggregatedValues.push({ x: item.x, y: null });
      continue;
    }
    aggregatedValue += value;
    aggregatedValues.push({ x: item.x, y: aggregatedValue });
  }

  return aggregatedValues;
}
