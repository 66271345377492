import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  button: {
    cursor: 'pointer',
    backgroundColor: 'transparent',
    color: 'white',
    border: 0,
    margin: 0,
    padding: 0,
    width: theme.dimensions.headerHeight,
    height: theme.dimensions.headerHeight,
    fontSize: '1.25rem',
    '&:hover': {
      opacity: 0.8,
      transition: 'opacity 0.15s linear'
    }
  }
}));

const HeaderIconButton = ({ icon, onClick }) => {
  const styles = useStyles();

  return (
    <button className={styles.button} onClick={onClick}>
      <FontAwesomeIcon icon={icon} />
    </button>
  );
};

export default HeaderIconButton;
