import { useMemo } from 'react';

import _ from 'lodash';

import { growthModelApiService } from '../../../services/growthModelApi.service';
import { createApiSlice } from '../utils/createApiSlice';

const getGrowthModelApiGrowthsSlice = createApiSlice({
  reducerPath: ['growthModelApiAdmin', 'getGrowthModelApiGrowths'],
  apiFunction: growthModelApiService.getGrowths,
  getStateUpdatePath: (action) => {
    const fieldId = _.get(action, 'payload.apiArguments.0');
    return [fieldId];
  },
  getSelectorPath: (fieldId) => {
    return [fieldId];
  },
  apiArgumentsFn: ({ fieldId }) => [fieldId]
});

const { actions, useApiSelectors } = getGrowthModelApiGrowthsSlice;

export const getGrowthModelApiGrowthsActions = actions;

export const useGetGrowthModelApiGrowthsSelectors = (fieldId) => {
  const { inProgress, errorMessage, success, updatedAt, response } =
    useApiSelectors(fieldId);

  return useMemo(
    () => ({
      inProgress,
      errorMessage,
      success,
      updatedAt,
      response
    }),
    [errorMessage, inProgress, response, success, updatedAt]
  );
};

export default getGrowthModelApiGrowthsSlice;
