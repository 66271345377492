import { useMemo } from 'react';

import _ from 'lodash';

import { apiUpdateIntervals } from '../../../config';
import { reportsService } from '../../../services/reports.service';
import { createApiSlice } from '../utils/createApiSlice';

const getReportsGrowersSlice = createApiSlice({
  reducerPath: ['reports', 'getReportsGrowers'],
  apiFunction: reportsService.getReportsGrowers,
  getStateUpdatePath: (action) => {
    const seasonYear = _.get(action, 'payload.apiArguments.0');
    return [seasonYear];
  },
  getSelectorPath: (seasonYear) => {
    return [seasonYear];
  },
  apiArgumentsFn: ({ seasonYear }) => [seasonYear],
  updateIntervalSeconds: apiUpdateIntervals.growersUpdateIntervalSeconds
});

const { actions, useApiSelectors } = getReportsGrowersSlice;

export const getReportsGrowersActions = actions;

export const useGetReportsGrowersSelectors = (seasonYear) => {
  const { inProgress, errorMessage, success, response } =
    useApiSelectors(seasonYear);

  return useMemo(
    () => ({
      inProgress,
      errorMessage,
      success,
      response
    }),
    [errorMessage, inProgress, response, success]
  );
};

export default getReportsGrowersSlice;
