import { addToken, client } from './client';

export const growthModelSnapshotsService = {
  getGrowthModelSnapshotsDates,
  getGrowthModelSnapshot
};

async function getGrowthModelSnapshotsDates(
  token,
  growerId,
  seasonId,
  farmId,
  fieldId,
  growthId
) {
  const response = await client.get(
    `/v2/growers/${growerId}/seasons/${seasonId}/farms/${farmId}/fields/${fieldId}/growths/${growthId}/growth-model/snapshots/descriptors`,
    addToken(token)
  );
  return response.data;
}

async function getGrowthModelSnapshot(
  token,
  growerId,
  seasonId,
  farmId,
  fieldId,
  growthId,
  feedbackId
) {
  const response = await client.get(
    `/v2/growers/${growerId}/seasons/${seasonId}/farms/${farmId}/fields/${fieldId}/growths/${growthId}/growth-model/snapshots/${feedbackId}`,
    addToken(token)
  );
  return response.data;
}
