import React, { Fragment, useMemo } from 'react';

import makeStyles from '@material-ui/core/styles/makeStyles';
import _ from 'lodash';
import moment from 'moment';

import { grainMoistureContentColor } from './GrainMoistureChart';

const textHeight = 12;
const textPadding = 5;

const useStyles = makeStyles(() => ({
  moistureSamples: {
    dominantBaseline: 'text-before-edge',
    fontSize: `${textHeight / 16}rem`,
    opacity: 0.5
  }
}));

const MoistureSample = ({ rect, circleY, radius }) => {
  return (
    <circle
      cx={rect.x}
      r={radius}
      cy={circleY}
      fill={grainMoistureContentColor}
    />
  );
};

const MoistureSamples = ({
  moistureSamples,
  timeScale,
  paddingLeft,
  paddingTop,
  height
}) => {
  const styles = useStyles();

  const radius = height * 0.2;
  const circleY =
    paddingTop +
    textHeight +
    textPadding +
    radius +
    (height - textHeight - textPadding) / 2;

  const rectangles = useMemo(() => {
    return _.chain(moistureSamples)
      .map((irrigationEvent) => {
        const id = _.get(irrigationEvent, 'id');
        const date = moment(_.get(irrigationEvent, 'date')).set({
          hour: 12,
          minute: 0,
          second: 0,
          millisecond: 0
        });
        const x = timeScale(date.toDate());

        return {
          id,
          x
        };
      })
      .filter((item) => !!item.x)
      .value();
  }, [moistureSamples, timeScale]);

  return (
    <>
      <text
        className={styles.moistureSamples}
        y={paddingTop + textPadding}
        x={paddingLeft}
        fill={'#000000'}
      >
        Moisture Samples
      </text>
      {_.map(rectangles, (rect) => (
        <Fragment key={`${rect.id}-${rect.startDate}`}>
          <MoistureSample rect={rect} radius={radius} circleY={circleY} />
        </Fragment>
      ))}
    </>
  );
};

export default MoistureSamples;
