import { useMemo } from 'react';

import { seasonService } from '../../../../services';
import { createApiSlice } from '../../utils/createApiSlice';

const addSeasonDeviceSlice = createApiSlice({
  reducerPath: ['devices', 'seasonDevices', 'addSeasonDevice'],
  apiFunction: seasonService.addDeviceV2
});

const { actions, useApiSelectors } = addSeasonDeviceSlice;

export const addSeasonDeviceActions = actions;

export const useAddSeasonDeviceSelectors = () => {
  const { inProgress, success, errorMessage, response, updatedAt } =
    useApiSelectors();

  return useMemo(
    () => ({ inProgress, success, errorMessage, response, updatedAt }),
    [errorMessage, inProgress, response, success, updatedAt]
  );
};

export default addSeasonDeviceSlice;
