// See https://github.com/erikras/ducks-modular-redux
// See https://redux-toolkit.js.org/usage/usage-guide#simplifying-slices-with-createslice

import _ from 'lodash';

import configSlices from './config';
import devicesSlices from './devices';
import feedbackSlices from './feedback';
import gffSlices from './gff';
import growthModelApiAdminSlices from './growth-model-api-admin';
import growthsSlices from './growths';
import reportsSlices from './reports';
import seasonsSlice from './seasons';
import statisticsSlices from './statistics';
import uiSlices from './ui';
import userSlices from './user';
import { combineDescriptors } from './utils/combineReducersTree';
import { combineRequestSagas } from './utils/combineRequestSagas';

const descriptors = _.flattenDeep([
  uiSlices,
  userSlices,
  gffSlices,
  devicesSlices,
  growthsSlices,
  feedbackSlices,
  configSlices,
  reportsSlices,
  statisticsSlices,
  seasonsSlice,
  growthModelApiAdminSlices
]);

export const slicesReducers = combineDescriptors(descriptors);
export const requestSagas = combineRequestSagas(descriptors);
