import React, { useContext, useMemo } from 'react';

import _ from 'lodash';

import { AuthContext } from '../../../_context/AuthContext';
import { FarmStructureContext } from '../../../_context/FarmStructureContext';
import { canModifyFeedback } from '../../../_hooks/Growths/useCanModifyFeedback';
import TimelineRectangle from '../../Charts/TimelineRectangle';

import { parseDate } from './_utils/growthTimelineUtils';

const GrowthStagesTimeline = ({
  svgWidth,
  paddingTop,
  barHeight,
  barGap,
  rectangleRadius,
  timelineGrowths,
  selectedTimeScale,
  onMouseOver = _.noop,
  onMouseOut = _.noop,
  onClick = _.noop
}) => {
  const { isUserView } = useContext(AuthContext);
  const { isHistoricalSeason } = useContext(FarmStructureContext);

  const growthRectangles = useMemo(() => {
    return _.map(timelineGrowths, (growth, idx) => {
      const growthId = _.get(growth, 'id');

      const rectangles = _.chain(growth)
        .get('stages')
        .map((stage) => {
          const growthStage = _.get(stage, 'growthStage');
          const canEditFeedback = canModifyFeedback(
            stage,
            isUserView,
            isHistoricalSeason
          );

          const x0 = selectedTimeScale(parseDate(stage.startDate));
          const rectWidth =
            selectedTimeScale(parseDate(stage.endDate)) -
            selectedTimeScale(parseDate(stage.startDate));
          const x1 = x0 + rectWidth;

          const rangeX0 = selectedTimeScale.range()[0];
          const rangeX1 = selectedTimeScale.range()[1];

          const visibleX0 = x0 < rangeX0 ? rangeX0 : x0;
          const visibleX1 = x1 > rangeX1 ? rangeX1 : x1;
          const visibleMidX =
            x0 >= rangeX0
              ? (visibleX1 - visibleX0) / 2
              : rangeX0 - x0 + (visibleX1 - visibleX0) / 2;

          const y = idx * (barHeight + barGap) + paddingTop;

          const height = barHeight;
          const color = stage.backgroundColor;
          const textColor = stage.textColor;
          return {
            growthId,
            id: `${growthId}-${growthStage}`,
            x: x0,
            y,
            width: rectWidth,
            height,
            color,
            textColor,
            startDate: stage.startDate,
            endDate: stage.endDate,
            growthStage: stage.growthStage,
            observed: stage.observed,
            pending: stage.pending,
            visibleMidX,
            canEditFeedback
          };
        })
        .filter((rectangle) => rectangle.width > 0)
        .value();
      return { growthId, rectangles };
    });
  }, [
    barGap,
    barHeight,
    isHistoricalSeason,
    isUserView,
    paddingTop,
    selectedTimeScale,
    timelineGrowths
  ]);

  return (
    <g>
      {svgWidth > 0 && (
        <>
          {_.map(growthRectangles, (growth) => (
            <g key={_.get(growth, 'growthId')}>
              {_.map(_.get(growth, 'rectangles'), (rectangle) => (
                <TimelineRectangle
                  key={_.get(rectangle, 'id')}
                  rectangleRadius={rectangleRadius}
                  rectangle={rectangle}
                  onMouseOver={onMouseOver}
                  onMouseOut={onMouseOut}
                  onClick={onClick}
                />
              ))}
            </g>
          ))}
        </>
      )}
    </g>
  );
};

export default GrowthStagesTimeline;
