import { toast, Bounce } from 'react-toastify';

const toastGeneral = {
  transition: Bounce,
  closeButton: true,
  autoClose: 10000,
  position: 'bottom-right'
};

export const toastError = { ...toastGeneral, type: 'error' };
export const toastSuccess = { ...toastGeneral, type: 'success' };
export const toaster = toast;
