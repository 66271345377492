import React, { useCallback } from 'react';

import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import { makeStyles } from '@material-ui/core/styles';
import cx from 'classnames';
import { useDispatch } from 'react-redux';

import { useMenuState } from '../../../../../_hooks/ui/useMenuState';
import { menuActions } from '../../../../../_store/slices/ui/menuSlice';
import FarmOptimizerTextLogo from '../../../../../assets/utils/images/farmoptimizer-logo-text-only.png';
import HeaderIconButton from '../../HeaderIconButton';

const useStyles = makeStyles((theme) => ({
  headerLogo: {
    height: theme.dimensions.headerHeight,
    width: theme.dimensions.menuDrawerWidth,
    display: 'flex',
    alignItems: 'center',
    transition: 'width 0.3s',

    justifyContent: 'space-between'
  },
  closedSideMenu: {
    width: theme.dimensions.collapsedMenuDrawerWidth,
    justifyContent: 'center'
  },
  logoContainer: {
    height: '70%',
    width: `calc(${theme.dimensions.gffDrawerWidth} - 20px - 100px)`,
    marginLeft: '20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#f1f4f6ee',
    borderRadius: '0.25rem'
  },
  logo: {
    maxHeight: '80%',
    maxWidth: '80%'
  }
}));

const HeaderLogo = () => {
  const styles = useStyles();
  const dispatch = useDispatch();

  const { collapsedSideMenu } = useMenuState();

  const handleHeaderIconButtonClick = useCallback(() => {
    dispatch(menuActions.toggleSideMenu());
  }, [dispatch]);

  return (
    <div
      className={cx(styles.headerLogo, {
        [styles.closedSideMenu]: collapsedSideMenu
      })}
    >
      {!collapsedSideMenu && (
        <div className={styles.logoContainer}>
          <img
            className={styles.logo}
            src={FarmOptimizerTextLogo}
            alt="RiceTec Logo"
          />
        </div>
      )}
      <HeaderIconButton
        icon={collapsedSideMenu ? faBars : faTimes}
        onClick={handleHeaderIconButtonClick}
      />
    </div>
  );
};

export default HeaderLogo;
