import React, { useCallback, useContext, useMemo } from 'react';

import { TableCell } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Alert from '@material-ui/lab/Alert';
import _ from 'lodash';
import moment from 'moment';
import { useDispatch } from 'react-redux';

import { AuthContext } from '../../_context/AuthContext';
import { FarmStructureContext } from '../../_context/FarmStructureContext';
import { getActivities } from '../../_helpers/Growths/growthModelHelpers';
import { openCreateGrowthDialog } from '../../_store/actions/uiActions';
import { useActiveGrowthSelectors } from '../../_store/selectors/growthsSelectors';
import { useGrowthModelSelectors } from '../../_store/selectors/uiSelectors';
import { AccordionDataRow } from '../Configuration/AccordionDataRow';

import ActivityType from './ActivityType';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  createGrowthButton: {
    marginTop: '1rem'
  },
  tabs: {
    marginBottom: '1rem'
  }
}));

const FieldActivitiesDetails = ({ field }) => {
  const dispatch = useDispatch();
  const styles = useStyles();

  const showTable = useMediaQuery((theme) => theme.breakpoints.up('sm'));

  const { isUserView } = useContext(AuthContext);

  const { selectedSeasonId, isHistoricalSeason } =
    useContext(FarmStructureContext);

  const growerId = _.get(field, 'growerId');
  const farmId = _.get(field, 'farmId');
  const fieldId = _.get(field, 'id');

  const { growth, isLoading, notFound, errorMessage } =
    useActiveGrowthSelectors(selectedSeasonId, fieldId);

  const { selectedGrowthModel } = useGrowthModelSelectors();

  const activities = useMemo(
    () => getActivities(growth, selectedGrowthModel),
    [growth, selectedGrowthModel]
  );

  const handleCreateGrowthClick = useCallback(() => {
    dispatch(openCreateGrowthDialog(growerId, farmId, fieldId));
  }, [dispatch, farmId, fieldId, growerId]);

  return (
    <Box margin={2} className={styles.container}>
      {!growth && isLoading && <CircularProgress />}
      {!isLoading && !notFound && errorMessage && (
        <Alert severity="error">{errorMessage}</Alert>
      )}
      {!isLoading &&
        (notFound || (!growth && !errorMessage)) &&
        !isHistoricalSeason && (
          <>
            <Alert severity="info">
              There are no growths configured for this field. In order to
              display the growth model, please add planting.
            </Alert>
            {!isUserView && (
              <Button
                size="small"
                variant="contained"
                color="primary"
                className={styles.createGrowthButton}
                onClick={handleCreateGrowthClick}
              >
                Add Planting
              </Button>
            )}
          </>
        )}
      {!isLoading &&
        (notFound || (!growth && !errorMessage)) &&
        isHistoricalSeason && (
          <>
            <Alert severity="info">
              There were no growths configured for this field.
            </Alert>
          </>
        )}
      {growth && showTable && (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Description</TableCell>
              <TableCell align="center">Category</TableCell>
              <TableCell align="right">Dates</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {_.map(activities, (activity) => (
              <TableRow key={_.get(activity, 'id')}>
                <TableCell>{_.get(activity, 'description')}</TableCell>
                <TableCell>
                  <ActivityType activityType={_.get(activity, 'type')} />
                </TableCell>
                <TableCell align="right">
                  {!_.get(activity, 'endDate') &&
                    moment(_.get(activity, 'date')).format('ll')}
                  {_.get(activity, 'endDate') &&
                    `${moment(_.get(activity, 'date')).format('ll')} - ${moment(
                      _.get(activity, 'endDate')
                    ).format('ll')}`}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
      {growth && !showTable && (
        <Grid container spacing={2}>
          {_.map(activities, (activity) => (
            <Grid item xs={12} key={_.get(activity, 'id')}>
              <AccordionDataRow
                title={_.get(activity, 'description')}
                text={
                  !_.get(activity, 'endDate')
                    ? moment(_.get(activity, 'date')).format('ll')
                    : `${moment(_.get(activity, 'date')).format(
                        'll'
                      )} - ${moment(_.get(activity, 'endDate')).format('ll')}`
                }
              />
            </Grid>
          ))}
        </Grid>
      )}
    </Box>
  );
};

export default FieldActivitiesDetails;
