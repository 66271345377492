import React, { useCallback, useEffect, useRef, useState } from 'react';

import makeStyles from '@material-ui/core/styles/makeStyles';
import _ from 'lodash';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useResizeDetector } from 'react-resize-detector';
import { CSSTransition } from 'react-transition-group';

import { useDisableScrollSelectors } from '../../_store/selectors/uiSelectors';
import CommonPage from '../CommonPage';

import transitionStyles from './AppPageTransition.module.scss';

const useStyles = makeStyles((theme) => ({
  appPage: {
    flex: 1,
    padding: '15px 15px 0',
    [theme.breakpoints.up('md')]: { padding: '30px 30px 0' }
  }
}));

const AppPage = ({ header, children }) => {
  const styles = useStyles();

  const { disableScroll } = useDisableScrollSelectors();
  const scrollRef = useRef(<></>);
  const [lockedScroll, setLockedScroll] = useState();

  // This is a workaround for a bug that prevented shrinking the PerfectScrollbar when its content shrinked
  // Just using the useResizeDetector hook is enough to trigger re-rendering the component and ensuring that the scrollbar shrinks
  const { ref: contentRef } = useResizeDetector();

  useEffect(() => {
    if (!disableScroll || !scrollRef.current) {
      setLockedScroll(undefined);
      return;
    }

    setLockedScroll({
      scrollTop: _.get(scrollRef.current, 'scrollTop', 0),
      scrollLeft: _.get(scrollRef.current, 'scrollLeft', 0)
    });
  }, [disableScroll]);

  const handleScroll = useCallback(() => {
    if (lockedScroll) {
      scrollRef.current.scrollTop = lockedScroll.scrollTop;
      scrollRef.current.scrollLeft = lockedScroll.scrollLeft;
    }
  }, [lockedScroll]);

  return (
    <CommonPage>
      <PerfectScrollbar
        containerRef={(ref) => (scrollRef.current = ref)}
        onScrollY={handleScroll}
        onScrollX={handleScroll}
      >
        <div ref={contentRef}>
          {header}
          <CSSTransition
            in
            timeout={1000}
            appear
            classNames={transitionStyles}
            unmountOnExit
          >
            <div className={styles.appPage}>{children}</div>
          </CSSTransition>
        </div>
      </PerfectScrollbar>
    </CommonPage>
  );
};

export default AppPage;
