import { addToken, client } from './client';

export const userService = {
  login,
  forgotPassword,
  validateResetPasswordToken,
  resetPassword,
  refreshToken,
  getUsers,
  createUser,
  updateUser,
  changePassword,
  deleteUser,
  getCurrentUser,
  acceptTermsOfService,
  getNotificationsConfig,
  saveNotificationsConfig,
  getUserActions,
  pageViewAction
};

async function login(username, password) {
  const response = await client.post(`/users/authenticate`, {
    username,
    password
  });
  return response.data;
}

async function forgotPassword(usernameEmail) {
  const response = await client.post(`/users/forgot-password`, {
    usernameEmail
  });
  return response.data;
}

async function validateResetPasswordToken(userId, token) {
  const response = await client.post(`/users/validate-password-reset-token`, {
    userId,
    token
  });
  return response.data;
}

async function resetPassword(data) {
  const response = await client.post(`/users/reset-password`, data);
  return response.data;
}

async function refreshToken(refreshToken) {
  const response = await client.post(`/users/refresh-token`, { refreshToken });
  return response.data;
}

async function getUsers(token) {
  const response = await client.get(`/users`, addToken(token));
  return response.data;
}

async function createUser(token, user) {
  const response = await client.post(`/users`, user, addToken(token));
  return response.data;
}

async function updateUser(token, userId, user) {
  const response = await client.put(`/users/${userId}`, user, addToken(token));
  return response.data;
}

async function deleteUser(token, userId) {
  const response = await client.delete(`/users/${userId}`, addToken(token));
  return response.data;
}

async function changePassword(token, requestData) {
  const response = await client.post(
    '/users/current/change-password',
    requestData,
    addToken(token)
  );
  return response.data;
}

async function getCurrentUser(token) {
  const response = await client.get(`/users/current`, addToken(token));
  return response.data;
}

async function acceptTermsOfService(token, termsOfServiceId) {
  const response = await client.post(
    `/users/current/accept-terms-of-service`,
    { termsOfServiceId },
    addToken(token)
  );
  return response.data;
}

async function getNotificationsConfig(token) {
  const response = await client.get(
    `/users/current/notifications/config`,
    addToken(token)
  );
  return response.data;
}

async function saveNotificationsConfig(token, config) {
  const response = await client.put(
    `/users/current/notifications/config`,
    config,
    addToken(token)
  );
  return response.data;
}

async function getUserActions(token, userId) {
  const response = await client.get(
    `/users/${userId}/actions`,
    addToken(token)
  );
  return response.data;
}

async function pageViewAction(token, payload) {
  const response = await client.post(
    `/users/actions/page-view`,
    payload,
    addToken(token)
  );
  return response.data;
}
