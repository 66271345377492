import _ from 'lodash';
import moment from 'moment';

export const commonLineDataSetParams = (hexColor) => ({
  type: 'scatter',
  fill: false,
  lineTension: 0.1,
  showLine: true,
  borderCapStyle: 'round',
  borderDash: [],
  borderDashOffset: 0.0,
  borderJoinStyle: 'miter',
  pointHitRadius: 5,
  pointRadius: 2,
  backgroundColor: `${hexColor}33`,
  borderColor: `${hexColor}`,
  pointBorderColor: `${hexColor}`,
  pointBackgroundColor: `${hexColor}`,
  pointHoverBackgroundColor: `${hexColor}`,
  pointHoverBorderColor: `${hexColor}`
});

export const colors = [
  '#ef5350',
  '#ab47bc',
  '#5c6bc0',
  '#26a69a',
  '#9ccc65',
  '#ffee58',
  '#ffa726',
  '#8d6e63',
  '#78909c',
  '#ec407a',
  '#7e57c2',
  '#42a5f5',
  '#26c6da',
  '#66bb6a',
  '#d4e157',
  '#ffca28',
  '#ff7043',
  '#bdbdbd'
];

export const commonOptions = {
  responsive: true,
  tooltips: {
    mode: 'nearest',
    intersect: false
  },
  legend: {
    display: true
  },
  scales: {
    'x-axis': {
      type: 'time',
      time: {
        minUnit: 'day',
        displayFormats: {
          hour: 'HA'
        },
        tooltipFormat: 'LLL'
      },
      display: true,
      gridLines: {
        display: true
      },
      ticks: {
        autoSkip: true
      }
    }
  }
};

export const generateLabels = (datasets, startDate, endDate) => {
  const firstDate = _.chain(datasets).get('data').first().get('x').value();
  const lastDate = _.chain(datasets).get('data').last().get('x').value();
  const fromDate = startDate || firstDate;
  const toDate = endDate || lastDate;

  const timeDiffDays = moment(toDate).diff(moment(fromDate), 'days');

  return _.map(_.range(0, timeDiffDays + 1), (i) =>
    moment(fromDate).add(i, 'days').toISOString()
  );
};
