import React, { useCallback, useContext, useEffect } from 'react';

import MomentUtils from '@date-io/moment';
import { Tooltip } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import _ from 'lodash';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { AuthContext } from '../../../_context/AuthContext';
import {
  createGrowthActions,
  deleteGrowthActions,
  modifyGrowthActions
} from '../../../_store/actions/growthsActions';
import { closeCreateGrowthDialog } from '../../../_store/actions/uiActions';
import { useVarietiesConfig } from '../../../_store/selectors/configSelectors';
import {
  useCreateGrowthSelectors,
  useDeleteGrowthSelectors,
  useModifyGrowthSelectors
} from '../../../_store/selectors/growthsSelectors';
import { growthConfig } from '../../../config';
import CreateUpdateDeleteDialogActions from '../../CreateUpdateDeleteDialogActions';
import ResponsiveDialogContent from '../../ResponsiveDialogContent';
import VarietyDropdown from '../VarietyDropdown';

const GrowthForm = ({
  growerId,
  seasonId,
  farmId,
  fieldId,
  growthId,
  field,
  season
}) => {
  const dispatch = useDispatch();

  const createSelectors = useCreateGrowthSelectors();
  const modifySelectors = useModifyGrowthSelectors();

  const isEdit = !!growthId;
  const activeGrowth = _.get(field, 'activeGrowth');

  const { isAdmin } = useContext(AuthContext);

  const { varieties } = useVarietiesConfig();

  const {
    inProgress: submitInProgress,
    success: submitSuccess,
    error: submitError
  } = isEdit ? modifySelectors : createSelectors;

  const {
    inProgress: deleteInProgress,
    success: deleteSuccess,
    error: deleteError
  } = useDeleteGrowthSelectors();

  const inProgress = deleteInProgress || submitInProgress;
  const success = submitSuccess || deleteSuccess;
  const apiError = submitError || deleteError;

  const { register, unregister, handleSubmit, errors, watch, setValue } =
    useForm({
      defaultValues: isEdit
        ? {
            lotNumber: _.get(activeGrowth, 'lotNumber'),
            plantingDate: moment(_.get(activeGrowth, 'plantingDate')),
            varietyId:
              _.find(
                varieties,
                (variety) =>
                  _.get(variety, 'id') === _.get(activeGrowth, 'varietyId')
              ) != null
                ? _.get(activeGrowth, 'varietyId')
                : _.chain(varieties).last().get('id').value(),
            targetGrainMoisture:
              _.get(activeGrowth, 'targetGrainMoisture') ||
              growthConfig.defaultTargetGrainMoisture,
            isReference: isAdmin
              ? _.get(activeGrowth, 'isReference', false)
              : undefined
          }
        : {
            plantingDate: moment(),
            varietyId: _.chain(varieties).last().get('id').value(),
            targetGrainMoisture: growthConfig.defaultTargetGrainMoisture,
            isReference: isAdmin ? false : undefined
          }
    });

  const seasonStartDate = _.get(season, 'startDate');
  const seasonEndDate = _.get(season, 'endDate');

  const fieldName = _.get(field, 'name');
  const varietyId = watch('varietyId');
  const isReference = watch('isReference');

  useEffect(() => {
    register(
      { name: 'plantingDate' },
      { required: 'Planting Date is required' }
    );
    register({ name: 'varietyId', required: 'Seed Product is required' });
  }, [register]);

  useEffect(() => {
    if (isAdmin) {
      register({ name: 'isReference' });
      return () => unregister('isReference');
    }
  }, [isAdmin, register, unregister]);

  const handleClose = useCallback(() => {
    dispatch(closeCreateGrowthDialog());
  }, [dispatch]);

  useEffect(() => {
    if (success) {
      dispatch(createGrowthActions.clear());
      dispatch(modifyGrowthActions.clear());
      dispatch(deleteGrowthActions.clear());
      handleClose();
    }
  }, [dispatch, handleClose, success]);

  const plantingDateError = _.get(errors, 'plantingDate.message');

  const handleFormSubmit = useCallback(
    (formData) => {
      const growth = {
        ...formData,
        plantingDate: formData.plantingDate.toISOString()
      };
      if (!formData.targetGrainMoisture) {
        growth.targetGrainMoisture = null;
      }

      if (isEdit) {
        dispatch(
          modifyGrowthActions.request(
            growerId,
            seasonId,
            farmId,
            fieldId,
            growthId,
            growth
          )
        );
      } else {
        dispatch(
          createGrowthActions.request(
            growerId,
            seasonId,
            farmId,
            fieldId,
            growth
          )
        );
      }
    },
    [isEdit, dispatch, growerId, seasonId, farmId, fieldId, growthId]
  );

  const handlePlantingDateChange = useCallback(
    (date) => {
      setValue('plantingDate', date);
    },
    [setValue]
  );

  const handleSeedProductChange = useCallback(
    (e) => {
      setValue('varietyId', e.target.value);
    },
    [setValue]
  );

  const handleConfirmDelete = useCallback(() => {
    dispatch(
      deleteGrowthActions.request(growerId, seasonId, farmId, fieldId, growthId)
    );
  }, [dispatch, farmId, fieldId, growerId, growthId, seasonId]);

  const handleIsReferenceChange = useCallback(
    (event) => {
      setValue('isReference', event.target.checked);
    },
    [setValue]
  );

  return (
    <form noValidate onSubmit={handleSubmit(handleFormSubmit)}>
      <DialogTitle>{`${fieldName} - ${
        isEdit ? 'Edit' : 'Add'
      } Planting`}</DialogTitle>
      <ResponsiveDialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <VarietyDropdown
              value={varietyId}
              onChange={handleSeedProductChange}
              variant="outlined"
              required
              fullWidth
              select
              id="varietyId"
              label="Seed Product"
              name="varietyId"
              error={!!errors.varietyId}
              helperText={_.get(errors, 'varietyId.message')}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              inputRef={register()}
              variant="outlined"
              fullWidth
              id="lotNumber"
              label="Lot Number"
              name="lotNumber"
              error={!!errors.lotNumber}
              helperText={_.get(errors, 'lotNumber.message')}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              inputRef={register({
                min: {
                  value: 0.00000001,
                  message:
                    'Target Grain Moisture Content must be greater than 0'
                },
                max: {
                  value: 100,
                  message: 'Target Grain Moisture Content must be less than 100'
                },
                required: 'Target Grain Moisture Content is required',
                valueAsNumber: true
              })}
              required
              inputProps={{ min: 0, max: 100 }}
              fullWidth
              variant="standard"
              type="number"
              id="targetGrainMoisture"
              label="Target Grain Moisture Content"
              name="targetGrainMoisture"
              error={!!_.get(errors, 'targetGrainMoisture')}
              helperText={_.get(errors, 'targetGrainMoisture.message')}
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <DatePicker
                disableToolbar
                variant="inline"
                format="L"
                id="plantingDate"
                name="plantingDate"
                label="Planting Date"
                value={watch('plantingDate')}
                onChange={handlePlantingDateChange}
                minDate={seasonStartDate}
                maxDate={seasonEndDate}
                autoOk
              />
            </MuiPickersUtilsProvider>
          </Grid>
          {isAdmin && (
            <Grid item xs={12}>
              <Tooltip title="Reference Growth can be used for adjusting the Growth Model">
                <FormControlLabel
                  control={
                    <Checkbox
                      id="isReference"
                      name="isReference"
                      checked={isReference || false}
                      onChange={handleIsReferenceChange}
                    />
                  }
                  label="Reference Growth"
                />
              </Tooltip>
            </Grid>
          )}
          <Grid item xs={12}>
            {plantingDateError && (
              <FormHelperText error>{plantingDateError}</FormHelperText>
            )}
          </Grid>
          <Grid item xs={12}>
            {apiError && <FormHelperText error>{apiError}</FormHelperText>}
          </Grid>
        </Grid>
      </ResponsiveDialogContent>
      <CreateUpdateDeleteDialogActions
        isEdit={isEdit}
        inProgress={inProgress}
        onDelete={handleConfirmDelete}
        onCancel={handleClose}
      />
    </form>
  );
};

export default GrowthForm;
