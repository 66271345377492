import React from 'react';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(() => ({
  deleteButtons: {
    '& > :not(:first-child)': {
      marginLeft: '0.5rem'
    }
  }
}));

const ConfirmDeleteButton = ({
  open,
  disabled,
  onDelete,
  onCancel,
  onConfirmDelete
}) => {
  const styles = useStyles();

  return (
    <Box className={styles.deleteButtons}>
      {!open && (
        <Button
          onClick={onDelete}
          disabled={disabled}
          variant="contained"
          color="secondary"
        >
          Delete
        </Button>
      )}
      {open && (
        <>
          <Button
            onClick={onCancel}
            disabled={disabled}
            variant="contained"
            color="default"
          >
            Cancel
          </Button>
          <Button
            onClick={onConfirmDelete}
            disabled={disabled}
            variant="contained"
            color="secondary"
          >
            Confirm Delete
          </Button>
        </>
      )}
    </Box>
  );
};

export default ConfirmDeleteButton;
