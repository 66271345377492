import { useMemo } from 'react';

import { userService } from '../../../services';
import { createApiSlice } from '../utils/createApiSlice';

const forgotPasswordSlice = createApiSlice({
  reducerPath: ['newUser', 'forgotPassword'],
  apiFunction: userService.forgotPassword,
  authenticate: false,
  apiArgumentsFn: ({ usernameEmail }) => [usernameEmail]
});

const { actions, useApiSelectors } = forgotPasswordSlice;

export const forgotPasswordActions = actions;

export const useForgotPasswordSelectors = () => {
  const { inProgress, errorMessage, success } = useApiSelectors();

  return useMemo(
    () => ({
      inProgress,
      errorMessage,
      success
    }),
    [errorMessage, inProgress, success]
  );
};

export default forgotPasswordSlice;
