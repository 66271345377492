import React from 'react';

import UploadShapefileDialog from './UploadShapefileDialog';

const ConfigurationDialogs = () => {
  return (
    <>
      <UploadShapefileDialog />
    </>
  );
};

export default ConfigurationDialogs;
