import { growthService } from '../../services';
import {
  addMoistureSampleActionTypes,
  deleteMoistureSampleActionTypes,
  updateMoistureSampleActionTypes
} from '../actionTypes/moistureSamplesActionTypes';

import { buildApiActions } from './apiActions';

export const addMoistureSampleActions = buildApiActions(
  addMoistureSampleActionTypes,
  growthService.addMoistureSampleV2
);

export const updateMoistureSampleActions = buildApiActions(
  updateMoistureSampleActionTypes,
  growthService.updateMoistureSampleV2
);

export const deleteMoistureSampleActions = buildApiActions(
  deleteMoistureSampleActionTypes,
  growthService.deleteMoistureSampleV2
);
