import getFarmsStatisticsSlice from './getFarmsStatisticsSlice';
import getFieldsStatisticsSlice from './getFieldsStatisticsSlice';
import getGrowersStatisticsSlice from './getGrowersStatisticsSlice';
import getPageViewsStatisticsSlice from './getPageViewsStatisticsSlice';
import getSeasonFeedbackStatisticsSlice from './getSeasonFeedbackStatisticsSlice';
import getSeasonFieldsStatisticsSlice from './getSeasonFieldsStatisticsSlice';
import getUsersPageViewsStatisticsSlice from './getUsersPageViewsStatisticsSlice';

const statisticsSlices = [
  getGrowersStatisticsSlice,
  getFarmsStatisticsSlice,
  getFieldsStatisticsSlice,
  getSeasonFieldsStatisticsSlice,
  getSeasonFeedbackStatisticsSlice,
  getUsersPageViewsStatisticsSlice,
  getPageViewsStatisticsSlice
];

export default statisticsSlices;
