import { useMemo } from 'react';

import _ from 'lodash';

import { feedbackService } from '../../../services/feedback.service';
import { createApiSlice } from '../utils/createApiSlice';

const changeUserFeedbackStatusSlice = createApiSlice({
  reducerPath: ['feedback', 'changeUserFeedbackStatus'],
  apiFunction: feedbackService.postUsersFeedbackStatus,
  getStateUpdatePath: (action) => {
    const feedbackId = _.first(_.get(action, 'payload.apiArguments'));
    return [feedbackId];
  },
  getSelectorPath: (feedbackId) => {
    return [feedbackId];
  }
});

const { actions, useApiSelectors } = changeUserFeedbackStatusSlice;

export const changeUserFeedbackStatusActions = actions;

export const useChangeUserFeedbackStatusSelectors = (feedbackId) => {
  const { inProgress, errorMessage, success } = useApiSelectors(feedbackId);

  return useMemo(
    () => ({
      inProgress,
      errorMessage,
      success
    }),
    [errorMessage, inProgress, success]
  );
};

export default changeUserFeedbackStatusSlice;
