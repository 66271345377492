import React, { useCallback, useContext, useEffect, useMemo } from 'react';

import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CircularProgress, Tooltip } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import _ from 'lodash';
import { useDispatch } from 'react-redux';

import {
  aquariusGrowerDevicesTypes,
  growerDevicesVendors
} from '../../../_constants/growerDevicesConstants';
import { AuthContext } from '../../../_context/AuthContext';
import { FarmStructureContext } from '../../../_context/FarmStructureContext';
import {
  stopAllCommandActions,
  useStopAllCommandSelectors
} from '../../../_store/slices/devices/commands/stopAllCommandSlice';
import {
  toaster,
  toastError,
  toastSuccess
} from '../../../_utilities/toast-utils';
import ConfirmButton from '../../../Components/ConfirmButton';

const useStyles = makeStyles(() => ({
  stopAll: {
    alignSelf: 'center'
  },
  button: { fontSize: '1rem', fontWeight: 'bold' },
  icon: { fontSize: '1.25rem' },
  text: { display: 'inline-block', marginLeft: '0.75rem' }
}));

const StopAllButton = () => {
  const styles = useStyles();
  const dispatch = useDispatch();

  const { selectedGrower, selectedSeason, selectedGrowerId } =
    useContext(FarmStructureContext);
  const { isUser, isAdmin } = useContext(AuthContext);

  const { inProgress, errorMessage, success } =
    useStopAllCommandSelectors(selectedGrowerId);

  const growerName = _.get(selectedGrower, 'name');

  const hasPumps = useMemo(
    () =>
      _.chain(selectedSeason)
        .get('devices')
        .some(
          (device) =>
            _.get(device, 'vendor') === growerDevicesVendors.Aquarius.type &&
            _.get(device, 'deviceType') ===
              aquariusGrowerDevicesTypes.PumpController.deviceType
        )
        .value(),
    [selectedSeason]
  );

  const shouldShowStopAllButton = (isUser || isAdmin) && hasPumps;

  useEffect(() => {
    if (success) {
      toaster('Stop All Pumps command sent', toastSuccess);
      dispatch(stopAllCommandActions.clear());
    }
  }, [dispatch, success]);

  useEffect(() => {
    if (errorMessage) {
      toaster(
        `Error sending Stop All Pumps command: ${errorMessage}`,
        toastError
      );
      dispatch(stopAllCommandActions.clear());
    }
  }, [dispatch, errorMessage]);

  const handleStop = useCallback(async () => {
    dispatch(stopAllCommandActions.submit({ growerId: selectedGrowerId }));
  }, [dispatch, selectedGrowerId]);

  return (
    <>
      {shouldShowStopAllButton && (
        <Tooltip title={`Stop all Pumps for ${growerName}`}>
          <div className={styles.stopAll}>
            <ConfirmButton
              className={styles.button}
              color="primary"
              size="small"
              onConfirm={handleStop}
              variant="contained"
              disableElevation
              disabled={inProgress}
            >
              {!inProgress && (
                <FontAwesomeIcon
                  className={styles.icon}
                  icon={faExclamationCircle}
                />
              )}
              {inProgress && <CircularProgress size={20} color="primary" />}
              <div className={styles.text}>STOP ALL</div>
            </ConfirmButton>
          </div>
        </Tooltip>
      )}
    </>
  );
};

export default StopAllButton;
