import { useCallback, useMemo } from 'react';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useDispatch } from 'react-redux';

import {
  growersDrawerActions,
  useGrowersDrawerSelectors
} from '../../_store/slices/ui/growersDrawerSlice';
import {
  menuActions,
  useMenuSelectors
} from '../../_store/slices/ui/menuSlice';

export const useMenuState = () => {
  const dispatch = useDispatch();

  const { openSideMenu, showMobileSideMenu } = useMenuSelectors();
  const { growersDrawerOpen } = useGrowersDrawerSelectors();

  const showMobileMenu = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const closeCollidingDrawer = useMediaQuery((theme) =>
    theme.breakpoints.down('xs')
  );

  const collapsedSideMenu = !openSideMenu && !showMobileMenu;

  const toggleMobileSideMenu = useCallback(() => {
    if (!showMobileSideMenu && growersDrawerOpen && closeCollidingDrawer) {
      dispatch(growersDrawerActions.toggleGrowersDrawerOpen());
    }
    dispatch(menuActions.toggleMobileSideMenu());
  }, [closeCollidingDrawer, dispatch, growersDrawerOpen, showMobileSideMenu]);

  return useMemo(
    () => ({
      toggleMobileSideMenu,
      showMobileSideMenu,
      collapsedSideMenu,
      showMobileMenu
    }),
    [
      toggleMobileSideMenu,
      showMobileSideMenu,
      collapsedSideMenu,
      showMobileMenu
    ]
  );
};
