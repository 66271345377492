import React from 'react';

import makeStyles from '@material-ui/core/styles/makeStyles';

import { ContentContainer } from '../../Layout/Content';
import { ContentHeader } from '../../Layout/Content';

const useStyles = makeStyles((theme) => ({
  headerContainer: {
    marginBottom: '1rem'
  },
  chartsHeader: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      textAlign: 'center',
      '& > :not(:first-child)': {
        marginTop: '0.25rem'
      }
    }
  }
}));

const ChartsHeader = ({ children }) => {
  const styles = useStyles();
  return (
    <ContentContainer className={styles.headerContainer}>
      <ContentHeader className={styles.chartsHeader}>{children}</ContentHeader>
    </ContentContainer>
  );
};

export default ChartsHeader;
