import React from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import _ from 'lodash';
import moment from 'moment';

import { useCanModifyFeedback } from '../../_hooks/Growths/useCanModifyFeedback';
import { useGrowthStages } from '../../_hooks/Growths/useGrowthStages';
import { AccordionDataRow } from '../Configuration/AccordionDataRow';

import FeedbackButton from './FeedbackButton';
import GrowthStageBadge from './GrowthStageBadge';

const useStyles = makeStyles(() => ({
  stageItem: {
    marginTop: '1rem'
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'stretch'
  },
  feedbackButton: {
    marginLeft: '0.25rem'
  }
}));

const GrowthStage = ({ stage, growerId, seasonId, farmId, fieldId }) => {
  const styles = useStyles();

  const growthStage = _.get(stage, 'growthStage');
  const stageObserved = _.get(stage, 'observed');
  const stagePending = _.get(stage, 'pending');
  const growthModelDate = _.get(stage, 'date');
  const accumulatedGdd = _.get(stage, 'accumulatedGdd', 0).toFixed(0);

  const { isFeedbackModificationAllowed } = useCanModifyFeedback(stage);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} className={styles.header}>
        <GrowthStageBadge
          growthStage={growthStage}
          stageObserved={stageObserved}
          stagePending={stagePending}
        />
        {isFeedbackModificationAllowed && (
          <Box className={styles.feedbackButton}>
            <FeedbackButton
              growerId={growerId}
              seasonId={seasonId}
              farmId={farmId}
              fieldId={fieldId}
              stage={stage}
            />
          </Box>
        )}
      </Grid>
      <Grid item xs={12}>
        <AccordionDataRow
          title="Estimated Date"
          text={growthModelDate && moment(growthModelDate).format('ll')}
          inline
        />
      </Grid>
      <Grid item xs={12}>
        <AccordionDataRow
          title="Accumulated GDD"
          text={accumulatedGdd}
          inline
        />
      </Grid>
    </Grid>
  );
};

const GrowthModelList = ({ growth, growerId, seasonId, farmId, fieldId }) => {
  const styles = useStyles();

  const { growthStages } = useGrowthStages(growth);

  return (
    <Grid container spacing={2}>
      {_.map(growthStages, (stage) => (
        <Grid
          item
          xs={12}
          key={_.get(stage, 'growthStage')}
          className={styles.stageItem}
        >
          <GrowthStage
            stage={stage}
            growerId={growerId}
            seasonId={seasonId}
            farmId={farmId}
            fieldId={fieldId}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default GrowthModelList;
