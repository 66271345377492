import { useCallback, useEffect, useMemo } from 'react';

import _ from 'lodash';
import { useDispatch } from 'react-redux';

import { useGlobalSeasonSelectors } from '../../_store/slices/gff/seasons/globalSeasonSlice';
import { reportsAppliedFieldsSelectionActions } from '../../_store/slices/reports/reportsAppliedFieldsSelectionSlice';
import { reportsFieldsSelectionActions } from '../../_store/slices/reports/reportsFieldsSelectionSlice';
import { reportsFiltersActions } from '../../_store/slices/reports/reportsFiltersSlice';
import {
  deleteSavedFilterActions,
  useDeleteSavedFilterSelectors
} from '../../_store/slices/reports/savedFilters/deleteSavedFilterSlice';
import {
  getSavedFiltersActions,
  useGetSavedFiltersSelectors
} from '../../_store/slices/reports/savedFilters/getSavedFiltersSlice';
import {
  postSavedFilterActions,
  usePostSavedFilterSelectors
} from '../../_store/slices/reports/savedFilters/postSavedFilterSlice';
import {
  updateSavedFilterActions,
  useUpdateSavedFilterSelectors
} from '../../_store/slices/reports/savedFilters/updateSavedFilterSlice';
import { toaster, toastError } from '../../_utilities/toast-utils';

export function useSavedReportsFilters() {
  const dispatch = useDispatch();

  const { selectedSeason: seasonYear } = useGlobalSeasonSelectors();

  const {
    inProgress: getSavedFiltersInProgress,
    errorMessage: getSavedFiltersErrorMessage,
    success: getSavedFiltersSuccess,
    response: filtersResponse
  } = useGetSavedFiltersSelectors();

  const {
    inProgress: postSavedFilterInProgress,
    errorMessage: postSavedFilterErrorMessage,
    success: postSavedFilterSuccess
  } = usePostSavedFilterSelectors();

  const {
    inProgress: deleteSavedFilterInProgress,
    errorMessage: deleteSavedFilterErrorMessage,
    success: deleteSavedFilterSuccess
  } = useDeleteSavedFilterSelectors();

  const {
    inProgress: updateSavedFilterInProgress,
    errorMessage: updateSavedFilterErrorMessage,
    success: updateSavedFilterSuccess
  } = useUpdateSavedFilterSelectors();

  const savedFilters = useMemo(
    () => _.get(filtersResponse, ['filtersByYear', seasonYear]),
    [filtersResponse, seasonYear]
  );

  const inProgress =
    getSavedFiltersInProgress ||
    postSavedFilterInProgress ||
    deleteSavedFilterInProgress ||
    updateSavedFilterInProgress;
  const errorMessage = getSavedFiltersErrorMessage;
  const success = getSavedFiltersSuccess;

  useEffect(() => {
    dispatch(getSavedFiltersActions.submit());
  }, [dispatch]);

  useEffect(() => {
    if (postSavedFilterSuccess) {
      dispatch(postSavedFilterActions.clear());
      dispatch(getSavedFiltersActions.submit());
    }
  }, [dispatch, postSavedFilterSuccess]);

  useEffect(() => {
    if (postSavedFilterErrorMessage) {
      toaster(
        `Error saving filter: ${postSavedFilterErrorMessage}`,
        toastError
      );
      dispatch(postSavedFilterActions.clear());
    }
  }, [dispatch, postSavedFilterErrorMessage]);

  useEffect(() => {
    if (deleteSavedFilterSuccess) {
      dispatch(deleteSavedFilterActions.clear());
      dispatch(getSavedFiltersActions.submit());
    }
  }, [deleteSavedFilterSuccess, dispatch]);

  useEffect(() => {
    if (updateSavedFilterSuccess) {
      dispatch(updateSavedFilterActions.clear());
      dispatch(getSavedFiltersActions.submit());
    }
  }, [updateSavedFilterSuccess, dispatch]);

  useEffect(() => {
    if (deleteSavedFilterErrorMessage) {
      toaster(
        `Error deleting filter: ${deleteSavedFilterErrorMessage}`,
        toastError
      );
      dispatch(deleteSavedFilterActions.clear());
    }
  }, [dispatch, deleteSavedFilterErrorMessage]);

  useEffect(() => {
    if (updateSavedFilterErrorMessage) {
      toaster(
        `Error updating filter name: ${updateSavedFilterErrorMessage}`,
        toastError
      );
      dispatch(updateSavedFilterActions.clear());
    }
  }, [dispatch, updateSavedFilterErrorMessage]);

  const saveFilters = useCallback(
    (name, fieldsChecked) => {
      dispatch(
        postSavedFilterActions.submit({
          seasonYear,
          filter: { name, fieldsChecked }
        })
      );
    },
    [dispatch, seasonYear]
  );

  const deleteFilters = useCallback(
    (filterId) => {
      dispatch(deleteSavedFilterActions.submit({ seasonYear, filterId }));
    },
    [dispatch, seasonYear]
  );

  const loadFilters = useCallback(
    (filter) => {
      const fieldsChecked = _.get(filter, 'fieldsChecked');
      const filters = {
        tree: fieldsChecked,
        seasonYear
      };
      dispatch(reportsAppliedFieldsSelectionActions.replaceStateTree(filters));
      dispatch(reportsFieldsSelectionActions.replaceStateTree(filters));
      dispatch(reportsFiltersActions.selectFilter(filter));
    },
    [dispatch, seasonYear]
  );

  const saveEditFilters = useCallback(
    (filterId, name) => {
      dispatch(updateSavedFilterActions.submit({ seasonYear, filterId, name }));
    },
    [dispatch, seasonYear]
  );

  return useMemo(
    () => ({
      inProgress,
      errorMessage,
      success,
      savedFilters,
      saveFilters,
      deleteFilters,
      loadFilters,
      saveEditFilters
    }),
    [
      inProgress,
      errorMessage,
      success,
      savedFilters,
      saveFilters,
      deleteFilters,
      loadFilters,
      saveEditFilters
    ]
  );
}
