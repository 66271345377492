import { useMemo } from 'react';

import { feedbackService } from '../../../services/feedback.service';
import { createApiSlice } from '../utils/createApiSlice';

const getUsersFeedbackSlice = createApiSlice({
  reducerPath: ['feedback', 'getUsersFeedback'],
  apiFunction: feedbackService.getUsersFeedback
});

const { actions, useApiSelectors } = getUsersFeedbackSlice;

export const getUsersFeedbackActions = actions;

export const getUsersFeedbackSelectors = useApiSelectors;

export const useGetUsersFeedbackSelectors = () => {
  const { inProgress, errorMessage, success, response } =
    getUsersFeedbackSelectors();

  return useMemo(
    () => ({
      inProgress,
      errorMessage,
      success,
      feedback: response
    }),
    [errorMessage, inProgress, response, success]
  );
};

export default getUsersFeedbackSlice;
