import { useMemo } from 'react';

import _ from 'lodash';
import moment from 'moment';
import { useSelector } from 'react-redux';

import { userService } from '../../../services';
import { createApiSlice } from '../utils/createApiSlice';

const authSlice = createApiSlice({
  reducerPath: ['newUser', 'auth'],
  apiFunction: userService.login,
  authenticate: false,
  customReducers: {
    updateCurrentUser: (state, action) => {
      _.set(state, ['updatedAt'], moment().toISOString());
      _.set(state, ['response'], {
        ..._.get(state, 'response'),
        ...action.payload.response
      });
    }
  }
});

const { actions, createResponseSelector, useApiSelectors, selectors } =
  authSlice;

export const authActions = actions;

export const authSelectors = {
  getToken: createResponseSelector('token'),
  getCurrentUser: selectors.response()
};

export const useAuthSelectors = () => {
  const { inProgress, errorMessage, response } = useApiSelectors();

  const currentUser = response;
  const isAuthenticated = !!currentUser;
  const token = useSelector(authSelectors.getToken);
  const refreshToken = _.get(response, 'refreshToken');

  return useMemo(
    () => ({
      currentUser,
      isAuthenticated,
      inProgress,
      errorMessage,
      token,
      refreshToken
    }),
    [
      currentUser,
      errorMessage,
      inProgress,
      isAuthenticated,
      refreshToken,
      token
    ]
  );
};

export default authSlice;
