import React, { useCallback, useContext, useEffect, useMemo } from 'react';

import { Alert } from '@material-ui/lab';
import _ from 'lodash';

import { FarmStructureContext } from '../../_context/FarmStructureContext';
import {
  DASHBOARD_TABS,
  LOCATION_SOURCES,
  WeatherContext,
  WeatherProvider
} from '../../_context/WeatherContext';
import { useActiveGrowth } from '../../_hooks/Growths/useActiveGrowth';
import { usePageViewAction } from '../../_hooks/usePageViewAction';
import { useFieldWithGrowthSelector } from '../../_store/selectors/fieldsSelectors';
import LoadingIndicator from '../../Components/LoadingIndicator';
import PageTabs from '../../Components/PageTabs';
import AppPage from '../../Layout/AppPage';
import ContentBody from '../../Layout/Content/ContentBody';
import ContentContainer from '../../Layout/Content/ContentContainer';
import PageTitle from '../../Layout/PageTitle';

import CurrentConditions from './components/tabs/CurrentConditions';
import Forecast from './components/tabs/Forecast';
import Growth from './components/tabs/Growth';
import Historical from './components/tabs/Historical';
import TitleHeaderDetails from './components/TitleHeaderDetails';

import 'chartjs-adapter-moment';

const tabsContent = [
  {
    tabId: DASHBOARD_TABS.CURRENT_CONDITIONS,
    name: 'Current conditions'
  },
  {
    tabId: DASHBOARD_TABS.FORECAST,
    name: 'Forecast'
  },
  {
    tabId: DASHBOARD_TABS.HISTORICAL,
    name: 'Historical'
  },
  {
    tabId: DASHBOARD_TABS.GROWTH,
    name: 'Growth'
  }
];

const WeatherDashboard = () => {
  const {
    dashboardTab,
    setDashboardTab,
    locationSource,
    location,
    locationError,
    selectedWeatherStation,
    weatherStations
  } = useContext(WeatherContext);

  const {
    selectedGrowerId,
    selectedSeasonId,
    selectedFarmId,
    selectedFieldId
  } = useContext(FarmStructureContext);

  const { field } = useFieldWithGrowthSelector(
    selectedGrowerId,
    selectedSeasonId,
    selectedFarmId,
    selectedFieldId
  );

  const { activeGrowth } = useActiveGrowth(field);
  const hasGrowth = !!_.get(activeGrowth, 'plantingDate');

  const showWeatherStation =
    locationSource === LOCATION_SOURCES.WEATHER_STATION;
  const showFieldLocation = locationSource === LOCATION_SOURCES.FIELD;
  const showBrowserLocation =
    locationSource === LOCATION_SOURCES.BROWSER_LOCATION;

  const availableTabs = useMemo(() => {
    const tabsWithGrowth =
      hasGrowth && showFieldLocation
        ? tabsContent
        : _.filter(tabsContent, (tab) => tab.tabId !== DASHBOARD_TABS.GROWTH);
    if (locationSource !== LOCATION_SOURCES.WEATHER_STATION) {
      return tabsWithGrowth;
    }
    return _.filter(
      tabsWithGrowth,
      (tab) => tab.tabId !== DASHBOARD_TABS.FORECAST
    );
  }, [hasGrowth, locationSource, showFieldLocation]);

  const showPage =
    (showWeatherStation && selectedWeatherStation) ||
    (showFieldLocation && !!selectedFieldId) ||
    (showBrowserLocation && !!location);

  const selectedTab = useMemo(
    () => _.find(availableTabs, (tab) => _.get(tab, 'tabId') === dashboardTab),
    [availableTabs, dashboardTab]
  );

  usePageViewAction(_.get(selectedTab, 'name'));

  useEffect(() => {
    if (
      (!hasGrowth || !showFieldLocation) &&
      dashboardTab === DASHBOARD_TABS.GROWTH
    ) {
      setDashboardTab(DASHBOARD_TABS.CURRENT_CONDITIONS);
    }
  }, [dashboardTab, hasGrowth, setDashboardTab, showFieldLocation]);

  const handleTabChange = useCallback(
    (tab) => {
      setDashboardTab(tab);
    },
    [setDashboardTab]
  );

  return (
    <AppPage
      header={
        <PageTitle>
          <TitleHeaderDetails />
        </PageTitle>
      }
    >
      <>
        {showPage && (
          <>
            <PageTabs
              tabs={availableTabs}
              onTabChange={handleTabChange}
              activeTabId={dashboardTab}
            />
            {dashboardTab === DASHBOARD_TABS.CURRENT_CONDITIONS && (
              <CurrentConditions />
            )}
            {dashboardTab === DASHBOARD_TABS.FORECAST && <Forecast />}
            {dashboardTab === DASHBOARD_TABS.HISTORICAL && <Historical />}
            {dashboardTab === DASHBOARD_TABS.GROWTH && <Growth />}
          </>
        )}
        {!showPage && !showBrowserLocation && (
          <ContentContainer>
            <ContentBody>
              <Alert severity="info">
                {!_.isEmpty(weatherStations)
                  ? 'Please select a Field, a Weather Station or use current location'
                  : 'Please select a Field or use current location'}
              </Alert>
            </ContentBody>
          </ContentContainer>
        )}
        {showBrowserLocation && !location && !locationError && (
          <ContentContainer>
            <ContentBody>
              <LoadingIndicator />
            </ContentBody>
          </ContentContainer>
        )}
        {showBrowserLocation && locationError && (
          <ContentContainer>
            <ContentBody>
              <Alert severity="error">{locationError}</Alert>
            </ContentBody>
          </ContentContainer>
        )}
      </>
    </AppPage>
  );
};

const WeatherDashboardWithContext = () => (
  <WeatherProvider>
    <WeatherDashboard />
  </WeatherProvider>
);

export default WeatherDashboardWithContext;
