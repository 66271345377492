import React, { Fragment, useMemo } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import cx from 'classnames';
import _ from 'lodash';
import moment from 'moment';

import { useGrowthStagesConfig } from '../../_store/selectors/configSelectors';
import { faObservedGrowthStageIcon } from '../Growths/faObservedGrowthStageIcon';
import GrowthStageBadge from '../Growths/GrowthStageBadge';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  table: {
    maxWidth: '70rem'
  },
  stageObserved: {
    fontWeight: 'bold'
  },
  stageObservedIcon: {
    marginRight: '0.5rem'
  },
  growthStageCircle: (props) => ({
    marginLeft: '0.25rem',
    backgroundColor: props.growthStageColor,
    width: '1.5rem',
    height: '1.5rem',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  })
}));

const GrowthStageCircle = ({ stage }) => {
  const name = _.get(stage, 'name');
  const color = _.get(stage, 'outlineColor');

  const styles = useStyles({ growthStageColor: color });

  return (
    <Tooltip title={name}>
      <Box className={styles.growthStageCircle} />
    </Tooltip>
  );
};

const GrowthModelRow = ({ stage, snapshots }) => {
  const styles = useStyles();

  const smallBadge = useMediaQuery((theme) => theme.breakpoints.down('xs'));

  const growthStage = _.get(stage, 'type');
  return (
    <TableRow key={growthStage}>
      <TableCell>
        {!smallBadge && <GrowthStageBadge growthStage={growthStage} />}
        {smallBadge && <GrowthStageCircle stage={stage} />}
      </TableCell>
      {_.map(snapshots, (snapshot, idx) => {
        const stageObserved = _.get(snapshot, 'observed');
        const startDate = _.get(snapshot, 'startDate');
        const growthModelDate = startDate ? moment(startDate).format('ll') : '';
        const accumulatedGdd = _.get(snapshot, 'accumulatedGdd');
        const dateModified = _.get(snapshot, 'dateModified');
        return (
          <Fragment key={idx}>
            <TableCell
              align="right"
              className={cx({ [styles.stageObserved]: !!stageObserved })}
            >
              <Tooltip
                title={
                  stageObserved && dateModified
                    ? `Modified: ${moment(dateModified).format('MM/DD/YYYY')}`
                    : ''
                }
              >
                <div>
                  {stageObserved && (
                    <FontAwesomeIcon
                      className={styles.stageObservedIcon}
                      icon={faObservedGrowthStageIcon}
                    />
                  )}
                  {growthModelDate && moment(growthModelDate).format('ll')}
                </div>
              </Tooltip>
            </TableCell>
            <TableCell
              align="right"
              className={cx({ [styles.stageObserved]: !!stageObserved })}
            >
              {accumulatedGdd}
            </TableCell>
          </Fragment>
        );
      })}
    </TableRow>
  );
};

const GrowthModelSnapshotsTable = ({
  timelineActivities,
  firstSnapshot,
  secondSnapshot
}) => {
  const styles = useStyles();

  const { growthStagesConfig } = useGrowthStagesConfig();

  const firstDate = useMemo(
    () =>
      firstSnapshot
        ? moment(_.get(firstSnapshot, 'date')).format('ll')
        : undefined,
    [firstSnapshot]
  );

  const secondDate = useMemo(
    () =>
      secondSnapshot
        ? moment(_.get(secondSnapshot, 'date')).format('ll')
        : undefined,
    [secondSnapshot]
  );

  const growthStages = useMemo(() => {
    const stagesByStage = _.chain(timelineActivities)
      .map((item) => _.chain(item).get('stages').keyBy('growthStage').value())
      .value();
    return _.chain(growthStagesConfig)
      .values()
      .orderBy('order')
      .map((stageConfig) => ({
        stage: stageConfig,
        snapshots: _.map(stagesByStage, (item) =>
          _.get(item, [_.get(stageConfig, 'type')])
        )
      }))
      .filter((stage) =>
        _.chain(stage)
          .get('snapshots')
          .some((item) => !!item)
          .value()
      )
      .value();
  }, [growthStagesConfig, timelineActivities]);

  return (
    <Box className={styles.container}>
      <Table className={styles.table}>
        <TableHead>
          <TableRow>
            <TableCell align="center" />
            {firstSnapshot && (
              <TableCell align="center" colSpan={2}>
                {firstDate}
              </TableCell>
            )}
            {secondSnapshot && (
              <TableCell align="center" colSpan={2}>
                {secondDate}
              </TableCell>
            )}
          </TableRow>
          <TableRow>
            <TableCell align="center">Growth Stage</TableCell>
            {firstSnapshot && (
              <>
                <TableCell align="center">Estimated Date</TableCell>
                <TableCell align="center">Accumulated GDD</TableCell>
              </>
            )}
            {secondSnapshot && (
              <>
                <TableCell align="center">Estimated Date</TableCell>
                <TableCell align="center">Accumulated GDD</TableCell>
              </>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {_.map(growthStages, (row) => (
            <GrowthModelRow
              key={_.get(row, 'stage.growthStage')}
              stage={_.get(row, 'stage')}
              snapshots={_.get(row, 'snapshots')}
            />
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};

export default GrowthModelSnapshotsTable;
