import { farmStructureActionTypes } from '../actionTypes/farmStructureActionTypes';

export const farmStructureReducer = (state = {}, action) => {
  switch (action.type) {
    case farmStructureActionTypes.SELECT_GROWER:
      return {
        ...state,
        growerId: action.payload.growerId,
        farmId: null,
        fieldId: null
      };
    case farmStructureActionTypes.SELECT_SEASON:
      return {
        ...state,
        growerId: action.payload.growerId,
        seasonId: action.payload.seasonId,
        farmId: null,
        fieldId: null
      };
    case farmStructureActionTypes.SELECT_FARM:
      return {
        ...state,
        growerId: action.payload.growerId,
        seasonId: action.payload.seasonId,
        farmId: action.payload.farmId,
        fieldId: null
      };
    case farmStructureActionTypes.SELECT_FIELD:
      return {
        ...state,
        growerId: action.payload.growerId,
        seasonId: action.payload.seasonId,
        farmId: action.payload.farmId,
        fieldId: action.payload.fieldId
      };
    default:
      return state;
  }
};
