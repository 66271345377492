import { farmStructureActionTypes } from '../actionTypes/farmStructureActionTypes';

export const selectGrowerAction = (growerId) => ({
  type: farmStructureActionTypes.SELECT_GROWER,
  payload: {
    growerId
  }
});

export const selectSeasonAction = (growerId, seasonId) => ({
  type: farmStructureActionTypes.SELECT_SEASON,
  payload: {
    growerId,
    seasonId
  }
});

export const selectFarmAction = (growerId, seasonId, farmId) => ({
  type: farmStructureActionTypes.SELECT_FARM,
  payload: {
    growerId,
    seasonId,
    farmId
  }
});

export const selectFieldAction = (growerId, seasonId, farmId, fieldId) => ({
  type: farmStructureActionTypes.SELECT_FIELD,
  payload: { growerId, seasonId, farmId, fieldId }
});

export const forceUpdateFarmStructureAction = () => ({
  type: farmStructureActionTypes.FORCE_UPDATE_FARM_STRUCTURE
});
