import { useMemo } from 'react';

import { getGrowthStages } from '../../_helpers/Growths/growthModelHelpers';
import { useGrowthModelSelectors } from '../../_store/selectors/uiSelectors';

export function useGrowthStages(growth) {
  const { selectedGrowthModel } = useGrowthModelSelectors();

  return useMemo(
    () => ({ growthStages: getGrowthStages(growth, selectedGrowthModel) }),
    [growth, selectedGrowthModel]
  );
}
