import React, { useCallback } from 'react';

import { FormControlLabel, Switch } from '@material-ui/core';
import { useDispatch } from 'react-redux';

import {
  cropDashboardActions,
  useCropDashboardSelectors
} from '../../../_store/slices/ui/cropDashboardSlice';

const GrowthsToggle = () => {
  const dispatch = useDispatch();

  const { showAllForGrower } = useCropDashboardSelectors();

  const handleChange = useCallback(() => {
    dispatch(cropDashboardActions.toggleShowAllForGrower());
  }, [dispatch]);

  return (
    <FormControlLabel
      control={
        <Switch
          checked={showAllForGrower}
          onChange={handleChange}
          name="checkedB"
          color="primary"
        />
      }
      label="Show all Farms"
    />
  );
};

export default GrowthsToggle;
