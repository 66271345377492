import React, { useCallback, useContext, useMemo } from 'react';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Tooltip from '@material-ui/core/Tooltip';
import _ from 'lodash';
import moment from 'moment';
import { useDispatch } from 'react-redux';

import { AuthContext } from '../../_context/AuthContext';
import { FarmStructureContext } from '../../_context/FarmStructureContext';
import { getGrowthStages } from '../../_helpers/Growths/growthModelHelpers';
import {
  openAddMoistureSampleDialog,
  openSetTargetMoistureDialog
} from '../../_store/actions/uiActions';
import { useGrowthStagesConfig } from '../../_store/selectors/configSelectors';
import { useActiveGrowthSelectors } from '../../_store/selectors/growthsSelectors';
import { useGrowthModelSelectors } from '../../_store/selectors/uiSelectors';
import { ResponsiveHeaderButtonsContainer } from '../../Layout/Content';

const useStyles = makeStyles(() => ({
  addButton: {
    '&:hover': {
      textDecoration: 'none',
      color: '#fff'
    },
    marginLeft: '0.25rem',
    marginRight: '0.25rem'
  }
}));

const HeaderButton = ({ onClick, text, startIcon, disabled = false }) => {
  const styles = useStyles();

  return (
    <Button
      size="small"
      variant="contained"
      color="primary"
      className={styles.addButton}
      startIcon={startIcon}
      onClick={onClick}
      disabled={disabled}
    >
      {text}
    </Button>
  );
};

const HarvestAdvisorHeaderButtons = () => {
  const dispatch = useDispatch();

  const { isUserView } = useContext(AuthContext);

  const { growthStagesConfig } = useGrowthStagesConfig();

  const {
    selectedField,
    selectedFieldId: fieldId,
    selectedFarmId: farmId,
    selectedSeasonId: seasonId,
    selectedGrowerId: growerId,
    isHistoricalSeason
  } = useContext(FarmStructureContext);

  const { growth, isLoading: inProgress } = useActiveGrowthSelectors(
    seasonId,
    fieldId
  );

  const { selectedGrowthModel } = useGrowthModelSelectors();

  const growthId = _.get(growth, 'id');

  const penultimateStage = useMemo(() => {
    const stages = getGrowthStages(growth, selectedGrowthModel);
    return !!stages && stages.length > 1
      ? stages[stages.length - 2]
      : undefined;
  }, [growth, selectedGrowthModel]);

  const penultimateStageDate = _.get(penultimateStage, 'date');

  const penultimateStageName = _.get(growthStagesConfig, [
    _.get(penultimateStage, 'growthStage'),
    'name'
  ]);

  const addMoistureSampleButtonDisabled = useMemo(() => {
    if (!penultimateStageDate) {
      return true;
    }
    return moment(penultimateStageDate).isAfter(moment(), 'day');
  }, [penultimateStageDate]);

  const handleSetTargetMoistureClick = useCallback(() => {
    dispatch(
      openSetTargetMoistureDialog(growerId, seasonId, farmId, fieldId, growthId)
    );
  }, [dispatch, farmId, fieldId, growerId, growthId, seasonId]);

  const handleAddMoistureSampleClick = useCallback(() => {
    dispatch(
      openAddMoistureSampleDialog(growerId, seasonId, farmId, fieldId, growthId)
    );
  }, [dispatch, farmId, fieldId, growerId, growthId, seasonId]);

  return (
    <ResponsiveHeaderButtonsContainer>
      {selectedField &&
        !inProgress &&
        growth &&
        !isUserView &&
        !isHistoricalSeason && (
          <>
            <HeaderButton
              onClick={handleSetTargetMoistureClick}
              text="Set Target Moisture"
            />
            <Tooltip
              title={`Adding Moisture Samples will become available when the growth enters the ${penultimateStageName} stage`}
              disableFocusListener={!addMoistureSampleButtonDisabled}
              disableHoverListener={!addMoistureSampleButtonDisabled}
              disableTouchListener={!addMoistureSampleButtonDisabled}
            >
              <Box>
                <HeaderButton
                  onClick={handleAddMoistureSampleClick}
                  text="Add Moisture Sample"
                  disabled={addMoistureSampleButtonDisabled}
                />
              </Box>
            </Tooltip>
          </>
        )}
    </ResponsiveHeaderButtonsContainer>
  );
};

export default HarvestAdvisorHeaderButtons;
