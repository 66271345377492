export const uiActionTypes = {
  OPEN_CREATE_GROWTH_DIALOG: 'OPEN_CREATE_GROWTH_DIALOG',
  CLOSE_CREATE_GROWTH_DIALOG: 'CLOSE_CREATE_GROWTH_DIALOG',
  OPEN_MODIFY_GROWTH_DIALOG: 'OPEN_MODIFY_GROWTH_DIALOG',
  CLOSE_MODIFY_GROWTH_DIALOG: 'CLOSE_MODIFY_GROWTH_DIALOG',
  OPEN_GROWTH_FEEDBACK_DIALOG: 'OPEN_GROWTH_FEEDBACK_DIALOG',
  CLOSE_GROWTH_FEEDBACK_DIALOG: 'CLOSE_GROWTH_FEEDBACK_DIALOG',
  SELECT_GROWTH_MODEL_TAB: 'SELECT_GROWTH_MODEL_TAB',
  OPEN_ADD_IRRIGATION_ACTIVITY_DIALOG: 'OPEN_ADD_IRRIGATION_ACTIVITY_DIALOG',
  OPEN_MODIFY_IRRIGATION_ACTIVITY_DIALOG:
    'OPEN_MODIFY_IRRIGATION_ACTIVITY_DIALOG',
  CLOSE_IRRIGATION_ACTIVITY_DIALOG: 'CLOSE_IRRIGATION_ACTIVITY_DIALOG',
  OPEN_ADD_FIELD_ACCESSIBILITY_FEEDBACK_DIALOG:
    'OPEN_ADD_FIELD_ACCESSIBILITY_FEEDBACK_DIALOG',
  CLOSE_ADD_FIELD_ACCESSIBILITY_FEEDBACK_DIALOG:
    'CLOSE_ADD_FIELD_ACCESSIBILITY_FEEDBACK_DIALOG',
  DISABLE_SCROLL: 'DISABLE_SCROLL',
  ENABLE_SCROLL: 'ENABLE_SCROLL',
  SELECT_GROWTH_DASHBOARD_TAB: 'SELECT_GROWTH_DASHBOARD_TAB',
  SELECT_MAP_LAYER: 'SELECT_MAP_LAYER',
  TOGGLE_FIELD_MAP_LAYER: 'TOGGLE_FIELD_MAP_LAYER',
  TOGGLE_DETAILS_MAP_LAYER: 'TOGGLE_DETAILS_MAP_LAYER',
  OPEN_SET_TARGET_MOISTURE_DIALOG: 'OPEN_SET_TARGET_MOISTURE_DIALOG',
  CLOSE_SET_TARGET_MOISTURE_DIALOG: 'CLOSE_SET_TARGET_MOISTURE_DIALOG',
  OPEN_ADD_MOISTURE_SAMPLE_DIALOG: 'OPEN_ADD_MOISTURE_SAMPLE_DIALOG',
  CLOSE_ADD_MOISTURE_SAMPLE_DIALOG: 'CLOSE_ADD_MOISTURE_SAMPLE_DIALOG',
  SELECT_IRRIGATION_VIEW: 'SELECT_IRRIGATION_VIEW',
  SET_WEATHER_PARAMETERS: 'SET_WEATHER_PARAMETERS',
  TOGGLE_ACTIVITY: 'TOGGLE_ACTIVITY',
  TOGGLE_USER_FEEDBACK_STATUS: 'TOGGLE_USER_FEEDBACK_STATUS',
  SET_MAP_ACTIVITY_TYPE: 'SET_MAP_ACTIVITY_TYPE',
  TOGGLE_ZOOM_TO_FIELD: 'TOGGLE_ZOOM_TO_FIELD',
  SELECT_GROWTH_MODEL: 'SELECT_GROWTH_MODEL'
};
