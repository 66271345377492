import React, { useCallback, useMemo } from 'react';

import _ from 'lodash';
import { useDispatch } from 'react-redux';

import { getGrowthStages } from '../../../_helpers/Growths/growthModelHelpers';
import { closeAddMoistureSampleDialog } from '../../../_store/actions/uiActions';
import { useActiveGrowthSelectors } from '../../../_store/selectors/growthsSelectors';
import {
  useAddMoistureSampleDialogSelectors,
  useGrowthModelSelectors
} from '../../../_store/selectors/uiSelectors';
import ResponsiveDialog from '../../ResponsiveDialog';

import MoistureSampleForm from './MoistureSampleForm';

const MoistureSampleDialog = () => {
  const dispatch = useDispatch();

  const { growerId, seasonId, farmId, fieldId, growthId, sampleId, open } =
    useAddMoistureSampleDialogSelectors();

  const { growth } = useActiveGrowthSelectors(seasonId, fieldId);

  const { selectedGrowthModel } = useGrowthModelSelectors();

  const penultimateStageDate = useMemo(() => {
    const stages = getGrowthStages(growth, selectedGrowthModel);
    return !!stages && stages.length > 1
      ? _.get(stages, [stages.length - 2, 'date'])
      : undefined;
  }, [growth, selectedGrowthModel]);

  const moistureSamples = _.get(growth, 'moistureSamples');

  const moistureSample = useMemo(
    () => _.find(moistureSamples, (sample) => _.get(sample, 'id') === sampleId),
    [moistureSamples, sampleId]
  );

  const handleClose = useCallback(() => {
    dispatch(closeAddMoistureSampleDialog());
  }, [dispatch]);

  return (
    <ResponsiveDialog open={open} maxWidth="xs">
      {open && (
        <MoistureSampleForm
          minDate={penultimateStageDate}
          moistureSample={moistureSample}
          moistureSamples={moistureSamples}
          onClose={handleClose}
          growerId={growerId}
          seasonId={seasonId}
          farmId={farmId}
          fieldId={fieldId}
          growthId={growthId}
        />
      )}
    </ResponsiveDialog>
  );
};

export default MoistureSampleDialog;
