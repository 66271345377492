import { faRedo } from '@fortawesome/free-solid-svg-icons/faRedo';
import { faRulerHorizontal } from '@fortawesome/free-solid-svg-icons/faRulerHorizontal';
import { faSync } from '@fortawesome/free-solid-svg-icons/faSync';
import { faWater } from '@fortawesome/free-solid-svg-icons/faWater';

export const fieldIrrigationType = {
  StandardFlood: {
    type: 'StandardFlood',
    name: 'Standard Flood',
    icon: faWater,
    order: 0
  },
  SingleAwd: {
    type: 'SingleAwd',
    name: 'Single AWD',
    icon: faRedo,
    order: 1
  },
  MultipleAwd: {
    type: 'MultipleAwd',
    name: 'Multiple AWD',
    icon: faSync,
    order: 2
  },
  RowRice: {
    type: 'RowRice',
    name: 'Row Rice',
    icon: faRulerHorizontal,
    order: 3
  }
};

export const irrigationType = {
  Paddy: {
    type: 'Paddy',
    name: 'Paddy Rice',
    order: 0
  },
  Row: {
    type: 'Row',
    name: 'Row Rice',
    order: 1
  }
};

export const fieldAccessibilityConditions = [
  {
    type: 'Poor',
    name: 'Poor'
  },
  {
    type: 'Marginal',
    name: 'Marginal'
  },
  {
    type: 'Good',
    name: 'Good'
  }
];
