import React, { useCallback, useContext, useEffect, useMemo } from 'react';

import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Alert } from '@material-ui/lab';
import _ from 'lodash';
import { useDispatch } from 'react-redux';

import { AuthContext } from '../../_context/AuthContext';
import { FarmStructureContext } from '../../_context/FarmStructureContext';
import { usePageViewAction } from '../../_hooks/usePageViewAction';
import {
  updateAllActiveGrowthsForFarmAction,
  updateAllActiveGrowthsForGrowerAction
} from '../../_store/actions/growthsActions';
import { selectGrowthDashboardTab } from '../../_store/actions/uiActions';
import { useSeasonWithGrowthsSelector } from '../../_store/selectors/seasonsSelectors';
import { useGrowthDashboardSelectors } from '../../_store/selectors/uiSelectors';
import { useCropDashboardSelectors } from '../../_store/slices/ui/cropDashboardSlice';
import ActivitiesHeaderButtons from '../../Components/Activities/ActivitiesHeaderButtons';
import ActivitiesTabContent from '../../Components/Activities/ActivitiesTabContent';
import ResponsiveActivities from '../../Components/Activities/ResponsiveActivities';
import FieldAccessibility from '../../Components/FieldAccessibility/FieldAccessibility';
import FieldAccessibilityHeaderButtons from '../../Components/FieldAccessibility/FieldAccessibilityHeaderButtons';
import GrowthModelSnapshots from '../../Components/GrowthModelSnapshots/GrowthModelSnapshots';
import GrowthModelSelector from '../../Components/Growths/GrowthModelSelector';
import GrowthTimeline from '../../Components/Growths/GrowthTimeline';
import FieldHarvestAdvisor from '../../Components/HarvestAdvisor/FieldHarvestAdvisor';
import GrowerHarvestAdvisor from '../../Components/HarvestAdvisor/GrowerHarvestAdvisor';
import LoadingIndicator from '../../Components/LoadingIndicator';
import PageTabs from '../../Components/PageTabs';
import AppPage from '../../Layout/AppPage';
import {
  ContentBody,
  ContentContainer,
  ResponsiveContentHeader
} from '../../Layout/Content';
import PageTitle from '../../Layout/PageTitle';

import GrowthsToggle from './Components/GrowthsToggle';
import ResponsiveFieldsDetails from './Components/ResponsiveFieldsDetails';

export const dashboardTabsIds = {
  GROWTH_MODEL: 'GROWTH_MODEL',
  ACTIVITIES: 'ACTIVITIES',
  HARVEST_ADVISOR: 'HARVEST_ADVISOR',
  GROWTH_SNAPSHOTS: 'GROWTH_SNAPSHOTS',
  FIELD_ACCESSIBILITY: 'FIELD_ACCESSIBILITY'
};

const dashboardTabs = [
  { tabId: dashboardTabsIds.GROWTH_MODEL, name: 'Growth Model' },
  { tabId: dashboardTabsIds.ACTIVITIES, name: 'Activities' },
  { tabId: dashboardTabsIds.HARVEST_ADVISOR, name: 'Harvest Advisor' },
  { tabId: dashboardTabsIds.FIELD_ACCESSIBILITY, name: 'Field Accessibility' },
  { tabId: dashboardTabsIds.GROWTH_SNAPSHOTS, name: 'Growth Snapshots' }
];

export const defaultDashboardTab = _.first(dashboardTabs);

const useStyles = makeStyles(() => ({
  headerControls: {
    '& > :not(:first-child)': {
      marginLeft: '0.5rem'
    }
  }
}));

const DashboardPage = () => {
  const styles = useStyles();
  const dispatch = useDispatch();

  const { isAdmin } = useContext(AuthContext);

  const mobileLayout = useMediaQuery((theme) => theme.breakpoints.down('xs'));

  const { showAllForGrower } = useCropDashboardSelectors();

  const { tabId } = useGrowthDashboardSelectors();

  const {
    selectedGrower,
    selectedGrowerId,
    selectedSeasonId,
    selectedFarmId,
    isActiveSeason,
    farms,
    loading: farmStructureLoading,
    isHistoricalSeason
  } = useContext(FarmStructureContext);

  const { inProgress: seasonInProgress } = useSeasonWithGrowthsSelector(
    selectedGrowerId,
    selectedSeasonId
  );

  const isGrowerFarmSelected =
    !!selectedGrower && (showAllForGrower || !!selectedFarmId);

  const loading = farmStructureLoading || seasonInProgress;

  const availableTabs = useMemo(
    () =>
      _.filter(dashboardTabs, (tab) => {
        const id = _.get(tab, 'tabId');
        if (id === dashboardTabsIds.FIELD_ACCESSIBILITY) {
          return !isHistoricalSeason;
        }
        return true;
      }),
    [isHistoricalSeason]
  );

  const selectedTab = useMemo(
    () => _.find(availableTabs, (tab) => _.get(tab, 'tabId') === tabId),
    [availableTabs, tabId]
  );

  usePageViewAction(_.get(selectedTab, 'name'));

  const showGrowthModel = tabId === dashboardTabsIds.GROWTH_MODEL;
  const showActivities = tabId === dashboardTabsIds.ACTIVITIES;
  const showHarvestAdvisor = tabId === dashboardTabsIds.HARVEST_ADVISOR;
  const showHistorical = tabId === dashboardTabsIds.GROWTH_SNAPSHOTS;
  const showFieldAccessibility =
    !isHistoricalSeason && tabId === dashboardTabsIds.FIELD_ACCESSIBILITY;

  useEffect(() => {
    if (!selectedTab) {
      dispatch(selectGrowthDashboardTab(_.get(defaultDashboardTab, 'tabId')));
    }
  }, [dispatch, loading, selectedGrower, selectedTab]);

  useEffect(() => {
    if (
      (showGrowthModel || showActivities || showHarvestAdvisor) &&
      selectedGrowerId &&
      selectedSeasonId &&
      !_.isEmpty(farms)
    ) {
      if (showAllForGrower) {
        dispatch(
          updateAllActiveGrowthsForGrowerAction(
            selectedGrowerId,
            selectedSeasonId
          )
        );
      }
      if (!showAllForGrower && selectedFarmId) {
        dispatch(
          updateAllActiveGrowthsForFarmAction(
            selectedGrowerId,
            selectedSeasonId,
            selectedFarmId
          )
        );
      }
    }
  }, [
    dispatch,
    selectedGrowerId,
    showGrowthModel,
    farms,
    selectedSeasonId,
    isActiveSeason,
    showActivities,
    showAllForGrower,
    selectedFarmId,
    showHarvestAdvisor
  ]);

  const handleTabChange = useCallback(
    (tabId) => {
      dispatch(selectGrowthDashboardTab(tabId));
    },
    [dispatch]
  );

  return (
    <AppPage
      header={
        <PageTitle>
          <Box className={styles.headerControls}>
            {isAdmin && <GrowthModelSelector showLabel />} <GrowthsToggle />
          </Box>
        </PageTitle>
      }
    >
      {loading && <LoadingIndicator />}
      {!loading && (
        <>
          <PageTabs
            tabs={availableTabs}
            onTabChange={handleTabChange}
            activeTabId={tabId}
          />
          {isGrowerFarmSelected && showGrowthModel && (
            <>
              {!mobileLayout && (
                <ContentContainer>
                  <ResponsiveContentHeader>
                    <span>Timeline</span>
                  </ResponsiveContentHeader>
                  <ContentBody>
                    <GrowthTimeline />
                  </ContentBody>
                </ContentContainer>
              )}
              <ContentContainer>
                <ResponsiveContentHeader>Fields</ResponsiveContentHeader>
                <ContentBody>
                  <ResponsiveFieldsDetails />
                </ContentBody>
              </ContentContainer>
            </>
          )}
          {isGrowerFarmSelected && showActivities && (
            <>
              {!mobileLayout && (
                <ContentContainer>
                  <ResponsiveContentHeader>
                    <span>Timeline</span>
                    <ActivitiesHeaderButtons />
                  </ResponsiveContentHeader>
                  <ContentBody>
                    <ActivitiesTabContent />
                  </ContentBody>
                </ContentContainer>
              )}
              <ContentContainer>
                <ResponsiveContentHeader>Activities</ResponsiveContentHeader>
                <ContentBody>
                  <ResponsiveActivities />
                </ContentBody>
              </ContentContainer>
            </>
          )}
          {isGrowerFarmSelected && showHarvestAdvisor && (
            <>
              <GrowerHarvestAdvisor />
              <FieldHarvestAdvisor />
            </>
          )}
          {isGrowerFarmSelected && showFieldAccessibility && (
            <ContentContainer>
              <ResponsiveContentHeader>
                <span>Field Accessibility</span>
                <FieldAccessibilityHeaderButtons />
              </ResponsiveContentHeader>
              <ContentBody>
                <FieldAccessibility />
              </ContentBody>
            </ContentContainer>
          )}
          {isGrowerFarmSelected && showHistorical && (
            <GrowthModelSnapshots mobileLayout={mobileLayout} />
          )}
          {!isGrowerFarmSelected && (
            <ContentContainer>
              <ContentBody>
                {!selectedGrowerId && (
                  <Alert severity="info">Please select a grower</Alert>
                )}
                {selectedGrowerId && (
                  <Alert severity="info">Please select a farm</Alert>
                )}
              </ContentBody>
            </ContentContainer>
          )}
        </>
      )}
    </AppPage>
  );
};

export default DashboardPage;
