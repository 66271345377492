import React, { useEffect, useMemo, useState } from 'react';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import _ from 'lodash';
import { Bar } from 'react-chartjs-2';

import { generateLabels } from '../../IrrigationDashboard/Components/sensorChartsUtils';

import {
  commonLineDataSetParams,
  getAggregatedValues,
  getDefaultOptions,
  getValue
} from './weatherChartUtils';

const GDDChart = ({ chartRef, GDDData, timeUnit, startDate, endDate }) => {
  const [options, setOptions] = useState();

  const highChart = useMediaQuery((theme) => theme.breakpoints.down('xs'));

  const graphData = useMemo(() => {
    if (GDDData) {
      const GDDValues = _.filter(
        getValue(GDDData, 'gdd'),
        (item) => _.isNumber(item.y) && !_.isNaN(item.y)
      );
      const minTempValues = getValue(GDDData, 'airTempMin');
      const maxTempValues = getValue(GDDData, 'airTempMax');

      const accumulatedGDD = getAggregatedValues(GDDData, 'gdd');

      const datasets = [
        {
          ...commonLineDataSetParams('#6d4c41'),
          label: 'Accumulated GDD',
          yAxisID: 'y-axis-gdd',
          data: accumulatedGDD
        },
        {
          label: 'GDD',
          yAxisID: 'y-axis-gdd',
          type: 'bar',
          backgroundColor: '#8d6e6366',
          borderColor: '#8d6e63',
          data: GDDValues
        },
        {
          ...commonLineDataSetParams('#ef5350'),
          label: 'Max Temperature',
          yAxisID: 'y-axis-temp',
          data: maxTempValues
        },
        {
          ...commonLineDataSetParams('#ef9a9a'),
          label: 'Min Temperature',
          yAxisID: 'y-axis-temp',
          fill: '-1',
          data: minTempValues
        }
      ].filter((dataset) => dataset.data);

      const labels = generateLabels(datasets, startDate, endDate);

      return {
        labels,
        datasets
      };
    }
  }, [GDDData, endDate, startDate]);

  useEffect(() => {
    const defaultOptions = getDefaultOptions(timeUnit);
    defaultOptions.scales['y-axis-temp'] = {
      type: 'linear',
      display: true,
      position: 'left',
      id: 'y-axis-temp',
      grid: {
        display: false
      }
    };
    defaultOptions.scales['y-axis-gdd'] = {
      type: 'linear',
      display: true,
      position: 'right',
      id: 'y-axis-gdd',
      grid: {
        display: true
      },
      min: 0
    };

    setOptions(defaultOptions);
  }, [timeUnit]);

  return (
    <>
      {graphData && options && highChart && (
        <Bar
          ref={chartRef}
          data={graphData}
          options={options}
          height={250}
          type="bar"
        />
      )}
      {graphData && options && !highChart && (
        <Bar
          ref={chartRef}
          data={graphData}
          options={options}
          height={120}
          type="bar"
        />
      )}
    </>
  );
};

export default GDDChart;
