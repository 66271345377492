import { addToken, client } from './client';

export const deviceCommands = {
  postCommandV2,
  getCommandsV2,
  postStopAllCommandV2
};

async function postCommandV2(token, growerId, seasonId, deviceId, command) {
  const response = await client.post(
    `/v2/growers/${growerId}/seasons/${seasonId}/devices/${deviceId}/commands`,
    command,
    addToken(token)
  );
  return response.data;
}

async function getCommandsV2(token, growerId, seasonId, deviceId) {
  const response = await client.get(
    `/v2/growers/${growerId}/seasons/${seasonId}/devices/${deviceId}/commands`,
    addToken(token)
  );
  return response.data;
}

async function postStopAllCommandV2(token, growerId) {
  const response = await client.post(
    `/v2/growers/${growerId}/devices/stop-all`,
    {},
    addToken(token)
  );
  return response.data;
}
