import { useMemo } from 'react';

import { growerDevicesService } from '../../../../services/growerDevices.service';
import { createApiSlice } from '../../utils/createApiSlice';

const deleteGrowerDeviceSlice = createApiSlice({
  reducerPath: ['devices', 'growerDevices', 'deleteGrowerDevice'],
  apiFunction: growerDevicesService.deleteGrowerDevicesV2
});

const { actions, useApiSelectors } = deleteGrowerDeviceSlice;

export const deleteGrowerDeviceActions = actions;

export const useDeleteGrowerDeviceSelectors = () => {
  const { inProgress, success, errorMessage, response, updatedAt } =
    useApiSelectors();

  return useMemo(
    () => ({ inProgress, success, errorMessage, response, updatedAt }),
    [errorMessage, inProgress, response, success, updatedAt]
  );
};

export default deleteGrowerDeviceSlice;
