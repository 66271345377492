import _ from 'lodash';

import FlowMeterIcon from '../Components/Devices/DeviceIcon/Icons/FlowMeterIcon';
import LevelIcon from '../Components/Devices/DeviceIcon/Icons/LevelIcon';
import MoistureIcon from '../Components/Devices/DeviceIcon/Icons/MoistureIcon';
import PumpIcon from '../Components/Devices/DeviceIcon/Icons/PumpIcon';
import TelemetryIcon from '../Components/Devices/DeviceIcon/Icons/TelemetryIcon';

export const growerDevicesVendors = {
  Aquarius: {
    type: 0,
    name: 'Aquarius'
  },
  Ambient: {
    type: 1,
    name: 'Ambient'
  }
};

export const aquariusGrowerDevicesTypes = {
  MoistureSensor: {
    deviceType: 0,
    name: 'Moisture Sensor',
    icon: <MoistureIcon size={40} />
  },
  WaterLevelSensor: {
    deviceType: 1,
    name: 'Water Level Sensor',
    icon: <LevelIcon size={40} />
  },
  FlowMeter: {
    deviceType: 2,
    name: 'Flow Meter',
    icon: <FlowMeterIcon size={40} />
  },
  TelemetryHub: {
    deviceType: 3,
    name: 'Telemetry Hub',
    icon: <TelemetryIcon size={40} />
  },
  PumpController: {
    deviceType: 4,
    name: 'Pump Controller',
    icon: <PumpIcon size={40} />
  }
};

export const aquariusFieldDevicesTypes = [
  aquariusGrowerDevicesTypes.MoistureSensor.deviceType,
  aquariusGrowerDevicesTypes.WaterLevelSensor.deviceType,
  aquariusGrowerDevicesTypes.FlowMeter.deviceType
];

export const aquariusSeasonDevicesTypes = [
  aquariusGrowerDevicesTypes.TelemetryHub.deviceType,
  aquariusGrowerDevicesTypes.PumpController.deviceType
];

export const aquariusGrowerDevicesTypesByDeviceType = _.chain(
  aquariusGrowerDevicesTypes
)
  .values()
  .keyBy('deviceType')
  .value();
