import React, { useContext, useMemo } from 'react';

import _ from 'lodash';

import { FarmStructureContext } from '../../../_context/FarmStructureContext';
import {
  LOCATION_SOURCES,
  WeatherContext
} from '../../../_context/WeatherContext';
import ChartDownloadButton from '../../../Components/Charts/ChartDownloadButton';

import { chartSelectorItems } from './ChartSelector';

const WeatherChartDownloadButton = ({ chartRef, tabName }) => {
  const { selectedChart, locationSource, selectedWeatherStation } =
    useContext(WeatherContext);

  const { selectedField } = useContext(FarmStructureContext);

  const showWeatherStation =
    locationSource === LOCATION_SOURCES.WEATHER_STATION;
  const showFieldLocation = locationSource === LOCATION_SOURCES.FIELD;
  const showBrowserLocation =
    locationSource === LOCATION_SOURCES.BROWSER_LOCATION;

  const fieldName = _.get(selectedField, 'name');
  const weatherStationName = _.get(selectedWeatherStation, 'name');

  const locationName = useMemo(() => {
    if (showFieldLocation) {
      return fieldName;
    }
    if (showWeatherStation) {
      return weatherStationName;
    }
    if (showBrowserLocation) {
      return 'Current Location';
    }
    return '';
  }, [
    fieldName,
    showBrowserLocation,
    showFieldLocation,
    showWeatherStation,
    weatherStationName
  ]);

  const filename = useMemo(() => {
    const chartName = _.chain(chartSelectorItems)
      .find((item) => _.get(item, 'id') === selectedChart)
      .get('name')
      .value();
    return `${locationName}-${tabName}-${chartName}-Chart.png`;
  }, [locationName, selectedChart, tabName]);

  return (
    <ChartDownloadButton
      chartRef={chartRef}
      fileName={filename}
      size="small"
      color="primary"
      variant="contained"
      disableElevation
      iconButton
    />
  );
};

export default WeatherChartDownloadButton;
