import React, { useMemo } from 'react';

import makeStyles from '@material-ui/core/styles/makeStyles';
import _ from 'lodash';

const useStyles = makeStyles(() => ({
  text: {
    opacity: 0.875
  }
}));

const GrowthActivitiesTimelineLabels = ({
  svgWidth,
  barHeight,
  barGap,
  growthModelTimelineBarHeight,
  growthModelTimelineGap,
  timelineActivities,
  fieldNamesWidth
}) => {
  const styles = useStyles();

  const labels = useMemo(() => {
    return _.flatMap(timelineActivities, (growth) => {
      const startY = _.get(growth, 'startY');
      return _.chain(growth)
        .get('activitiesByType')
        .values()
        .filter((activities) => !_.isEmpty(activities))
        .map((activities, rowIdx) => {
          const y =
            startY +
            rowIdx * (barHeight + barGap) +
            barHeight / 2 +
            growthModelTimelineBarHeight +
            growthModelTimelineGap;
          const type = _.get(activities, '0.type');

          return { y, type, name: type.replace('Application', '') };
        })
        .value();
    });
  }, [
    barGap,
    barHeight,
    growthModelTimelineBarHeight,
    growthModelTimelineGap,
    timelineActivities
  ]);

  return (
    <g>
      {svgWidth > 0 && (
        <>
          {_.map(labels, (label) => (
            <text
              key={`${_.get(label, 'y')}-${_.get(label, 'type')}`}
              className={styles.text}
              x={fieldNamesWidth + 10}
              y={_.get(label, 'y')}
              fontSize={14}
              textAnchor="start"
              dominantBaseline="middle"
              fill="#000000"
            >
              {_.get(label, 'name')}
            </text>
          ))}
        </>
      )}
    </g>
  );
};

export default GrowthActivitiesTimelineLabels;
