import getAllDeviceStateSlice from './getAllDeviceStateSlice';
import getCurrentDeviceStateSlice from './getCurrentDeviceStateSlice';
import getDailyDeviceStateSlice from './getDailyDeviceStateSlice';
import getHourlyDeviceStateSlice from './getHourlyDeviceStateSlice';

const devicesStateSlices = [
  getDailyDeviceStateSlice,
  getCurrentDeviceStateSlice,
  getAllDeviceStateSlice,
  getHourlyDeviceStateSlice
];

export default devicesStateSlices;
