import { all, fork } from 'redux-saga/effects';

import apiErrorsSaga from './apiErrorsSaga';
import apiRequestSaga from './apiRequestSaga';
import configSaga from './configSaga';
import currentUserSaga from './currentUserSaga';
import farmStructureSaga from './farmStructureSaga';
import fieldAccessibilitySaga from './fieldAccessibilitySaga';
import gffConfigSaga from './gffConfigSaga';
import growthsFeedbackSaga from './growthsFeedbackSaga';
import growthsSaga from './growthsSaga';
import moistureSamplesSaga from './moistureSamplesSaga';
import slicesRequestsSaga from './slicesRequestsSaga';
import weatherSaga from './weatherSaga';

export default function* rootSaga() {
  yield all([
    fork(apiRequestSaga),
    fork(apiErrorsSaga),
    fork(farmStructureSaga),
    fork(growthsSaga),
    fork(growthsFeedbackSaga),
    fork(configSaga),
    fork(fieldAccessibilitySaga),
    fork(gffConfigSaga),
    fork(moistureSamplesSaga),
    fork(weatherSaga),
    fork(currentUserSaga),
    fork(slicesRequestsSaga)
  ]);
}
