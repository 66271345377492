import _ from 'lodash';
import moment from 'moment';
import {
  all,
  call,
  fork,
  put,
  select,
  takeEvery,
  takeLatest
} from 'redux-saga/effects';

import { apiUpdateIntervals } from '../../config';
import {
  addFieldAccessibilityFeedbackActions,
  getFieldAccessibilityActions
} from '../actions/fieldAccessibilityActions';
import {
  addFieldAccessibilityFeedbackActionTypes,
  fieldAccessibilityActionTypes
} from '../actionTypes/fieldAccessibilityActionTypes';
import { requestStatus } from '../helpers/requestStatus';
import { farmsSelector } from '../selectors/farmsSelectors';
import {
  fieldAccessibilityErrorStatusSelector,
  fieldAccessibilityStatusSelector,
  fieldAccessibilityUpdatedAtSelector
} from '../selectors/fieldAccessibilitySelectors';
import { fieldsSelector } from '../selectors/fieldsSelectors';

function* updateFieldAccessibility(
  growerId,
  seasonId,
  farmId,
  fieldId,
  forceUpdate = false
) {
  const fieldAccessibilityStatus = yield select(
    fieldAccessibilityStatusSelector(fieldId)
  );
  const updatedAt = yield select(fieldAccessibilityUpdatedAtSelector(fieldId));
  const errorStatus = yield select(
    fieldAccessibilityErrorStatusSelector(fieldId)
  );

  const shouldUpdate =
    !updatedAt ||
    moment().hour() !== moment(updatedAt).hour() ||
    moment().diff(moment(updatedAt), 'seconds') >
      apiUpdateIntervals.fieldAccessibilityUpdateIntervalSeconds ||
    (fieldAccessibilityStatus === requestStatus.FAILURE && errorStatus !== 404);
  if (
    fieldAccessibilityStatus !== requestStatus.IN_PROGRESS &&
    (forceUpdate || shouldUpdate)
  ) {
    yield put(
      getFieldAccessibilityActions.request(growerId, seasonId, farmId, fieldId)
    );
  }
}

function* updateFieldAccessibilityForAllFieldsForGrower(growerId, seasonId) {
  if (!growerId || !seasonId) {
    return;
  }
  const farmsById = yield select(farmsSelector(growerId, seasonId));
  const fields = _.chain(farmsById)
    .values()
    .flatMap((farm) => _.chain(farm).get('fields').values().value())
    .map((field) => ({ fieldId: field.id, farmId: field.farmId }))
    .value();
  yield all(
    fields.map((field) =>
      call(
        updateFieldAccessibility,
        growerId,
        seasonId,
        field.farmId,
        field.fieldId
      )
    )
  );
}

function* updateFieldAccessibilityForAllFieldsForFarm(
  growerId,
  seasonId,
  farmId
) {
  if (!growerId || !seasonId || !farmId) {
    return;
  }
  const fieldsById = yield select(fieldsSelector(growerId, seasonId, farmId));
  const fieldsWithFieldAccessibility = _.chain(fieldsById)
    .map((field) => ({ fieldId: field.id, farmId: field.farmId }))
    .value();
  yield all(
    fieldsWithFieldAccessibility.map((field) =>
      call(
        updateFieldAccessibility,
        growerId,
        seasonId,
        field.farmId,
        field.fieldId
      )
    )
  );
}

function* handleUpdateFieldAccessibility(action) {
  const { growerId, seasonId, farmId } = action.payload;
  if (growerId && seasonId && !farmId) {
    yield call(
      updateFieldAccessibilityForAllFieldsForGrower,
      growerId,
      seasonId
    );
  } else if (growerId && seasonId && farmId) {
    yield call(
      updateFieldAccessibilityForAllFieldsForFarm,
      growerId,
      seasonId,
      farmId
    );
  }
}

function* handleAddFieldAccessibilityFeedback(action) {
  const { growerId, seasonId, farmId, fieldId, feedback } = action.payload;

  yield put(
    addFieldAccessibilityFeedbackActions.request(
      growerId,
      seasonId,
      farmId,
      fieldId,
      feedback
    )
  );
}

function* handleFieldAccessibilityFeedbackAdded(action) {
  const growerId = _.get(action, 'payload.apiArguments.0');
  const seasonId = _.get(action, 'payload.apiArguments.1');
  const farmId = _.get(action, 'payload.apiArguments.2');
  const fieldId = _.get(action, 'payload.apiArguments.3');
  yield call(
    updateFieldAccessibility,
    growerId,
    seasonId,
    farmId,
    fieldId,
    true
  );
}

function* watchUpdateFieldAccessibility() {
  yield takeEvery(
    fieldAccessibilityActionTypes.UPDATE_FIELD_ACCESSIBILITY,
    handleUpdateFieldAccessibility
  );
}

function* watchAddFieldAccessibilityFeedback() {
  yield takeLatest(
    addFieldAccessibilityFeedbackActionTypes.SUBMIT,
    handleAddFieldAccessibilityFeedback
  );
}

function* watchFieldAccessibilityFeedbackAdded() {
  yield takeLatest(
    addFieldAccessibilityFeedbackActionTypes.SUCCEEDED,
    handleFieldAccessibilityFeedbackAdded
  );
}

function* fieldAccessibilitySaga() {
  yield all([
    fork(watchUpdateFieldAccessibility),
    fork(watchAddFieldAccessibilityFeedback),
    fork(watchFieldAccessibilityFeedbackAdded)
  ]);
}

export default fieldAccessibilitySaga;
