import _ from 'lodash';
import { all, put, fork, takeEvery } from 'redux-saga/effects';

import { saveGrowthStageConfigRequestAction } from '../actions/configActions';
import { growthStagesConfigActionTypes } from '../actionTypes/configActionTypes';

function* handleSaveGrowthStageConfig(action) {
  const growthStageType = _.get(action, 'payload.growthStageType');
  const config = _.get(action, 'payload.config');
  yield put(
    saveGrowthStageConfigRequestAction.request(growthStageType, config)
  );
}

function* watchSaveGrowthStageConfig() {
  yield takeEvery(
    growthStagesConfigActionTypes.SAVE_GROWTH_STAGE_CONFIG,
    handleSaveGrowthStageConfig
  );
}

function* configSaga() {
  yield all([fork(watchSaveGrowthStageConfig)]);
}

export default configSaga;
