import { combineReducers } from 'redux';

import { slicesReducers } from '../slices';
import { clearUserDataActions } from '../slices/user/clearUserDataSlice';

import { configReducer } from './configReducer';
import { farmsReducer } from './farmsReducer';
import { farmStructureReducer } from './farmStructureReducer';
import { fieldAccessibilityReducer } from './fieldAccessibilityReducer';
import { fieldsReducer } from './fieldsReducer';
import { growersReducer } from './growersReducer';
import { growthsFeedbackReducer } from './growthsFeedbackReducer';
import { growthsReducer } from './growthsReducer';
import { moistureSamplesReducer } from './moistureSamplesReducer';
import { notificationsConfigReducer } from './notificationsConfigReducer';
import { termsOfServiceReducer } from './termsOfServiceReducer';
import { uiReducer } from './uiReducer';
import { userManagementReducer } from './userManagementReducer';
import { userReducer } from './userReducer';
import { weatherReducer } from './weatherReducer';

const reducers = {
  user: userReducer,
  growers: growersReducer,
  farms: farmsReducer,
  fields: fieldsReducer,
  growths: growthsReducer,
  growthsFeedback: growthsFeedbackReducer,
  farmStructure: farmStructureReducer,
  config: configReducer,
  uiLegacy: uiReducer,
  fieldAccessibility: fieldAccessibilityReducer,
  userManagement: userManagementReducer,
  moistureSamples: moistureSamplesReducer,
  termsOfService: termsOfServiceReducer,
  weather: weatherReducer,
  notificationsConfig: notificationsConfigReducer
};

const allReducers = combineReducers({ ...reducers, ...slicesReducers });

const rootReducer = (state, action) => {
  if (action.type === clearUserDataActions.clear.type) {
    // clear the store
    state = {};
  }
  return allReducers(state, action);
};

export default rootReducer;
