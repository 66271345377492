import React, { useCallback, useContext, useState } from 'react';

import { Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import makeStyles from '@material-ui/core/styles/makeStyles';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import CloseIcon from '@material-ui/icons/Close';

import { AuthContext } from '../../../../../_context/AuthContext';
import ResponsiveDialog from '../../../../../Components/ResponsiveDialog';
import ResponsiveDialogContent from '../../../../../Components/ResponsiveDialogContent';
import ChangePasswordDialog from '../../ChangePasswordDialog';
import EnvironmentNameBadge from '../../EnvironmentNameBadge';
import StopAllButton from '../../StopAllButton';

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '1rem 1.5rem',
    borderBottom: '1px solid rgba(0, 0, 0, 0.125)'
  },
  icon: {
    marginRight: '0.75rem',
    color: theme.palette.brand.primary.light
  },
  usernameContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  username: {
    fontWeight: '500',
    fontSize: '1.25rem'
  },
  buttonContainer: { marginLeft: '1rem' },
  closeButton: {
    marginLeft: '1rem'
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '1rem 0',
    '& > :not(:first-child)': {
      marginTop: '1rem'
    }
  }
}));

const MobileHeaderMenu = ({ isOpen, toggleOpen }) => {
  const styles = useStyles();
  const { user, isActive, logOut, tosAccepted } = useContext(AuthContext);

  const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] =
    useState(false);

  const handleLogoutClick = useCallback(() => {
    logOut();
  }, [logOut]);

  const handleToggleChangePasswordModal = useCallback(() => {
    setIsChangePasswordModalOpen(!isChangePasswordModalOpen);
  }, [isChangePasswordModalOpen]);

  return (
    <>
      <ResponsiveDialog open={isOpen} onClose={toggleOpen} maxWidth="xs">
        <Box className={styles.header}>
          <Box className={styles.usernameContainer}>
            <AccountCircleIcon fontSize="large" className={styles.icon} />
            <Typography
              className={styles.username}
            >{`${user.firstName} ${user.lastName}`}</Typography>
          </Box>
          <Box className={styles.buttonContainer}>
            <IconButton onClick={toggleOpen} className={styles.closeButton}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
        <ResponsiveDialogContent>
          <Box className={styles.buttonsContainer}>
            <EnvironmentNameBadge color="default" />
            {isActive && tosAccepted && <StopAllButton />}
            <Button
              onClick={handleToggleChangePasswordModal}
              variant="contained"
              color="primary"
            >
              Change Password
            </Button>
            <Button
              onClick={handleLogoutClick}
              variant="contained"
              color="primary"
            >
              Log Out
            </Button>
          </Box>
        </ResponsiveDialogContent>
      </ResponsiveDialog>
      <ChangePasswordDialog
        isOpen={isChangePasswordModalOpen}
        toggle={handleToggleChangePasswordModal}
      />
    </>
  );
};

export default MobileHeaderMenu;
