import { farmService } from '../../services';
import {
  createFarmActionTypes,
  deleteFarmActionTypes,
  getConfigFarmsActionTypes,
  updateFarmActionTypes
} from '../actionTypes/farmsActionTypes';

import { buildApiActions } from './apiActions';

export const getConfigFarmsActions = buildApiActions(
  getConfigFarmsActionTypes,
  farmService.getFarmsV2
);

export const createFarmActions = buildApiActions(
  createFarmActionTypes,
  farmService.createFarmV2
);

export const updateFarmActions = buildApiActions(
  updateFarmActionTypes,
  farmService.updateFarmV2
);

export const deleteFarmActions = {
  ...buildApiActions(deleteFarmActionTypes, farmService.deleteFarmV2),
  select: (growerId, farmId) => ({
    type: deleteFarmActionTypes.SELECT,
    payload: {
      growerId,
      farmId
    }
  })
};
