import { getErrorMessage } from '../../services/errors';
import {
  createUserActionTypes,
  deleteUserActionTypes,
  getUsersActionTypes,
  updateUserActionTypes
} from '../actionTypes/userManagementActionTypes';
import { requestStatus } from '../helpers/requestStatus';

export const userManagementReducer = (state = {}, action) => {
  switch (action.type) {
    case getUsersActionTypes.REQUEST:
      return {
        ...state,
        users: {
          status: requestStatus.IN_PROGRESS
        }
      };
    case getUsersActionTypes.SUCCEEDED:
      return {
        ...state,
        users: {
          status: requestStatus.SUCCESS,
          response: action.payload.response
        }
      };
    case getUsersActionTypes.FAILED:
      return {
        ...state,
        users: {
          status: requestStatus.FAILURE,
          error: {
            message: getErrorMessage(action.payload.apiError)
          }
        }
      };
    case createUserActionTypes.REQUEST:
      return {
        ...state,
        createUser: {
          status: requestStatus.IN_PROGRESS
        }
      };
    case createUserActionTypes.SUCCEEDED:
      return {
        ...state,
        createUser: {
          status: requestStatus.SUCCESS
        }
      };
    case createUserActionTypes.FAILED:
      return {
        ...state,
        createUser: {
          status: requestStatus.FAILURE,
          error: {
            message: getErrorMessage(action.payload.apiError)
          }
        }
      };
    case createUserActionTypes.CLEAR:
      return {
        ...state,
        createUser: {}
      };
    case updateUserActionTypes.REQUEST:
      return {
        ...state,
        updateUser: {
          status: requestStatus.IN_PROGRESS
        }
      };
    case updateUserActionTypes.SUCCEEDED:
      return {
        ...state,
        updateUser: {
          status: requestStatus.SUCCESS
        }
      };
    case updateUserActionTypes.FAILED:
      return {
        ...state,
        updateUser: {
          status: requestStatus.FAILURE,
          error: {
            message: getErrorMessage(action.payload.apiError)
          }
        }
      };
    case updateUserActionTypes.CLEAR:
      return {
        ...state,
        updateUser: {}
      };
    case deleteUserActionTypes.REQUEST:
      return {
        ...state,
        deleteUser: {
          status: requestStatus.IN_PROGRESS
        }
      };
    case deleteUserActionTypes.SUCCEEDED:
      return {
        ...state,
        deleteUser: {
          status: requestStatus.SUCCESS
        }
      };
    case deleteUserActionTypes.FAILED:
      return {
        ...state,
        deleteUser: {
          status: requestStatus.FAILURE,
          error: {
            message: getErrorMessage(action.payload.apiError)
          }
        }
      };
    case deleteUserActionTypes.CLEAR:
      return {
        ...state,
        deleteUser: {}
      };
    case deleteUserActionTypes.SELECT:
      return {
        ...state,
        deleteUser: {
          userId: action.payload.userId
        }
      };
    default:
      return state;
  }
};
