import { buildApiActionTypes } from './apiActionTypes';

export const fieldAccessibilityActionTypes = {
  UPDATE_FIELD_ACCESSIBILITY: 'UPDATE_FIELD_ACCESSIBILITY'
};

export const getFieldAccessibilityActionTypes = buildApiActionTypes(
  'GET_FIELD_ACCESSIBILITY'
);

export const addFieldAccessibilityFeedbackActionTypes = buildApiActionTypes(
  'ADD_FIELD_ACCESSIBILITY_FEEDBACK'
);
