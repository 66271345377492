import React from 'react';

import useMediaQuery from '@material-ui/core/useMediaQuery';

import GrowthModelList from './GrowthModelList';
import GrowthModelTable from './GrowthModelTable';

const ResponsiveGrowthModelList = ({
  growth,
  growerId,
  seasonId,
  farmId,
  fieldId
}) => {
  const showTable = useMediaQuery((theme) => theme.breakpoints.up('sm'));

  return (
    <>
      {showTable && (
        <GrowthModelTable
          growth={growth}
          growerId={growerId}
          seasonId={seasonId}
          farmId={farmId}
          fieldId={fieldId}
        />
      )}
      {!showTable && (
        <GrowthModelList
          growth={growth}
          growerId={growerId}
          seasonId={seasonId}
          farmId={farmId}
          fieldId={fieldId}
        />
      )}
    </>
  );
};

export default ResponsiveGrowthModelList;
