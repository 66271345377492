import { useMemo } from 'react';

import _ from 'lodash';

import { growthModelSnapshotsService } from '../../../../services/growthModelSnapshots.service';
import { createApiSlice } from '../../utils/createApiSlice';

const getSnapshotsDatesSlice = createApiSlice({
  reducerPath: ['newGrowths', 'snapshots', 'dates'],
  apiFunction: growthModelSnapshotsService.getGrowthModelSnapshotsDates,
  getStateUpdatePath: (action) => {
    const growthId = _.last(_.get(action, 'payload.apiArguments'));
    return [growthId];
  },
  getSelectorPath: (growthId) => {
    return [growthId];
  },
  apiArgumentsFn: ({ growerId, seasonId, farmId, fieldId, growthId }) => [
    growerId,
    seasonId,
    farmId,
    fieldId,
    growthId
  ]
});

const { actions, useApiSelectors, requestSaga } = getSnapshotsDatesSlice;

export const getSnapshotsDatesActions = actions;

export const getSnapshotsDatesRequestSaga = requestSaga;

export const useGetSnapshotsDatesSelectors = (growthId) => {
  const {
    inProgress,
    errorMessage,
    success,
    response: snapshotsDates
  } = useApiSelectors(growthId);

  return useMemo(
    () => ({
      snapshotsDates,
      inProgress,
      errorMessage,
      success
    }),
    [errorMessage, inProgress, snapshotsDates, success]
  );
};

export default getSnapshotsDatesSlice;
