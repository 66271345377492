import React, { useCallback, useContext } from 'react';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Alert from '@material-ui/lab/Alert';
import _ from 'lodash';
import { useDispatch } from 'react-redux';

import { AuthContext } from '../../_context/AuthContext';
import { FarmStructureContext } from '../../_context/FarmStructureContext';
import { openCreateGrowthDialog } from '../../_store/actions/uiActions';
import { useActiveGrowthSelectors } from '../../_store/selectors/growthsSelectors';

import GrowthDetails from './GrowthDetails';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  createGrowthButton: {
    marginTop: '1rem'
  },
  tabs: {
    marginBottom: '1rem'
  }
}));

const FieldDetails = ({ field }) => {
  const dispatch = useDispatch();
  const styles = useStyles();

  const { isUserView } = useContext(AuthContext);

  const { isHistoricalSeason } = useContext(FarmStructureContext);

  const growerId = _.get(field, 'growerId');
  const seasonId = _.get(field, 'seasonId');
  const farmId = _.get(field, 'farmId');
  const fieldId = _.get(field, 'id');

  const { growth, isLoading, notFound, errorMessage } =
    useActiveGrowthSelectors(seasonId, fieldId);

  const handleCreateGrowthClick = useCallback(() => {
    dispatch(openCreateGrowthDialog(growerId, seasonId, farmId, fieldId));
  }, [dispatch, farmId, fieldId, growerId, seasonId]);

  return (
    <Box margin={2} className={styles.container}>
      {!growth && isLoading && <CircularProgress />}
      {!isLoading && !notFound && errorMessage && (
        <Alert severity="error">{errorMessage}</Alert>
      )}
      {!isLoading &&
        (notFound || (!growth && !errorMessage)) &&
        !isHistoricalSeason && (
          <>
            <Alert severity="info">
              There are no growths configured for this field. In order to
              display the growth model, please add planting.
            </Alert>
            {!isUserView && (
              <Button
                size="small"
                variant="contained"
                color="primary"
                className={styles.createGrowthButton}
                onClick={handleCreateGrowthClick}
              >
                Add Planting
              </Button>
            )}
          </>
        )}
      {!isLoading &&
        (notFound || (!growth && !errorMessage)) &&
        isHistoricalSeason && (
          <Alert severity="info">
            There were no growths configured for this field.
          </Alert>
        )}
      <GrowthDetails growth={growth} />
    </Box>
  );
};

export default FieldDetails;
