import React, { useCallback, useMemo, useRef } from 'react';

import { Tooltip } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { CSVLink } from 'react-csv';

export const csvTimestampFormat = 'YYYY-MM-DD HH:mm:ss';
export const csvDateFormat = 'YYYY-MM-DD';
export const csvFilenameTimestampFormat = 'YYYY-MM-DDTHH_mm_ss';

const CsvDownloadButton = ({ dataArray, header, filename }) => {
  const csvLink = useRef();

  const csvDataArray = useMemo(
    () => (header ? [header, ...dataArray] : dataArray),
    [dataArray, header]
  );

  const handleDownload = useCallback(() => {
    if (csvLink.current) {
      csvLink.current.link.click();
    }
  }, []);

  // Conditional rendering of CSVLink components is a workaround for the bug in the library that does not let it properly update the data when the data is changed
  return (
    <>
      {csvDataArray && (
        <CSVLink filename={filename} data={csvDataArray} ref={csvLink} />
      )}
      <Tooltip title="Download CSV" placement="top">
        <IconButton onClick={handleDownload}>
          <CloudDownloadIcon />
        </IconButton>
      </Tooltip>
    </>
  );
};

export default CsvDownloadButton;
