import { useMemo } from 'react';

import { reportsService } from '../../../../services/reports.service';
import { createApiSlice } from '../../utils/createApiSlice';

const getSavedFiltersSlice = createApiSlice({
  reducerPath: ['reports', 'savedFilters', 'getSavedFilters'],
  apiFunction: reportsService.getSavedFilters,
  apiArgumentsFn: () => []
});

const { actions, useApiSelectors } = getSavedFiltersSlice;

export const getSavedFiltersActions = actions;

export const useGetSavedFiltersSelectors = () => {
  const { inProgress, errorMessage, success, response } = useApiSelectors();

  return useMemo(
    () => ({
      inProgress,
      errorMessage,
      success,
      response
    }),
    [errorMessage, inProgress, response, success]
  );
};

export default getSavedFiltersSlice;
