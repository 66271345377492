import React from 'react';

import CreateGrowthDialog from './CreateGrowthDialog';
import GrowthFeedbackDialog from './GrowthFeedbackDialog';

const GrowthDialogs = () => {
  return (
    <>
      <CreateGrowthDialog />
      <GrowthFeedbackDialog />
    </>
  );
};

export default GrowthDialogs;
