import createGrowerDevicesSlice from './createGrowerDeviceSlice';
import deleteGrowerDeviceSlice from './deleteGrowerDeviceSlice';
import getGrowerDevicesSlice from './getGrowerDevicesSlice';
import updateGrowerDevicesSlice from './updateGrowerDeviceSlice';

const growerDevicesSlices = [
  getGrowerDevicesSlice,
  createGrowerDevicesSlice,
  updateGrowerDevicesSlice,
  deleteGrowerDeviceSlice
];

export default growerDevicesSlices;
