import { useMemo } from 'react';

import _ from 'lodash';

import { deviceCommands } from '../../../../services/deviceCommands.service';
import { createApiSlice } from '../../utils/createApiSlice';

const stopAllCommandSlice = createApiSlice({
  reducerPath: ['devices', 'commands', 'stopAllCommand'],
  apiFunction: deviceCommands.postStopAllCommandV2,
  getStateUpdatePath: (action) => {
    const growerId = _.get(action, 'payload.apiArguments.0');
    return [growerId];
  },
  getSelectorPath: (growerId) => {
    return [growerId];
  },
  apiArgumentsFn: ({ growerId }) => [growerId]
});

const { actions, selectors, useApiSelectors } = stopAllCommandSlice;

export const stopAllCommandActions = actions;

export const stopAllCommandSelectors = {
  ...selectors
};

export const useStopAllCommandSelectors = (growerId) => {
  const { inProgress, errorMessage, success, updatedAt } =
    useApiSelectors(growerId);

  return useMemo(
    () => ({
      inProgress,
      errorMessage,
      success,
      updatedAt
    }),
    [errorMessage, inProgress, success, updatedAt]
  );
};

export default stopAllCommandSlice;
