import React, { useMemo } from 'react';

import _ from 'lodash';

import { parseDate } from '../Growths/GrowthTimeline/_utils/growthTimelineUtils';

const StageItem = ({ item }) => {
  const x = _.get(item, 'x');
  const y = _.get(item, 'y');
  const lineY = _.get(item, 'lineY');
  return (
    <>
      <text x={x} y={y} fontSize={12} textAnchor="middle" fill="black">
        {_.get(item, 'label')}
      </text>
      <line x1={x} x2={x} y1={lineY + 0} y2={lineY + 15} stroke="black" />
    </>
  );
};

const CounceStagesTimeline = ({
  svgWidth,
  paddingTop,
  timelineActivities,
  selectedTimeScale
}) => {
  const growths = useMemo(() => {
    return _.map(timelineActivities, (growth) => {
      const growthId = _.get(growth, 'id');
      const startY = _.get(growth, 'startY');
      const counceModel = _.get(growth, 'counceModel');

      const vStages = _.chain(counceModel)
        .get('vegetativeSubstages')
        .map((stage) => {
          const x = selectedTimeScale(parseDate(stage.startDate));
          const y = startY + paddingTop + 25;
          const lineY = startY + paddingTop + 30;
          const label = `V${_.get(stage, 'stage')}`;
          return {
            growthId,
            id: `${growthId}-${label}`,
            date: _.get(stage, 'startDate'),
            x,
            y,
            lineY,
            label
          };
        })
        .value();
      const rStages = _.chain(counceModel)
        .get('reproductiveSubstages')
        .map((stage) => {
          const x = selectedTimeScale(parseDate(stage.startDate));
          const y = startY + paddingTop + 10;
          const lineY = startY + paddingTop + 30;
          const label = `R${_.get(stage, 'stage')}`;
          return {
            growthId,
            id: `${growthId}-${label}`,
            date: _.get(stage, 'startDate'),
            x,
            y,
            lineY,
            label
          };
        })
        .value();
      return { growthId, stages: _.orderBy([...vStages, ...rStages], 'x') };
    });
  }, [paddingTop, selectedTimeScale, timelineActivities]);

  return (
    <g>
      {svgWidth > 0 && (
        <>
          {_.map(growths, (growth) => (
            <g key={_.get(growth, 'growthId')}>
              {_.map(_.get(growth, 'stages'), (item) => (
                <StageItem key={_.get(item, 'label')} item={item} />
              ))}
            </g>
          ))}
        </>
      )}
    </g>
  );
};

export default CounceStagesTimeline;
