import addFieldToSeasonSlice from './addFieldToSeasonSlice';
import createGrowerSeasonSlice from './createGrowerSeasonSlice';
import getSeasonsSlice from './getSeasonsSlice';
import getSeasonsWithGrowthsSlice from './getSeasonsWithGrowthsSlice';
import globalSeasonSlice from './globalSeasonSlice';
import removeFieldFromSeasonSlice from './removeFieldFromSeasonSlice';

const fieldsSlices = [
  getSeasonsSlice,
  getSeasonsWithGrowthsSlice,
  globalSeasonSlice,
  createGrowerSeasonSlice,
  addFieldToSeasonSlice,
  removeFieldFromSeasonSlice
];

export default fieldsSlices;
