import React, { PureComponent } from 'react';

const iconStyle = {
  cursor: 'pointer',
  stroke: 'none'
};

export default class TelemetryIcon extends PureComponent {
  render() {
    const { size = 20, onClick, style } = this.props;

    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        id="svg"
        width={size}
        height={size}
        viewBox="0 0 100 100"
        style={{ ...iconStyle, ...style }}
        onClick={onClick}
      >
        {/* <path d="M50 20A40 40 0 1 0 50 70 30 30 0 1 1 50 20z" stroke="black" 
        strokeWidth="2" fill="red"/>
M 50 20 Move to position (50, 20)
A 40 40 0 1 0 50 70 Draw an arc from that point to position (50, 70). The arc should have a radius of 40 in the x-axis and 40 in the y axis.
30 30 0 1 1 50 20 Draw another arc in the opposite direction from the current point to (50, 20) with a radius of 30 in both axes.
z Join the ends
#607D8B */}

        <line
          x1="85"
          y1="85"
          x2="50"
          y2="50"
          stroke="mediumaquamarine"
          strokeWidth="4"
        />
        <line
          x1="85"
          y1="85"
          x2="50"
          y2="50"
          stroke="#3cb98f"
          strokeWidth="2"
          transform="translate(0 2)"
        />
        <line
          x1="50"
          y1="80"
          x2="50"
          y2="50"
          stroke="mediumaquamarine"
          strokeWidth="4"
        />
        <line
          x1="50"
          y1="80"
          x2="50"
          y2="50"
          stroke="#3cb98f"
          strokeWidth="2"
          transform="translate(-2 0)"
        />
        <line
          x1="15"
          y1="75"
          x2="50"
          y2="50"
          stroke="mediumaquamarine"
          strokeWidth="4"
        />
        <line
          x1="15"
          y1="75"
          x2="50"
          y2="50"
          stroke="#3cb98f"
          strokeWidth="2"
          transform="translate(0 2)"
        />
        <line
          x1="20"
          y1="30"
          x2="50"
          y2="50"
          stroke="mediumaquamarine"
          strokeWidth="4"
        />
        <line
          x1="20"
          y1="30"
          x2="50"
          y2="50"
          stroke="#3cb98f"
          strokeWidth="2"
          transform="translate(0 2)"
        />

        <path
          d="M45 15A15 15 0 1 0 85 65 90 90 0 0 1 45 15z"
          fill="gold"
          transform="translate(5 -10)"
        />

        <g transform="translate(15 25) rotate(50 40 17)">
          <polygon points="39,17 41,-10 43,-10 45,17" fill="gray" />
          <circle cx="42" cy="-10" r="3" fill="gray" />
          <path
            d="M36 -4A6 6 0 1 0 48 -4 7 7 0 0 1 36 -4z"
            fill="#555"
            transform="scale(0.5) translate(42 -16)"
          />
        </g>

        <path
          d="M45 15A15 15 0 1 0 85 65 45 45 0 0 1 45 15z"
          fill="#FFB300"
          transform="translate(5 -10)"
        />

        <path
          d="M60 35A13 13 0 1 1 78 33 12 12 0 1 0 60 35z"
          fill="gold"
          transform="rotate(50 40 17) translate(3 -31)"
        />
        <path
          d="M60 35A13 13 0 1 1 78 33 12 12 0 1 0 60 35z"
          fill="gold"
          transform="rotate(50 40 17) translate(5 -39)"
        />
        <path
          d="M60 35A13 13 0 1 1 78 33 12 12 0 1 0 60 35z"
          fill="gold"
          transform="rotate(50 40 17) translate(7 -47)"
        />

        <circle cx="85" cy="85" r="10" fill="mediumaquamarine" />
        <path d="M78 78A5 5 0 1 0 92 92 11 11 0 0 1 78 78z" fill="#3cb98f" />
        <circle cx="50" cy="80" r="10" fill="mediumaquamarine" />
        <path d="M43 73A5 5 0 1 0 57 87 11 11 0 0 1 43 73z" fill="#3cb98f" />
        <circle cx="15" cy="75" r="10" fill="mediumaquamarine" />
        <path d="M8 68A5 5 0 1 0 22 82 11 11 0 0 1 8 68z" fill="#3cb98f" />
        <circle cx="20" cy="30" r="10" fill="mediumaquamarine" />
        <path d="M13 23A5 5 0 1 0 27 37 11 11 0 0 1 13 23z" fill="#3cb98f" />
      </svg>
    );
  }
}
