import React, { useMemo, useState } from 'react';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import _ from 'lodash';
import { useResizeDetector } from 'react-resize-detector';

import LoadingIndicator from '../../LoadingIndicator';
import GrowthStagesLegend from '../GrowthStagesLegend';

import { useTimelineGrowths } from './_hooks/useTimelineGrowths';
import GrowthTimelineChart from './GrowthTimelineChart';
import GrowthTimelineSelector from './GrowthTimelineSelector';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%'
  },
  timelineContainer: {
    position: 'relative',
    width: '100%'
  },
  svg: {
    position: 'absolute'
  }
}));

const GrowthTimeline = () => {
  const styles = useStyles();

  const { width, ref: containerRef } = useResizeDetector();

  const [rangeSelection, setRangeSelection] = useState();
  const handleRangeSelected = useMemo(
    () => _.debounce(setRangeSelection, 10),
    []
  );

  const { inProgress, timelineGrowths, minDate, maxDate } =
    useTimelineGrowths();

  const hasGrowths = _.get(timelineGrowths, 'length', 0) > 0;

  return (
    <div className={styles.container}>
      <div className={styles.timelineContainer} ref={containerRef}>
        {!inProgress && hasGrowths && !_.isNaN(width) && _.isNumber(width) && (
          <>
            <GrowthTimelineChart
              timelineGrowths={timelineGrowths}
              containerWidth={width}
              minDate={minDate}
              maxDate={maxDate}
              rangeSelection={rangeSelection}
            />
            <GrowthTimelineSelector
              timelineGrowths={timelineGrowths}
              containerWidth={width}
              minDate={minDate}
              maxDate={maxDate}
              onRangeSelected={handleRangeSelected}
            />
            <GrowthStagesLegend />
          </>
        )}
      </div>
      {!inProgress && !hasGrowths && (
        <Alert severity="info">
          <AlertTitle>No Growths</AlertTitle>
          There are no active growths configured for the selected Grower. In
          order to display the growths timeline, please create a growth.
        </Alert>
      )}
      {inProgress && <LoadingIndicator />}
    </div>
  );
};

export default GrowthTimeline;
