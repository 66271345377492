import { useMemo } from 'react';

import { useSelector } from 'react-redux';

import { AGGREGATIONS } from '../../../_constants/irrigationDashboardConstants';
import { createDataSlice } from '../utils/createDataSlice';

const irrigationDashboardSlice = createDataSlice({
  reducerPath: ['ui', 'irrigationDashboard'],
  initialState: { aggregation: AGGREGATIONS.DAILY },
  reducers: {
    setAggregation(state, action) {
      state.aggregation = action.payload.aggregation;
    }
  }
});

const { actions, baseSelector: irrigationDashboardSelector } =
  irrigationDashboardSlice;

export const irrigationDashboardActions = actions;

export const useIrrigationDashboardSelectors = () => {
  const { aggregation } = useSelector(irrigationDashboardSelector);

  return useMemo(
    () => ({
      aggregation
    }),
    [aggregation]
  );
};

export default irrigationDashboardSlice;
