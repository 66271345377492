import { addToken, client } from './client';

export const growthModelApiService = {
  getFields,
  getGrowths,
  getGrowthStages,
  getGrowthFeedback,
  getHistoricalGrowthStagesCsv,
  getProductionGrowthStagesCsv
};

async function getFields(token, season) {
  const response = await client.get(
    `/growth-model-admin/fields?season=${season}`,
    addToken(token)
  );
  return response.data;
}

async function getGrowths(token, fieldId) {
  const response = await client.get(
    `/growth-model-admin/fields/${fieldId}/growths`,
    addToken(token)
  );
  return response.data;
}

async function getGrowthStages(token, fieldId, growthId) {
  const response = await client.get(
    `/growth-model-admin/fields/${fieldId}/growths/${growthId}/stages`,
    addToken(token)
  );
  return response.data;
}

async function getGrowthFeedback(token, fieldId, growthId) {
  const response = await client.get(
    `/growth-model-admin/fields/${fieldId}/growths/${growthId}/feedback`,
    addToken(token)
  );
  return response.data;
}

async function getHistoricalGrowthStagesCsv(token, fieldId, growthId) {
  const response = await client.get(
    `/growth-model-admin/fields/${fieldId}/growths/${growthId}/stages/historical/csv`,
    addToken(token)
  );
  return response.data;
}

async function getProductionGrowthStagesCsv(token, season) {
  const response = await client.get(
    `/growth-model-admin/growth-stages/production/csv?season=${season}`,
    addToken(token)
  );
  return response.data;
}
