import { useMemo } from 'react';

import { feedbackService } from '../../../services/feedback.service';
import { createApiSlice } from '../utils/createApiSlice';

const addUserFeedbackSlice = createApiSlice({
  reducerPath: ['feedback', 'addUserFeedback'],
  apiFunction: feedbackService.addUserFeedback
});

const { actions, useApiSelectors } = addUserFeedbackSlice;

export const addUserFeedbackActions = actions;

export const addUserFeedbackSelectors = useApiSelectors;

export const useAddUserFeedbackSelectors = () => {
  const { inProgress, errorMessage, success } = addUserFeedbackSelectors();

  return useMemo(
    () => ({
      inProgress,
      errorMessage,
      success
    }),
    [errorMessage, inProgress, success]
  );
};

export default addUserFeedbackSlice;
