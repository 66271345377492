import { fieldService } from '../../services';
import {
  createFieldActionTypes,
  deleteFieldActionTypes,
  getConfigFieldsActionTypes,
  updateFieldActionTypes
} from '../actionTypes/fieldsActionTypes';

import { buildApiActions } from './apiActions';

export const getConfigFieldsActions = buildApiActions(
  getConfigFieldsActionTypes,
  fieldService.getFieldsV2
);

export const createFieldActions = buildApiActions(
  createFieldActionTypes,
  fieldService.createFieldV2
);

export const updateFieldActions = buildApiActions(
  updateFieldActionTypes,
  fieldService.updateFieldV2
);

export const deleteFieldActions = {
  ...buildApiActions(deleteFieldActionTypes, fieldService.deleteFieldV2),
  select: (growerId, farmId, fieldId) => ({
    type: deleteFieldActionTypes.SELECT,
    payload: {
      growerId,
      farmId,
      fieldId
    }
  })
};
