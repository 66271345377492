import deleteSavedFilterSlice from './deleteSavedFilterSlice';
import getSavedFiltersSlice from './getSavedFiltersSlice';
import postSavedFilterSlice from './postSavedFilterSlice';
import updateSavedFilterSlice from './updateSavedFilterSlice';

const savedFiltersSlices = [
  getSavedFiltersSlice,
  postSavedFilterSlice,
  deleteSavedFilterSlice,
  updateSavedFilterSlice
];

export default savedFiltersSlices;
