import React from 'react';

import MoistureSampleDialog from './MoistureSampleDialog';
import SetTargetMoistureDialog from './SetTargetMoistureDialog';

const HarvestAdvisorDialogs = () => {
  return (
    <>
      <SetTargetMoistureDialog />
      <MoistureSampleDialog />
    </>
  );
};

export default HarvestAdvisorDialogs;
