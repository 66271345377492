import React, { useMemo } from 'react';

import _ from 'lodash';

import TimelineRectangle from '../Charts/TimelineRectangle';
import { parseDate } from '../Growths/GrowthTimeline/_utils/growthTimelineUtils';

const GrowthActivitiesTimeline = ({
  svgWidth,
  barHeight,
  barGap,
  growthModelTimelineBarHeight,
  growthModelTimelineGap,
  rectangleRadius,
  timelineActivities,
  selectedTimeScale,
  onMouseOver = _.noop,
  onMouseOut = _.noop,
  onClick = _.noop
}) => {
  const growthRectangles = useMemo(() => {
    return _.map(timelineActivities, (growth) => {
      const growthId = _.get(growth, 'id');
      const startY = _.get(growth, 'startY');
      const rectangles = _.chain(growth)
        .get('activitiesByType')
        .values()
        .flatMap((activities, rowIdx) => {
          return _.map(activities, (activity) => {
            const x0 = selectedTimeScale(parseDate(activity.date));
            const rectWidth =
              selectedTimeScale(parseDate(activity.endDate)) -
              selectedTimeScale(parseDate(activity.date));
            const x1 = x0 + rectWidth;

            const rangeX0 = selectedTimeScale.range()[0];
            const rangeX1 = selectedTimeScale.range()[1];

            const visibleX0 = x0 < rangeX0 ? rangeX0 : x0;
            const visibleX1 = x1 > rangeX1 ? rangeX1 : x1;
            const visibleMidX =
              x0 >= rangeX0
                ? (visibleX1 - visibleX0) / 2
                : rangeX0 - x0 + (visibleX1 - visibleX0) / 2;

            const y =
              startY +
              rowIdx * (barHeight + barGap) +
              growthModelTimelineBarHeight +
              growthModelTimelineGap;

            const height = barHeight;
            const color = activity.backgroundColor;
            return {
              growthId,
              id: `${growthId}-${activity.id}`,
              x: x0,
              y,
              width: rectWidth,
              height,
              color,
              startDate: activity.date,
              endDate: activity.endDate,
              growthStage: activity.growthStage,
              visibleMidX,
              fieldName: _.get(growth, 'fieldName'),
              description: _.get(activity, 'description')
            };
          });
        })
        .filter((rectangle) => rectangle.width > 0)
        .value();
      return { growthId, rectangles };
    });
  }, [
    barGap,
    barHeight,
    growthModelTimelineBarHeight,
    growthModelTimelineGap,
    selectedTimeScale,
    timelineActivities
  ]);

  return (
    <g>
      {svgWidth > 0 && (
        <>
          {_.map(growthRectangles, (growth) => (
            <g key={_.get(growth, 'growthId')}>
              {_.map(_.get(growth, 'rectangles'), (rectangle) => (
                <TimelineRectangle
                  key={_.get(rectangle, 'id')}
                  rectangleRadius={rectangleRadius}
                  rectangle={rectangle}
                  onMouseOver={onMouseOver}
                  onMouseOut={onMouseOut}
                  onClick={onClick}
                />
              ))}
            </g>
          ))}
        </>
      )}
    </g>
  );
};

export default GrowthActivitiesTimeline;
