import { useMemo } from 'react';

import _ from 'lodash';

import { growthStagesTypes } from '../../_constants/growthsConstants';
import { useGrowthModelSelectors } from '../../_store/selectors/uiSelectors';

export function getActiveGrowth(field, selectedGrowthModel) {
  const fieldGrowth = _.get(field, 'activeGrowth');
  const growthModel = _.chain(fieldGrowth)
    .get('growthModels')
    .find((model) => _.get(model, 'type') === selectedGrowthModel)
    .value();
  return {
    ..._.omit(fieldGrowth, 'growthModels'),
    ..._.omit(growthModel, 'type')
  };
}

export function useActiveGrowth(field) {
  const { selectedGrowthModel } = useGrowthModelSelectors();

  const activeGrowth = useMemo(() => {
    const growth = getActiveGrowth(field, selectedGrowthModel);
    if (!growth || _.isEmpty(growth)) {
      return {
        estimatedCurrentGrowthStage: growthStagesTypes.NotPlanted
      };
    }

    return growth;
  }, [field, selectedGrowthModel]);

  return useMemo(() => ({ activeGrowth }), [activeGrowth]);
}
