import { useMemo } from 'react';

import _ from 'lodash';

import { growthModelSnapshotsService } from '../../../../services/growthModelSnapshots.service';
import { createApiSlice } from '../../utils/createApiSlice';

const getSnapshotSlice = createApiSlice({
  reducerPath: ['newGrowths', 'snapshots', 'snapshot'],
  apiFunction: growthModelSnapshotsService.getGrowthModelSnapshot,
  getStateUpdatePath: (action) => {
    const snapshotId = _.last(_.get(action, 'payload.apiArguments'));
    return [snapshotId];
  },
  getSelectorPath: (snapshotId) => {
    return [snapshotId];
  },
  apiArgumentsFn: ({
    growerId,
    seasonId,
    farmId,
    fieldId,
    growthId,
    snapshotId
  }) => [growerId, seasonId, farmId, fieldId, growthId, snapshotId]
});

const { actions, selectors, useApiSelectors, requestSaga } = getSnapshotSlice;

export const getSnapshotActions = actions;

export const getSnapshotSelectors = {
  ...selectors
};

export const getSnapshotRequestSaga = requestSaga;

export const useGetSnapshotSelectors = (snapshotId) => {
  const {
    inProgress,
    errorMessage,
    success,
    response: snapshot
  } = useApiSelectors(snapshotId);

  return useMemo(
    () => ({
      snapshot,
      inProgress,
      errorMessage,
      success
    }),
    [errorMessage, inProgress, snapshot, success]
  );
};

export default getSnapshotSlice;
