import React, { useMemo } from 'react';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Line } from 'react-chartjs-2';

import { generateLabels } from '../../IrrigationDashboard/Components/sensorChartsUtils';

import {
  commonLineDataSetParams,
  getDefaultOptions,
  getValue
} from './weatherChartUtils';

const WindSpeedChart = ({
  chartRef,
  weatherData,
  timeUnit,
  startDate,
  endDate
}) => {
  const highChart = useMediaQuery((theme) => theme.breakpoints.down('xs'));

  const graphData = useMemo(() => {
    if (weatherData) {
      // Daily chart
      const avgWindSpeedValues = getValue(weatherData, 'windSpeedAvg');
      const minWindSpeedValues = getValue(weatherData, 'windSpeedMin');
      const maxWindSpeedValues = getValue(weatherData, 'windSpeedMax');

      // Hourly chart
      const windSpeedValues = getValue(weatherData, 'windSpeed');

      const datasets = [
        {
          ...commonLineDataSetParams('#ffa726'),
          label: 'Max Wind Speed',
          data: maxWindSpeedValues
        },
        {
          ...commonLineDataSetParams('#fb8c00'),
          label: 'Average Wind Speed',
          data: avgWindSpeedValues
        },
        {
          ...commonLineDataSetParams('#ffcc80'),
          label: 'Min Wind Speed',
          fill: maxWindSpeedValues && avgWindSpeedValues ? '-2' : undefined,
          data: minWindSpeedValues
        },
        {
          ...commonLineDataSetParams('#fb8c00'),
          label: 'Wind Speed',
          data: windSpeedValues
        }
      ].filter((dataset) => dataset.data);

      const labels = generateLabels(datasets, startDate, endDate);

      return {
        labels,
        datasets
      };
    }
  }, [endDate, startDate, weatherData]);

  return (
    <>
      {graphData && highChart && (
        <Line
          ref={chartRef}
          data={graphData}
          options={getDefaultOptions(timeUnit)}
          height={250}
          type="scatter"
        />
      )}
      {graphData && !highChart && (
        <Line
          ref={chartRef}
          data={graphData}
          options={getDefaultOptions(timeUnit)}
          height={120}
          type="scatter"
        />
      )}
    </>
  );
};

export default WindSpeedChart;
