import { configService } from '../../services';
import {
  growthStagesConfigActionTypes,
  saveGrowthStageConfigRequestActionTypes
} from '../actionTypes/configActionTypes';

import { buildApiActions } from './apiActions';

export const saveGrowthStageConfigAction = (growthStageType, config) => ({
  type: growthStagesConfigActionTypes.SAVE_GROWTH_STAGE_CONFIG,
  payload: {
    growthStageType,
    config
  }
});

export const saveGrowthStageConfigRequestAction = buildApiActions(
  saveGrowthStageConfigRequestActionTypes,
  configService.saveStageConfigV2
);
