import { addToken, client } from './client';

export const growerService = {
  getGrowersV2,
  getAllGrowersV2,
  createGrowerV2,
  updateGrowerV2,
  deleteGrowerV2
};

async function getGrowersV2(token) {
  const response = await client.get(`/v2/growers`, addToken(token));
  return response.data;
}

async function getAllGrowersV2(token) {
  const response = await client.get(`/v2/growers/all`, addToken(token));
  return response.data;
}

async function createGrowerV2(token, grower) {
  const response = await client.post(`/v2/growers`, grower, addToken(token));
  return response.data;
}

async function updateGrowerV2(token, growerId, grower) {
  const response = await client.put(
    `/v2/growers/${growerId}`,
    grower,
    addToken(token)
  );
  return response.data;
}

async function deleteGrowerV2(token, growerId) {
  const response = await client.delete(
    `/v2/growers/${growerId}`,
    addToken(token)
  );
  return response.data;
}
