import React, { useEffect, useMemo, useRef, useState } from 'react';

import makeStyles from '@material-ui/core/styles/makeStyles';
import * as d3 from 'd3';
import _ from 'lodash';
import { v4 as uuid } from 'uuid';

import { brushHandles } from '../../_utilities/d3/brushHandles';
import ChartTimeAxis from '../Charts/ChartTimeAxis';

import { parseDate } from './_utils/fieldAccessibilityUtils';
import FieldAccessibilityTimeline from './FieldAccessibilityTimeline';

const selectorBarHeight = 4;
const selectorGap = 2;
const brushExcess = 10;
const scaleMargin = 20;
const scaleHeight = 40;
const todayLineExcess = 5;
const handlesWidth = 3;

const useStyles = makeStyles(() => ({
  selector: {
    marginTop: '1rem'
  }
}));

const FieldAccessibilityTimelineSelector = ({
  fieldAccessibility,
  containerWidth,
  minDate,
  maxDate,
  onRangeSelected
}) => {
  const styles = useStyles();

  const [clipPathId] = useState(`growthTimelineClipPath-${uuid()}`);

  const brushRef = useRef();

  const fieldAccessibilityCount = _.get(fieldAccessibility, 'length', 0);

  const timelinesHeight = useMemo(() => {
    const h =
      fieldAccessibilityCount * (selectorBarHeight + selectorGap) - selectorGap;
    return h > 0 ? h : 0;
  }, [fieldAccessibilityCount]);

  const brushHeight = useMemo(
    () => timelinesHeight + 2 * brushExcess,
    [timelinesHeight]
  );
  const height = useMemo(
    () => brushHeight + scaleMargin + scaleHeight,
    [brushHeight]
  );

  const timeScale = useMemo(
    () =>
      d3
        .scaleTime()
        .domain([parseDate(minDate), parseDate(maxDate)])
        .range([0, containerWidth]),
    [maxDate, minDate, containerWidth]
  );

  useEffect(() => {
    // Initialize and update selector brush
    if (!brushRef.current) {
      return;
    }
    const gBrush = d3.select(brushRef.current);

    const defaultSelection = [timeScale.range()[0], timeScale.range()[1]];

    const brush = d3
      .brushX()
      .extent([
        [0, 0],
        [containerWidth, brushHeight]
      ])
      .on('brush', brushed)
      .on('end', brushended);

    gBrush.call(brush).call(brush.move, defaultSelection);

    gBrush
      .selectAll('rect.selection')
      .style('fill', 'rgb(46, 64, 87)')
      .style('fill-opacity', 0.3)
      .style('stroke-width', 0);

    function brushed(event) {
      const selection = event.selection;
      if (selection) {
        const range = selection.map(timeScale.invert).map(d3.timeHour.round);

        onRangeSelected(range);
      }

      d3.select(this).call(brushHandles, selection, brushHeight, handlesWidth);
    }

    function brushended(event) {
      const selection = event.selection;
      if (!selection) {
        gBrush.call(brush.move, defaultSelection);
      }
    }
  }, [brushHeight, onRangeSelected, timeScale, containerWidth]);

  return (
    <svg className={styles.selector} width={containerWidth} height={height}>
      <ChartTimeAxis
        paddingTop={timelinesHeight + brushExcess + scaleMargin}
        selectedTimeScale={timeScale}
      />
      <g clipPath={`url(#${clipPathId})`}>
        <FieldAccessibilityTimeline
          svgWidth={containerWidth}
          paddingTop={brushExcess}
          barHeight={selectorBarHeight}
          barGap={selectorGap}
          rectangleRadius={0}
          fieldAccessibility={fieldAccessibility}
          selectedTimeScale={timeScale}
        />
      </g>
      <clipPath id={clipPathId}>
        <rect
          x={0}
          y={0}
          width={containerWidth}
          height={timelinesHeight + 2 * todayLineExcess}
        />
      </clipPath>
      <g ref={brushRef} />
    </svg>
  );
};

export default FieldAccessibilityTimelineSelector;
