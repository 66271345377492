import _ from 'lodash';

import { seasonService } from '../../../../services';
import { createApiSlice } from '../../utils/createApiSlice';

const getSeasonWithGrowthsSlice = createApiSlice({
  reducerPath: ['gff', 'seasons', 'withGrowths'],
  apiFunction: seasonService.getSeasonWithGrowthsV2,
  getStateUpdatePath: (action) => {
    const growerId = _.first(_.get(action, 'payload.apiArguments'));
    const seasonId = _.get(action, 'payload.apiArguments.1');
    return [growerId, seasonId];
  },
  getSelectorPath: (growerId, seasonId) => {
    return [growerId, seasonId];
  },
  responseMutationFn: (season) => {
    return {
      ...season,
      farms: _.chain(season)
        .get('farms')
        .map((farm) => ({
          ...farm,
          growerId: _.get(season, 'growerId'),
          seasonId: _.get(season, 'id'),
          fields: _.chain(farm)
            .get('fields')
            .map((field) => ({
              ...field,
              growerId: _.get(season, 'growerId'),
              seasonId: _.get(season, 'id'),
              farmId: _.get(farm, 'id')
            }))
            .keyBy((field) => _.get(field, 'id'))
            .value()
        }))
        .keyBy((farm) => _.get(farm, 'id'))
        .value()
    };
  },
  customReducers: {
    forceUpdate: () => {}
  }
});

const { actions, selectors, createResponseSelector } =
  getSeasonWithGrowthsSlice;

export const getSeasonWithGrowthsActions = actions;

export const getSeasonWithGrowthsSelectors = {
  ...selectors,
  farms: (growerId, seasonId) =>
    createResponseSelector(['farms'], growerId, seasonId),
  farm: (growerId, seasonId, farmId) =>
    createResponseSelector(['farms', farmId], growerId, seasonId),
  fields: (growerId, seasonId, farmId) =>
    createResponseSelector(['farms', farmId, 'fields'], growerId, seasonId),
  field: (growerId, seasonId, farmId, fieldId) =>
    createResponseSelector(
      ['farms', farmId, 'fields', fieldId],
      growerId,
      seasonId
    )
};

export default getSeasonWithGrowthsSlice;
