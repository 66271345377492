import React, { useCallback, useEffect, useMemo, useRef } from 'react';

import * as d3 from 'd3';

const MouseEventsOverlay = ({
  timeScale,
  paddingTop,
  paddingBottom,
  paddingLeft,
  paddingRight,
  containerWidth,
  containerHeight,
  onClick,
  onMouseOver,
  onMouseOut,
  onRangeSelection
}) => {
  const brushRef = useRef();

  const width = useMemo(
    () => containerWidth - paddingLeft - paddingRight,
    [containerWidth, paddingLeft, paddingRight]
  );

  const height = useMemo(
    () => containerHeight - paddingTop - paddingBottom,
    [containerHeight, paddingBottom, paddingTop]
  );

  const handleMouseOver = useCallback(
    (date) => {
      onMouseOver(date);
    },
    [onMouseOver]
  );

  const handleMouseOut = useCallback(() => {
    onMouseOut();
  }, [onMouseOut]);

  const handleRangeSelected = useCallback(
    (range) => {
      onRangeSelection(range);
    },
    [onRangeSelection]
  );

  const handleClick = useCallback(
    (date) => {
      onClick(date);
    },
    [onClick]
  );

  useEffect(() => {
    if (!brushRef.current) {
      return;
    }

    const g = d3.select(brushRef.current);

    g.on('mousemove', onMouseOver);
    g.on('mouseout', onMouseOut);
    g.on('click', onClick);

    function onMouseOver(event) {
      const mouseX = d3.pointer(event)[0];
      const date = timeScale.invert(mouseX);

      handleMouseOver(date);
    }

    function onMouseOut() {
      handleMouseOut();
    }

    function onClick(event) {
      const mouseX = d3.pointer(event)[0];
      const date = timeScale.invert(mouseX);

      handleClick(date);
    }
  }, [handleClick, handleMouseOut, handleMouseOver, timeScale]);

  useEffect(() => {
    // Initialize and update selector brush
    if (!brushRef.current) {
      return;
    }
    const gBrush = d3.select(brushRef.current);

    const brush = d3
      .brushX()
      .extent([
        [paddingLeft, paddingTop],
        [width + paddingLeft, height + paddingTop]
      ])
      .on('end', brushended);

    gBrush.call(brush).call(brush.clear);

    gBrush
      .selectAll('rect.selection')
      .style('fill', 'rgb(46, 64, 87)')
      .style('fill-opacity', 0.3)
      .style('stroke-width', 0);

    function brushended(event) {
      const selection = event.selection;
      if (selection) {
        const range = selection.map(timeScale.invert).map(d3.timeDay.round);
        handleRangeSelected(range);
        gBrush.call(brush.clear);
      }
    }
  }, [handleRangeSelected, height, paddingLeft, paddingTop, timeScale, width]);

  return <g ref={brushRef} />;
};

export default MouseEventsOverlay;
