import { useMemo } from 'react';

import { getGdd } from '../../_helpers/Growths/growthModelHelpers';
import { useGrowthModelSelectors } from '../../_store/selectors/uiSelectors';

export function useGdd(growth) {
  const { selectedGrowthModel } = useGrowthModelSelectors();

  return useMemo(
    () => ({ gdd: getGdd(growth, selectedGrowthModel) }),
    [growth, selectedGrowthModel]
  );
}
