import { buildApiActionTypes } from './apiActionTypes';

export const getUsersActionTypes = buildApiActionTypes('GET_USERS');

export const createUserActionTypes = buildApiActionTypes('CREATE_USER');

export const updateUserActionTypes = buildApiActionTypes('UPDATE_USER');

export const deleteUserActionTypes = {
  ...buildApiActionTypes('DELETE_USER'),
  SELECT: 'DELETE_USER_SELECT'
};
