import _ from 'lodash';
import moment from 'moment';

import { getErrorMessage } from '../../services/errors';
import {
  createGrowthActionTypes,
  deleteGrowthActionTypes,
  getActiveGrowthActionTypes,
  modifyGrowthActionTypes,
  setTargetMoistureActionTypes
} from '../actionTypes/growthsActionTypes';
import { requestStatus } from '../helpers/requestStatus';

export const growthsReducer = (state = {}, action) => {
  const seasonId = _.get(action, 'payload.apiArguments.1');
  const fieldId = _.last(_.get(action, 'payload.apiArguments'));
  switch (action.type) {
    case getActiveGrowthActionTypes.REQUEST:
      return {
        ...state,
        activeGrowths: {
          ..._.get(state, 'activeGrowths'),
          [seasonId]: {
            ..._.get(state, ['activeGrowths', seasonId]),
            [fieldId]: {
              ..._.get(state, ['activeGrowths', seasonId, fieldId]),
              status: requestStatus.IN_PROGRESS
            }
          }
        }
      };
    case getActiveGrowthActionTypes.SUCCEEDED:
      return {
        ...state,
        activeGrowths: {
          ..._.get(state, 'activeGrowths'),
          [seasonId]: {
            ..._.get(state, ['activeGrowths', seasonId]),
            [fieldId]: {
              status: requestStatus.SUCCESS,
              growth: {
                ...action.payload.response
              },
              updatedAt: moment().toISOString()
            }
          }
        }
      };
    case getActiveGrowthActionTypes.FAILED:
      return {
        ...state,
        activeGrowths: {
          ..._.get(state, 'activeGrowths'),
          [seasonId]: {
            ..._.get(state, ['activeGrowths', seasonId]),
            [fieldId]: {
              status: requestStatus.FAILURE,
              error: {
                message: getErrorMessage(action.payload.apiError),
                status: _.get(action, 'payload.apiError.response.status')
              },
              updatedAt: moment().toISOString()
            }
          }
        }
      };
    case createGrowthActionTypes.REQUEST:
      return {
        ...state,
        createGrowth: {
          status: requestStatus.IN_PROGRESS
        }
      };
    case createGrowthActionTypes.SUCCEEDED:
      return {
        ...state,
        createGrowth: {
          status: requestStatus.SUCCESS,
          growth: action.payload.response,
          updatedAt: moment().toISOString()
        }
      };
    case createGrowthActionTypes.FAILED:
      return {
        ...state,
        createGrowth: {
          status: requestStatus.FAILURE,
          error: {
            message: getErrorMessage(action.payload.apiError)
          }
        }
      };
    case createGrowthActionTypes.CLEAR:
      return {
        ...state,
        createGrowth: {}
      };
    case modifyGrowthActionTypes.REQUEST:
      return {
        ...state,
        modifyGrowth: {
          status: requestStatus.IN_PROGRESS
        }
      };
    case modifyGrowthActionTypes.SUCCEEDED:
      return {
        ...state,
        modifyGrowth: {
          status: requestStatus.SUCCESS,
          growth: action.payload.response,
          updatedAt: moment().toISOString()
        }
      };
    case modifyGrowthActionTypes.FAILED:
      return {
        ...state,
        modifyGrowth: {
          status: requestStatus.FAILURE,
          error: {
            message: getErrorMessage(action.payload.apiError)
          }
        }
      };
    case modifyGrowthActionTypes.CLEAR:
      return {
        ...state,
        modifyGrowth: {}
      };
    case deleteGrowthActionTypes.REQUEST:
      return {
        ...state,
        deleteGrowth: {
          status: requestStatus.IN_PROGRESS
        }
      };
    case deleteGrowthActionTypes.SUCCEEDED:
      return {
        ...state,
        deleteGrowth: {
          status: requestStatus.SUCCESS,
          growth: action.payload.response,
          updatedAt: moment().toISOString()
        }
      };
    case deleteGrowthActionTypes.FAILED:
      return {
        ...state,
        deleteGrowth: {
          status: requestStatus.FAILURE,
          error: {
            message: getErrorMessage(action.payload.apiError)
          }
        }
      };
    case deleteGrowthActionTypes.CLEAR:
      return {
        ...state,
        deleteGrowth: {}
      };
    case setTargetMoistureActionTypes.REQUEST:
      return {
        ...state,
        setTargetMoisture: {
          status: requestStatus.IN_PROGRESS
        }
      };
    case setTargetMoistureActionTypes.SUCCEEDED:
      return {
        ...state,
        setTargetMoisture: {
          status: requestStatus.SUCCESS,
          updatedAt: moment().toISOString()
        }
      };
    case setTargetMoistureActionTypes.FAILED:
      return {
        ...state,
        setTargetMoisture: {
          status: requestStatus.FAILURE,
          error: {
            message: getErrorMessage(action.payload.apiError)
          }
        }
      };
    case setTargetMoistureActionTypes.CLEAR:
      return {
        ...state,
        setTargetMoisture: {}
      };
    default:
      return state;
  }
};
