import { useContext, useMemo } from 'react';

import { FarmStructureContext } from '../../../_context/FarmStructureContext';
import { useAllGrowerActiveGrowthsSelectors } from '../../../_store/selectors/growthsSelectors';

import { useGrowthsTimelineActivities } from './useGrowthsTimelineActivities';

export const useTimelineActivities = () => {
  const { selectedGrowerId: growerId, selectedSeasonId } =
    useContext(FarmStructureContext);

  const { inProgress, growths } = useAllGrowerActiveGrowthsSelectors(
    growerId,
    selectedSeasonId
  );

  const { timelineActivities, minDate, maxDate } = useGrowthsTimelineActivities(
    { inProgress, growths }
  );

  return useMemo(
    () => ({
      inProgress,
      timelineActivities,
      minDate,
      maxDate
    }),
    [inProgress, maxDate, minDate, timelineActivities]
  );
};
