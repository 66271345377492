import React from 'react';

import { useShapefileUploadDialogSelectors } from '../../../_store/slices/ui/dialogs/shapefileUploadDialogSlice';
import ResponsiveDialog from '../../ResponsiveDialog';

import UploadShapefile from './UploadShapefile';

const UploadShapefileDialog = () => {
  const { open } = useShapefileUploadDialogSelectors();

  return (
    <ResponsiveDialog open={open}>
      {open && <UploadShapefile />}
    </ResponsiveDialog>
  );
};

export default UploadShapefileDialog;
