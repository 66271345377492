import _ from 'lodash';

import { defaultGrowthModel } from '../../_constants/growthsConstants';
import { mapLayerIds } from '../../_constants/mapLayersConstants';
import { CHARTS, FORECAST_TABS } from '../../_constants/weatherConstants';
import { DASHBOARD_TABS } from '../../_context/WeatherContext';
import { defaultDashboardTab } from '../../Pages/Dashboard/DashboardPage';
import { uiActionTypes } from '../actionTypes/uiActionTypes';
import { activityTypes } from '../selectors/configSelectors';

export const uiReducer = (
  state = {
    growthDashboardTabId: _.get(defaultDashboardTab, 'tabId'),
    mapLayers: {
      layerId: mapLayerIds.GrowthModel,
      showDetails: false
    },
    fieldMapLayers: {
      [mapLayerIds.GrowthModel]: true,
      [mapLayerIds.Weather]: true,
      [mapLayerIds.FieldAccessibility]: true,
      [mapLayerIds.Crops]: true,
      [mapLayerIds.Activities]: true
    },
    weatherParameters: {
      dashboardTab: DASHBOARD_TABS.CURRENT_CONDITIONS,
      forecastTab: FORECAST_TABS['3_DAYS'],
      selectedChart: CHARTS.TEMPERATURE,
      timespan: '7_DAYS',
      customDays: 150
    },
    selectedActivityTypes: {
      Scouting: true,
      NitrogenApplication: true,
      HerbicideApplication: true,
      FungicideApplication: true,
      Irrigation: true,
      Drydown: true
    },
    userFeedbackStatuses: {
      New: true,
      InReview: true,
      Reviewed: true
    },
    selectedMapActivityType: activityTypes.Irrigation.type,
    zoomToField: true,
    growthModel: defaultGrowthModel.type
  },
  action
) => {
  switch (action.type) {
    case uiActionTypes.OPEN_CREATE_GROWTH_DIALOG:
    case uiActionTypes.OPEN_MODIFY_GROWTH_DIALOG:
      return { ...state, createGrowthDialog: { ...action.payload } };
    case uiActionTypes.CLOSE_CREATE_GROWTH_DIALOG:
    case uiActionTypes.CLOSE_MODIFY_GROWTH_DIALOG:
      return _.omit(state, ['createGrowthDialog']);
    case uiActionTypes.OPEN_GROWTH_FEEDBACK_DIALOG:
      return { ...state, growthFeedbackDialog: { ...action.payload } };
    case uiActionTypes.CLOSE_GROWTH_FEEDBACK_DIALOG:
      return _.omit(state, ['growthFeedbackDialog']);
    case uiActionTypes.SELECT_GROWTH_MODEL_TAB:
      return { ...state, growthModelDisplay: { ...action.payload } };
    case uiActionTypes.OPEN_ADD_IRRIGATION_ACTIVITY_DIALOG:
    case uiActionTypes.OPEN_MODIFY_IRRIGATION_ACTIVITY_DIALOG:
      return { ...state, irrigationActivityDialog: { ...action.payload } };
    case uiActionTypes.CLOSE_IRRIGATION_ACTIVITY_DIALOG:
      return _.omit(state, ['irrigationActivityDialog']);
    case uiActionTypes.OPEN_ADD_FIELD_ACCESSIBILITY_FEEDBACK_DIALOG:
      return {
        ...state,
        addFieldAccessibilityFeedbackDialog: { ...action.payload }
      };
    case uiActionTypes.CLOSE_ADD_FIELD_ACCESSIBILITY_FEEDBACK_DIALOG:
      return _.omit(state, ['addFieldAccessibilityFeedbackDialog']);
    case uiActionTypes.DISABLE_SCROLL:
      return { ...state, disableScroll: true };
    case uiActionTypes.ENABLE_SCROLL:
      return { ...state, disableScroll: false };
    case uiActionTypes.SELECT_GROWTH_DASHBOARD_TAB:
      return { ...state, growthDashboardTabId: action.payload.tabId };
    case uiActionTypes.SELECT_MAP_LAYER:
      return {
        ...state,
        mapLayers: {
          ..._.get(state, 'mapLayers', {}),
          layerId: action.payload.mapLayerId
        }
      };
    case uiActionTypes.TOGGLE_FIELD_MAP_LAYER:
      return {
        ...state,
        fieldMapLayers: {
          ..._.get(state, 'fieldMapLayers', {}),
          [action.payload.mapLayerId]: !_.get(state, [
            'fieldMapLayers',
            action.payload.mapLayerId
          ])
        }
      };
    case uiActionTypes.TOGGLE_DETAILS_MAP_LAYER:
      return {
        ...state,
        mapLayers: {
          ..._.get(state, 'mapLayers', {}),
          showDetails: !_.get(state, 'mapLayers.showDetails')
        }
      };
    case uiActionTypes.OPEN_SET_TARGET_MOISTURE_DIALOG:
      return {
        ...state,
        openSetTargetMoistureDialog: { ...action.payload }
      };
    case uiActionTypes.CLOSE_SET_TARGET_MOISTURE_DIALOG:
      return _.omit(state, ['openSetTargetMoistureDialog']);
    case uiActionTypes.OPEN_ADD_MOISTURE_SAMPLE_DIALOG:
      return {
        ...state,
        addMoistureSampleDialog: { ...action.payload }
      };
    case uiActionTypes.CLOSE_ADD_MOISTURE_SAMPLE_DIALOG:
      return _.omit(state, ['addMoistureSampleDialog']);
    case uiActionTypes.SELECT_IRRIGATION_VIEW:
      return { ...state, irrigationView: action.payload.view };
    case uiActionTypes.SET_WEATHER_PARAMETERS:
      return {
        ...state,
        weatherParameters: {
          ..._.get(state, 'weatherParameters', {}),
          ...action.payload.parameters
        }
      };
    case uiActionTypes.TOGGLE_ACTIVITY:
      return {
        ...state,
        selectedActivityTypes: {
          ..._.get(state, 'selectedActivityTypes', {}),
          [action.payload.type]: !_.get(state, [
            'selectedActivityTypes',
            action.payload.type
          ])
        }
      };
    case uiActionTypes.TOGGLE_USER_FEEDBACK_STATUS:
      return {
        ...state,
        userFeedbackStatuses: {
          ..._.get(state, 'userFeedbackStatuses', {}),
          [action.payload.type]: !_.get(state, [
            'userFeedbackStatuses',
            action.payload.type
          ])
        }
      };
    case uiActionTypes.SET_MAP_ACTIVITY_TYPE:
      return {
        ...state,
        selectedMapActivityType: action.payload.type
      };
    case uiActionTypes.TOGGLE_ZOOM_TO_FIELD:
      return {
        ...state,
        zoomToField: !_.get(state, 'zoomToField')
      };
    case uiActionTypes.SELECT_GROWTH_MODEL:
      return {
        ...state,
        growthModel: action.payload.growthModel
      };
    default:
      return state;
  }
};
