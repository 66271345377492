import React, { useCallback, useContext, useMemo } from 'react';

import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import _ from 'lodash';

import { CHARTS } from '../../../_constants/weatherConstants';
import { WeatherContext } from '../../../_context/WeatherContext';
import ResponsiveToggleButtons from '../../../Components/ResponsiveToggleButtons';

import WeatherChartDownloadButton from './WeatherChartDownloadButton';

export const chartSelectorItems = [
  { id: CHARTS.TEMPERATURE, name: 'Temperature' },
  { id: CHARTS.PRECIPITATION, name: 'Precipitation' },
  { id: CHARTS.HUMIDITY, name: 'Humidity' },
  { id: CHARTS.WIND_SPEED, name: 'Wind Speed' },
  { id: CHARTS.GDD, name: 'GDD' }
];

const defaultCharts = _.values(CHARTS);

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    '& > :not(:first-child)': {
      marginLeft: '0.5rem',
      marginRight: '0.5rem'
    }
  }
}));

const ChartSelector = ({
  chartRef,
  tabName,
  availableCharts = defaultCharts
}) => {
  const styles = useStyles();

  const { selectedChart, setSelectedChart } = useContext(WeatherContext);

  const availableItems = useMemo(() => {
    const chartsMap = _.keyBy(availableCharts, (item) => item);

    return _.filter(
      chartSelectorItems,
      (item) => !!_.get(chartsMap, [_.get(item, 'id')])
    );
  }, [availableCharts]);

  const handleSelect = useCallback(
    (chartId) => {
      setSelectedChart(chartId);
    },
    [setSelectedChart]
  );

  return (
    <Box className={styles.container}>
      <ResponsiveToggleButtons
        items={availableItems}
        onSelect={handleSelect}
        selectedItemId={selectedChart}
        size="small"
        variant="contained"
        disableElevation
        dropdownBreakpoint="sm"
      />
      {!!chartRef && (
        <WeatherChartDownloadButton chartRef={chartRef} tabName={tabName} />
      )}
    </Box>
  );
};

export default ChartSelector;
