import React, { useCallback } from 'react';

import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import _ from 'lodash';
import { useDispatch } from 'react-redux';

import { toggleActivityAction } from '../../_store/actions/uiActions';
import { useActivitiesConfig } from '../../_store/selectors/configSelectors';
import { useSelectedActivityTypesSelectors } from '../../_store/selectors/uiSelectors';
import { ResponsiveHeaderButtonsContainer } from '../../Layout/Content';

const useStyles = makeStyles(() => ({
  toggleButton: (props) => ({
    '&:hover': {
      textDecoration: 'none',
      color: '#fff'
    },
    backgroundColor: props.color,
    opacity: props.selected ? 1 : 0.5,
    marginLeft: '0.25rem',
    marginRight: '0.25rem'
  }),
  filterIcon: {
    fontSize: '1.5rem',
    marginLeft: '0.5rem',
    marginRight: '0.5rem'
  }
}));

const ToggleButton = ({ text, color, type, tooltip }) => {
  const dispatch = useDispatch();

  const { selectedActivityTypes } = useSelectedActivityTypesSelectors();

  const selected = !!_.get(selectedActivityTypes, [type]);
  const styles = useStyles({ color, selected });

  const handleClick = useCallback(() => {
    dispatch(toggleActivityAction(type));
  }, [dispatch, type]);

  return (
    <Tooltip title={`${selected ? 'Hide' : 'Show'} ${tooltip} recommendations`}>
      <Button
        size="small"
        variant="contained"
        color="primary"
        className={styles.toggleButton}
        onClick={handleClick}
      >
        {text}
      </Button>
    </Tooltip>
  );
};

const ActivitiesHeaderButtons = () => {
  const { activityTypes } = useActivitiesConfig();
  const styles = useStyles();

  return (
    <ResponsiveHeaderButtonsContainer>
      <Tooltip title="Activities Recommendations Filters">
        <Box>
          <FontAwesomeIcon icon={faFilter} className={styles.filterIcon} />
        </Box>
      </Tooltip>
      {_.map(activityTypes, (config, type) => (
        <ToggleButton
          key={type}
          type={type}
          text={_.first(_.get(config, 'name'))}
          tooltip={_.get(config, 'name')}
          color={_.get(config, 'color')}
        />
      ))}
    </ResponsiveHeaderButtonsContainer>
  );
};

export default ActivitiesHeaderButtons;
