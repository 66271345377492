import React from 'react';

import { CircularProgress } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';

import AppHeader from '../../AppHeader';

const useStyles = makeStyles(() => ({
  container: {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  }
}));

const LoadingOverlay = () => {
  const styles = useStyles();

  return (
    <div className={styles.container}>
      <AppHeader empty />
      <CircularProgress />
    </div>
  );
};

export default LoadingOverlay;
