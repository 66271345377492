import { useMemo } from 'react';

import { seasonService } from '../../../services';
import { createApiSlice } from '../utils/createApiSlice';

const updateSeasonFieldSlice = createApiSlice({
  reducerPath: ['seasons', 'updateSeasonField'],
  apiFunction: seasonService.updateFieldV2
});

const { actions, useApiSelectors } = updateSeasonFieldSlice;

export const updateSeasonFieldActions = actions;

export const updateSeasonFieldSelectors = useApiSelectors;

export const useUpdateSeasonFieldSelectors = () => {
  const { inProgress, errorMessage, success } = updateSeasonFieldSelectors();

  return useMemo(
    () => ({
      inProgress,
      errorMessage,
      success
    }),
    [errorMessage, inProgress, success]
  );
};

export default updateSeasonFieldSlice;
