import { useMemo } from 'react';

import _ from 'lodash';
import { useSelector } from 'react-redux';

import { createDataSlice } from '../../utils/createDataSlice';

const selectedPumpsSlice = createDataSlice({
  reducerPath: ['devices', 'seasonDevices', 'selectedPumps'],
  initialState: {},
  reducers: {
    selectPump(state, action) {
      _.set(
        state,
        [_.get(action, 'payload.growerId'), _.get(action, 'payload.seasonId')],
        _.get(action, 'payload.pumpId')
      );
    }
  }
});

const { actions, baseSelector: selectedPumpsSelector } = selectedPumpsSlice;

export const selectedPumpActions = actions;

export const useSelectedPumpsSelectors = (growerId, seasonId) => {
  const selectedPumps = useSelector(selectedPumpsSelector);

  const selectedPumpId = _.get(selectedPumps, [growerId, seasonId]);

  return useMemo(
    () => ({
      selectedPumpId
    }),
    [selectedPumpId]
  );
};

export default selectedPumpsSlice;
