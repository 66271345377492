import addSeasonFieldDeviceSlice from './addSeasonFieldDeviceSlice';
import removeSeasonFieldDeviceSlice from './removeSeasonFieldDeviceSlice';
import updateSeasonFieldDeviceSlice from './updateSeasonFieldDeviceSlice';

const seasonFieldDevicesSlices = [
  addSeasonFieldDeviceSlice,
  removeSeasonFieldDeviceSlice,
  updateSeasonFieldDeviceSlice
];

export default seasonFieldDevicesSlices;
