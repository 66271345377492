import React, { useContext, useEffect, useMemo } from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import _ from 'lodash';
import { useDispatch } from 'react-redux';

import { FarmStructureContext } from '../../_context/FarmStructureContext';
import { getInfieldDrydown } from '../../_helpers/Growths/growthModelHelpers';
import { updateActiveGrowthAction } from '../../_store/actions/growthsActions';
import { useActiveGrowthSelectors } from '../../_store/selectors/growthsSelectors';
import { useGrowthModelSelectors } from '../../_store/selectors/uiSelectors';
import {
  ContentBody,
  ContentContainer,
  ResponsiveContentHeader
} from '../../Layout/Content';

import FieldHarvestAdvisorChart from './FieldHarvestAdvisorChart';
import HarvestAdvisorHeaderButtons from './HarvestAdvisorHeaderButtons';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%'
  },
  chartContainer: {
    position: 'relative',
    width: '100%'
  }
}));

const FieldHarvestAdvisor = () => {
  const styles = useStyles();
  const dispatch = useDispatch();

  const {
    selectedField,
    selectedGrowerId,
    selectedSeasonId,
    selectedFarmId,
    selectedFieldId
  } = useContext(FarmStructureContext);

  const {
    growth,
    notFound,
    isLoading: inProgress,
    errorMessage
  } = useActiveGrowthSelectors(selectedSeasonId, selectedFieldId);

  const { selectedGrowthModel } = useGrowthModelSelectors();

  const fieldName = _.get(selectedField, 'name');
  const targetGrainMoisture = _.get(growth, 'targetGrainMoisture');

  const infieldDrydown = useMemo(() => {
    const data = getInfieldDrydown(growth, selectedGrowthModel);
    if (targetGrainMoisture) {
      return _.map(data, (item) => ({ ...item, targetGrainMoisture }));
    }
    return data;
  }, [growth, selectedGrowthModel, targetGrainMoisture]);

  useEffect(() => {
    dispatch(
      updateActiveGrowthAction(
        selectedGrowerId,
        selectedSeasonId,
        selectedFarmId,
        selectedFieldId
      )
    );
  }, [
    dispatch,
    selectedFarmId,
    selectedField,
    selectedFieldId,
    selectedGrowerId,
    selectedSeasonId
  ]);

  return (
    <>
      {selectedFieldId && (
        <ContentContainer>
          <ResponsiveContentHeader>
            <span>{fieldName}</span>
            <HarvestAdvisorHeaderButtons />
          </ResponsiveContentHeader>
          <ContentBody>
            <div className={styles.container}>
              {selectedField && (
                <>
                  {inProgress && <CircularProgress />}
                  {!inProgress && !notFound && errorMessage && (
                    <Alert severity="info">
                      <AlertTitle>Active Growth Error</AlertTitle>
                      {errorMessage}
                    </Alert>
                  )}
                  {!inProgress && notFound && (
                    <Alert severity="info">
                      There are no growths configured for this field. In order
                      to display the growth model, please create a growth.
                    </Alert>
                  )}
                  {!inProgress && growth && _.isEmpty(infieldDrydown) && (
                    <Alert severity="info">
                      Harvest Advisor is not available for the selected Growth
                      Model
                    </Alert>
                  )}
                  {!inProgress &&
                    growth &&
                    !_.isEmpty(infieldDrydown) &&
                    !notFound &&
                    !errorMessage && (
                      <FieldHarvestAdvisorChart
                        growth={growth}
                        infieldDrydown={infieldDrydown}
                      />
                    )}
                </>
              )}
              {!selectedField && (
                <Alert severity="info">Please select a Field</Alert>
              )}
            </div>
          </ContentBody>
        </ContentContainer>
      )}
    </>
  );
};

export default FieldHarvestAdvisor;
