import _ from 'lodash';
import { fork } from 'redux-saga/effects';

export function combineRequestSagas(descriptors) {
  const requestSagas = _.chain(descriptors)
    .filter((descriptor) => !!_.get(descriptor, 'requestSaga'))
    .map((descriptor) => _.get(descriptor, 'requestSaga'))
    .value();
  return _.map(requestSagas, (saga) => fork(saga));
}
