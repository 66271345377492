import React from 'react';

import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { useCropTechnology } from '../../../_hooks/useCropTechnology';

import TechnologyCircle from './TechnologyCircle';

const useStyles = makeStyles(() => ({
  seedProduct: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'center'
  }
}));

const Variety = ({ varietyId }) => {
  const { varietyName, technologyColor, technologyTextColor } =
    useCropTechnology(varietyId);

  const styles = useStyles({
    color: technologyColor,
    textColor: technologyTextColor
  });

  return (
    <>
      {varietyId && (
        <Box className={styles.seedProduct}>
          <TechnologyCircle varietyId={varietyId} />
          <Box component="span">{varietyName}</Box>
        </Box>
      )}
    </>
  );
};

export default Variety;
