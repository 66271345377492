import React from 'react';

import DialogContent from '@material-ui/core/DialogContent';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(() => ({
  dialogContent: {
    overflow: 'hidden'
  }
}));

const ResponsiveDialogContent = ({ children, ...props }) => {
  const styles = useStyles();

  return (
    <DialogContent classes={{ root: styles.dialogContent }} {...props}>
      {children}
    </DialogContent>
  );
};

export default ResponsiveDialogContent;
