import { useMemo } from 'react';

import _ from 'lodash';

import { growthModelApiService } from '../../../services/growthModelApi.service';
import { createApiSlice } from '../utils/createApiSlice';

const getGrowthModelApiProductionGrowthStagesCsvSlice = createApiSlice({
  reducerPath: [
    'growthModelApiAdmin',
    'getGrowthModelApiProductionGrowthStagesCsv'
  ],
  apiFunction: growthModelApiService.getProductionGrowthStagesCsv,
  getStateUpdatePath: (action) => {
    const season = _.get(action, 'payload.apiArguments.0');
    return [season];
  },
  getSelectorPath: (season) => {
    return [season];
  },
  apiArgumentsFn: ({ season }) => [season]
});

const { actions, useApiSelectors } =
  getGrowthModelApiProductionGrowthStagesCsvSlice;

export const getGrowthModelApiProductionGrowthStagesCsvActions = actions;

export const useGetGrowthModelApiProductionGrowthStagesCsvSelectors = (
  season
) => {
  const { inProgress, errorMessage, success, updatedAt, response } =
    useApiSelectors(season);

  return useMemo(
    () => ({
      inProgress,
      errorMessage,
      success,
      updatedAt,
      response
    }),
    [errorMessage, inProgress, response, success, updatedAt]
  );
};

export default getGrowthModelApiProductionGrowthStagesCsvSlice;
