import React, { createContext, useCallback, useMemo } from 'react';

import _ from 'lodash';
import { useDispatch } from 'react-redux';

import { userRoles } from '../_constants/userConstants';
import { useSilentTokenRenewal } from '../_hooks/Auth/useSilentTokenRenewal';
import { useUnauthorizedErrorHandler } from '../_hooks/Auth/useUnauthorizedErrorHandler';
import { useAuthSelectors } from '../_store/slices/user/authSlice';
import { clearUserDataActions } from '../_store/slices/user/clearUserDataSlice';

export const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const dispatch = useDispatch();
  const { isAuthenticated, token, currentUser } = useAuthSelectors();

  const isActive = _.get(currentUser, 'isActive');
  const tosAccepted = _.get(currentUser, 'tosAccepted');

  const isLoggedIn = isAuthenticated;

  useSilentTokenRenewal(token);
  useUnauthorizedErrorHandler();

  const logOut = useCallback(() => {
    dispatch(clearUserDataActions.clear());
  }, [dispatch]);

  const hasRole = useCallback(
    (role) => _.get(currentUser, 'role') === _.get(role, 'type'),
    [currentUser]
  );

  const isAdmin = useMemo(() => hasRole(userRoles.Admin), [hasRole]);
  const isConsultant = useMemo(() => hasRole(userRoles.Consultant), [hasRole]);
  const isUser = useMemo(() => hasRole(userRoles.User), [hasRole]);
  const isUserView = useMemo(() => hasRole(userRoles.UserView), [hasRole]);

  const updateUser = useCallback(async () => {
    // const updatedUser = await userService.getUser(token, _.get(user, 'userId'));
    // setUser((existingUser) => ({ ...existingUser, ...updatedUser }));
    // todo fix
  }, []);

  const authContext = useMemo(
    () => ({
      user: currentUser,
      token,
      isLoggedIn,
      logOut,
      hasRole,
      updateUser,
      isAdmin,
      isConsultant,
      isUser,
      isUserView,
      isActive,
      tosAccepted
    }),
    [
      currentUser,
      token,
      isLoggedIn,
      logOut,
      hasRole,
      updateUser,
      isAdmin,
      isConsultant,
      isUser,
      isUserView,
      isActive,
      tosAccepted
    ]
  );

  return (
    <AuthContext.Provider value={authContext}>{children}</AuthContext.Provider>
  );
};
