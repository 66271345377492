import { useMemo } from 'react';

import _ from 'lodash';
import { useSelector } from 'react-redux';

import { createDataSlice } from '../utils/createDataSlice';

const pagingSlice = createDataSlice({
  reducerPath: ['ui', 'paging'],
  initialState: { paging: {} },
  reducers: {
    setPage: (state, action) => {
      const { key, page } = action.payload;
      _.set(state, ['paging', key, 'page'], page);
    },
    setRowsPerPage: (state, action) => {
      const { key, rowsPerPage } = action.payload;
      _.set(state, ['paging', key, 'rowsPerPage'], rowsPerPage);
    }
  }
});

const { actions, baseSelector: pagingSelector } = pagingSlice;

export const pagingActions = actions;

export const usePagingSelectors = (key) => {
  const { paging } = useSelector(pagingSelector);

  const page = _.get(paging, [key, 'page'], 0);
  const rowsPerPage = _.get(paging, [key, 'rowsPerPage'], 10);

  return useMemo(() => ({ page, rowsPerPage }), [page, rowsPerPage]);
};

export default pagingSlice;
