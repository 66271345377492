import React, { useContext, useMemo } from 'react';

import _ from 'lodash';

import { userRoles } from '../../_constants/userConstants';
import { AuthContext } from '../../_context/AuthContext';
import { FarmStructureContext } from '../../_context/FarmStructureContext';

import MenuSection from './MenuSection';
import { getWeatherStationItem, MenuItems } from './NavItems';

const AppNav = ({ collapsedSideMenuHover }) => {
  const { hasRole } = useContext(AuthContext);

  const { selectedGrower } = useContext(FarmStructureContext);

  const weatherKey = _.get(selectedGrower, 'ambientWeatherKey');

  const navItems = useMemo(() => {
    const items = _.isEmpty(weatherKey)
      ? MenuItems
      : [...MenuItems, getWeatherStationItem(weatherKey)];
    return _.filter(items, (navItem) =>
      _.some(navItem.requiredRoles, (role) => hasRole(role))
    );
  }, [hasRole, weatherKey]);

  const userMenuItems = useMemo(
    () =>
      _.filter(navItems, (item) =>
        _.includes(_.get(item, 'requiredRoles'), userRoles.User)
      ),
    [navItems]
  );

  const adminMenuItems = useMemo(
    () =>
      _.filter(
        navItems,
        (item) => !_.includes(_.get(item, 'requiredRoles'), userRoles.User)
      ),
    [navItems]
  );

  return (
    <>
      <MenuSection
        title="Menu"
        menuItems={userMenuItems}
        collapsedSideMenuHover={collapsedSideMenuHover}
      />
      {!_.isEmpty(adminMenuItems) && (
        <MenuSection
          title="Admin Menu"
          menuItems={adminMenuItems}
          collapsedSideMenuHover={collapsedSideMenuHover}
        />
      )}
    </>
  );
};

export default AppNav;
