import React, { useCallback, useMemo, useState } from 'react';

import { Accordion } from '@material-ui/core';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import _ from 'lodash';
import moment from 'moment';

import Variety from '../../Pages/Dashboard/Components/Variety';
import { AccordionDataRow } from '../Configuration/AccordionDataRow';

const useContentStyles = makeStyles(() => ({
  growthBadge: {
    marginTop: '0.25rem',
    marginBottom: '0.25rem'
  },
  editButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  seedProduct: {
    display: 'flex',
    justifyContent: 'flex-end'
  }
}));

const AccordionContent = ({ field }) => {
  const styles = useContentStyles();

  const varietyId = _.get(field, 'varietyId');
  const acres = _.get(field, 'acres');

  const plantingDate = useMemo(() => {
    const date = _.get(field, 'plantingDate');
    return !!date ? moment(date).format('ll') : null;
  }, [field]);

  const physiologicalMaturityDate = useMemo(() => {
    return _.get(field, 'physiologicalMaturityDate');
  }, [field]);

  return (
    <Grid container spacing={2}>
      {!!varietyId && (
        <Grid item xs={12}>
          <AccordionDataRow title="Seed Product" inline>
            <Box className={styles.seedProduct}>
              <Variety varietyId={varietyId} />
            </Box>
          </AccordionDataRow>
        </Grid>
      )}
      {acres && (
        <Grid item xs={12}>
          <AccordionDataRow title="Acres" text={acres.toFixed(1)} inline />
        </Grid>
      )}
      {plantingDate && (
        <Grid item xs={12}>
          <AccordionDataRow title="Planting Date" text={plantingDate} inline />
        </Grid>
      )}
      {physiologicalMaturityDate && (
        <Grid item xs={12}>
          <AccordionDataRow
            title="Phys. Maturity Date"
            text={moment(physiologicalMaturityDate).format('ll')}
            inline
          />
        </Grid>
      )}
    </Grid>
  );
};

const useHeaderStyles = makeStyles(() => ({
  header: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
}));

const AccordionHeader = ({ field }) => {
  const styles = useHeaderStyles();

  const harvestDate = _.get(field, 'harvestDate');

  return (
    <Box className={styles.header}>
      <Typography>
        <b>{!!harvestDate && moment(harvestDate).format('ll')}</b>
        {' - '}
        {_.get(field, 'name')}
      </Typography>
    </Box>
  );
};

const FieldHarvestAdvisorAccordion = ({ field }) => {
  const [expanded, setExpanded] = useState(false);

  const handleToggle = useCallback(() => {
    setExpanded((val) => !val);
  }, []);

  return (
    <Accordion expanded={expanded} onChange={handleToggle}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        id={`${_.get(field, 'id')}-header`}
      >
        <AccordionHeader field={field} />
      </AccordionSummary>
      <AccordionDetails>
        {expanded && <AccordionContent field={field} />}
      </AccordionDetails>
    </Accordion>
  );
};

const GrowerHarvestAdvisorAccordion = ({ fields }) => {
  return (
    <>
      {_.map(fields, (field) => (
        <FieldHarvestAdvisorAccordion key={_.get(field, 'id')} field={field} />
      ))}
    </>
  );
};

export default GrowerHarvestAdvisorAccordion;
