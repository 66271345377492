import React from 'react';

import useMediaQuery from '@material-ui/core/useMediaQuery';

import FieldsAccordion from './FieldsAccordion';
import FieldsTable from './FieldsTable';

const ResponsiveFieldsDetails = () => {
  const showTable = useMediaQuery((theme) => theme.breakpoints.up('md'), {
    noSsr: true
  });

  return (
    <>
      {!showTable && <FieldsAccordion />}
      {showTable && <FieldsTable />}
    </>
  );
};

export default ResponsiveFieldsDetails;
