import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects';

import { apiActionTypes } from '../actionTypes/apiActionTypes';
import { authSelectors } from '../slices/user/authSlice';
import { requestActionName } from '../slices/utils/createApiSlice';

function* handleApiRequest(action) {
  const { apiFunction, apiArguments, requestActions, authenticate } =
    action.payload;
  try {
    let response;
    if (authenticate) {
      const token = yield select(authSelectors.getToken);
      response = yield call(apiFunction, token, ...apiArguments);
    } else {
      response = yield call(apiFunction, ...apiArguments);
    }

    yield put(requestActions.succeeded(apiArguments, response));
  } catch (e) {
    yield put(requestActions.failed(apiArguments, e));
  }
}

function* handleRequest(action) {
  const { apiFunction, apiArguments, requestActions, authenticate } =
    action.payload;
  try {
    let response;
    if (authenticate) {
      const token = yield select(authSelectors.getToken);
      response = yield call(apiFunction, token, ...apiArguments);
    } else {
      response = yield call(apiFunction, ...apiArguments);
    }

    yield put(requestActions.success({ apiArguments, response }));
  } catch (e) {
    yield put(requestActions.error({ apiArguments, apiError: e }));
  }
}

function* watchApiRequests() {
  yield takeEvery(
    (action) => action.type.endsWith(apiActionTypes.REQUEST),
    handleApiRequest
  );
}

function* watchRequests() {
  yield takeEvery(
    (action) => action.type.endsWith(requestActionName),
    handleRequest
  );
}

function* apiRequestSaga() {
  yield all([fork(watchApiRequests), fork(watchRequests)]);
}

export default apiRequestSaga;
