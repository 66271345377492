import { createDataSlice } from '../utils/createDataSlice';

const clearUserDataSlice = createDataSlice({
  reducerPath: ['newUser', 'clearUserData'],
  initialState: {},
  reducers: {
    clear: () => ({})
  }
});

const { actions } = clearUserDataSlice;

export const clearUserDataActions = actions;

export default clearUserDataSlice;
