import React, { lazy, Suspense, useContext } from 'react';

import { Redirect, Route, Switch } from 'react-router-dom';

import { userRoles } from '../../../_constants/userConstants';
import { AuthContext } from '../../../_context/AuthContext';
import WeatherDashboard from '../../../Pages/WeatherDashboard';

import LoadingOverlay from './LoadingOverlay';
import RestrictedRoute from './RestrictedRoute';

const UserAdminPage = lazy(() => import('../../../Pages/UserAdmin'));
const YieldMapPage = lazy(() => import('../../../Pages/YieldMap'));
const LoginPage = lazy(() => import('../../../Pages/Login/LoginPage'));
const ForgotPasswordPage = lazy(() =>
  import('../../../Pages/Login/ForgotPasswordPage')
);
const ResetPasswordPage = lazy(() =>
  import('../../../Pages/Login/ResetPasswordPage')
);
const TokenRefreshPage = lazy(() =>
  import('../../../Pages/Login/TokenRefreshPage')
);
const Aquarius = lazy(() => import('../../../Pages/Aquarius'));
const NotificationsConfig = lazy(() =>
  import('../../../Pages/NotificationsConfig')
);
const GrowersConfigListPage = lazy(() =>
  import('../../../Pages/GrowersConfig/GrowersListPage')
);
const GrowersDevicesListPage = lazy(() =>
  import('../../../Pages/GrowersConfig/GrowersDevicesListPage')
);
const EditGrowerDevicePage = lazy(() =>
  import('../../../Pages/GrowersConfig/EditGrowerDevicePage')
);
const EditGrowerPage = lazy(() =>
  import('../../../Pages/GrowersConfig/EditGrowerPage')
);
const GrowerDetailsPage = lazy(() =>
  import('../../../Pages/GrowersConfig/GrowerDetailsPage')
);
const EditFarmPage = lazy(() =>
  import('../../../Pages/GrowersConfig/EditFarmPage')
);
const FarmDetailsPage = lazy(() =>
  import('../../../Pages/GrowersConfig/FarmDetailsPage')
);
const EditFieldPage = lazy(() =>
  import('../../../Pages/GrowersConfig/EditFieldPage')
);
const ApplicationConfigurationPage = lazy(() =>
  import('../../../Pages/ApplicationConfiguration/ApplicationConfigurationPage')
);
const MapPage = lazy(() => import('../../../Pages/Map'));
const DashboardPage = lazy(() => import('../../../Pages/Dashboard'));
const EditUserPage = lazy(() =>
  import('../../../Pages/UserAdmin/EditUserPage')
);
const UserDetailsPage = lazy(() =>
  import('../../../Pages/UserAdmin/UserDetailsPage')
);

const AccountInactivePage = lazy(() =>
  import('../../../Pages/AccountInactive')
);

const AcceptTermsOfServicePage = lazy(() =>
  import('../../../Pages/TermsOfService/AcceptTermsOfServicePage')
);
const ViewTermsOfServicePage = lazy(() =>
  import('../../../Pages/TermsOfService/ViewTermsOfServicePage')
);
const RedirectHandlerPage = lazy(() =>
  import('../../../Pages/RedirectHandler')
);
const SeasonConfigPage = lazy(() =>
  import('../../../Pages/SeasonsConfig/SeasonConfigPage')
);
const EditSeasonFieldPage = lazy(() =>
  import('../../../Pages/SeasonsConfig/EditSeasonFieldPage')
);
const SeasonFieldSensorConfigPage = lazy(() =>
  import('../../../Pages/SeasonsConfig/SeasonFieldSensorConfigPage')
);

const IrrigationDashboardPage = lazy(() =>
  import('../../../Pages/IrrigationDashboard')
);

const AddUserFeedbackPage = lazy(() =>
  import('../../../Pages/Feedback/AddUserFeedbackPage')
);

const UsersFeedbackPage = lazy(() =>
  import('../../../Pages/Feedback/UsersFeedbackPage')
);

const AboutPage = lazy(() => import('../../../Pages/About/AboutPage'));

const ReportsPage = lazy(() => import('../../../Pages/Reports/ReportsPage'));
const UsageStatisticsPage = lazy(() =>
  import('../../../Pages/Statistics/UsageStatisticsPage')
);
const GrowthModelApiAdminPage = lazy(() =>
  import('../../../Pages/GrowthModelApiAdmin/GrowthModelApiAdminPage')
);
const GrowthModelApiFieldPage = lazy(() =>
  import('../../../Pages/GrowthModelApiAdmin/GrowthModelApiFieldPage')
);

const Routes = () => {
  const { isLoggedIn, isActive, tosAccepted } = useContext(AuthContext);

  return (
    <Suspense fallback={<LoadingOverlay />}>
      <Switch>
        {!isLoggedIn && <Route exact path="/login" component={LoginPage} />}
        {!isLoggedIn && (
          <Route exact path="/forgot-password" component={ForgotPasswordPage} />
        )}
        {!isLoggedIn && (
          <Route exact path="/reset-password" component={ResetPasswordPage} />
        )}
        <RestrictedRoute
          exact
          path="/auth"
          component={TokenRefreshPage}
          allowedRoles={[
            userRoles.Admin,
            userRoles.Consultant,
            userRoles.User,
            userRoles.UserView
          ]}
        />
        {isLoggedIn && !isActive && (
          <Route exact path="/inactive" component={AccountInactivePage} />
        )}
        {isLoggedIn && isActive && !tosAccepted && (
          <Route
            exact
            path="/acceptTermsOfService"
            component={AcceptTermsOfServicePage}
          />
        )}
        {isLoggedIn && isActive && tosAccepted && (
          <RestrictedRoute
            exact
            path="/termsOfService"
            component={ViewTermsOfServicePage}
            allowedRoles={[
              userRoles.Admin,
              userRoles.Consultant,
              userRoles.User,
              userRoles.UserView
            ]}
          />
        )}
        {(!isLoggedIn || (isLoggedIn && isActive && tosAccepted)) && (
          <Route exact path="/redirect" component={RedirectHandlerPage} />
        )}
        <RestrictedRoute
          exact
          path="/yieldmaps"
          component={YieldMapPage}
          allowedRoles={[userRoles.Admin]}
        />
        <RestrictedRoute
          exact
          path="/usersConfig"
          component={UserAdminPage}
          allowedRoles={[userRoles.Admin]}
        />
        <RestrictedRoute
          exact
          path="/weather"
          component={WeatherDashboard}
          allowedRoles={[
            userRoles.Admin,
            userRoles.Consultant,
            userRoles.User,
            userRoles.UserView
          ]}
        />
        <RestrictedRoute
          exact
          path="/irrigationDashboard"
          component={IrrigationDashboardPage}
          allowedRoles={[
            userRoles.Admin,
            userRoles.Consultant,
            userRoles.User,
            userRoles.UserView
          ]}
        />
        <RestrictedRoute
          exact
          path="/aquarius"
          component={Aquarius}
          allowedRoles={[userRoles.Admin]}
        />
        <RestrictedRoute
          exact
          path="/notificationsConfig"
          component={NotificationsConfig}
          allowedRoles={[
            userRoles.Admin,
            userRoles.Consultant,
            userRoles.User,
            userRoles.UserView
          ]}
        />
        <RestrictedRoute
          exact
          path="/growersConfig"
          component={GrowersConfigListPage}
          allowedRoles={[userRoles.Admin, userRoles.Consultant]}
        />
        <RestrictedRoute
          exact
          path="/growersConfig/:growerId/devices"
          component={GrowersDevicesListPage}
          allowedRoles={[userRoles.Admin, userRoles.Consultant]}
        />
        <RestrictedRoute
          exact
          path="/growersConfig/:growerId/devices/new"
          component={EditGrowerDevicePage}
          allowedRoles={[userRoles.Admin, userRoles.Consultant]}
        />
        <RestrictedRoute
          exact
          path="/growersConfig/:growerId/devices/:deviceId/edit"
          component={EditGrowerDevicePage}
          allowedRoles={[userRoles.Admin, userRoles.Consultant]}
        />
        <RestrictedRoute
          exact
          path="/growersConfig/new"
          component={EditGrowerPage}
          allowedRoles={[userRoles.Admin]}
        />
        <RestrictedRoute
          exact
          path="/growersConfig/:growerId/edit"
          component={EditGrowerPage}
          allowedRoles={[userRoles.Admin]}
        />
        <RestrictedRoute
          exact
          path="/growersConfig/:growerId/farms"
          component={GrowerDetailsPage}
          allowedRoles={[userRoles.Admin, userRoles.Consultant]}
        />
        <RestrictedRoute
          exact
          path="/growersConfig/:growerId/farms/new"
          component={EditFarmPage}
          allowedRoles={[userRoles.Admin, userRoles.Consultant]}
        />
        <RestrictedRoute
          exact
          path="/growersConfig/:growerId/farms/:farmId/edit"
          component={EditFarmPage}
          allowedRoles={[userRoles.Admin, userRoles.Consultant]}
        />
        <RestrictedRoute
          exact
          path="/growersConfig/:growerId/farms/:farmId/fields"
          component={FarmDetailsPage}
          allowedRoles={[userRoles.Admin, userRoles.Consultant]}
        />
        <RestrictedRoute
          exact
          path="/growersConfig/:growerId/farms/:farmId/fields/new"
          component={EditFieldPage}
          allowedRoles={[userRoles.Admin, userRoles.Consultant]}
        />
        <RestrictedRoute
          exact
          path="/growersConfig/:growerId/farms/:farmId/fields/:fieldId/edit"
          component={EditFieldPage}
          allowedRoles={[userRoles.Admin, userRoles.Consultant]}
        />
        <RestrictedRoute
          exact
          path="/seasonsConfig"
          component={SeasonConfigPage}
          allowedRoles={[userRoles.Admin, userRoles.Consultant]}
        />
        <RestrictedRoute
          exact
          path="/seasonsConfig/fields/:fieldId/edit"
          component={EditSeasonFieldPage}
          allowedRoles={[userRoles.Admin, userRoles.Consultant]}
        />
        <RestrictedRoute
          exact
          path="/seasonsConfig/fields/:fieldId/devices"
          component={SeasonFieldSensorConfigPage}
          allowedRoles={[userRoles.Admin, userRoles.Consultant]}
        />
        <RestrictedRoute
          exact
          path="/applicationConfig"
          component={ApplicationConfigurationPage}
          allowedRoles={[userRoles.Admin]}
        />
        <RestrictedRoute
          exact
          path="/usersConfig/new"
          component={EditUserPage}
          allowedRoles={[userRoles.Admin]}
        />
        <RestrictedRoute
          exact
          path="/usersConfig/:userId/edit"
          component={EditUserPage}
          allowedRoles={[userRoles.Admin]}
        />
        <RestrictedRoute
          exact
          path="/usersConfig/:userId/details"
          component={UserDetailsPage}
          allowedRoles={[userRoles.Admin]}
        />
        <RestrictedRoute
          exact
          path="/map"
          component={MapPage}
          allowedRoles={[
            userRoles.Admin,
            userRoles.Consultant,
            userRoles.User,
            userRoles.UserView
          ]}
        />
        <RestrictedRoute
          exact
          path="/dashboard"
          component={DashboardPage}
          allowedRoles={[
            userRoles.Admin,
            userRoles.Consultant,
            userRoles.User,
            userRoles.UserView
          ]}
        />
        <RestrictedRoute
          exact
          path="/share-feedback"
          component={AddUserFeedbackPage}
          allowedRoles={[
            userRoles.Admin,
            userRoles.Consultant,
            userRoles.User,
            userRoles.UserView
          ]}
        />
        <RestrictedRoute
          exact
          path="/users-feedback"
          component={UsersFeedbackPage}
          allowedRoles={[userRoles.Admin]}
        />
        <RestrictedRoute
          exact
          path="/about"
          component={AboutPage}
          allowedRoles={[
            userRoles.Admin,
            userRoles.Consultant,
            userRoles.User,
            userRoles.UserView
          ]}
        />
        <RestrictedRoute
          exact
          path="/reports"
          component={ReportsPage}
          allowedRoles={[
            userRoles.Admin,
            userRoles.Consultant,
            userRoles.User,
            userRoles.UserView
          ]}
        />
        <RestrictedRoute
          exact
          path="/statistics"
          component={UsageStatisticsPage}
          allowedRoles={[userRoles.Admin]}
        />
        <RestrictedRoute
          exact
          path="/growth-model-api"
          component={GrowthModelApiAdminPage}
          allowedRoles={[userRoles.Admin]}
        />
        <RestrictedRoute
          exact
          path="/growth-model-api/:season/fields/:fieldId"
          component={GrowthModelApiFieldPage}
          allowedRoles={[userRoles.Admin]}
        />
        <Route
          exact
          path="/"
          render={() => {
            if (!isLoggedIn) {
              return <Redirect to="/login" />;
            }
            if (!isActive) {
              return <Redirect to="/inactive" />;
            }
            if (!tosAccepted) {
              return <Redirect to="/acceptTermsOfService" />;
            }
            return <Redirect to="/redirect" />;
          }}
        />
        <Route render={() => <Redirect to="/" />} />
      </Switch>
    </Suspense>
  );
};

export default Routes;
