import { useMemo } from 'react';

import { useSelector } from 'react-redux';

import { createDataSlice } from '../utils/createDataSlice';

const redirectSlice = createDataSlice({
  reducerPath: ['ui', 'redirect'],
  initialState: {},
  reducers: {
    setParams: {
      reducer: (state, action) => action.payload,
      prepare: (path, params = {}) => {
        return { payload: { path, params } };
      }
    },
    clear: () => ({})
  }
});

const { actions, baseSelector: redirectSelector } = redirectSlice;

export const redirectActions = actions;

export const useRedirectSelectors = () => {
  const { path, params } = useSelector(redirectSelector);

  return useMemo(() => ({ path, params }), [params, path]);
};

export default redirectSlice;
