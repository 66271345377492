import { buildApiActionTypes } from './apiActionTypes';

export const addMoistureSampleActionTypes = buildApiActionTypes(
  'ADD_MOISTURE_SAMPLE'
);

export const updateMoistureSampleActionTypes = buildApiActionTypes(
  'UPDATE_MOISTURE_SAMPLE'
);

export const deleteMoistureSampleActionTypes = buildApiActionTypes(
  'DELETE_MOISTURE_SAMPLE'
);
