import React, { useEffect, useMemo, useRef } from 'react';

import makeStyles from '@material-ui/core/styles/makeStyles';
import * as d3 from 'd3';
import _ from 'lodash';

const useStyles = makeStyles(() => ({
  axis: {
    '& > .tick': {
      stroke: 'lightgray',
      opacity: 0.5,
      shapeRendering: 'crispEdges'
    },
    '& > path': {
      strokeWidth: 0
    }
  },
  label: {
    textAnchor: 'middle',
    dominantBaseline: 'text-before-edge',
    opacity: 0.5
  }
}));

const yAxis = (g, y, axisX) =>
  g
    .attr('transform', `translate(${axisX},0)`)
    .call(d3.axisLeft(y))
    .selectAll('text')
    .attr('fill', '#000')
    .attr('stroke', 'none')
    .attr('font-size', 12);

const ChartLeftAxis = ({
  yScale,
  paddingLeft,
  paddingRight,
  axisWidth,
  label
}) => {
  const styles = useStyles();
  const yAxisRef = useRef();

  const axisX = useMemo(
    () => paddingLeft + axisWidth - paddingRight,
    [axisWidth, paddingLeft, paddingRight]
  );

  const axisRange = useMemo(() => yScale.range(), [yScale]);

  const axisTop = useMemo(() => _.min(axisRange), [axisRange]);
  const axisBottom = useMemo(() => _.max(axisRange), [axisRange]);

  const labelX = useMemo(
    () => (-1 * (axisTop + axisBottom)) / 2,
    [axisBottom, axisTop]
  );

  useEffect(() => {
    if (!yAxisRef.current) {
      return;
    }
    const gx = d3.select(yAxisRef.current);
    gx.call(yAxis, yScale, axisX);
  }, [axisX, yScale]);

  return (
    <g>
      <g ref={yAxisRef} className={styles.axis} />
      <text
        transform={`rotate(-90)`}
        x={labelX}
        y={paddingLeft}
        className={styles.label}
      >
        {label}
      </text>
    </g>
  );
};

export default ChartLeftAxis;
