import React, {
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState
} from 'react';

import { Tooltip } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import makeStyles from '@material-ui/core/styles/makeStyles';
import SyncAltIcon from '@material-ui/icons/SyncAlt';
import { Alert } from '@material-ui/lab';
import _ from 'lodash';
import moment from 'moment';

import { FarmStructureContext } from '../../../../_context/FarmStructureContext';
import { WeatherContext } from '../../../../_context/WeatherContext';
import { useActiveGrowth } from '../../../../_hooks/Growths/useActiveGrowth';
import { useFieldWithGrowthSelector } from '../../../../_store/selectors/fieldsSelectors';
import { useGlobalSeasonSelectors } from '../../../../_store/slices/gff/seasons/globalSeasonSlice';
import ChartsHeader from '../../../../Components/Charts/ChartsHeader';
import SelectDatesModal from '../../../../Components/Charts/SelectDatesModal';
import LoadingIndicator from '../../../../Components/LoadingIndicator';
import { ContentBody, ContentContainer } from '../../../../Layout/Content';
import ChartSelector from '../ChartSelector';
import WeatherCharts from '../WeatherCharts';

const useStyles = makeStyles((theme) => ({
  dates: {
    [theme.breakpoints.down('xs')]: {
      margin: '0.25rem 0'
    }
  },
  syncButton: {
    marginLeft: '0.5rem'
  }
}));

const Historical = () => {
  const styles = useStyles();

  const chartRef = useRef(null);

  const {
    historical,
    historicalGDD,
    historicalStartDate,
    historicalEndDate,
    showHourlyHistorical,
    selectedChart,
    setHistoricalStartDate,
    setHistoricalEndDate,
    success,
    inProgress,
    errorMessage,
    timespan,
    setTimespan,
    customDays,
    setCustomDays
  } = useContext(WeatherContext);
  const { isHistorical: isHistoricalSeason, selectedSeason: seasonYear } =
    useGlobalSeasonSelectors();

  const [isSelectDatesModalOpen, setIsSelectDatesModalOpen] = useState(false);

  const {
    selectedGrowerId,
    selectedSeasonId,
    selectedFarmId,
    selectedFieldId
  } = useContext(FarmStructureContext);

  const { field } = useFieldWithGrowthSelector(
    selectedGrowerId,
    selectedSeasonId,
    selectedFarmId,
    selectedFieldId
  );

  const { activeGrowth } = useActiveGrowth(field);
  const plantingDate = _.get(activeGrowth, 'plantingDate');

  const handleSelectDates = useCallback(() => {
    setIsSelectDatesModalOpen(true);
  }, []);

  const handleToggleSelectDatesModalOpen = useCallback(() => {
    setIsSelectDatesModalOpen(!isSelectDatesModalOpen);
  }, [isSelectDatesModalOpen]);

  const firstAvailableDate = useMemo(
    () =>
      isHistoricalSeason
        ? moment(`${seasonYear}-01-01`).startOf('year').toISOString()
        : moment().startOf('year').toISOString(),
    [isHistoricalSeason, seasonYear]
  );
  const lastAvailableDate = useMemo(
    () =>
      isHistoricalSeason
        ? moment(`${seasonYear}-12-31`).endOf('year').toISOString()
        : moment().endOf('day').toISOString(),
    [isHistoricalSeason, seasonYear]
  );

  const handleDatesSync = useCallback(() => {
    if (plantingDate) {
      setHistoricalStartDate(moment(plantingDate).format('YYYY-MM-DD'));
      setHistoricalEndDate(moment().format('YYYY-MM-DD'));
    }
  }, [plantingDate, setHistoricalEndDate, setHistoricalStartDate]);

  return (
    <>
      <ChartsHeader>
        <ChartSelector chartRef={chartRef} tabName="Historical" />
        <span className={styles.dates}>
          {historicalStartDate &&
            historicalEndDate &&
            `${moment(historicalStartDate).format('ll')} - ${moment(
              historicalEndDate
            ).format('ll')}`}
        </span>
        <span>
          <Button
            onClick={handleSelectDates}
            disabled={!historical}
            color="primary"
            size="small"
            variant="contained"
            disableElevation
          >
            Select Dates
          </Button>
          <Tooltip title="Sync dates to growth">
            <span>
              <IconButton
                className={styles.syncButton}
                onClick={handleDatesSync}
                color="primary"
                size="small"
                variant="contained"
                disableElevation
                disabled={!historical || !plantingDate}
              >
                <SyncAltIcon />
              </IconButton>
            </span>
          </Tooltip>
        </span>
      </ChartsHeader>
      <ContentContainer>
        <ContentBody>
          {success && (
            <>
              <WeatherCharts
                chartRef={chartRef}
                selectedChart={selectedChart}
                weatherData={historical}
                GDDData={historicalGDD}
                timeUnit={showHourlyHistorical ? 'hour' : 'day'}
                startDate={historicalStartDate}
                endDate={historicalEndDate}
              />
              <SelectDatesModal
                startDate={historicalStartDate}
                setStartDate={setHistoricalStartDate}
                setEndDate={setHistoricalEndDate}
                firstAvailableDate={firstAvailableDate}
                lastAvailableDate={lastAvailableDate}
                timespan={timespan}
                setTimespan={setTimespan}
                customDays={customDays}
                setCustomDays={setCustomDays}
                isOpen={isSelectDatesModalOpen}
                toggle={handleToggleSelectDatesModalOpen}
              />
            </>
          )}
          {inProgress && <LoadingIndicator />}
          {!inProgress && !!errorMessage && (
            <Alert severity="error">
              Something went wrong. Please try again later.
            </Alert>
          )}
        </ContentBody>
      </ContentContainer>
    </>
  );
};

export default Historical;
