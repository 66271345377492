import { useMemo } from 'react';

import _ from 'lodash';
import { useSelector } from 'react-redux';

import { createDataSlice } from '../utils/createDataSlice';

const unauthorizedErrorSlice = createDataSlice({
  reducerPath: ['newUser', 'unauthorizedError'],
  initialState: { hasError: false },
  reducers: {
    setError: (state) => {
      _.set(state, 'hasError', true);
    },
    clear: (state) => {
      _.set(state, 'hasError', false);
    }
  }
});

const { actions, baseSelector: unauthorizedErrorSelector } =
  unauthorizedErrorSlice;

export const unauthorizedErrorActions = actions;

export const useUnauthorizedErrorSelectors = () => {
  const { hasError } = useSelector(unauthorizedErrorSelector);

  return useMemo(() => ({ hasError }), [hasError]);
};

export default unauthorizedErrorSlice;
