import { buildApiActionTypes } from './apiActionTypes';

export const getConfigFarmsActionTypes =
  buildApiActionTypes('GET_CONFIG_FARMS');

export const createFarmActionTypes = buildApiActionTypes('CREATE_FARM');

export const updateFarmActionTypes = buildApiActionTypes('UPDATE_FARM');

export const deleteFarmActionTypes = {
  ...buildApiActionTypes('DELETE_FARM'),
  SELECT: 'DELETE_FARM_SELECT'
};
