import React, { PureComponent } from 'react';

import { PumpStateTypes } from '../../../../_constants/pumpConstants';

const iconStyle = {
  cursor: 'pointer',
  stroke: 'none'
};

export default class PumpIcon extends PureComponent {
  render() {
    const { size = 20, onClick, state, style, proxy } = this.props;
    const isRunning = state === PumpStateTypes.Running;

    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        id="svg"
        width={size}
        height={size}
        viewBox="0 0 100 100"
        style={{ ...iconStyle, ...style }}
        onClick={onClick}
      >
        <polygon points="43,20 47,8 51,20" fill="#637BAD" />
        <line
          x1="20"
          y1="43"
          x2="50"
          y2="58"
          style={{ stroke: '#637BAD', strokeWidth: 4 }}
        />
        <path
          d="M50,30 Q80,30 80,45"
          fill="transparent"
          stroke="#637BAD"
          strokeWidth="5"
        />

        <rect
          x="30"
          y="20"
          rx="8"
          ry="8"
          width="40"
          height="20"
          fill={isRunning ? '#00BCD4' : 'gold'}
        />

        <rect
          x="30"
          y="25"
          rx="4"
          ry="4"
          width="40"
          height="20"
          fill={isRunning ? '#00BCD4' : 'gold'}
        />

        <rect x="35" y="45" width="30" height="45" fill="#58D68D" />
        <rect x="55" y="45" width="10" height="45" fill="#2ECC71" />

        <path
          d="M15,60 C20,61 15,15 48,10"
          fill="transparent"
          stroke="#58D68D"
          strokeWidth="4"
          strokeLinecap="round"
        />
        <rect
          x="25"
          y="77"
          rx="4"
          ry="4"
          width="50"
          height="15"
          fill={proxy ? '#ef6c00' : 'gold'}
        />

        {isRunning && (
          <path
            fill="cornflowerblue"
            stroke="cornflowerblue"
            strokeWidth="3"
            d="M15 3 Q16.5 6.8 25 18 A12.8 12.8 0 1 1 5 18 Q13.5 6.8 15 3z"
            transform="scale(.35 .5), translate(213, 95)"
          />
        )}
      </svg>
    );
  }
}
