import moment from 'moment';

import { getErrorMessage } from '../../services/errors';
import {
  addMoistureSampleActionTypes,
  deleteMoistureSampleActionTypes,
  updateMoistureSampleActionTypes
} from '../actionTypes/moistureSamplesActionTypes';
import { requestStatus } from '../helpers/requestStatus';

export const moistureSamplesReducer = (state = {}, action) => {
  switch (action.type) {
    case addMoistureSampleActionTypes.REQUEST:
      return {
        ...state,
        addMoistureSample: {
          status: requestStatus.IN_PROGRESS
        }
      };
    case addMoistureSampleActionTypes.SUCCEEDED:
      return {
        ...state,
        addMoistureSample: {
          status: requestStatus.SUCCESS,
          response: action.payload.response,
          updatedAt: moment().toISOString()
        }
      };
    case addMoistureSampleActionTypes.FAILED:
      return {
        ...state,
        addMoistureSample: {
          status: requestStatus.FAILURE,
          error: {
            message: getErrorMessage(action.payload.apiError)
          }
        }
      };
    case addMoistureSampleActionTypes.CLEAR:
      return {
        ...state,
        addMoistureSample: {}
      };
    case updateMoistureSampleActionTypes.REQUEST:
      return {
        ...state,
        updateMoistureSample: {
          status: requestStatus.IN_PROGRESS
        }
      };
    case updateMoistureSampleActionTypes.SUCCEEDED:
      return {
        ...state,
        updateMoistureSample: {
          status: requestStatus.SUCCESS,
          response: action.payload.response,
          updatedAt: moment().toISOString()
        }
      };
    case updateMoistureSampleActionTypes.FAILED:
      return {
        ...state,
        updateMoistureSample: {
          status: requestStatus.FAILURE,
          error: {
            message: getErrorMessage(action.payload.apiError)
          }
        }
      };
    case updateMoistureSampleActionTypes.CLEAR:
      return {
        ...state,
        updateMoistureSample: {}
      };
    case deleteMoistureSampleActionTypes.REQUEST:
      return {
        ...state,
        deleteMoistureSample: {
          status: requestStatus.IN_PROGRESS
        }
      };
    case deleteMoistureSampleActionTypes.SUCCEEDED:
      return {
        ...state,
        deleteMoistureSample: {
          status: requestStatus.SUCCESS,
          updatedAt: moment().toISOString()
        }
      };
    case deleteMoistureSampleActionTypes.FAILED:
      return {
        ...state,
        deleteMoistureSample: {
          status: requestStatus.FAILURE,
          error: {
            message: getErrorMessage(action.payload.apiError)
          }
        }
      };
    case deleteMoistureSampleActionTypes.CLEAR:
      return {
        ...state,
        deleteMoistureSample: {}
      };
    default:
      return state;
  }
};
