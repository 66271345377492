import _ from 'lodash';
import moment from 'moment';

import { getErrorMessage } from '../../services/errors';
import {
  createFarmActionTypes,
  deleteFarmActionTypes,
  getConfigFarmsActionTypes,
  updateFarmActionTypes
} from '../actionTypes/farmsActionTypes';
import { requestStatus } from '../helpers/requestStatus';

export const farmsReducer = (state = {}, action) => {
  const growerId = _.first(_.get(action, 'payload.apiArguments'));
  switch (action.type) {
    case getConfigFarmsActionTypes.REQUEST:
      return {
        ...state,
        configFarms: {
          ..._.get(state, 'configFarms'),
          [growerId]: {
            ..._.get(state, ['configFarms', growerId]),
            status: requestStatus.IN_PROGRESS
          }
        }
      };
    case getConfigFarmsActionTypes.SUCCEEDED:
      return {
        ...state,
        configFarms: {
          ..._.get(state, 'configFarms'),
          [growerId]: {
            status: requestStatus.SUCCESS,
            farms: action.payload.response,
            updatedAt: moment().toISOString()
          }
        }
      };
    case getConfigFarmsActionTypes.FAILED:
      return {
        ...state,
        configFarms: {
          ..._.get(state, 'configFarms'),
          [growerId]: {
            status: requestStatus.FAILURE,
            error: {
              message: getErrorMessage(action.payload.apiError)
            },
            updatedAt: moment().toISOString()
          }
        }
      };
    case createFarmActionTypes.REQUEST:
      return {
        ...state,
        createFarm: {
          status: requestStatus.IN_PROGRESS
        }
      };
    case createFarmActionTypes.SUCCEEDED:
      return {
        ...state,
        createFarm: {
          status: requestStatus.SUCCESS,
          farm: action.payload.response,
          updatedAt: moment().toISOString()
        }
      };
    case createFarmActionTypes.FAILED:
      return {
        ...state,
        createFarm: {
          status: requestStatus.FAILURE,
          error: {
            message: getErrorMessage(action.payload.apiError)
          }
        }
      };
    case createFarmActionTypes.CLEAR:
      return {
        ...state,
        createFarm: {}
      };
    case updateFarmActionTypes.REQUEST:
      return {
        ...state,
        updateFarm: {
          status: requestStatus.IN_PROGRESS
        }
      };
    case updateFarmActionTypes.SUCCEEDED:
      return {
        ...state,
        updateFarm: {
          status: requestStatus.SUCCESS,
          farm: action.payload.response,
          updatedAt: moment().toISOString()
        }
      };
    case updateFarmActionTypes.FAILED:
      return {
        ...state,
        updateFarm: {
          status: requestStatus.FAILURE,
          error: {
            message: getErrorMessage(action.payload.apiError)
          }
        }
      };
    case updateFarmActionTypes.CLEAR:
      return {
        ...state,
        updateFarm: {}
      };
    case deleteFarmActionTypes.REQUEST:
      return {
        ...state,
        deleteFarm: {
          status: requestStatus.IN_PROGRESS,
          farm: _.get(state, 'deleteFarm.farm')
        }
      };
    case deleteFarmActionTypes.SUCCEEDED:
      return {
        ...state,
        deleteFarm: {
          status: requestStatus.SUCCESS,
          farm: _.get(state, 'deleteFarm.farm'),
          updatedAt: moment().toISOString()
        }
      };
    case deleteFarmActionTypes.FAILED:
      return {
        ...state,
        deleteFarm: {
          status: requestStatus.FAILURE,
          farm: _.get(state, 'deleteFarm.farm'),
          error: {
            message: getErrorMessage(action.payload.apiError)
          }
        }
      };
    case deleteFarmActionTypes.CLEAR:
      return {
        ...state,
        deleteFarm: {}
      };
    case deleteFarmActionTypes.SELECT:
      return {
        ...state,
        deleteFarm: {
          farm: _.find(
            _.get(state, ['configFarms', action.payload.growerId, 'farms']),
            (farm) => farm.id === action.payload.farmId
          )
        }
      };
    default:
      return state;
  }
};
