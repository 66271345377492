import { buildApiActionTypes } from './apiActionTypes';

export const notificationsConfigActionTypes = {
  UPDATE_GROWTH_STAGES_NOTIFICATIONS_CONFIG:
    'UPDATE_GROWTH_STAGES_NOTIFICATIONS_CONFIG',
  UPDATE_ACTIVITIES_NOTIFICATIONS_CONFIG:
    'UPDATE_ACTIVITIES_NOTIFICATIONS_CONFIG',
  UPDATE_PUMP_SYSTEM_NOTIFICATIONS_CONFIG:
    'UPDATE_PUMP_SYSTEM_NOTIFICATIONS_CONFIG'
};

export const getNotificationsConfigActionTypes = buildApiActionTypes(
  'GET_NOTIFICATIONS_CONFIG'
);
export const saveNotificationsConfigActionTypes = buildApiActionTypes(
  'SAVE_NOTIFICATIONS_CONFIG'
);
