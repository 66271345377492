import { useMemo } from 'react';

import _ from 'lodash';
import { useSelector } from 'react-redux';

import { requestStatus } from '../helpers/requestStatus';

export const useAddMoistureSampleSelectors = () => {
  const status = useSelector((state) =>
    _.get(state, 'moistureSamples.addMoistureSample.status')
  );
  const inProgress = status === requestStatus.IN_PROGRESS;
  const success = status === requestStatus.SUCCESS;
  const error = useSelector((state) =>
    _.get(state, 'moistureSamples.addMoistureSample.error.message')
  );

  return useMemo(
    () => ({
      inProgress,
      success,
      error
    }),
    [error, inProgress, success]
  );
};

export const useUpdateMoistureSampleSelectors = () => {
  const status = useSelector((state) =>
    _.get(state, 'moistureSamples.updateMoistureSample.status')
  );
  const inProgress = status === requestStatus.IN_PROGRESS;
  const success = status === requestStatus.SUCCESS;
  const error = useSelector((state) =>
    _.get(state, 'moistureSamples.updateMoistureSample.error.message')
  );

  return useMemo(
    () => ({
      inProgress,
      success,
      error
    }),
    [error, inProgress, success]
  );
};

export const useDeleteMoistureSampleSelectors = () => {
  const status = useSelector((state) =>
    _.get(state, 'moistureSamples.deleteMoistureSample.status')
  );
  const inProgress = status === requestStatus.IN_PROGRESS;
  const success = status === requestStatus.SUCCESS;
  const error = useSelector((state) =>
    _.get(state, 'moistureSamples.deleteMoistureSample.error.message')
  );

  return useMemo(
    () => ({
      inProgress,
      success,
      error
    }),
    [error, inProgress, success]
  );
};
