import _ from 'lodash';
import moment from 'moment';

import { getErrorMessage } from '../../services/errors';
import {
  getFieldWeatherActionTypes,
  getLocationWeatherActionTypes
} from '../actionTypes/weatherActionTypes';
import { requestStatus } from '../helpers/requestStatus';

export const weatherReducer = (state = {}, action) => {
  let seasonYear;
  const seasonId = _.get(action, 'payload.apiArguments.1');
  const fieldId = _.last(_.get(action, 'payload.apiArguments'));
  switch (action.type) {
    case getFieldWeatherActionTypes.REQUEST:
      return {
        ...state,
        fieldsWeather: {
          ..._.get(state, 'fieldsWeather'),
          [seasonId]: {
            ..._.get(state, ['fieldsWeather', seasonId]),
            [fieldId]: {
              status: requestStatus.IN_PROGRESS
            }
          }
        }
      };
    case getFieldWeatherActionTypes.SUCCEEDED:
      return {
        ...state,
        fieldsWeather: {
          ..._.get(state, 'fieldsWeather'),
          [seasonId]: {
            ..._.get(state, ['fieldsWeather', seasonId]),
            [fieldId]: {
              status: requestStatus.SUCCESS,
              response: action.payload.response,
              updatedAt: moment().toISOString()
            }
          }
        }
      };
    case getFieldWeatherActionTypes.FAILED:
      return {
        ...state,
        fieldsWeather: {
          ..._.get(state, 'fieldsWeather'),
          [seasonId]: {
            ..._.get(state, ['fieldsWeather', seasonId]),
            [fieldId]: {
              status: requestStatus.FAILURE,
              error: {
                message: getErrorMessage(action.payload.apiError),
                status: _.get(action, 'payload.apiError.response.status')
              },
              updatedAt: moment().toISOString()
            }
          }
        }
      };
    case getLocationWeatherActionTypes.REQUEST:
      seasonYear = _.get(action, 'payload.apiArguments.0');
      return {
        ...state,
        locationWeather: {
          ..._.get(state, 'locationWeather'),
          [seasonYear]: {
            status: requestStatus.IN_PROGRESS
          }
        }
      };
    case getLocationWeatherActionTypes.SUCCEEDED:
      seasonYear = _.get(action, 'payload.apiArguments.0');
      return {
        ...state,
        locationWeather: {
          ..._.get(state, 'locationWeather'),
          [seasonYear]: {
            status: requestStatus.SUCCESS,
            response: action.payload.response,
            coordinates: {
              lat: _.get(action, 'payload.apiArguments.1'),
              lon: _.get(action, 'payload.apiArguments.2')
            },
            seasonYear,
            updatedAt: moment().toISOString()
          }
        }
      };
    case getLocationWeatherActionTypes.FAILED:
      seasonYear = _.get(action, 'payload.apiArguments.0');
      return {
        ...state,
        locationWeather: {
          ..._.get(state, 'locationWeather'),
          [seasonYear]: {
            status: requestStatus.FAILURE,
            error: {
              message: getErrorMessage(action.payload.apiError),
              status: _.get(action, 'payload.apiError.response.status')
            },
            updatedAt: moment().toISOString()
          }
        }
      };
    default:
      return state;
  }
};
