import { useMemo } from 'react';

import { seasonService } from '../../../../services';
import { createApiSlice } from '../../utils/createApiSlice';

const removeSeasonDeviceSlice = createApiSlice({
  reducerPath: ['devices', 'seasonDevices', 'removeSeasonDevice'],
  apiFunction: seasonService.removeDeviceV2
});

const { actions, useApiSelectors } = removeSeasonDeviceSlice;

export const removeSeasonDeviceActions = actions;

export const useRemoveSeasonDeviceSelectors = () => {
  const { inProgress, success, errorMessage, response, updatedAt } =
    useApiSelectors();

  return useMemo(
    () => ({ inProgress, success, errorMessage, response, updatedAt }),
    [errorMessage, inProgress, response, success, updatedAt]
  );
};

export default removeSeasonDeviceSlice;
