import _ from 'lodash';

export const apiActionTypes = {
  SUBMIT: 'SUBMIT',
  REQUEST: 'REQUEST',
  SUCCEEDED: 'SUCCEEDED',
  FAILED: 'FAILED',
  CLEAR: 'CLEAR'
};

export const buildApiActionTypes = (actionBase) =>
  _.mapValues(apiActionTypes, (action) => `${actionBase}_${action}`);
