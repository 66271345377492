import React, { useContext } from 'react';

import { AuthContext } from '../../../../../_context/AuthContext';

import HeaderContent from './HeaderContent';
import HeaderLogo from './HeaderLogo';

const DesktopHeader = () => {
  const { isActive, tosAccepted } = useContext(AuthContext);

  return (
    <>
      {isActive && tosAccepted && <HeaderLogo />}
      <HeaderContent />
    </>
  );
};

export default DesktopHeader;
