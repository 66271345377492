import _ from 'lodash';

const getStatus = (e) => {
  return _.get(e, 'response.status');
};

export const isUnauthorized = (e) => {
  return getStatus(e) === 401;
};

export const getErrorMessage = (e) => {
  return (
    _.get(e, 'response.data.message') ||
    _.get(e, 'response.statusText') ||
    e.message
  );
};
