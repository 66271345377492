import React from 'react';

import { CHARTS } from '../../../_constants/weatherConstants';

import GDDChart from './GDDChart';
import HumidityChart from './HumidityChart';
import PrecipitationChart from './PrecipitationChart';
import TemperatureChart from './TemperatureChart';
import WindSpeedChart from './WindSpeedChart';

const WeatherCharts = ({
  chartRef,
  weatherData,
  GDDData,
  timeUnit,
  selectedChart,
  startDate,
  endDate
}) => {
  return (
    <>
      {selectedChart === CHARTS.TEMPERATURE && (
        <TemperatureChart
          chartRef={chartRef}
          weatherData={weatherData}
          timeUnit={timeUnit}
          startDate={startDate}
          endDate={endDate}
        />
      )}
      {selectedChart === CHARTS.PRECIPITATION && (
        <PrecipitationChart
          chartRef={chartRef}
          weatherData={weatherData}
          timeUnit={timeUnit}
          startDate={startDate}
          endDate={endDate}
        />
      )}
      {selectedChart === CHARTS.HUMIDITY && (
        <HumidityChart
          chartRef={chartRef}
          weatherData={weatherData}
          timeUnit={timeUnit}
          startDate={startDate}
          endDate={endDate}
        />
      )}
      {selectedChart === CHARTS.WIND_SPEED && (
        <WindSpeedChart
          chartRef={chartRef}
          weatherData={weatherData}
          timeUnit={timeUnit}
          startDate={startDate}
          endDate={endDate}
        />
      )}
      {selectedChart === CHARTS.GDD && (
        <GDDChart
          chartRef={chartRef}
          GDDData={GDDData}
          timeUnit={'day'}
          startDate={startDate}
          endDate={endDate}
        />
      )}
    </>
  );
};

export default WeatherCharts;
