import axios from 'axios';

import { apiUrl } from '../config';

export const client = axios.create({
  baseURL: apiUrl
});

function authHeader(token) {
  return { Authorization: `Bearer ${token}` };
}

export function addToken(token, config = {}) {
  return {
    ...config,
    headers: authHeader(token)
  };
}
