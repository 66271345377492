import React, { useMemo } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import makeStyles from '@material-ui/core/styles/makeStyles';
import _ from 'lodash';
import { useLocation } from 'react-router-dom';

import { MenuItems } from '../AppNav/NavItems';

const useStyles = makeStyles((theme) => ({
  pageTitle: {
    padding: '1rem',
    background: 'rgba(255, 255, 255, 0.55)',
    [theme.breakpoints.down('sm')]: { textAlign: 'center' }
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: { flexDirection: 'column' }
  },
  heading: {
    margin: 0,
    padding: 0,
    fontSize: '1.25rem',
    fontWeight: 400,
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center'
  },
  iconContainer: {
    width: '3.375rem',
    height: '3.375rem',
    marginRight: '1.25rem',
    display: 'flex',
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    background: '#fff',
    boxShadow:
      '0 0.46875rem 2.1875rem rgba(0, 0, 0, 0.03),\n' +
      ' 0 0.9375rem 1.40625rem rgba(0, 0, 0, 0.03),\n' +
      ' 0 0.25rem 0.53125rem rgba(0, 0, 0, 0.05),\n' +
      ' 0 0.125rem 0.1875rem rgba(0, 0, 0, 0.03)',
    borderRadius: '0.25rem',
    [theme.breakpoints.down('sm')]: { display: 'none' }
  },
  icon: {
    fontSize: '1.5rem'
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: { marginTop: '1rem' }
  }
}));

const PageTitle = ({ children, heading, icon }) => {
  const styles = useStyles();

  const location = useLocation();
  const pathname = location.pathname;

  const menuItem = useMemo(
    () =>
      _.find(
        MenuItems,
        (item) => !!pathname && `#${pathname}`.startsWith(_.get(item, 'to'))
      ),
    [pathname]
  );

  const selectedIcon = _.get(menuItem, 'icon', icon);

  return (
    <div className={styles.pageTitle}>
      <div className={styles.wrapper}>
        <div className={styles.heading}>
          <div className={styles.iconContainer}>
            {selectedIcon && (
              <FontAwesomeIcon icon={selectedIcon} className={styles.icon} />
            )}
          </div>
          <div>{_.get(menuItem, 'label', heading)}</div>
        </div>
        <div className={styles.actions}>{children}</div>
      </div>
    </div>
  );
};

export default PageTitle;
