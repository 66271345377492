import { useMemo } from 'react';

import _ from 'lodash';
import { useSelector } from 'react-redux';

import { useGlobalSeasonSelectors } from '../gff/seasons/globalSeasonSlice';
import { createDataSlice } from '../utils/createDataSlice';

const reportsFieldsSelectionSlice = createDataSlice({
  reducerPath: ['reports', 'reportsFieldsSelection'],
  initialState: { fieldsChecked: {}, touched: false },
  reducers: {
    toggleChecked(state, action) {
      const { seasonYear, growerId, seasonId, farmId, fieldId } =
        action.payload;
      _.set(
        state,
        ['fieldsChecked', seasonYear, growerId, seasonId, farmId, fieldId],
        !_.get(state, [
          'fieldsChecked',
          seasonYear,
          growerId,
          seasonId,
          farmId,
          fieldId
        ])
      );
      _.set(state, 'touched', true);
    },
    mergeStateTree(state, action) {
      const { tree, seasonYear } = action.payload;
      _.set(
        state,
        ['fieldsChecked', seasonYear],
        _.merge({ ..._.get(state, ['fieldsChecked', seasonYear]) }, tree)
      );
      _.set(state, 'touched', true);
    },
    replaceStateTree(state, action) {
      const { tree, seasonYear } = action.payload;
      _.set(state, ['fieldsChecked', seasonYear], tree);
      _.set(state, 'touched', false);
    },
    resetTouched(state) {
      _.set(state, 'touched', false);
    }
  }
});

const { actions, baseSelector: reportsFieldsSelectionSelector } =
  reportsFieldsSelectionSlice;

export const reportsFieldsSelectionActions = actions;

export const useReportsFieldsSelectionSelectors = () => {
  const { selectedSeason: seasonYear } = useGlobalSeasonSelectors();

  const { fieldsChecked, touched } = useSelector(
    reportsFieldsSelectionSelector
  );

  const seasonFieldsChecked = _.get(fieldsChecked, [seasonYear]);

  return useMemo(
    () => ({
      fieldsChecked: seasonFieldsChecked,
      touched
    }),
    [seasonFieldsChecked, touched]
  );
};

export default reportsFieldsSelectionSlice;
