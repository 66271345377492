import _ from 'lodash';
import moment from 'moment';

import { getErrorMessage } from '../../services/errors';
import {
  getNotificationsConfigActionTypes,
  notificationsConfigActionTypes,
  saveNotificationsConfigActionTypes
} from '../actionTypes/notificationsConfigActionTypes';
import { requestStatus } from '../helpers/requestStatus';

export const notificationsConfigReducer = (state = {}, action) => {
  const growerId = _.get(action, 'payload.growerId');
  const channel = _.get(action, 'payload.channel');
  const selected = _.get(action, 'payload.selected');
  switch (action.type) {
    case getNotificationsConfigActionTypes.REQUEST:
      return {
        ...state,
        notificationsConfig: {
          status: requestStatus.IN_PROGRESS
        }
      };
    case getNotificationsConfigActionTypes.SUCCEEDED:
      return {
        ...state,
        notificationsConfig: {
          status: requestStatus.SUCCESS,
          config: action.payload.response,
          updatedAt: moment().toISOString()
        }
      };
    case getNotificationsConfigActionTypes.FAILED:
      return {
        ...state,
        notificationsConfig: {
          status: requestStatus.FAILURE,
          error: {
            message: getErrorMessage(action.payload.apiError)
          }
        }
      };
    case getNotificationsConfigActionTypes.CLEAR:
      return {
        ...state,
        notificationsConfig: {}
      };
    case saveNotificationsConfigActionTypes.REQUEST:
      return {
        ...state,
        saveNotificationsConfig: {
          status: requestStatus.IN_PROGRESS
        }
      };
    case saveNotificationsConfigActionTypes.SUCCEEDED:
      return {
        ...state,
        notificationsConfig: {
          status: requestStatus.SUCCESS,
          config: action.payload.response,
          updatedAt: moment().toISOString()
        },
        saveNotificationsConfig: {
          status: requestStatus.SUCCESS,
          response: action.payload.response,
          updatedAt: moment().toISOString()
        }
      };
    case saveNotificationsConfigActionTypes.FAILED:
      return {
        ...state,
        saveNotificationsConfig: {
          status: requestStatus.FAILURE,
          error: {
            message: getErrorMessage(action.payload.apiError)
          }
        }
      };
    case saveNotificationsConfigActionTypes.CLEAR:
      return {
        ...state,
        saveNotificationsConfig: {}
      };
    case notificationsConfigActionTypes.UPDATE_GROWTH_STAGES_NOTIFICATIONS_CONFIG:
      return {
        ...state,
        notificationsConfig: {
          ..._.get(state, 'notificationsConfig', {}),
          config: {
            ..._.get(state, 'notificationsConfig.config', {}),
            growersGrowthStages: {
              ..._.get(
                state,
                'notificationsConfig.config.growersGrowthStages',
                {}
              ),
              [growerId]: {
                ..._.get(
                  state,
                  [
                    'notificationsConfig',
                    'config',
                    'growersGrowthStages',
                    growerId
                  ],
                  {}
                ),
                [channel]: selected
              }
            }
          }
        }
      };
    case notificationsConfigActionTypes.UPDATE_ACTIVITIES_NOTIFICATIONS_CONFIG:
      return {
        ...state,
        notificationsConfig: {
          ..._.get(state, 'notificationsConfig', {}),
          config: {
            ..._.get(state, 'notificationsConfig.config', {}),
            growersActivities: {
              ..._.get(
                state,
                'notificationsConfig.config.growersActivities',
                {}
              ),
              [growerId]: {
                ..._.get(
                  state,
                  [
                    'notificationsConfig',
                    'config',
                    'growersActivities',
                    growerId
                  ],
                  {}
                ),
                [channel]: selected
              }
            }
          }
        }
      };
    case notificationsConfigActionTypes.UPDATE_PUMP_SYSTEM_NOTIFICATIONS_CONFIG:
      const seasonId = _.get(action, 'payload.seasonId');
      const pumpId = _.get(action, 'payload.pumpId');
      return {
        ...state,
        notificationsConfig: {
          ..._.get(state, 'notificationsConfig', {}),
          config: {
            ..._.get(state, 'notificationsConfig.config', {}),
            growersPumpSystems: {
              ..._.get(
                state,
                'notificationsConfig.config.growersPumpSystems',
                {}
              ),
              [growerId]: {
                ..._.get(
                  state,
                  [
                    'notificationsConfig',
                    'config',
                    'growersPumpSystems',
                    growerId
                  ],
                  {}
                ),
                [seasonId]: {
                  ..._.get(
                    state,
                    [
                      'notificationsConfig',
                      'config',
                      'growersPumpSystems',
                      growerId,
                      seasonId
                    ],
                    {}
                  ),
                  [pumpId]: {
                    ..._.get(
                      state,
                      [
                        'notificationsConfig',
                        'config',
                        'growersPumpSystems',
                        growerId,
                        seasonId,
                        pumpId
                      ],
                      {}
                    ),
                    [channel]: selected
                  }
                }
              }
            }
          }
        }
      };
    default:
      return state;
  }
};
