import React, { useCallback, useContext, useMemo, useState } from 'react';

import { TableCell } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import cx from 'classnames';
import _ from 'lodash';
import moment from 'moment';

import { growthStagesTypes } from '../../_constants/growthsConstants';
import { FarmStructureContext } from '../../_context/FarmStructureContext';
import { useActiveGrowth } from '../../_hooks/Growths/useActiveGrowth';
import { useAllGrowerFieldsSelector } from '../../_store/selectors/fieldsSelectors';
import Variety from '../../Pages/Dashboard/Components/Variety';
import GrowthStageBadge from '../Growths/GrowthStageBadge';

import ActivitiesCollapse from './ActivitiesCollapse';

const useStyles = makeStyles(() => ({
  selectedFieldCell: { fontWeight: 'bold' },
  selectedFieldRow: { backgroundColor: '#FAFAFA' }
}));

const FieldRow = ({ field }) => {
  const styles = useStyles();

  const [open, setOpen] = useState(false);

  const { selectedFieldId } = useContext(FarmStructureContext);
  const isSelected = _.get(field, 'id') === selectedFieldId;
  const { activeGrowth } = useActiveGrowth(field);

  const varietyId = _.get(activeGrowth, 'varietyId');

  const plantingDate = useMemo(() => {
    const date = _.get(activeGrowth, 'plantingDate');
    return !!date ? moment(date) : null;
  }, [activeGrowth]);

  const growthStage = useMemo(
    () =>
      _.get(
        activeGrowth,
        'estimatedCurrentGrowthStage',
        growthStagesTypes.Unknown
      ),
    [activeGrowth]
  );

  const stageObserved = useMemo(
    () => _.get(activeGrowth, 'stageObserved', false),
    [activeGrowth]
  );

  const stagePending = useMemo(
    () => _.get(activeGrowth, 'stagePending', false),
    [activeGrowth]
  );

  const harvestDate = _.get(activeGrowth, 'harvestDate');

  const handleCollapseButtonClick = useCallback(() => {
    setOpen((open) => !open);
  }, []);

  return (
    <>
      <TableRow className={cx({ [styles.selectedFieldRow]: isSelected })}>
        <TableCell className={cx({ [styles.selectedFieldCell]: isSelected })}>
          {_.get(field, 'farmName')}
        </TableCell>
        <TableCell className={cx({ [styles.selectedFieldCell]: isSelected })}>
          {_.get(field, 'name')}
        </TableCell>
        <TableCell>
          {!!varietyId && <Variety varietyId={varietyId} />}
        </TableCell>
        <TableCell align="right">
          {!!plantingDate && plantingDate.format('ll')}
        </TableCell>
        <TableCell align="center">
          <GrowthStageBadge
            growthStage={growthStage}
            stageObserved={stageObserved}
            stagePending={stagePending}
          />
        </TableCell>
        <TableCell align="right">
          {!!harvestDate && moment(harvestDate).format('ll')}
        </TableCell>
        <TableCell width="30rem">
          <IconButton size="small" onClick={handleCollapseButtonClick}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <ActivitiesCollapse open={open} field={field} />
        </TableCell>
      </TableRow>
    </>
  );
};

const ActivitiesTable = () => {
  const { fields } = useAllGrowerFieldsSelector();
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Farm</TableCell>
          <TableCell>Field</TableCell>
          <TableCell>Seed Product</TableCell>
          <TableCell align="center">Planting Date</TableCell>
          <TableCell align="center">Growth Stage</TableCell>
          <TableCell align="center">Estimated Harvest Date</TableCell>
          <TableCell />
        </TableRow>
      </TableHead>

      <TableBody>
        {_.map(fields, (field) => (
          <FieldRow key={_.get(field, 'id')} field={field} />
        ))}
      </TableBody>
    </Table>
  );
};

export default ActivitiesTable;
