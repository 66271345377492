import { weatherService } from '../../services';
import {
  getFieldWeatherActionTypes,
  getLocationWeatherActionTypes,
  weatherActionTypes
} from '../actionTypes/weatherActionTypes';

import { buildApiActions } from './apiActions';

export const updateFieldWeatherAction = (
  growerId,
  seasonId,
  farmId,
  fieldId
) => ({
  type: weatherActionTypes.UPDATE_FIELD_WEATHER,
  payload: {
    growerId,
    seasonId,
    farmId,
    fieldId
  }
});

export const forceUpdateFieldWeatherAction = (
  growerId,
  seasonId,
  farmId,
  fieldId
) => ({
  type: weatherActionTypes.FORCE_UPDATE_FIELD_WEATHER,
  payload: {
    growerId,
    seasonId,
    farmId,
    fieldId
  }
});

export const getFieldWeatherActions = buildApiActions(
  getFieldWeatherActionTypes,
  weatherService.getFieldWeatherV2
);

export const updateLocationWeatherAction = (seasonYear, lat, lon) => ({
  type: weatherActionTypes.UPDATE_LOCATION_WEATHER,
  payload: {
    seasonYear,
    lat,
    lon
  }
});

export const forceUpdateLocationWeatherAction = (seasonYear, lat, lon) => ({
  type: weatherActionTypes.FORCE_UPDATE_LOCATION_WEATHER,
  payload: {
    seasonYear,
    lat,
    lon
  }
});

export const updateWeatherStationWeatherAction = (
  growerId,
  seasonId,
  deviceId
) => ({
  type: weatherActionTypes.UPDATE_WEATHER_STATION_WEATHER,
  payload: {
    growerId,
    seasonId,
    deviceId
  }
});

export const forceUpdateWeatherStationWeatherAction = (
  growerId,
  seasonId,
  deviceId
) => ({
  type: weatherActionTypes.FORCE_UPDATE_WEATHER_STATION_WEATHER,
  payload: {
    growerId,
    seasonId,
    deviceId
  }
});

export const getLocationWeatherActions = buildApiActions(
  getLocationWeatherActionTypes,
  weatherService.getLocationWeatherV2
);
