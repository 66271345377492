import React, { useCallback, useEffect, useRef } from 'react';

import { Tooltip } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import * as d3 from 'd3';
import _ from 'lodash';

import { fieldIrrigationType } from '../../_constants/fieldsConstants';
import { useCropDashboardSelectors } from '../../_store/slices/ui/cropDashboardSlice';

// Based on https://stackoverflow.com/a/36152510
function wrapText(self, fieldNamesWidth) {
  let textWidth = self.node().getComputedTextLength(); // Width of text in pixel.
  const initialText = self.text(); // Initial text.
  let textLength = initialText.length; // Length of text in characters.
  let text = initialText;
  const precision = 10;
  let maxIterations = 100;
  const maxWidth = fieldNamesWidth - 10;

  while (
    maxIterations > 0 &&
    text.length > 0 &&
    Math.abs(maxWidth - textWidth) > precision
  ) {
    text =
      textWidth >= maxWidth
        ? text.slice(0, -textLength * 0.15)
        : initialText.slice(0, textLength * 1.15);
    self.text(text !== initialText ? text + '...' : text);
    textWidth = self.node().getComputedTextLength();
    textLength = text.length;
    maxIterations--;
  }
}

const dotRadius = 8;
const dotMargin = 8;

const useStyles = makeStyles(() => ({
  text: {
    opacity: 0.875
  }
}));

const FieldName = ({
  name,
  irrigationType,
  technology,
  barHeight,
  barGap,
  paddingTop,
  startY,
  height,
  idx,
  fieldNamesWidth,
  showTechnology,
  onMouseOver = _.noop,
  onMouseOut = _.noop
}) => {
  const styles = useStyles();

  const textRef = useRef();
  const irrigationTypeRef = useRef();

  const y =
    _.isNumber(startY) && _.isNumber(height)
      ? startY + height / 2
      : (barHeight + barGap) / 2 + idx * (barHeight + barGap) + paddingTop;

  const nameY = !!irrigationType ? y - 13 : y;
  const irrigationTypeY = y + 13;

  const irrigationTypeName = _.get(fieldIrrigationType, [
    irrigationType,
    'name'
  ]);

  const x = showTechnology ? 2 * dotRadius + dotMargin : 0;

  const width = fieldNamesWidth - x;

  useEffect(() => {
    if (!textRef.current) {
      return;
    }
    d3.select(textRef.current).text(name).call(wrapText, width);
  }, [width, name]);

  useEffect(() => {
    if (!irrigationTypeRef.current || !irrigationTypeName) {
      return;
    }
    d3.select(irrigationTypeRef.current)
      .text(irrigationTypeName)
      .call(wrapText, width);
  }, [width, irrigationType, irrigationTypeName]);

  const handleMouseEnter = useCallback(
    (event) => {
      onMouseOver({ element: event.currentTarget, data: technology });
    },
    [onMouseOver, technology]
  );

  const handleMouseLeave = useCallback(() => {
    onMouseOut();
  }, [onMouseOut]);

  return (
    <g>
      {showTechnology && !!technology && (
        <circle
          cx={dotRadius}
          cy={nameY}
          r={dotRadius}
          fill={_.get(technology, 'technologyColor')}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        />
      )}
      <Tooltip title={name}>
        <text
          ref={textRef}
          className={styles.text}
          x={x}
          y={nameY}
          fontSize={14}
          textAnchor="start"
          dominantBaseline="middle"
          fill="#000000"
        />
      </Tooltip>
      {!!irrigationType && (
        <text
          ref={irrigationTypeRef}
          className={styles.text}
          x={0}
          y={irrigationTypeY}
          fontSize={13}
          textAnchor="start"
          dominantBaseline="middle"
          fill="#000000"
        />
      )}
    </g>
  );
};

const TimelineFieldNames = ({
  fieldNames,
  paddingTop,
  barHeight,
  barGap,
  fieldNamesWidth,
  showTechnology,
  disableFarmName = false,
  onMouseOver = _.noop,
  onMouseOut = _.noop
}) => {
  const yAxisRef = useRef();

  const { showAllForGrower } = useCropDashboardSelectors();

  return (
    <g ref={yAxisRef}>
      {_.map(fieldNames, (field, idx) => (
        <FieldName
          key={field.id}
          name={
            showAllForGrower && !disableFarmName
              ? `${field.fieldName} (${field.farmName})`
              : field.fieldName
          }
          irrigationType={field.irrigationType}
          technology={field.technology}
          startY={field.startY}
          height={field.height}
          barHeight={barHeight}
          barGap={barGap}
          paddingTop={paddingTop}
          idx={idx}
          fieldNamesWidth={fieldNamesWidth}
          showTechnology={showTechnology}
          onMouseOver={onMouseOver}
          onMouseOut={onMouseOut}
        />
      ))}
    </g>
  );
};

export default TimelineFieldNames;
