import getGrowthModelReportSlice from './getGrowthModelReportSlice';
import getReportsGrowersSlice from './getReportsGrowersSlice';
import reportsAppliedFieldsSelectionSlice from './reportsAppliedFieldsSelectionSlice';
import reportsChartsSlice from './reportsChartsSlice';
import reportsFieldsSelectionSlice from './reportsFieldsSelectionSlice';
import reportsFiltersSlice from './reportsFiltersSlice';
import reportsPageSizeSlice from './reportsPageSizeSlice';
import savedFiltersSlices from './savedFilters';

const reportsSlices = [
  getReportsGrowersSlice,
  reportsFieldsSelectionSlice,
  reportsAppliedFieldsSelectionSlice,
  getGrowthModelReportSlice,
  savedFiltersSlices,
  reportsChartsSlice,
  reportsFiltersSlice,
  reportsPageSizeSlice
];

export default reportsSlices;
