import { addToken, client } from './client';

export const farmService = {
  getFarmsV2,
  createFarmV2,
  updateFarmV2,
  deleteFarmV2
};

async function getFarmsV2(token, growerId) {
  const response = await client.get(
    `/v2/growers/${growerId}/farms`,
    addToken(token)
  );
  return response.data;
}

async function createFarmV2(token, growerId, farm) {
  const response = await client.post(
    `/v2/growers/${growerId}/farms`,
    farm,
    addToken(token)
  );
  return response.data;
}

async function updateFarmV2(token, growerId, farmId, farm) {
  const response = await client.put(
    `/v2/growers/${growerId}/farms/${farmId}`,
    farm,
    addToken(token)
  );
  return response.data;
}

async function deleteFarmV2(token, growerId, farmId) {
  const response = await client.delete(
    `/v2/growers/${growerId}/farms/${farmId}`,
    addToken(token)
  );
  return response.data;
}
