import distance from '@turf/distance';
import _ from 'lodash';
import moment from 'moment';
import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';

import { apiUpdateIntervals } from '../../config';
import {
  getFieldWeatherActions,
  getLocationWeatherActions
} from '../actions/weatherActions';
import { weatherActionTypes } from '../actionTypes/weatherActionTypes';
import { requestStatus } from '../helpers/requestStatus';
import {
  fieldWeatherErrorStatusSelector,
  fieldWeatherStatusSelector,
  fieldWeatherUpdatedAtSelector,
  locationWeatherErrorStatusSelector,
  locationWeathersCoordinatesSelector,
  locationWeatherStatusSelector,
  locationWeatherUpdatedAtSelector
} from '../selectors/weatherSelectors';
import {
  getCurrentDeviceStateActions,
  getCurrentDeviceStateSelectors
} from '../slices/devices/state/getCurrentDeviceStateSlice';
import {
  getDailyDeviceStateActions,
  getDailyDeviceStateSelectors
} from '../slices/devices/state/getDailyDeviceStateSlice';

const distanceThresholdKilometers = 0.5;

function* updateFieldWeather(
  growerId,
  seasonId,
  farmId,
  fieldId,
  forceUpdate = false
) {
  const fieldWeatherStatus = yield select(
    fieldWeatherStatusSelector(seasonId, fieldId)
  );
  const updatedAt = yield select(
    fieldWeatherUpdatedAtSelector(seasonId, fieldId)
  );
  const errorStatus = yield select(
    fieldWeatherErrorStatusSelector(seasonId, fieldId)
  );

  const shouldUpdate =
    !updatedAt ||
    moment().diff(moment(updatedAt), 'seconds') >
      apiUpdateIntervals.weatherUpdateIntervalSeconds ||
    (fieldWeatherStatus === requestStatus.FAILURE && errorStatus !== 404);
  if (
    fieldWeatherStatus !== requestStatus.IN_PROGRESS &&
    (forceUpdate || shouldUpdate)
  ) {
    yield put(
      getFieldWeatherActions.request(growerId, seasonId, farmId, fieldId)
    );
  }
}

function* updateLocationWeather(seasonYear, lat, lon, forceUpdate = false) {
  const locationWeatherStatus = yield select(
    locationWeatherStatusSelector(seasonYear)
  );
  const updatedAt = yield select(locationWeatherUpdatedAtSelector(seasonYear));
  const errorStatus = yield select(
    locationWeatherErrorStatusSelector(seasonYear)
  );
  const coordinates = yield select(
    locationWeathersCoordinatesSelector(seasonYear)
  );

  const distanceKilometers = !!coordinates
    ? distance([lon, lat], [coordinates.lon, coordinates.lat])
    : undefined;

  const shouldUpdate =
    !updatedAt ||
    (seasonYear === moment().year() &&
      moment().diff(moment(updatedAt), 'seconds') >
        apiUpdateIntervals.weatherUpdateIntervalSeconds) ||
    (locationWeatherStatus === requestStatus.FAILURE && errorStatus !== 404) ||
    (!!coordinates && distanceKilometers > distanceThresholdKilometers);
  if (
    locationWeatherStatus !== requestStatus.IN_PROGRESS &&
    (forceUpdate || shouldUpdate)
  ) {
    yield put(getLocationWeatherActions.request(seasonYear, lat, lon));
  }
}

function* updateWeatherStationWeatherByType(
  selectors,
  actions,
  growerId,
  seasonId,
  deviceId,
  forceUpdate = false
) {
  const status = yield select(selectors.status(growerId, seasonId, deviceId));
  const updatedAt = yield select(
    selectors.updatedAt(growerId, seasonId, deviceId)
  );

  const shouldUpdate =
    !updatedAt ||
    moment().diff(moment(updatedAt), 'seconds') >
      apiUpdateIntervals.weatherUpdateIntervalSeconds ||
    status === requestStatus.FAILURE;
  if (status !== requestStatus.IN_PROGRESS && (forceUpdate || shouldUpdate)) {
    yield put(actions.request(growerId, seasonId, deviceId));
  }
}

function* updateWeatherStationWeather(
  growerId,
  seasonId,
  deviceId,
  forceUpdate = false
) {
  yield updateWeatherStationWeatherByType(
    getDailyDeviceStateSelectors,
    getDailyDeviceStateActions,
    growerId,
    seasonId,
    deviceId,
    forceUpdate
  );
  yield updateWeatherStationWeatherByType(
    getCurrentDeviceStateSelectors,
    getCurrentDeviceStateActions,
    growerId,
    seasonId,
    deviceId,
    forceUpdate
  );
}

function* handleUpdateFieldWeather(action) {
  const growerId = _.get(action, 'payload.growerId');
  const seasonId = _.get(action, 'payload.seasonId');
  const farmId = _.get(action, 'payload.farmId');
  const fieldId = _.get(action, 'payload.fieldId');
  if (growerId && farmId && fieldId) {
    yield call(updateFieldWeather, growerId, seasonId, farmId, fieldId);
  }
}

function* handleForceUpdateFieldWeather(action) {
  const growerId = _.get(action, 'payload.growerId');
  const seasonId = _.get(action, 'payload.seasonId');
  const farmId = _.get(action, 'payload.farmId');
  const fieldId = _.get(action, 'payload.fieldId');
  if (growerId && farmId && fieldId) {
    yield call(updateFieldWeather, growerId, seasonId, farmId, fieldId, true);
  }
}

function* handleUpdateLocationWeather(action) {
  const seasonYear = _.get(action, 'payload.seasonYear');
  const lat = _.get(action, 'payload.lat');
  const lon = _.get(action, 'payload.lon');
  if (lat && lon) {
    yield call(updateLocationWeather, seasonYear, lat, lon);
  }
}

function* handleForceUpdateLocationWeather(action) {
  const seasonYear = _.get(action, 'payload.seasonYear');
  const lat = _.get(action, 'payload.lat');
  const lon = _.get(action, 'payload.lon');
  if (lat && lon) {
    yield call(updateLocationWeather, seasonYear, lat, lon, true);
  }
}

function* handleUpdateWeatherStationWeather(action) {
  const growerId = _.get(action, 'payload.growerId');
  const seasonId = _.get(action, 'payload.seasonId');
  const deviceId = _.get(action, 'payload.deviceId');
  if (growerId && seasonId && deviceId) {
    yield call(updateWeatherStationWeather, growerId, seasonId, deviceId);
  }
}

function* handleForceUpdateWeatherStationWeather(action) {
  const growerId = _.get(action, 'payload.growerId');
  const seasonId = _.get(action, 'payload.seasonId');
  const deviceId = _.get(action, 'payload.deviceId');
  if (growerId && seasonId && deviceId) {
    yield call(updateWeatherStationWeather, growerId, seasonId, deviceId, true);
  }
}

function* watchUpdateFieldWeather() {
  yield takeLatest(
    weatherActionTypes.UPDATE_FIELD_WEATHER,
    handleUpdateFieldWeather
  );
}

function* watchForceUpdateFieldWeather() {
  yield takeLatest(
    weatherActionTypes.FORCE_UPDATE_FIELD_WEATHER,
    handleForceUpdateFieldWeather
  );
}

function* watchUpdateLocationWeather() {
  yield takeLatest(
    weatherActionTypes.UPDATE_LOCATION_WEATHER,
    handleUpdateLocationWeather
  );
}

function* watchForceUpdateLocationWeather() {
  yield takeLatest(
    weatherActionTypes.FORCE_UPDATE_LOCATION_WEATHER,
    handleForceUpdateLocationWeather
  );
}

function* watchUpdateWeatherStationWeather() {
  yield takeLatest(
    weatherActionTypes.UPDATE_WEATHER_STATION_WEATHER,
    handleUpdateWeatherStationWeather
  );
}

function* watchForceUpdateWeatherStationWeather() {
  yield takeLatest(
    weatherActionTypes.FORCE_UPDATE_WEATHER_STATION_WEATHER,
    handleForceUpdateWeatherStationWeather
  );
}

function* weatherSaga() {
  yield all([
    fork(watchUpdateFieldWeather),
    fork(watchForceUpdateFieldWeather),
    fork(watchUpdateLocationWeather),
    fork(watchForceUpdateLocationWeather),
    fork(watchUpdateWeatherStationWeather),
    fork(watchForceUpdateWeatherStationWeather)
  ]);
}

export default weatherSaga;
