import React, { useMemo } from 'react';

import { Chart } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import _ from 'lodash';
import { Line } from 'react-chartjs-2';

import 'chartjs-adapter-moment';
import { useGrowthStagesConfig } from '../../_store/selectors/configSelectors';

Chart.register(annotationPlugin);

export const commonLineDataSetParams = (hexColor) => ({
  type: 'line',
  fill: false,
  lineTension: 0.1,
  borderCapStyle: 'butt',
  borderDash: [],
  borderDashOffset: 0.0,
  borderJoinStyle: 'miter',
  pointBorderWidth: 1,
  pointHoverRadius: 5,
  pointHoverBorderWidth: 2,
  pointRadius: 3,
  pointHitRadius: 10,
  backgroundColor: `${hexColor}33`,
  borderColor: `${hexColor}`,
  pointBorderColor: `${hexColor}`,
  pointBackgroundColor: `${hexColor}`,
  pointHoverBackgroundColor: `${hexColor}`,
  pointHoverBorderColor: `${hexColor}`
});

const GrowthModelChart = ({
  chartRef,
  defaultOptions,
  chartData,
  growthStages
}) => {
  const { growthStagesConfig } = useGrowthStagesConfig();

  const growthStagesAnnotations = useMemo(() => {
    if (!growthStages) {
      return [];
    }

    return _.map(growthStages, (stage) => {
      const growthStage = _.get(stage, 'growthStage');
      const backgroundColor = _.get(growthStagesConfig, [
        growthStage,
        'outlineColor'
      ]);
      const textColor = _.get(growthStagesConfig, [growthStage, 'textColor']);
      const growthStageName = _.get(growthStagesConfig, [growthStage, 'name']);
      return {
        drawTime: 'afterDatasetsDraw',
        type: 'line',
        mode: 'vertical',
        scaleID: 'x-axis',
        value: stage.date,
        borderColor: backgroundColor,
        borderWidth: 2,
        label: {
          position: 'center',
          backgroundColor: backgroundColor,
          fontColor: textColor,
          content: `${growthStageName}${
            stage.accumulatedGdd ? ` - ${stage.accumulatedGdd}` : ''
          }`,
          enabled: true,
          rotation: 270
        }
      };
    });
  }, [growthStages, growthStagesConfig]);

  const options = useMemo(() => {
    return {
      ...defaultOptions,
      plugins: {
        annotation: {
          events: ['click', 'contextmenu'],
          annotations: growthStagesAnnotations
        }
      }
    };
  }, [defaultOptions, growthStagesAnnotations]);

  return (
    <>
      {chartData && (
        <Line
          ref={chartRef}
          data={chartData}
          options={options}
          height={120}
          type="line"
        />
      )}
    </>
  );
};

export default GrowthModelChart;
