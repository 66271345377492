import React, { useCallback, useState } from 'react';

import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import cx from 'classnames';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { useMenuState } from '../../_hooks/ui/useMenuState';
import AppNav from '../AppNav';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'fixed',
    height: `calc(100vh - ${theme.dimensions.headerHeight})`,
    backgroundImage: 'linear-gradient(to right, #141e30, #243b55)',
    display: 'flex',
    width: theme.dimensions.menuDrawerWidth,
    zIndex: '11',
    overflow: 'hidden',
    minWidth: theme.dimensions.menuDrawerWidth,
    flex: `0 0 ${theme.dimensions.menuDrawerWidth}`,
    transition: 'all .2s'
  },
  menuContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  scrollbar: {
    width: '100%'
  },
  innerContainer: {
    padding: '2px 20px 20px',
    width: '100%'
  },
  collapsedMenuContainer: {
    transition: 'width .3s ease',
    width: theme.dimensions.collapsedMenuDrawerWidth,
    minWidth: theme.dimensions.collapsedMenuDrawerWidth,
    flex: `0 0 ${theme.dimensions.collapsedMenuDrawerWidth}`,
    zIndex: '13'
  },
  collapsedMenuContainerHover: {
    width: theme.dimensions.menuDrawerWidth
  },
  footer: {
    padding: '1.5rem',
    color: 'rgba(255, 255, 255, 0.7)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    transition: 'opacity .3s ease',
    whiteSpace: 'nowrap',
    lineHeight: '1.5',
    fontSize: '0.88rem',
    fontWeight: '400',
    '& > :not(:first-child)': {
      marginTop: '0.5rem'
    }
  },
  hiddenFooter: {
    opacity: '0'
  },
  copyright: {},
  footerLink: {
    color: 'rgba(255, 255, 255, 0.7)',
    '&:hover': {
      color: 'rgba(255, 255, 255, 0.7)'
    }
  }
}));

function MenuContent({ onCLick }) {
  const styles = useStyles();

  const [collapsedSideMenuHover, setCollapsedSideMenuHover] = useState(false);

  const { collapsedSideMenu } = useMenuState();

  const handleMouseEnter = useCallback(() => {
    setCollapsedSideMenuHover(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setCollapsedSideMenuHover(false);
  }, []);

  return (
    <div
      className={cx(styles.container, {
        [styles.collapsedMenuContainer]: collapsedSideMenu,
        [styles.collapsedMenuContainerHover]:
          collapsedSideMenu && collapsedSideMenuHover
      })}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <PerfectScrollbar className={styles.scrollbar}>
        <Box className={styles.menuContainer}>
          <div className={styles.innerContainer}>
            <AppNav collapsedSideMenuHover={collapsedSideMenuHover} />
          </div>
          <Box
            className={cx(styles.footer, {
              [styles.hiddenFooter]:
                collapsedSideMenu && !collapsedSideMenuHover
            })}
          >
            <span className={styles.copyright}>&copy; RiceTec Inc.</span>
            <a
              className={styles.footerLink}
              onClick={onCLick}
              href="#/termsOfService"
            >
              Terms of Service
            </a>
            <a className={styles.footerLink} onClick={onCLick} href="#/about">
              About
            </a>
          </Box>
        </Box>
      </PerfectScrollbar>
    </div>
  );
}

export default MenuContent;
