import { select } from 'redux-saga/effects';

import {
  farmIdSelector,
  fieldIdSelector,
  growerIdSelector,
  seasonIdSelector
} from '../selectors/farmStructureSelectors';

export function* getFarmStructureSelection() {
  const growerId = yield select(growerIdSelector);
  const seasonId = yield select(seasonIdSelector);
  const farmId = yield select(farmIdSelector);
  const fieldId = yield select(fieldIdSelector);

  return { growerId, seasonId, farmId, fieldId };
}
