import React, { useCallback, useState } from 'react';

import GrowersTreeView from '../../Pages/Reports/Components/GrowersTreeView';

import ReportsSavedFilters from './ReportsSavedFilters';

const ReportsDrawerContent = () => {
  const [showSavedFilters, setShowSavedFilters] = useState(false);

  const handleShowSavedFilters = useCallback(() => {
    setShowSavedFilters(true);
  }, []);

  const handleHideSavedFilters = useCallback(() => {
    setShowSavedFilters(false);
  }, []);

  return (
    <>
      {!showSavedFilters && (
        <GrowersTreeView onShowSavedFilters={handleShowSavedFilters} />
      )}
      {showSavedFilters && (
        <ReportsSavedFilters onHideSavedFilters={handleHideSavedFilters} />
      )}
    </>
  );
};

export default ReportsDrawerContent;
