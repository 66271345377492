import { buildApiActionTypes } from './apiActionTypes';

export const getGrowersActionTypes = buildApiActionTypes('GET_GROWERS');

export const getConfigGrowersActionTypes =
  buildApiActionTypes('GET_CONFIG_GROWERS');

export const createGrowerActionTypes = buildApiActionTypes('CREATE_GROWER');

export const updateGrowerActionTypes = buildApiActionTypes('UPDATE_GROWER');

export const deleteGrowerActionTypes = {
  ...buildApiActionTypes('DELETE_GROWER'),
  SELECT: 'DELETE_GROWER_SELECT'
};
