import { useMemo } from 'react';

import _ from 'lodash';
import moment from 'moment';

import { useGetCurrentDeviceStateSelectors } from '../../_store/slices/devices/state/getCurrentDeviceStateSlice';
import { useGetDailyDeviceStateSelectors } from '../../_store/slices/devices/state/getDailyDeviceStateSlice';

const dailyHistoricalUnits = {
  airTempAvg: 'F',
  airTempMax: 'F',
  airTempMin: 'F',
  dewPointAvg: 'F',
  dewPointMax: 'F',
  dewPointMin: 'F',
  precipAccPeriod: 'in',
  relativeHumidityAvg: '%',
  relativeHumidityMax: '%',
  relativeHumidityMin: '%',
  windSpeedAvg: 'mph',
  windSpeedMax: 'mph',
  windSpeedMin: 'mph'
};

const currentConditionsUnits = {
  airTemp: 'F',
  dewPoint: 'F',
  precipAccLastHour: 'in/h',
  relativeHumidity: '%',
  windSpeed: 'mph',
  mslPressure: 'mb'
};

const InHgToMb = 33.863886666667;

function getLastValueItem(obj, field) {
  return _.chain(obj)
    .get([field])
    .filter((item) => _.has(item, 'value'))
    .last()
    .value();
}

function mapWeatherParameter(
  weatherStationField,
  outputField,
  input,
  output = {}
) {
  for (let item of _.get(input, weatherStationField, [])) {
    const timestamp = _.get(item, 'timestamp');
    _.set(
      output,
      [timestamp, `${outputField}Avg`],
      _.get(item, 'aggregations.Avg.value')
    );
    _.set(
      output,
      [timestamp, `${outputField}Min`],
      _.get(item, 'aggregations.Min.value')
    );
    _.set(
      output,
      [timestamp, `${outputField}Max`],
      _.get(item, 'aggregations.Max.value')
    );
  }
}

export function useWeatherStationWeather(growerId, seasonId, deviceId) {
  const {
    inProgress: dailyStateInProgress,
    success: dailyStateSuccess,
    errorMessage: dailyStateErrorMessage,
    state: dailyDeviceState,
    updatedAt: dailyUpdatedAt
  } = useGetDailyDeviceStateSelectors(growerId, seasonId, deviceId);

  const {
    inProgress: currentStateInProgress,
    success: currentStateSuccess,
    errorMessage: currentStateErrorMessage,
    state: currentDeviceState,
    updatedAt: currentUpdatedAt
  } = useGetCurrentDeviceStateSelectors(growerId, seasonId, deviceId);

  const dailyHistoricalItems = useMemo(() => {
    const byDate = {};
    mapWeatherParameter(
      'outdoorTemperatureF',
      'airTemp',
      dailyDeviceState,
      byDate
    );
    mapWeatherParameter('dewPointF', 'dewPoint', dailyDeviceState, byDate);
    mapWeatherParameter('windSpeedMph', 'windSpeed', dailyDeviceState, byDate);
    mapWeatherParameter(
      'humidity',
      'relativeHumidity',
      dailyDeviceState,
      byDate
    );

    for (let dailyRain of _.get(dailyDeviceState, 'dailyRainIn', [])) {
      const timestamp = _.get(dailyRain, 'timestamp');
      _.set(
        byDate,
        [timestamp, 'precipAccPeriod'],
        _.get(dailyRain, 'aggregations.Max.value')
      );
    }

    const allItems = _.chain(byDate)
      .toPairs()
      .map((pair) => ({ date: pair[0], ...pair[1] }))
      .orderBy('date')
      .value();

    const valuesWithGaps = [];
    for (let i = 0; i < allItems.length; i++) {
      const val = allItems[i];
      valuesWithGaps.push(val);
      if (i < allItems.length - 1) {
        const nextVal = allItems[i + 1];
        const currentTime = moment(val.date);
        const nextTime = moment(nextVal.date);
        if (nextTime.diff(currentTime, 'days', true) > 1) {
          valuesWithGaps.push({
            date: currentTime.clone().add(1, 'day').toISOString(),
            airTempAvg: null,
            airTempMax: null,
            airTempMin: null,
            dewPointAvg: null,
            dewPointMax: null,
            dewPointMin: null,
            relativeHumidityAvg: null,
            relativeHumidityMax: null,
            relativeHumidityMin: null,
            windSpeedAvg: null,
            windSpeedMax: null,
            windSpeedMin: null
          });
        }
      }
    }
    return valuesWithGaps;
  }, [dailyDeviceState]);

  const dailyHistorical = useMemo(
    () => ({
      items: dailyHistoricalItems,
      units: dailyHistoricalUnits
    }),
    [dailyHistoricalItems]
  );

  const dailyGddItems = useMemo(
    () =>
      _.chain(dailyHistoricalItems)
        .map((item) => {
          const date = _.get(item, 'date');
          const airTempMin = _.get(item, 'airTempMin');
          const airTempMax = _.get(item, 'airTempMax');
          const maxTemp = airTempMax > 94 ? 94 : airTempMax;
          const minTemp = airTempMin > 70 ? 70 : airTempMin;
          const gdd =
            _.isNumber(airTempMax) && _.isNumber(airTempMin)
              ? (maxTemp + minTemp) / 2 - 50 > 0
                ? (maxTemp + minTemp) / 2 - 50
                : 0
              : null;
          return { date, airTempMin, airTempMax, gdd };
        })
        .value(),
    [dailyHistoricalItems]
  );

  const dailyGdd = useMemo(
    () => ({
      items: dailyGddItems
    }),
    [dailyGddItems]
  );

  const currentConditions = useMemo(() => {
    const temperatureItem = getLastValueItem(
      currentDeviceState,
      'outdoorTemperatureF'
    );
    const dewPointItem = getLastValueItem(currentDeviceState, 'dewPointF');
    const windSpeedItem = getLastValueItem(currentDeviceState, 'windSpeedMph');
    const windDirectionItem = getLastValueItem(
      currentDeviceState,
      'windDirection'
    );
    const hourlyRainRateItem = getLastValueItem(
      currentDeviceState,
      'hourlyRainRateInH'
    );
    const humidityItem = getLastValueItem(currentDeviceState, 'humidity');
    const absolutePressureItem = getLastValueItem(
      currentDeviceState,
      'absolutePressureInHg'
    );

    const timestamps = _.map(
      [
        temperatureItem,
        dewPointItem,
        windSpeedItem,
        windDirectionItem,
        hourlyRainRateItem,
        humidityItem,
        absolutePressureItem
      ],
      (item) => _.get(item, 'timestamp')
    );
    const timeStamp = _.max(timestamps);

    const pressureMb = !_.isUndefined(_.get(absolutePressureItem, 'value'))
      ? Math.round(_.get(absolutePressureItem, 'value') * InHgToMb)
      : undefined;

    return {
      isOnline: _.get(currentDeviceState, 'isOnline'),
      latestItemDate: _.get(currentDeviceState, 'latestItemDate'),
      validTimeStart: timeStamp,
      airTemp: _.get(temperatureItem, 'value'),
      dewPoint: _.get(dewPointItem, 'value'),
      windSpeed: _.get(windSpeedItem, 'value'),
      windDirection: _.get(windDirectionItem, 'value'),
      precipAccLastHour: _.get(hourlyRainRateItem, 'value'),
      mslPressure: pressureMb,
      relativeHumidity: _.get(humidityItem, 'value'),
      units: currentConditionsUnits
    };
  }, [currentDeviceState]);

  const inProgress = dailyStateInProgress || currentStateInProgress;
  const success = dailyStateSuccess && currentStateSuccess;
  const errorMessage = dailyStateErrorMessage || currentStateErrorMessage;
  const updatedAt = dailyUpdatedAt && currentUpdatedAt;

  const weather = useMemo(
    () => ({ dailyHistorical, currentConditions, dailyGdd }),
    [currentConditions, dailyGdd, dailyHistorical]
  );

  return useMemo(
    () => ({
      inProgress,
      success,
      errorMessage,
      weather,
      updatedAt
    }),
    [inProgress, success, errorMessage, weather, updatedAt]
  );
}
