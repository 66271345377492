import React, { useCallback, useContext } from 'react';

import {
  faClock,
  faCloudShowersHeavy,
  faLongArrowAltUp,
  faTachometerAlt,
  faThermometerHalf,
  faTint,
  faUmbrella,
  faWind
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Paper } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Alert } from '@material-ui/lab';
import cx from 'classnames';
import _ from 'lodash';
import moment from 'moment';

import {
  LOCATION_SOURCES,
  WeatherContext
} from '../../../../_context/WeatherContext';
import { useGlobalSeasonSelectors } from '../../../../_store/slices/gff/seasons/globalSeasonSlice';
import LoadingIndicator from '../../../../Components/LoadingIndicator';
import ContentBody from '../../../../Layout/Content/ContentBody';
import ContentContainer from '../../../../Layout/Content/ContentContainer';
import ForecastWidget from '../ForecastWidget';

const useStyles = makeStyles(() => ({
  conditionsCard: {
    display: 'flex',
    flexDirection: 'column',
    padding: '1rem',
    height: '7rem',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  cardTitle: {
    textAlign: 'center',
    opacity: 0.6,
    lineHeight: 1
  },
  iconWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
    width: '2.1rem',
    height: '2.1rem',
    backgroundColor: '#00000033',
    borderRadius: '50%'
  },
  cardText: {
    fontSize: '1.2rem',
    fontWeight: 'bold'
  },
  cardIcon: {
    width: '100%',
    fontSize: '1.1rem',
    color: '#495057'
  },
  cardDateText: {
    fontSize: '1rem',
    fontWeight: 'normal'
  },
  conditionsImage: {
    height: '4rem'
  },
  currentConditionsCard: {
    backgroundImage: 'linear-gradient(to top, #f5f5f5 0%, #ffffff 100%)'
  },
  temperatureCard: {
    backgroundImage: 'linear-gradient(to top, #ef9a9a 0%, #ffcdd2 100%)'
  },
  dewPointCard: {
    backgroundImage: 'linear-gradient(to top, #81d4fa 0%, #b3e5fc 100%)'
  },
  precipitationCard: {
    backgroundImage: 'linear-gradient(to top, #a5d6a7 0%, #c8e6c9 100%)'
  },
  humidityCard: {
    backgroundImage: 'linear-gradient(to top, #b39ddb 0%, #d1c4e9 100%)'
  },
  windSpeedCard: {
    backgroundImage: 'linear-gradient(to top, #ffcc80 0%, #ffe0b2 100%)'
  },
  windDirectionCard: {
    backgroundImage: 'linear-gradient(to top, #fff59d 0%, #fff9c4 100%)'
  },
  pressureCard: {
    backgroundImage: 'linear-gradient(to top, #80cbc4 0%, #b2dfdb 100%)'
  }
}));

const WeatherCard = ({ style, icon, value, description }) => {
  const styles = useStyles();

  return (
    <>
      {!_.isNull(value) && (
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Paper className={cx(style, styles.conditionsCard)}>
            <div className={styles.iconWrapper}>
              <FontAwesomeIcon className={styles.cardIcon} icon={icon} />
            </div>
            <div className={styles.cardText}>{value}</div>
            <div className={styles.cardTitle}>{description}</div>
          </Paper>
        </Grid>
      )}
    </>
  );
};

const getValue = (currentConditions, separator, key) => {
  if (_.isUndefined(_.get(currentConditions, [key]))) {
    return null;
  }
  return (
    <>
      {_.get(currentConditions, [key])}
      {separator}
      {_.get(currentConditions, ['units', key])}
    </>
  );
};

const CurrentConditions = () => {
  const styles = useStyles();

  const {
    currentConditions,
    dailyForecast,
    errorMessage,
    inProgress,
    success,
    locationSource
  } = useContext(WeatherContext);

  const { isHistorical: isHistoricalSeason } = useGlobalSeasonSelectors();

  const showForecastWidget = useMediaQuery((theme) =>
    theme.breakpoints.up('sm')
  );

  const isOnline = _.get(currentConditions, 'isOnline');
  const latestItemDate = _.get(currentConditions, 'latestItemDate');

  const isWeatherStation = locationSource === LOCATION_SOURCES.WEATHER_STATION;

  const getCardinalDirection = useCallback((angle) => {
    return ['N', 'NE', 'E', 'SE', 'S', 'SW', 'W', 'NW'][
      Math.round(angle / 45) % 8
    ];
  }, []);

  const windDirection = _.get(currentConditions, 'windDirection');

  return (
    <>
      {success && !isHistoricalSeason && (
        <>
          {!isWeatherStation && showForecastWidget && (
            <ForecastWidget dailyForecast={dailyForecast} />
          )}
          {(!isWeatherStation || isOnline) && (
            <>
              <Grid container spacing={2}>
                {!!_.get(currentConditions, 'iconUrl') && (
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Paper
                      className={cx(
                        styles.currentConditionsCard,
                        styles.conditionsCard
                      )}
                    >
                      <img
                        className={styles.conditionsImage}
                        alt="Current conditions icon"
                        src={_.get(currentConditions, 'iconUrl')}
                      />
                      <div className={styles.cardTitle}>Current conditions</div>
                    </Paper>
                  </Grid>
                )}
                {isWeatherStation && (
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Paper
                      className={cx(
                        styles.currentConditionsCard,
                        styles.conditionsCard
                      )}
                    >
                      <div className={styles.iconWrapper}>
                        <FontAwesomeIcon
                          className={styles.cardIcon}
                          icon={faClock}
                        />
                      </div>
                      <div className={styles.cardDateText}>
                        {moment(
                          _.get(currentConditions, 'validTimeStart')
                        ).format('lll')}
                      </div>
                      <div className={styles.cardTitle}>Time</div>
                    </Paper>
                  </Grid>
                )}
                <WeatherCard
                  style={styles.temperatureCard}
                  icon={faThermometerHalf}
                  value={getValue(currentConditions, '\u00b0', 'airTemp')}
                  description="Temperature"
                />
                <WeatherCard
                  style={styles.dewPointCard}
                  icon={faTint}
                  value={getValue(currentConditions, '\u00b0', 'dewPoint')}
                  description="Dew Point"
                />
                <WeatherCard
                  style={styles.precipitationCard}
                  icon={faCloudShowersHeavy}
                  value={getValue(currentConditions, ' ', 'precipAccLastHour')}
                  description="Precipitation"
                />
                <WeatherCard
                  style={styles.humidityCard}
                  icon={faUmbrella}
                  value={getValue(currentConditions, ' ', 'relativeHumidity')}
                  description="Humidity"
                />
                <WeatherCard
                  style={styles.windSpeedCard}
                  icon={faWind}
                  value={getValue(currentConditions, ' ', 'windSpeed')}
                  description="Wind Speed"
                />
                {!_.isUndefined(windDirection) && (
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Paper
                      className={cx(
                        styles.windDirectionCard,
                        styles.conditionsCard
                      )}
                    >
                      <div className={cx(styles.iconWrapper)}>
                        <div
                          style={{
                            transform: `rotateZ(${windDirection}deg)`
                          }}
                        >
                          <FontAwesomeIcon
                            className={styles.cardIcon}
                            icon={faLongArrowAltUp}
                          />
                        </div>
                      </div>
                      <div className={cx(styles.cardText)}>
                        {isNaN(parseInt(windDirection))
                          ? windDirection
                          : `${windDirection}\u00b0 - ${getCardinalDirection(
                              windDirection
                            )}`}
                      </div>
                      <div className={styles.cardTitle}>Wind Direction</div>
                    </Paper>
                  </Grid>
                )}
                <WeatherCard
                  style={styles.pressureCard}
                  icon={faTachometerAlt}
                  value={getValue(currentConditions, ' ', 'mslPressure')}
                  description="Pressure"
                />
              </Grid>
            </>
          )}
          {isWeatherStation && !isOnline && (
            <Alert severity="error">
              {`The Weather Station is offline. Please try again later. Last seen: ${
                !!latestItemDate
                  ? moment(latestItemDate).format('lll')
                  : 'unknown'
              }`}
            </Alert>
          )}
        </>
      )}
      {(isHistoricalSeason || inProgress || !!errorMessage) && (
        <ContentContainer>
          <ContentBody>
            {isHistoricalSeason && (
              <Alert severity="warning">
                Current Conditions are not available for historical seasons.
                Please select the current season.
              </Alert>
            )}
            {inProgress && <LoadingIndicator />}
            {!!errorMessage && !inProgress && (
              <Alert severity="error">
                Something went wrong. Please try again later.
              </Alert>
            )}
          </ContentBody>
        </ContentContainer>
      )}
    </>
  );
};

export default CurrentConditions;
