import _ from 'lodash';
import { all, fork, put, takeLatest } from 'redux-saga/effects';

import { forceUpdateActiveGrowthAction } from '../actions/growthsActions';
import {
  addMoistureSampleActionTypes,
  deleteMoistureSampleActionTypes,
  updateMoistureSampleActionTypes
} from '../actionTypes/moistureSamplesActionTypes';
import { getSeasonWithGrowthsActions } from '../slices/gff/seasons/getSeasonsWithGrowthsSlice';

function* updateActiveGrowth(action) {
  const growerId = _.get(action, 'payload.apiArguments.0');
  const seasonId = _.get(action, 'payload.apiArguments.1');
  const farmId = _.get(action, 'payload.apiArguments.2');
  const fieldId = _.get(action, 'payload.apiArguments.3');
  yield put(forceUpdateActiveGrowthAction(growerId, seasonId, farmId, fieldId));
  yield put(getSeasonWithGrowthsActions.forceUpdate({ growerId, seasonId }));
}

function* watchAddMoistureSample() {
  yield takeLatest(addMoistureSampleActionTypes.SUCCEEDED, updateActiveGrowth);
}

function* watchUpdateMoistureSample() {
  yield takeLatest(
    updateMoistureSampleActionTypes.SUCCEEDED,
    updateActiveGrowth
  );
}

function* watchDeleteMoistureSample() {
  yield takeLatest(
    deleteMoistureSampleActionTypes.SUCCEEDED,
    updateActiveGrowth
  );
}

function* moistureSamplesSaga() {
  yield all([fork(watchAddMoistureSample)]);
  yield all([fork(watchUpdateMoistureSample)]);
  yield all([fork(watchDeleteMoistureSample)]);
}

export default moistureSamplesSaga;
