import {
  faCalendarAlt,
  faCompass,
  faEnvelope,
  faStar
} from '@fortawesome/free-regular-svg-icons';
import {
  faChartLine,
  faBroadcastTower,
  faCalendarWeek,
  faCloudSun,
  faCloudSunRain,
  faCog,
  faCogs,
  faFaucet,
  faUsersCog,
  faClipboardList
} from '@fortawesome/free-solid-svg-icons';

import { userRoles } from '../../_constants/userConstants';

const WEATHER_STATION_URL = 'https://ambientweather.net/dashboard';

export const MenuItems = [
  {
    icon: faCompass,
    label: 'Farm Map',
    to: '#/map',
    requiredRoles: [
      userRoles.Admin,
      userRoles.Consultant,
      userRoles.User,
      userRoles.UserView
    ]
  },
  {
    icon: faCalendarAlt,
    label: 'Crop Dashboard',
    to: '#/dashboard',
    requiredRoles: [
      userRoles.Admin,
      userRoles.Consultant,
      userRoles.User,
      userRoles.UserView
    ]
  },
  {
    icon: faFaucet,
    label: 'Irrigation Dashboard',
    to: '#/irrigationDashboard',
    requiredRoles: [
      userRoles.Admin,
      userRoles.Consultant,
      userRoles.User,
      userRoles.UserView
    ],
    disableForNoFieldDevices: true
  },
  {
    icon: faCompass,
    label: 'Yield Maps Demo',
    to: '#/yieldmaps',
    requiredRoles: [userRoles.Admin]
  },
  {
    icon: faUsersCog,
    label: 'User Management',
    to: '#/usersConfig',
    requiredRoles: [userRoles.Admin]
  },
  {
    icon: faCogs,
    label: 'Growers Configuration',
    to: '#/growersConfig',
    requiredRoles: [userRoles.Admin, userRoles.Consultant]
  },
  {
    icon: faCalendarWeek,
    label: 'Seasons Configuration',
    to: '#/seasonsConfig',
    requiredRoles: [userRoles.Admin, userRoles.Consultant]
  },
  {
    icon: faCog,
    label: 'Application Configuration',
    to: '#/applicationConfig',
    requiredRoles: [userRoles.Admin]
  },
  {
    icon: faCloudSunRain,
    label: 'Weather',
    to: '#/weather',
    requiredRoles: [
      userRoles.Admin,
      userRoles.Consultant,
      userRoles.User,
      userRoles.UserView
    ]
  },
  {
    icon: faStar,
    label: 'Users Feedback Management',
    to: '#/users-feedback',
    requiredRoles: [userRoles.Admin]
  },
  {
    icon: faBroadcastTower,
    label: 'Aquarius API',
    to: '#/aquarius',
    requiredRoles: [userRoles.Admin]
  },
  {
    icon: faEnvelope,
    label: 'Notifications',
    to: '#/notificationsConfig',
    requiredRoles: [
      userRoles.Admin,
      userRoles.Consultant,
      userRoles.User,
      userRoles.UserView
    ]
  },
  {
    icon: faClipboardList,
    label: 'Reports',
    to: '#/reports',
    requiredRoles: [
      userRoles.Admin,
      userRoles.Consultant,
      userRoles.User,
      userRoles.UserView
    ]
  },
  {
    icon: faChartLine,
    label: 'Usage Statistics',
    to: '#/statistics',
    requiredRoles: [userRoles.Admin]
  },
  {
    icon: faCogs,
    label: 'Growth Model API',
    to: '#/growth-model-api',
    requiredRoles: [userRoles.Admin]
  }
];

export const getWeatherStationItem = (weatherKey) => ({
  icon: faCloudSun,
  label: 'Weather Station',
  to: `${WEATHER_STATION_URL}/${weatherKey}`,
  requiredRoles: [
    userRoles.Admin,
    userRoles.Consultant,
    userRoles.User,
    userRoles.UserView
  ],
  target: '_blank'
});
