import React, { PureComponent } from 'react';

const iconStyle = {
  cursor: 'pointer',
  stroke: 'none'
};

export default class LevelIcon extends PureComponent {
  render() {
    const { size = 20, onClick, style } = this.props;

    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        id="svg"
        width={size}
        height={size}
        viewBox="0 0 100 100"
        style={{ ...iconStyle, ...style }}
        onClick={onClick}
      >
        <polygon points="40,17 42,5 44,17" fill="gold" />
        <circle cx="42" cy="6" r="2" fill="gold" />
        <rect
          x="35"
          y="17"
          rx="5"
          ry="5"
          width="31"
          height="20"
          fill="#607D8B"
        />
        <rect x="32.5" y="23" width="36" height="67" fill="#009688" />
        {/* <!--  green: #009688; blue: #0097A7  --> */}

        <rect
          x="28"
          y="23"
          rx="3"
          ry="3"
          width="45"
          height="12"
          fill="#00BCD4"
        />
        <rect
          x="28"
          y="78"
          rx="3"
          ry="3"
          width="45"
          height="12"
          fill="#00BCD4"
        />

        <circle cx="58" cy="44.5" r="1.5" fill="white" />
        <rect x="58" y="43" width="10.5" height="3" fill="white" />
        <circle cx="58" cy="51.5" r="1.5" fill="white" />
        <rect x="58" y="50" width="10.5" height="3" fill="white" />
        <circle cx="58" cy="58.5" r="1.5" fill="white" />
        <rect x="58" y="57" width="10.5" height="3" fill="white" />

        <rect
          x="32.5"
          y="64"
          width="36"
          height="14"
          fill="#5C6BC0"
          fillOpacity="1.0"
        />
        <ellipse cx="42" cy="64" rx="9" ry="4" fill="#009688" />
        <ellipse cx="59" cy="64" rx="9" ry="4" fill="#009688" />
      </svg>
    );
  }
}
