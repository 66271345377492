import { useContext, useMemo } from 'react';

import _ from 'lodash';
import moment from 'moment';

import { FarmStructureContext } from '../../../../_context/FarmStructureContext';
import { getGrowthStages } from '../../../../_helpers/Growths/growthModelHelpers';
import { useGrowthsCropTechnologies } from '../../../../_hooks/useCropTechnology';
import { useGrowthStagesConfig } from '../../../../_store/selectors/configSelectors';
import { useAllGrowerActiveGrowthsSelectors } from '../../../../_store/selectors/growthsSelectors';
import { useGrowthModelSelectors } from '../../../../_store/selectors/uiSelectors';
import { momentFormat } from '../_utils/growthTimelineUtils';

export const useTimelineGrowths = () => {
  const { selectedGrowerId: growerId, selectedSeasonId: seasonId } =
    useContext(FarmStructureContext);

  const { growthStagesConfig } = useGrowthStagesConfig();

  const { inProgress, growths } = useAllGrowerActiveGrowthsSelectors(
    growerId,
    seasonId
  );

  const { selectedGrowthModel } = useGrowthModelSelectors();

  const growthsTechnologies = useGrowthsCropTechnologies(growths);

  const timelineGrowths = useMemo(() => {
    if (inProgress || !growths) {
      return null;
    }
    return _.map(growths, (growth) => {
      const fieldName = _.get(growth, 'fieldName');
      const farmName = _.get(growth, 'farmName');
      const growerId = _.get(growth, 'growerId');
      const seasonId = _.get(growth, 'seasonId');
      const farmId = _.get(growth, 'farmId');
      const fieldId = _.get(growth, 'fieldId');
      const id = _.get(growth, 'id');
      const growthStages = getGrowthStages(growth, selectedGrowthModel);

      const stages = _.map(growthStages, (stage) => {
        const growthStage = _.get(stage, 'growthStage');
        const observed = _.get(stage, 'observed');
        const pending = _.get(stage, 'pending');
        const backgroundColor = _.get(growthStagesConfig, [
          growthStage,
          'outlineColor'
        ]);
        const textColor = _.get(growthStagesConfig, [growthStage, 'textColor']);
        const growthStageName = _.get(growthStagesConfig, [
          growthStage,
          'name'
        ]);
        const start = moment(_.get(stage, 'date'));
        const end = moment(_.get(stage, 'endDate'));
        const startDate = start.format(momentFormat);
        const endDate = end
          ? moment(end).format(momentFormat)
          : start.add(10, 'days').format(momentFormat);
        const addFeedbackAllowed = _.get(stage, 'addFeedbackAllowed');
        const editFeedbackAllowed = _.get(stage, 'editFeedbackAllowed');

        return {
          growthStage,
          growthStageName: growthStageName,
          startDate: startDate,
          endDate: endDate,
          backgroundColor,
          textColor,
          observed,
          pending,
          addFeedbackAllowed,
          editFeedbackAllowed
        };
      });

      return {
        id,
        fieldName,
        farmName,
        growerId,
        seasonId,
        farmId,
        fieldId,
        stages,
        technology: _.get(growthsTechnologies, [id])
      };
    });
  }, [
    inProgress,
    growths,
    selectedGrowthModel,
    growthsTechnologies,
    growthStagesConfig
  ]);

  const startDates = useMemo(
    () =>
      _.flatMap(timelineGrowths || [], (growth) =>
        _.map(growth.stages, (stage) => stage.startDate)
      ),
    [timelineGrowths]
  );

  const endDates = useMemo(
    () =>
      _.flatMap(timelineGrowths || [], (growth) =>
        _.map(growth.stages, (stage) => stage.endDate)
      ),
    [timelineGrowths]
  );

  const allDates = useMemo(
    () => [...startDates, ...endDates],
    [endDates, startDates]
  );

  const minDate = useMemo(() => _.min(allDates), [allDates]);
  const maxDate = useMemo(() => _.max(allDates), [allDates]);

  return useMemo(
    () => ({
      inProgress,
      timelineGrowths,
      minDate,
      maxDate
    }),
    [inProgress, maxDate, minDate, timelineGrowths]
  );
};
