import React from 'react';

import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      '& > :not(:first-child)': {
        marginTop: '0.5rem'
      }
    }
  }
}));

const ResponsiveHeaderButtonsContainer = ({ children }) => {
  const styles = useStyles();

  return <Box className={styles.container}>{children}</Box>;
};

export default ResponsiveHeaderButtonsContainer;
