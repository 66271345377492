import { useEffect, useMemo } from 'react';

import _ from 'lodash';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { pageViewActionActions } from '../_store/slices/user/pageViewActionSlice';
import { MenuItems } from '../Layout/AppNav/NavItems';

export function usePageViewAction(tab = null, page = null) {
  const dispatch = useDispatch();

  const location = useLocation();
  const pathname = location.pathname;

  const menuItem = useMemo(
    () =>
      _.find(
        MenuItems,
        (item) => !!pathname && `#${pathname}`.startsWith(_.get(item, 'to'))
      ),
    [pathname]
  );

  const pageName = _.get(menuItem, 'label', page);

  useEffect(() => {
    dispatch(pageViewActionActions.request({ page: pageName, tab }));
  }, [dispatch, pageName, tab]);
}
