import { useMemo } from 'react';

import { seasonService } from '../../../../services';
import { createApiSlice } from '../../utils/createApiSlice';

const updateSeasonDeviceSlice = createApiSlice({
  reducerPath: ['devices', 'seasonDevices', 'updateSeasonDevice'],
  apiFunction: seasonService.updateDeviceV2
});

const { actions, useApiSelectors } = updateSeasonDeviceSlice;

export const updateSeasonDeviceActions = actions;

export const useUpdateSeasonDeviceSelectors = () => {
  const { inProgress, success, errorMessage, response, updatedAt } =
    useApiSelectors();

  return useMemo(
    () => ({ inProgress, success, errorMessage, response, updatedAt }),
    [errorMessage, inProgress, response, success, updatedAt]
  );
};

export default updateSeasonDeviceSlice;
