import { useMemo } from 'react';

import { userService } from '../../../services';
import { createApiSlice } from '../utils/createApiSlice';

const validateResetPasswordTokenSlice = createApiSlice({
  reducerPath: ['newUser', 'validateResetPasswordToken'],
  apiFunction: userService.validateResetPasswordToken,
  authenticate: false,
  apiArgumentsFn: ({ userId, token }) => [userId, token]
});

const { actions, useApiSelectors } = validateResetPasswordTokenSlice;

export const validateResetPasswordTokenActions = actions;

export const useValidateResetPasswordTokenSelectors = () => {
  const { inProgress, errorMessage, success } = useApiSelectors();

  return useMemo(
    () => ({
      inProgress,
      errorMessage,
      success
    }),
    [errorMessage, inProgress, success]
  );
};

export default validateResetPasswordTokenSlice;
