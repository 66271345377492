import { useMemo } from 'react';

import _ from 'lodash';

import { statisticsService } from '../../../services/statistics.service';
import { createApiSlice } from '../utils/createApiSlice';

const getSeasonFeedbackStatisticsSlice = createApiSlice({
  reducerPath: ['statistics', 'getSeasonFeedbackStatistics'],
  apiFunction: statisticsService.getSeasonFeedbackStatistics,
  getStateUpdatePath: (action) => {
    const seasonYear = _.get(action, 'payload.apiArguments.0');
    return [seasonYear];
  },
  getSelectorPath: (seasonYear) => {
    return [seasonYear];
  },
  apiArgumentsFn: ({ seasonYear }) => [seasonYear]
});

const { actions, useApiSelectors } = getSeasonFeedbackStatisticsSlice;

export const getSeasonFeedbackStatisticsActions = actions;

export const useGetSeasonFeedbackStatisticsSelectors = (seasonYear) => {
  const { inProgress, errorMessage, success, response } =
    useApiSelectors(seasonYear);

  return useMemo(
    () => ({
      inProgress,
      errorMessage,
      success,
      response
    }),
    [errorMessage, inProgress, response, success]
  );
};

export default getSeasonFeedbackStatisticsSlice;
