import { useMemo } from 'react';

import _ from 'lodash';

import { useGlobalSeasonSelectors } from '../../../../_store/slices/gff/seasons/globalSeasonSlice';
import { useGetReportsGrowersSelectors } from '../../../../_store/slices/reports/getReportsGrowersSlice';
import { useReportsFieldsSelectionSelectors } from '../../../../_store/slices/reports/reportsFieldsSelectionSlice';

function getCheckedDisabledIndeterminate(fieldsCount, checkedFieldsCount) {
  const disabled = fieldsCount === 0;
  const checked = checkedFieldsCount > 0 && checkedFieldsCount === fieldsCount;
  const indeterminate =
    checkedFieldsCount > 0 && checkedFieldsCount < fieldsCount;

  return { checked, disabled, indeterminate };
}

export function getReportsGffTreeParams(items) {
  const fieldsCount = _.sumBy(items, 'fieldsCount');
  const checkedFieldsCount = _.sumBy(items, 'checkedFieldsCount');

  const { checked, disabled, indeterminate } = getCheckedDisabledIndeterminate(
    fieldsCount,
    checkedFieldsCount
  );

  return { fieldsCount, checkedFieldsCount, checked, disabled, indeterminate };
}

export function useReportsGrowersWithSelection() {
  const { selectedSeason: seasonYear } = useGlobalSeasonSelectors();

  const { inProgress, errorMessage, success, response } =
    useGetReportsGrowersSelectors(seasonYear);

  const { fieldsChecked, touched } = useReportsFieldsSelectionSelectors();

  const growersWithSelection = useMemo(
    () =>
      _.map(_.get(response, 'growers'), (grower) => {
        const seasonId = _.get(grower, 'seasonId');
        const farms = _.chain(grower)
          .get('farms')
          .map((farm) => {
            const fields = _.chain(farm)
              .get('fields')
              .map((field) => ({
                ...field,
                checked: !!_.get(fieldsChecked, [
                  _.get(grower, 'id'),
                  seasonId,
                  _.get(farm, 'id'),
                  _.get(field, 'id')
                ])
              }))
              .value();
            const farmFieldsCount = _.get(fields, 'length', 0);
            const farmCheckedFieldsCount = _.chain(fields)
              .filter((field) => !!_.get(field, 'checked'))
              .get('length', 0)
              .value();

            const { checked, disabled, indeterminate } =
              getCheckedDisabledIndeterminate(
                farmFieldsCount,
                farmCheckedFieldsCount
              );

            return {
              ...farm,
              disabled,
              checked,
              indeterminate,
              fieldsCount: farmFieldsCount,
              checkedFieldsCount: farmCheckedFieldsCount,
              fields
            };
          })
          .value();

        const {
          fieldsCount,
          checkedFieldsCount,
          checked,
          disabled,
          indeterminate
        } = getReportsGffTreeParams(farms);

        return {
          ...grower,
          disabled,
          checked,
          indeterminate,
          fieldsCount,
          checkedFieldsCount,
          farms
        };
      }),
    [fieldsChecked, response]
  );

  return useMemo(
    () => ({
      inProgress,
      errorMessage,
      success,
      growers: growersWithSelection,
      touched
    }),
    [inProgress, errorMessage, success, growersWithSelection, touched]
  );
}
