import { addToken, client } from './client';

export const weatherService = {
  getFieldWeatherV2,
  getLocationWeatherV2
};

async function getFieldWeatherV2(token, growerId, seasonId, farmId, fieldId) {
  const response = await client.get(
    `/v2/growers/${growerId}/seasons/${seasonId}/farms/${farmId}/fields/${fieldId}/weather`,
    addToken(token)
  );
  return response.data;
}

async function getLocationWeatherV2(token, seasonYear, lat, lon) {
  const response = await client.get(
    `/v2/weather?lat=${lat}&lon=${lon}&seasonYear=${seasonYear}`,
    addToken(token)
  );
  return response.data;
}
