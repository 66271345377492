import _ from 'lodash';

import { getErrorMessage } from '../../services/errors';
import { getCurrentTermsOfServiceActionTypes } from '../actionTypes/termsOfServiceActionTypes';
import { requestStatus } from '../helpers/requestStatus';

export const termsOfServiceReducer = (state = {}, action) => {
  switch (action.type) {
    case getCurrentTermsOfServiceActionTypes.REQUEST:
      return {
        ...state,
        current: {
          status: requestStatus.IN_PROGRESS
        }
      };
    case getCurrentTermsOfServiceActionTypes.SUCCEEDED:
      return {
        ...state,
        current: {
          status: requestStatus.SUCCESS,
          response: action.payload.response
        }
      };
    case getCurrentTermsOfServiceActionTypes.FAILED:
      return {
        ...state,
        current: {
          status: requestStatus.FAILURE,
          error: {
            message: getErrorMessage(action.payload.apiError),
            status: _.get(action, 'payload.apiError.response.status')
          }
        }
      };
    default:
      return state;
  }
};
