import cropDashboardSlice from './cropDashboardSlice';
import dialogsSlices from './dialogs';
import growersConfigSlice from './growersConfigSlice';
import growersDrawerSlice from './growersDrawerSlice';
import irrigationDashboardSlice from './irrigationDashboardSlice';
import menuSlice from './menuSlice';
import pagingSlice from './pagingSlice';
import redirectSlice from './redirectSlice';

const uiSlices = [
  dialogsSlices,
  growersDrawerSlice,
  menuSlice,
  redirectSlice,
  cropDashboardSlice,
  pagingSlice,
  growersConfigSlice,
  irrigationDashboardSlice
];

export default uiSlices;
