import { apiActionTypes } from '../actionTypes/apiActionTypes';

export const buildApiAction = (actionType, payload = {}) => ({
  type: actionType,
  payload
});

export const buildApiActions = (
  actionTypes,
  apiFunction,
  authenticate = true
) => {
  const requestActions = {
    succeeded: (apiArguments, response) =>
      buildApiAction(actionTypes[apiActionTypes.SUCCEEDED], {
        response,
        apiArguments
      }),
    failed: (apiArguments, apiError) =>
      buildApiAction(actionTypes[apiActionTypes.FAILED], {
        apiError,
        apiArguments
      })
  };

  return {
    ...requestActions,
    submit: (payload) =>
      buildApiAction(actionTypes[apiActionTypes.SUBMIT], payload),
    request: (...args) =>
      buildApiAction(actionTypes[apiActionTypes.REQUEST], {
        requestActions,
        apiFunction,
        apiArguments: args,
        authenticate
      }),
    clear: () => buildApiAction(actionTypes[apiActionTypes.CLEAR])
  };
};
