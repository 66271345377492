import React, { useCallback, useContext, useEffect, useMemo } from 'react';

import MomentUtils from '@date-io/moment';
import { Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import makeStyles from '@material-ui/core/styles/makeStyles';
import TextField from '@material-ui/core/TextField';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import cx from 'classnames';
import _ from 'lodash';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import {
  growthFeedbackType,
  growthStagesTypes
} from '../../../_constants/growthsConstants';
import { FarmStructureContext } from '../../../_context/FarmStructureContext';
import {
  addGrowthFeedbackActions,
  deleteGrowthFeedbackActions,
  updateGrowthFeedbackActions
} from '../../../_store/actions/growthsFeedbackActions';
import { useFieldSelectors } from '../../../_store/selectors/fieldsSelectors';
import {
  useAddGrowthFeedbackSelectors,
  useDeleteGrowthFeedbackSelectors,
  useUpdateGrowthFeedbackSelectors
} from '../../../_store/selectors/growthsFeedbackSelectors';
import CreateUpdateDeleteDialogActions from '../../CreateUpdateDeleteDialogActions';
import ResponsiveDialogContent from '../../ResponsiveDialogContent';
import GrowthStageBadge from '../GrowthStageBadge';

const useStyles = makeStyles(() => ({
  affectedStages: {
    marginTop: '0.5rem',
    '& > :not(:first-child)': {
      marginTop: '0.5rem'
    }
  },
  selectedStage: {
    marginTop: '0.75rem !important',
    marginBottom: '0.75rem'
  }
}));

const GrowthFeedbackForm = ({
  availableGrowthStages,
  availableFeedbackTypes,
  selectedFeedback,
  growth,
  onClose,
  growerId,
  seasonId,
  farmId,
  fieldId,
  selectedGrowthStage,
  growthStagesConfig
}) => {
  const styles = useStyles();
  const dispatch = useDispatch();

  const { selectedSeason } = useContext(FarmStructureContext);

  const seasonEndDate = _.get(selectedSeason, 'endDate');

  const defaultType = useMemo(
    () => _.chain(availableFeedbackTypes).first().get('type').value(),
    [availableFeedbackTypes]
  );

  const defaultStage = useMemo(() => {
    const hasStage =
      !!selectedGrowthStage &&
      !!_.find(
        availableGrowthStages,
        (stage) => stage.type === selectedGrowthStage
      );
    return hasStage
      ? selectedGrowthStage
      : _.chain(availableGrowthStages).first().get('type').value();
  }, [availableGrowthStages, selectedGrowthStage]);

  const { register, unregister, handleSubmit, errors, watch, setValue } =
    useForm({
      defaultValues: selectedFeedback
        ? {
            ..._.pick(selectedFeedback, [
              'name',
              'description',
              'stage',
              'type',
              'yieldBushelsPerAcre'
            ]),
            date: moment(selectedFeedback.date)
          }
        : { date: moment(), type: defaultType, stage: defaultStage }
    });

  const addSelectors = useAddGrowthFeedbackSelectors();
  const updateSelectors = useUpdateGrowthFeedbackSelectors();

  const isEdit = !!selectedFeedback;

  const {
    isLoading: submitInProgress,
    success: submitSuccess,
    error: submitError
  } = isEdit ? updateSelectors : addSelectors;

  const {
    isLoading: deleteInProgress,
    success: deleteSuccess,
    error: deleteError
  } = useDeleteGrowthFeedbackSelectors();

  const { field } = useFieldSelectors(growerId, seasonId, farmId, fieldId);
  const fieldName = _.get(field, 'name');

  const inProgress = deleteInProgress || submitInProgress;
  const success = submitSuccess || deleteSuccess;
  const apiError = submitError || deleteError;
  const selectedType = watch('type');
  const showStage = selectedType === growthFeedbackType.GrowthStage.type;

  const plantingDate = _.get(growth, 'plantingDate');

  const orderedFeedback = useMemo(
    () =>
      _.chain(growth)
        .get('growthFeedback')
        .map((feedback) => ({
          ...feedback,
          order: _.get(growthStagesConfig, [feedback.stage, 'order'])
        }))
        .orderBy((feedback) => feedback.order)
        .value(),
    [growth, growthStagesConfig]
  );

  const previousFeedback = useMemo(
    () =>
      _.findLast(
        orderedFeedback,
        (feedback) =>
          feedback.order <
          _.get(growthStagesConfig, [selectedGrowthStage, 'order'])
      ),
    [growthStagesConfig, orderedFeedback, selectedGrowthStage]
  );

  const nextFeedback = useMemo(
    () =>
      _.find(
        orderedFeedback,
        (feedback) =>
          feedback.order >
          _.get(growthStagesConfig, [selectedGrowthStage, 'order'])
      ),
    [growthStagesConfig, orderedFeedback, selectedGrowthStage]
  );

  const affectedGrowthStages = useMemo(() => {
    const orderedGrowthStages = _.chain(growthStagesConfig)
      .values()
      .orderBy('order')
      .value();
    return _.chain(orderedGrowthStages)
      .filter(
        (stage) =>
          _.get(stage, 'order') > _.get(growthStagesConfig, 'Planting.order') &&
          _.get(stage, 'order') <= _.get(growthStagesConfig, 'Harvest.order')
      )
      .filter(
        (stage) =>
          (!previousFeedback ||
            _.get(stage, 'order') > _.get(previousFeedback, 'order')) &&
          (!nextFeedback ||
            _.get(stage, 'order') < _.get(nextFeedback, 'order'))
      )
      .value();
  }, [growthStagesConfig, nextFeedback, previousFeedback]);

  const minDate = useMemo(() => {
    if (isEdit) {
      return moment(_.get(previousFeedback, 'date', plantingDate)).add(
        1,
        'day'
      );
    }
    const maxExistingFeedbackDate = _.chain(orderedFeedback)
      .last()
      .get('date')
      .value();
    if (maxExistingFeedbackDate) {
      return moment(maxExistingFeedbackDate).add(1, 'day');
    }
    return moment(plantingDate).add(1, 'day');
  }, [isEdit, orderedFeedback, plantingDate, previousFeedback]);

  const maxDate = useMemo(() => {
    if (!isEdit) {
      return moment(seasonEndDate).endOf('day');
    }
    const succeedingFeedbackDate = _.get(nextFeedback, 'date');
    if (succeedingFeedbackDate) {
      return moment(succeedingFeedbackDate).subtract(1, 'day').endOf('day');
    }
    return moment(seasonEndDate).endOf('day');
  }, [isEdit, nextFeedback, seasonEndDate]);

  const selectedDate = watch('date');

  useEffect(() => {
    register({ name: 'date' }, { required: 'Date is required' });
    register({ name: 'type' }, { required: 'Type is required' });
  }, [register]);

  useEffect(() => {
    if (showStage) {
      register({ name: 'stage' }, { required: 'Stage is required' });
    } else {
      unregister('stage');
    }
  }, [register, showStage, unregister]);

  useEffect(() => {
    if (maxDate && selectedDate.isAfter(maxDate)) {
      if (minDate && minDate.isAfter()) {
        setValue('date', minDate);
      } else {
        setValue('date', moment());
      }
    }
    if (minDate && selectedDate.isBefore(minDate)) {
      setValue('date', minDate);
    }
  }, [maxDate, minDate, selectedDate, setValue]);

  const dateError = _.get(errors, 'date.message');
  const dateOutOfRange =
    !selectedDate ||
    selectedDate.isBefore(minDate) ||
    selectedDate.isAfter(maxDate);

  const handleClose = useCallback(() => {
    dispatch(addGrowthFeedbackActions.clear());
    dispatch(updateGrowthFeedbackActions.clear());
    dispatch(deleteGrowthFeedbackActions.clear());
    onClose();
  }, [dispatch, onClose]);

  useEffect(() => {
    if (success) {
      handleClose();
    }
  }, [handleClose, success]);

  const handleFormSubmit = useCallback(
    (formData) => {
      const yieldBushelsPerAcre = !_.isNaN(
        _.get(formData, 'yieldBushelsPerAcre')
      )
        ? _.get(formData, 'yieldBushelsPerAcre')
        : undefined;

      const formDateNoon = formData.date
        .clone()
        .set({ hour: 12, minute: 0, second: 0, millisecond: 0 });

      const feedback = {
        ...formData,
        date: formDateNoon.toISOString()
      };

      if (!!yieldBushelsPerAcre) {
        feedback.yieldBushelsPerAcre = yieldBushelsPerAcre;
      }

      if (isEdit) {
        dispatch(
          updateGrowthFeedbackActions.submit({
            growerId,
            seasonId,
            farmId,
            fieldId,
            feedback,
            feedbackId: selectedFeedback.id
          })
        );
      } else {
        dispatch(
          addGrowthFeedbackActions.submit({
            growerId,
            seasonId,
            farmId,
            fieldId,
            feedback
          })
        );
      }
    },
    [isEdit, dispatch, growerId, seasonId, farmId, fieldId, selectedFeedback]
  );

  const handleConfirmDelete = useCallback(() => {
    dispatch(
      deleteGrowthFeedbackActions.submit({
        growerId,
        seasonId,
        farmId,
        fieldId,
        feedbackId: selectedFeedback.id
      })
    );
  }, [dispatch, farmId, fieldId, growerId, seasonId, selectedFeedback]);

  const handleDateChange = useCallback(
    (date) => {
      setValue('date', date);
    },
    [setValue]
  );

  return (
    <form noValidate onSubmit={handleSubmit(handleFormSubmit)}>
      <DialogTitle>
        {fieldName} - {isEdit ? 'Modify' : 'Add'} Growth Feedback
      </DialogTitle>
      <ResponsiveDialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <GrowthStageBadge growthStage={selectedGrowthStage} />
          </Grid>
          <Grid item xs={12}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                autoOk
                disableToolbar
                variant="inline"
                format="L"
                id="date"
                name="date"
                label="Date"
                value={selectedDate}
                onChange={handleDateChange}
                minDate={minDate}
                maxDate={maxDate}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          {selectedGrowthStage === growthStagesTypes.Harvest && (
            <Grid item xs={12}>
              <TextField
                inputRef={register({
                  min: {
                    value: 0.00000001,
                    message: 'Yield must be greater than 0'
                  },
                  valueAsNumber: true
                })}
                inputProps={{ min: 0.00000001 }}
                variant="standard"
                type="number"
                id="yieldBushelsPerAcre"
                label="Yield"
                name="yieldBushelsPerAcre"
                error={!!_.get(errors, 'yieldBushelsPerAcre')}
                helperText={_.get(errors, 'yieldBushelsPerAcre.message')}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">bu/ac</InputAdornment>
                  )
                }}
              />
            </Grid>
          )}
          {!_.isEmpty(affectedGrowthStages) && (
            <Grid item xs={12}>
              <Typography>
                The following estimated growth stages will be affected:
              </Typography>
              <Box className={styles.affectedStages}>
                {_.map(affectedGrowthStages, (stage) => (
                  <GrowthStageBadge
                    className={cx({
                      [styles.selectedStage]:
                        _.get(stage, 'type') === selectedGrowthStage
                    })}
                    growthStage={_.get(stage, 'type')}
                    small={_.get(stage, 'type') !== selectedGrowthStage}
                  />
                ))}
              </Box>
            </Grid>
          )}
          {dateError && (
            <Grid item xs={12}>
              <FormHelperText error>{dateError}</FormHelperText>
            </Grid>
          )}
          <Grid item xs={12}>
            {apiError && <FormHelperText error>{apiError}</FormHelperText>}
          </Grid>
        </Grid>
      </ResponsiveDialogContent>
      <CreateUpdateDeleteDialogActions
        isEdit={isEdit}
        inProgress={inProgress}
        confirmDisabled={!!dateOutOfRange}
        onDelete={handleConfirmDelete}
        onCancel={handleClose}
      />
    </form>
  );
};

export default GrowthFeedbackForm;
