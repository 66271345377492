import React, { useMemo } from 'react';

import moment from 'moment';

import { momentFormat } from '../Growths/GrowthTimeline/_utils/growthTimelineUtils';

import ChartDateLine from './ChartDateLine';

const ChartTodayLine = ({
  paddingTop,
  height,
  selectedTimeScale,
  lineWidth,
  roundToDay = false
}) => {
  const today = useMemo(
    () => (roundToDay ? moment().format(momentFormat) : moment().toISOString()),
    [roundToDay]
  );

  return (
    <ChartDateLine
      paddingTop={paddingTop}
      height={height}
      selectedTimeScale={selectedTimeScale}
      lineWidth={lineWidth}
      date={today}
    />
  );
};

export default ChartTodayLine;
