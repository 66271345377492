import React, { useCallback, useEffect, useMemo } from 'react';

import MomentUtils from '@date-io/moment';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import _ from 'lodash';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import {
  addMoistureSampleActions,
  deleteMoistureSampleActions,
  updateMoistureSampleActions
} from '../../../_store/actions/moistureSamplesActions';
import {
  useAddMoistureSampleSelectors,
  useDeleteMoistureSampleSelectors,
  useUpdateMoistureSampleSelectors
} from '../../../_store/selectors/moistureSamplesSelectors';
import CreateUpdateDeleteDialogActions from '../../CreateUpdateDeleteDialogActions';
import ResponsiveDialogContent from '../../ResponsiveDialogContent';

const MoistureSampleForm = ({
  minDate,
  moistureSample,
  moistureSamples,
  onClose,
  growerId,
  seasonId,
  farmId,
  fieldId,
  growthId
}) => {
  const dispatch = useDispatch();

  const { register, handleSubmit, errors, setValue, watch } = useForm({
    defaultValues: moistureSample
      ? {
          grainMoistureContent: _.get(moistureSample, 'grainMoistureContent')
        }
      : {}
  });

  const isEdit = !!moistureSample;
  const sampleId = _.get(moistureSample, 'id');

  const addSelectors = useAddMoistureSampleSelectors();
  const updateSelectors = useUpdateMoistureSampleSelectors();

  const {
    inProgress: saveInProgress,
    success: saveSuccess,
    error: saveError
  } = isEdit ? updateSelectors : addSelectors;

  const {
    inProgress: deleteInProgress,
    success: deleteSuccess,
    error: deleteError
  } = useDeleteMoistureSampleSelectors();

  const inProgress = saveInProgress || deleteInProgress;
  const success = saveSuccess || deleteSuccess;
  const apiError = saveError || deleteError;

  const selectedDate = watch('date');
  const dateError = _.get(errors, 'date.message');

  const minDateMoment = useMemo(
    () => (!!minDate ? moment(minDate) : undefined),
    [minDate]
  );

  const handleClose = useCallback(() => {
    dispatch(addMoistureSampleActions.clear());
    dispatch(updateMoistureSampleActions.clear());
    dispatch(deleteMoistureSampleActions.clear());
    onClose();
  }, [dispatch, onClose]);

  useEffect(() => {
    register({ name: 'date' });
    const sampleDate = moment(_.get(moistureSample, 'date'));
    if (!!sampleDate) {
      setValue('date', sampleDate);
    } else {
      setValue('date', moment());
    }
  }, [moistureSample, register, setValue]);

  useEffect(() => {
    if (success) {
      handleClose();
    }
  }, [handleClose, success]);

  const otherSamplesDates = useMemo(
    () =>
      _.chain(moistureSamples)
        .filter((sample) => _.get(sample, 'id') !== _.get(moistureSample, 'id'))
        .map((sample) => moment(_.get(sample, 'date')))
        .value(),
    [moistureSample, moistureSamples]
  );

  const handleFormSubmit = useCallback(
    (formData) => {
      const moistureSample = {
        ...formData,
        date: formData.date
          .set({ hour: 12, minute: 0, second: 0, millisecond: 0 })
          .toISOString()
      };

      if (isEdit) {
        dispatch(
          updateMoistureSampleActions.request(
            growerId,
            seasonId,
            farmId,
            fieldId,
            growthId,
            sampleId,
            moistureSample
          )
        );
      } else {
        dispatch(
          addMoistureSampleActions.request(
            growerId,
            seasonId,
            farmId,
            fieldId,
            growthId,
            moistureSample
          )
        );
      }
    },
    [dispatch, farmId, fieldId, growerId, growthId, isEdit, sampleId, seasonId]
  );

  const handleDateChange = useCallback(
    (date) => {
      setValue('date', date);
    },
    [setValue]
  );

  const handleConfirmDelete = useCallback(() => {
    dispatch(
      deleteMoistureSampleActions.request(
        growerId,
        seasonId,
        farmId,
        fieldId,
        growthId,
        sampleId
      )
    );
  }, [dispatch, farmId, fieldId, growerId, growthId, sampleId, seasonId]);

  const handleShouldDisableDate = useCallback(
    (date) => {
      return !!_.find(otherSamplesDates, (sampleDate) =>
        date.isSame(sampleDate, 'day')
      );
    },
    [otherSamplesDates]
  );

  return (
    <form noValidate onSubmit={handleSubmit(handleFormSubmit)}>
      <DialogTitle>{`${
        isEdit ? 'Modify' : 'Add'
      } Moisture Sample`}</DialogTitle>
      <ResponsiveDialogContent>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <DatePicker
                format={'L'}
                id="date"
                name="date"
                label="Date"
                value={selectedDate}
                onChange={handleDateChange}
                showTodayButton
                shouldDisableDate={handleShouldDisableDate}
                disableFuture
                minDate={minDateMoment}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          {dateError && (
            <Grid item xs={12}>
              <FormHelperText error>{dateError}</FormHelperText>
            </Grid>
          )}
          <Grid item xs={12}>
            <TextField
              inputRef={register({
                min: {
                  value: 0.00000001,
                  message: 'Grain Moisture Content must be greater than 0'
                },
                max: {
                  value: 100,
                  message: 'Grain Moisture Content must be less than 100'
                },
                valueAsNumber: true,
                required: 'Grain Moisture Content is required'
              })}
              inputProps={{ min: 0, max: 100 }}
              required
              fullWidth
              variant="standard"
              type="number"
              id="grainMoistureContent"
              label="Grain Moisture Content"
              name="grainMoistureContent"
              error={!!_.get(errors, 'grainMoistureContent')}
              helperText={_.get(errors, 'grainMoistureContent.message')}
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>
              }}
            />
          </Grid>
          <Grid item xs={12}>
            {apiError && <FormHelperText error>{apiError}</FormHelperText>}
          </Grid>
        </Grid>
      </ResponsiveDialogContent>
      <CreateUpdateDeleteDialogActions
        isEdit={isEdit}
        inProgress={inProgress}
        onDelete={handleConfirmDelete}
        onCancel={handleClose}
      />
    </form>
  );
};

export default MoistureSampleForm;
