import { useMemo } from 'react';

import { statisticsService } from '../../../services/statistics.service';
import { createApiSlice } from '../utils/createApiSlice';

const getFieldsStatisticsSlice = createApiSlice({
  reducerPath: ['statistics', 'getFieldsStatistics'],
  apiFunction: statisticsService.getFieldsStatistics,
  apiArgumentsFn: () => []
});

const { actions, useApiSelectors } = getFieldsStatisticsSlice;

export const getFieldsStatisticsActions = actions;

export const useGetFieldsStatisticsSelectors = () => {
  const { inProgress, errorMessage, success, response } = useApiSelectors();

  return useMemo(
    () => ({
      inProgress,
      errorMessage,
      success,
      response
    }),
    [errorMessage, inProgress, response, success]
  );
};

export default getFieldsStatisticsSlice;
