import { createTheme } from '@material-ui/core/styles';

export const theme = createTheme({
  dimensions: {
    headerHeight: '3.75rem',
    gffDrawerWidth: '19.75rem',
    menuDrawerWidth: '17.5rem',
    collapsedMenuDrawerWidth: '5rem'
  },
  palette: {
    brand: {
      primary: {
        main: '#095540',
        light: '#6ba43a',
        lighter: '#92d266'
      },
      secondary: {
        main: '#562e00'
      }
    }
  }
});
