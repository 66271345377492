import React from 'react';

import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import _ from 'lodash';

const useRowStyles = makeStyles(() => ({
  dataRow: (props) => ({
    fontSize: '0.88rem',
    borderBottom: '1px solid rgba(0, 0, 0, 0.125)',
    paddingBottom: '0.25rem',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0.5rem',
    flexDirection: props.inline ? 'row' : 'column'
  }),
  rowTitle: {
    fontSize: '0.88rem',
    fontWeight: 'bold',
    marginRight: '1rem'
  },
  rowValue: {
    fontSize: '0.88rem',
    textAlign: 'right'
  }
}));

export function AccordionDataRow({ title, text, inline = false, children }) {
  const styles = useRowStyles({ inline });

  const hasText = !_.isUndefined(text) && !_.isNull(text);

  return (
    <Box className={styles.dataRow}>
      <Box>
        <Typography className={styles.rowTitle}>{title}</Typography>
      </Box>
      <Box>
        {hasText && <Typography className={styles.rowValue}>{text}</Typography>}
        {!hasText && children}
      </Box>
    </Box>
  );
}
