import React from 'react';

import Drawer from '@material-ui/core/Drawer';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useLocation } from 'react-router-dom';

import { useGrowersDrawerState } from '../../_hooks/ui/useGrowersDrawerState';

import DrawerSeasonMenu from './DrawerSeasonMenu';
import GrowersDrawerContent from './GrowersDrawerContent';
import ReportsDrawerContent from './ReportsDrawerContent';

const useStyles = makeStyles((theme) => ({
  drawer: {},
  drawerPaper: {
    width: theme.dimensions.gffDrawerWidth,
    paddingTop: theme.dimensions.headerHeight,
    backgroundColor: 'rgb(46, 64, 87)'
  },
  scrollbar: {
    '& > .ps__rail-y > .ps__thumb-y': {
      backgroundColor: 'rgb(21, 28, 43, 1)'
    }
  }
}));

const GrowersDrawer = () => {
  const styles = useStyles();

  const pathname = useLocation().pathname;

  const { growersDrawerOpen } = useGrowersDrawerState();

  const isReportsPath = pathname.startsWith('/reports');

  return (
    <Drawer
      open={growersDrawerOpen}
      anchor="right"
      className={styles.drawer}
      classes={{ paper: styles.drawerPaper }}
      variant="persistent"
      PaperProps={{ elevation: 10 }}
    >
      <DrawerSeasonMenu />
      {!isReportsPath && <GrowersDrawerContent />}
      {isReportsPath && <ReportsDrawerContent />}
    </Drawer>
  );
};

export default GrowersDrawer;
