import { useMemo } from 'react';

import { reportsService } from '../../../../services/reports.service';
import { createApiSlice } from '../../utils/createApiSlice';

const deleteSavedFilterSlice = createApiSlice({
  reducerPath: ['reports', 'savedFilters', 'deleteSavedFilter'],
  apiFunction: reportsService.deleteSavedFilter,
  apiArgumentsFn: ({ seasonYear, filterId }) => [seasonYear, filterId]
});

const { actions, useApiSelectors } = deleteSavedFilterSlice;

export const deleteSavedFilterActions = actions;

export const useDeleteSavedFilterSelectors = () => {
  const { inProgress, errorMessage, success, response } = useApiSelectors();

  return useMemo(
    () => ({
      inProgress,
      errorMessage,
      success,
      response
    }),
    [errorMessage, inProgress, response, success]
  );
};

export default deleteSavedFilterSlice;
