import { useMemo } from 'react';

import _ from 'lodash';

import { growthModelApiService } from '../../../services/growthModelApi.service';
import { createApiSlice } from '../utils/createApiSlice';

const getGrowthModelApiGrowthFeedbackSliceSlice = createApiSlice({
  reducerPath: ['growthModelApiAdmin', 'getGrowthModelApiGrowthFeedbackSlice'],
  apiFunction: growthModelApiService.getGrowthFeedback,
  getStateUpdatePath: (action) => {
    const fieldId = _.get(action, 'payload.apiArguments.0');
    const growthId = _.get(action, 'payload.apiArguments.1');
    return [fieldId, growthId];
  },
  getSelectorPath: (fieldId, growthId) => {
    return [fieldId, growthId];
  },
  apiArgumentsFn: ({ fieldId, growthId }) => [fieldId, growthId]
});

const { actions, useApiSelectors } = getGrowthModelApiGrowthFeedbackSliceSlice;

export const getGrowthModelApiGrowthFeedbackSliceActions = actions;

export const useGetGrowthModelApiGrowthFeedbackSliceSelectors = (
  fieldId,
  growthId
) => {
  const { inProgress, errorMessage, success, updatedAt, response } =
    useApiSelectors(fieldId, growthId);

  return useMemo(
    () => ({
      inProgress,
      errorMessage,
      success,
      updatedAt,
      response
    }),
    [errorMessage, inProgress, response, success, updatedAt]
  );
};

export default getGrowthModelApiGrowthFeedbackSliceSlice;
