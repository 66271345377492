import React from 'react';

import ConfigurationDialogs from '../../Components/Configuration/Dialogs/ConfigurationDialogs';
import FieldAccessibilityDialogs from '../../Components/FieldAccessibility/Dialogs/FieldAccessibilityDialogs';
import GrowthDialogs from '../../Components/Growths/Dialogs/GrowthDialogs';
import HarvestAdvisorDialogs from '../../Components/HarvestAdvisor/Dialogs/HarvestAdvisorDialogs';

const Dialogs = () => {
  return (
    <>
      <GrowthDialogs />
      <FieldAccessibilityDialogs />
      <HarvestAdvisorDialogs />
      <ConfigurationDialogs />
    </>
  );
};

export default Dialogs;
