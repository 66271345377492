import React, { useCallback, useEffect, useMemo } from 'react';

import MomentUtils from '@date-io/moment';
import { Box } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Alert from '@material-ui/lab/Alert';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import _ from 'lodash';
import moment from 'moment';
import { useDispatch } from 'react-redux';

import {
  getSnapshotsDatesActions,
  useGetSnapshotsDatesSelectors
} from '../../_store/slices/growths/snapshots/getSnapshotsDatesSlice';
import LoadingIndicator from '../LoadingIndicator';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    justifyContent: 'center'
  },
  select: {
    marginLeft: '0.5rem',
    marginRight: '0.5rem'
  }
}));

const GrowthModelSnapshotsDatesSelector = ({
  growerId,
  seasonId,
  farmId,
  fieldId,
  growthId,
  firstSnapshot,
  secondSnapshot,
  activeGrowthInProgress,
  onFirstSnapshotChange,
  onSecondSnapshotChange
}) => {
  const styles = useStyles();
  const dispatch = useDispatch();

  const {
    snapshotsDates,
    inProgress: datesInProgress,
    errorMessage: datesErrorMessage
  } = useGetSnapshotsDatesSelectors(growthId);

  const inProgress = datesInProgress || activeGrowthInProgress;

  const availableSnapshots = useMemo(
    () =>
      _.chain(snapshotsDates)
        .filter((item) => !!_.get(item, 'dateUpdated'))
        .map((item) => ({
          snapshotId: _.get(item, 'snapshotId'),
          name: moment(_.get(item, 'dateUpdated')).format('ll'),
          date: _.get(item, 'dateUpdated'),
          day: moment(_.get(item, 'dateUpdated')).format('yyyy-MM-DD')
        }))
        .groupBy((item) => _.get(item, 'day'))
        .values()
        .map((values) => _.chain(values).orderBy('date').last().value())
        .value(),
    [snapshotsDates]
  );

  const hasSingleSnapshot = availableSnapshots.length === 1;

  const firstSnapshotId = _.get(firstSnapshot, 'snapshotId');
  const secondSnapshotId = _.get(secondSnapshot, 'snapshotId');
  const firstSnapshotDayMoment = useMemo(
    () =>
      _.get(firstSnapshot, 'day')
        ? moment(_.get(firstSnapshot, 'day'))
        : undefined,
    [firstSnapshot]
  );

  const secondSnapshotDayMoment = useMemo(
    () =>
      _.get(secondSnapshot, 'day')
        ? moment(_.get(secondSnapshot, 'day'))
        : undefined,
    [secondSnapshot]
  );

  const firstAvailableSnapshots = useMemo(
    () =>
      _.filter(
        availableSnapshots,
        (date) => _.get(date, 'snapshotId') !== secondSnapshotId
      ),
    [availableSnapshots, secondSnapshotId]
  );

  const secondAvailableSnapshots = useMemo(
    () =>
      _.filter(
        availableSnapshots,
        (date) => _.get(date, 'snapshotId') !== firstSnapshotId
      ),
    [availableSnapshots, firstSnapshotId]
  );

  const firstAvailableSnapshotsByDay = useMemo(
    () => _.keyBy(firstAvailableSnapshots, 'day'),
    [firstAvailableSnapshots]
  );

  const secondAvailableSnapshotsByDay = useMemo(
    () => _.keyBy(secondAvailableSnapshots, 'day'),
    [secondAvailableSnapshots]
  );

  useEffect(() => {
    if (_.isEmpty(availableSnapshots)) {
      onFirstSnapshotChange(undefined);
      onSecondSnapshotChange(undefined);
    }
    if (
      !firstSnapshot ||
      !_.find(
        availableSnapshots,
        (date) => _.get(date, 'snapshotId') === firstSnapshotId
      )
    ) {
      onFirstSnapshotChange(_.first(availableSnapshots));
    }
    if (hasSingleSnapshot) {
      return;
    }
    if (
      !secondSnapshot ||
      !_.find(
        availableSnapshots,
        (date) => _.get(date, 'snapshotId') === secondSnapshotId
      )
    ) {
      onSecondSnapshotChange(_.last(availableSnapshots));
    }
  }, [
    availableSnapshots,
    firstSnapshot,
    firstSnapshotId,
    hasSingleSnapshot,
    onFirstSnapshotChange,
    onSecondSnapshotChange,
    secondSnapshot,
    secondSnapshotId
  ]);

  useEffect(() => {
    if (growerId && seasonId && farmId && fieldId && growthId) {
      dispatch(
        getSnapshotsDatesActions.submit({
          growerId,
          seasonId,
          farmId,
          fieldId,
          growthId
        })
      );
    }
  }, [dispatch, farmId, fieldId, growerId, growthId, seasonId]);

  const handleFirstDateChange = useCallback(
    (date) => {
      const snapshot = _.get(firstAvailableSnapshotsByDay, [
        date.format('yyyy-MM-DD')
      ]);
      if (snapshot) {
        onFirstSnapshotChange(snapshot);
      }
    },
    [firstAvailableSnapshotsByDay, onFirstSnapshotChange]
  );

  const handleSecondDateChange = useCallback(
    (date) => {
      const snapshot = _.get(secondAvailableSnapshotsByDay, [
        date.format('yyyy-MM-DD')
      ]);
      if (snapshot) {
        onSecondSnapshotChange(snapshot);
      }
    },
    [secondAvailableSnapshotsByDay, onSecondSnapshotChange]
  );

  const handleShouldDisableFirstDate = useCallback(
    (day) => {
      return !_.has(firstAvailableSnapshotsByDay, day.format('yyyy-MM-DD'));
    },
    [firstAvailableSnapshotsByDay]
  );

  const handleShouldDisableSecondDate = useCallback(
    (day) => {
      return !_.has(secondAvailableSnapshotsByDay, day.format('yyyy-MM-DD'));
    },
    [secondAvailableSnapshotsByDay]
  );

  return (
    <Box className={styles.container}>
      {!fieldId && <Alert severity="info">Please select a Field</Alert>}
      {inProgress && <LoadingIndicator />}
      {!inProgress && datesErrorMessage && (
        <Alert severity="error">{datesErrorMessage}</Alert>
      )}
      {!activeGrowthInProgress && fieldId && !growthId && (
        <Alert severity="info">
          There are no growths configured for this field.{' '}
        </Alert>
      )}

      {fieldId && growthId && !inProgress && !datesErrorMessage && (
        <>
          {!_.isEmpty(availableSnapshots) && (
            <>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <DatePicker
                  className={styles.select}
                  disableToolbar
                  variant="inline"
                  format="L"
                  id="firstSnapshot"
                  name="firstSnapshot"
                  label="First Snapshot"
                  shouldDisableDate={handleShouldDisableFirstDate}
                  value={firstSnapshotDayMoment}
                  onChange={handleFirstDateChange}
                  autoOk
                />
              </MuiPickersUtilsProvider>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <DatePicker
                  className={styles.select}
                  disableToolbar
                  variant="inline"
                  format="L"
                  id="secondSnapshot"
                  name="secondSnapshot"
                  label="Second Snapshot"
                  shouldDisableDate={handleShouldDisableSecondDate}
                  value={secondSnapshotDayMoment}
                  onChange={handleSecondDateChange}
                  autoOk
                  disabled={hasSingleSnapshot}
                />
              </MuiPickersUtilsProvider>
            </>
          )}
          {_.isEmpty(availableSnapshots) && (
            <Alert severity="warning">There are no available dates</Alert>
          )}
        </>
      )}
    </Box>
  );
};

export default GrowthModelSnapshotsDatesSelector;
