import { buildApiActionTypes } from './apiActionTypes';

export const addGrowthFeedbackActionTypes = buildApiActionTypes(
  'ADD_GROWTH_FEEDBACK'
);

export const updateGrowthFeedbackActionTypes = buildApiActionTypes(
  'UPDATE_GROWTH_FEEDBACK'
);

export const deleteGrowthFeedbackActionTypes = buildApiActionTypes(
  'DELETE_GROWTH_FEEDBACK'
);
