import _ from 'lodash';
import { all, fork, put, select, takeLatest } from 'redux-saga/effects';

import { forceUpdateActiveGrowthAction } from '../actions/growthsActions';
import {
  addGrowthFeedbackActions,
  deleteGrowthFeedbackActions,
  updateGrowthFeedbackActions
} from '../actions/growthsFeedbackActions';
import {
  addGrowthFeedbackActionTypes,
  deleteGrowthFeedbackActionTypes,
  updateGrowthFeedbackActionTypes
} from '../actionTypes/growthsFeedbackActionTypes';
import { activeGrowthIdSelector } from '../selectors/growthsSelectors';
import { getSeasonWithGrowthsActions } from '../slices/gff/seasons/getSeasonsWithGrowthsSlice';

function* handleAddGrowthFeedback(action) {
  const { growerId, seasonId, farmId, fieldId, feedback } = action.payload;
  const growthId = yield select(activeGrowthIdSelector(seasonId, fieldId));

  yield put(
    addGrowthFeedbackActions.request(
      growerId,
      seasonId,
      farmId,
      fieldId,
      growthId,
      feedback
    )
  );
}

function* handleUpdateGrowthFeedback(action) {
  const { growerId, seasonId, farmId, fieldId, feedbackId, feedback } =
    action.payload;
  const growthId = yield select(activeGrowthIdSelector(seasonId, fieldId));

  yield put(
    updateGrowthFeedbackActions.request(
      growerId,
      seasonId,
      farmId,
      fieldId,
      growthId,
      feedbackId,
      feedback
    )
  );
}

function* handleDeleteGrowthFeedback(action) {
  const { growerId, seasonId, farmId, fieldId, feedbackId } = action.payload;
  const growthId = yield select(activeGrowthIdSelector(seasonId, fieldId));

  yield put(
    deleteGrowthFeedbackActions.request(
      growerId,
      seasonId,
      farmId,
      fieldId,
      growthId,
      feedbackId
    )
  );
}

function* updateActiveGrowth(action) {
  const growerId = _.get(action, 'payload.apiArguments.0');
  const seasonId = _.get(action, 'payload.apiArguments.1');
  const farmId = _.get(action, 'payload.apiArguments.2');
  const fieldId = _.get(action, 'payload.apiArguments.3');
  yield put(forceUpdateActiveGrowthAction(growerId, seasonId, farmId, fieldId));
  yield put(getSeasonWithGrowthsActions.forceUpdate({ growerId, seasonId }));
}

function* watchAddGrowthFeedback() {
  yield takeLatest(
    addGrowthFeedbackActionTypes.SUBMIT,
    handleAddGrowthFeedback
  );
}

function* watchUpdateGrowthFeedback() {
  yield takeLatest(
    updateGrowthFeedbackActionTypes.SUBMIT,
    handleUpdateGrowthFeedback
  );
}

function* watchDeleteGrowthFeedback() {
  yield takeLatest(
    deleteGrowthFeedbackActionTypes.SUBMIT,
    handleDeleteGrowthFeedback
  );
}

function* watchGrowthFeedbackAdded() {
  yield takeLatest(addGrowthFeedbackActionTypes.SUCCEEDED, updateActiveGrowth);
}

function* watchGrowthFeedbackUpdated() {
  yield takeLatest(
    updateGrowthFeedbackActionTypes.SUCCEEDED,
    updateActiveGrowth
  );
}

function* watchGrowthFeedbackDeleted() {
  yield takeLatest(
    deleteGrowthFeedbackActionTypes.SUCCEEDED,
    updateActiveGrowth
  );
}

function* farmStructureSaga() {
  yield all([fork(watchAddGrowthFeedback)]);
  yield all([fork(watchUpdateGrowthFeedback)]);
  yield all([fork(watchDeleteGrowthFeedback)]);
  yield all([fork(watchGrowthFeedbackAdded)]);
  yield all([fork(watchGrowthFeedbackUpdated)]);
  yield all([fork(watchGrowthFeedbackDeleted)]);
}

export default farmStructureSaga;
