import { useCallback, useEffect, useMemo } from 'react';

import jwtDecode from 'jwt-decode';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { useAuthSelectors } from '../../_store/slices/user/authSlice';
import { clearUserDataActions } from '../../_store/slices/user/clearUserDataSlice';
import { tokenRefreshActions } from '../../_store/slices/user/tokenRefreshSlice';

const TOKEN_VERIFICATION_PERIOD_FACTOR = 0.1;

export function useSilentTokenRenewal(token) {
  const history = useHistory();
  const dispatch = useDispatch();

  const { refreshToken } = useAuthSelectors();

  const decodedToken = useMemo(() => {
    if (token) {
      return jwtDecode(token);
    }
  }, [token]);

  const tokenIssuedAtTime = useMemo(() => {
    if (decodedToken) {
      return moment.unix(decodedToken.iat);
    }
  }, [decodedToken]);

  const tokenExpirationTime = useMemo(() => {
    if (decodedToken) {
      return moment.unix(decodedToken.exp);
    }
  }, [decodedToken]);

  const tokenValiditySeconds = useMemo(() => {
    if (tokenIssuedAtTime && tokenExpirationTime) {
      return tokenExpirationTime.diff(tokenIssuedAtTime, 'seconds');
    }
  }, [tokenExpirationTime, tokenIssuedAtTime]);

  const tokenVerificationPeriodSeconds = useMemo(() => {
    if (tokenValiditySeconds) {
      return tokenValiditySeconds * TOKEN_VERIFICATION_PERIOD_FACTOR;
    }
  }, [tokenValiditySeconds]);

  const verifyTokenExpiration = useCallback(() => {
    if (!tokenVerificationPeriodSeconds || !tokenExpirationTime) {
      return;
    }

    if (
      moment()
        .add(tokenVerificationPeriodSeconds * 2, 'seconds')
        .isAfter(tokenExpirationTime)
    ) {
      if (refreshToken) {
        dispatch(tokenRefreshActions.submit({ refreshToken }));
      } else {
        dispatch(clearUserDataActions.clear());
        history.push('/');
      }
    }
  }, [
    dispatch,
    history,
    refreshToken,
    tokenExpirationTime,
    tokenVerificationPeriodSeconds
  ]);

  useEffect(() => {
    verifyTokenExpiration();
    if (tokenVerificationPeriodSeconds) {
      const interval = setInterval(() => {
        verifyTokenExpiration();
      }, tokenVerificationPeriodSeconds * 1000);

      return () => clearInterval(interval);
    }
  }, [tokenVerificationPeriodSeconds, verifyTokenExpiration]);
}
