import _ from 'lodash';

import { getErrorMessage } from '../../services/errors';
import { saveGrowthStageConfigRequestActionTypes } from '../actionTypes/configActionTypes';
import { requestStatus } from '../helpers/requestStatus';
import { authActions } from '../slices/user/authSlice';

export const configReducer = (state = {}, action) => {
  switch (action.type) {
    case authActions.success.type:
      return {
        ...state,
        ...action.payload.response.config
      };
    case saveGrowthStageConfigRequestActionTypes.REQUEST:
      return {
        ...state,
        saveStageConfig: {
          status: requestStatus.IN_PROGRESS
        }
      };
    case saveGrowthStageConfigRequestActionTypes.SUCCEEDED:
      return {
        ...state,
        saveStageConfig: {
          status: requestStatus.SUCCESS
        },
        growthModel: {
          ..._.get(state, 'growthModel'),
          ...action.payload.response
        }
      };
    case saveGrowthStageConfigRequestActionTypes.FAILED:
      return {
        ...state,
        saveStageConfig: {
          status: requestStatus.FAILURE,
          error: {
            message: getErrorMessage(action.payload.apiError),
            status: _.get(action, 'payload.apiError.response.status')
          }
        }
      };
    case saveGrowthStageConfigRequestActionTypes.CLEAR:
      return _.omit(state, 'saveStageConfig');
    default:
      return state;
  }
};
