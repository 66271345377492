import { growthService } from '../../services';
import {
  addGrowthFeedbackActionTypes,
  updateGrowthFeedbackActionTypes,
  deleteGrowthFeedbackActionTypes
} from '../actionTypes/growthsFeedbackActionTypes';

import { buildApiActions } from './apiActions';

export const addGrowthFeedbackActions = buildApiActions(
  addGrowthFeedbackActionTypes,
  growthService.addGrowthFeedbackV2
);

export const updateGrowthFeedbackActions = buildApiActions(
  updateGrowthFeedbackActionTypes,
  growthService.updateGrowthFeedbackV2
);

export const deleteGrowthFeedbackActions = buildApiActions(
  deleteGrowthFeedbackActionTypes,
  growthService.deleteGrowthFeedbackV2
);
