import { useMemo } from 'react';

import _ from 'lodash';
import { useSelector } from 'react-redux';

import { requestStatus } from '../helpers/requestStatus';

export const fieldWeatherStatusSelector = (seasonId, fieldId) => (state) =>
  _.get(state, ['weather', 'fieldsWeather', seasonId, fieldId, 'status']);

export const fieldWeatherUpdatedAtSelector = (seasonId, fieldId) => (state) =>
  _.get(state, ['weather', 'fieldsWeather', seasonId, fieldId, 'updatedAt']);

export const fieldWeatherErrorStatusSelector = (seasonId, fieldId) => (state) =>
  _.get(state, [
    'weather',
    'fieldsWeather',
    seasonId,
    fieldId,
    'error',
    'status'
  ]);

export const locationWeatherStatusSelector = (seasonYear) => (state) =>
  _.get(state, ['weather', 'locationWeather', seasonYear, 'status']);

export const locationWeatherUpdatedAtSelector = (seasonYear) => (state) =>
  _.get(state, ['weather', 'locationWeather', seasonYear, 'updatedAt']);

export const locationWeatherErrorStatusSelector = (seasonYear) => (state) =>
  _.get(state, ['weather', 'locationWeather', seasonYear, 'error', 'status']);

export const locationWeathersCoordinatesSelector = (seasonYear) => (state) =>
  _.get(state, ['weather', 'locationWeather', seasonYear, 'coordinates']);

export const useFieldWeatherSelectors = (seasonId, fieldId) => {
  const status = useSelector(fieldWeatherStatusSelector(seasonId, fieldId));
  const updatedAt = useSelector(
    fieldWeatherUpdatedAtSelector(seasonId, fieldId)
  );
  const inProgress = status === requestStatus.IN_PROGRESS;
  const success = status === requestStatus.SUCCESS;

  const weather = useSelector((state) =>
    _.get(state, ['weather', 'fieldsWeather', seasonId, fieldId, 'response'])
  );

  const error = useSelector((state) =>
    _.get(state, ['weather', 'fieldsWeather', seasonId, fieldId, 'error'])
  );
  const errorMessage = _.get(error, 'message');

  return useMemo(
    () => ({
      inProgress,
      success,
      updatedAt,
      errorMessage,
      weather
    }),
    [inProgress, success, updatedAt, errorMessage, weather]
  );
};

export const useLocationWeatherSelectors = (seasonYear) => {
  const status = useSelector(locationWeatherStatusSelector(seasonYear));
  const updatedAt = useSelector(locationWeatherUpdatedAtSelector(seasonYear));
  const inProgress = status === requestStatus.IN_PROGRESS;
  const success = status === requestStatus.SUCCESS;

  const weather = useSelector((state) =>
    _.get(state, ['weather', 'locationWeather', seasonYear, 'response'])
  );

  const error = useSelector((state) =>
    _.get(state, ['weather', 'locationWeather', seasonYear, 'error'])
  );
  const errorMessage = _.get(error, 'message');

  return useMemo(
    () => ({
      inProgress,
      success,
      updatedAt,
      errorMessage,
      weather
    }),
    [inProgress, success, updatedAt, errorMessage, weather]
  );
};
