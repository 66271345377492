import React from 'react';

import { Tooltip } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { useCropTechnology } from '../../../_hooks/useCropTechnology';

const useStyles = makeStyles(() => ({
  technologyCircle: (props) => ({
    marginRight: '0.5rem',
    backgroundColor: props.color,
    width: '1rem',
    height: '1rem',
    minWidth: '1rem',
    borderRadius: '50%'
  }),
  tooltip: (props) => ({
    backgroundColor: props.color,
    color: props.textColor,
    fontSize: '0.75rem'
  }),
  tooltipArrow: (props) => ({
    color: props.color
  })
}));

const TechnologyCircle = ({ varietyId }) => {
  const { technologyName, technologyColor, technologyTextColor } =
    useCropTechnology(varietyId);

  const styles = useStyles({
    color: technologyColor,
    textColor: technologyTextColor
  });

  return (
    <>
      {varietyId && (
        <Tooltip
          classes={{ tooltip: styles.tooltip, arrow: styles.tooltipArrow }}
          title={technologyName}
          arrow
        >
          <Box className={styles.technologyCircle} />
        </Tooltip>
      )}
    </>
  );
};

export default TechnologyCircle;
