import { useMemo } from 'react';

import { useSelector } from 'react-redux';

import { createDataSlice } from '../utils/createDataSlice';

const reportsPageSizeSlice = createDataSlice({
  reducerPath: ['reports', 'reportsPageSize'],
  initialState: { pageSize: 10 },
  reducers: {
    setPageSize(state, action) {
      state.pageSize = action.payload;
    }
  }
});

const { actions, baseSelector: reportsPageSizeSelector } = reportsPageSizeSlice;

export const reportsPageSizeActions = actions;

export const useReportsPageSizeSelectors = () => {
  const { pageSize } = useSelector(reportsPageSizeSelector);

  return useMemo(
    () => ({
      pageSize
    }),
    [pageSize]
  );
};

export default reportsPageSizeSlice;
