import { useMemo } from 'react';

import { reportsService } from '../../../services/reports.service';
import { createApiSlice } from '../utils/createApiSlice';

const getGrowthModelReportSlice = createApiSlice({
  reducerPath: ['reports', 'getGrowthModelReport'],
  apiFunction: reportsService.getGrowthModelReport,
  apiArgumentsFn: ({ fields }) => [fields]
});

const { actions, useApiSelectors } = getGrowthModelReportSlice;

export const getGrowthModelReportActions = actions;

export const useGetGrowthModelReportSelectors = () => {
  const { inProgress, errorMessage, success, response } = useApiSelectors();

  return useMemo(
    () => ({
      inProgress,
      errorMessage,
      success,
      response
    }),
    [errorMessage, inProgress, response, success]
  );
};

export default getGrowthModelReportSlice;
