import React, { useCallback, useEffect } from 'react';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ListIcon from '@material-ui/icons/List';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import _ from 'lodash';
import { useDispatch } from 'react-redux';

import { useGdd } from '../../_hooks/Growths/useGdd';
import { useGrowthStages } from '../../_hooks/Growths/useGrowthStages';
import { selectGrowthModelTab } from '../../_store/actions/uiActions';
import { useGrowthModelDisplaySelectors } from '../../_store/selectors/uiSelectors';

import GrowthModelGddChart from './GrowthModelGddChart';
import ResponsiveGrowthModelList from './ResponsiveGrowthModelList';

const tabIds = {
  GROWTH_MODEL_LIST: 0,
  GROWTH_MODEL_CHART: 1
};

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  createGrowthButton: {
    marginTop: '1rem'
  },
  tabs: {
    marginBottom: '1rem'
  }
}));

const GrowthDetails = ({ growth }) => {
  const dispatch = useDispatch();
  const styles = useStyles();

  const enableCharts = useMediaQuery((theme) => theme.breakpoints.up('sm'));

  const growerId = _.get(growth, 'growerId');
  const seasonId = _.get(growth, 'seasonId');
  const farmId = _.get(growth, 'farmId');
  const fieldId = _.get(growth, 'fieldId');

  const { tabId } = useGrowthModelDisplaySelectors();

  const { growthStages } = useGrowthStages(growth);
  const { gdd: growthModelGdd } = useGdd(growth);

  useEffect(() => {
    if (!enableCharts) {
      dispatch(selectGrowthModelTab(tabIds.GROWTH_MODEL_LIST));
    }
  }, [dispatch, enableCharts]);

  const handleTabChange = useCallback(
    (event, tabId) => {
      dispatch(selectGrowthModelTab(tabId));
    },
    [dispatch]
  );

  return (
    <>
      {growth && (
        <>
          <Typography variant="h6" component="h6">
            Growth Model
          </Typography>
          {enableCharts && (
            <Tabs
              value={tabId}
              classes={{ root: styles.tabs }}
              onChange={handleTabChange}
            >
              <Tab icon={<ListIcon />} label="List" />
              <Tab icon={<ShowChartIcon />} label="Chart" />
            </Tabs>
          )}
          {tabId === tabIds.GROWTH_MODEL_LIST && (
            <ResponsiveGrowthModelList
              growth={growth}
              growerId={growerId}
              seasonId={seasonId}
              farmId={farmId}
              fieldId={fieldId}
            />
          )}
          {tabId === tabIds.GROWTH_MODEL_CHART && (
            <GrowthModelGddChart
              GDDData={growthModelGdd}
              growthStages={growthStages}
            />
          )}
        </>
      )}
    </>
  );
};

export default GrowthDetails;
