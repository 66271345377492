import { useMemo } from 'react';

import _ from 'lodash';
import { useSelector } from 'react-redux';

import { requestStatus } from '../helpers/requestStatus';

export const useAddGrowthFeedbackSelectors = () => {
  const status = useSelector((state) =>
    _.get(state, 'growthsFeedback.addGrowthFeedback.status')
  );
  const isLoading = status === requestStatus.IN_PROGRESS;
  const success = status === requestStatus.SUCCESS;
  const error = useSelector((state) =>
    _.get(state, 'growthsFeedback.addGrowthFeedback.error.message')
  );

  return useMemo(
    () => ({
      isLoading,
      success,
      error
    }),
    [error, isLoading, success]
  );
};

export const useUpdateGrowthFeedbackSelectors = () => {
  const status = useSelector((state) =>
    _.get(state, 'growthsFeedback.updateGrowthFeedback.status')
  );
  const isLoading = status === requestStatus.IN_PROGRESS;
  const success = status === requestStatus.SUCCESS;
  const error = useSelector((state) =>
    _.get(state, 'growthsFeedback.updateGrowthFeedback.error.message')
  );

  return useMemo(
    () => ({
      isLoading,
      success,
      error
    }),
    [error, isLoading, success]
  );
};

export const useDeleteGrowthFeedbackSelectors = () => {
  const status = useSelector((state) =>
    _.get(state, 'growthsFeedback.deleteGrowthFeedback.status')
  );
  const isLoading = status === requestStatus.IN_PROGRESS;
  const success = status === requestStatus.SUCCESS;
  const error = useSelector((state) =>
    _.get(state, 'growthsFeedback.deleteGrowthFeedback.error.message')
  );

  return useMemo(
    () => ({
      isLoading,
      success,
      error
    }),
    [error, isLoading, success]
  );
};
