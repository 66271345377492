import { useMemo } from 'react';

import { seasonService } from '../../../../services';
import { createApiSlice } from '../../utils/createApiSlice';

const createGrowerSeasonSlice = createApiSlice({
  reducerPath: ['gff', 'seasons', 'createGrowerSeason'],
  apiFunction: seasonService.createSeasonV2
});

const { actions, useApiSelectors } = createGrowerSeasonSlice;

export const createGrowerSeasonActions = actions;

export const useCreateGrowerSeasonSelectors = () => {
  const { inProgress, errorMessage, response } = useApiSelectors();

  return useMemo(
    () => ({
      inProgress,
      errorMessage,
      response
    }),
    [inProgress, errorMessage, response]
  );
};

export default createGrowerSeasonSlice;
