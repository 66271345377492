import { addToken, client } from './client';

export const fieldAccessibilityService = {
  getFieldAccessibilityV2,
  addFieldAccessibilityFeedbackV2
};

async function getFieldAccessibilityV2(
  token,
  growerId,
  seasonId,
  farmId,
  fieldId
) {
  const response = await client.get(
    `/v2/growers/${growerId}/seasons/${seasonId}/farms/${farmId}/fields/${fieldId}/field-accessibility`,
    addToken(token)
  );
  return response.data;
}

async function addFieldAccessibilityFeedbackV2(
  token,
  growerId,
  seasonId,
  farmId,
  fieldId,
  feedback
) {
  const response = await client.post(
    `/v2/growers/${growerId}/seasons/${seasonId}/farms/${farmId}/fields/${fieldId}/field-accessibility/feedback`,
    feedback,
    addToken(token)
  );
  return response.data;
}
