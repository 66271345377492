import React from 'react';

import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import makeStyles from '@material-ui/core/styles/makeStyles';
import cx from 'classnames';

const useStyles = makeStyles(() => ({
  container: {
    padding: 0,
    marginBottom: '1rem'
  }
}));

const ContentContainer = ({ children, className, maxWidth = false }) => {
  const styles = useStyles();

  return (
    <Container maxWidth={maxWidth} className={cx(styles.container, className)}>
      <Paper>{children}</Paper>
    </Container>
  );
};

export default ContentContainer;
