import React, { useCallback, useContext, useEffect } from 'react';

import {
  faDrawPolygon,
  faSeedling,
  faUserTie
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Checkbox, FormControlLabel, Tooltip } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FilterListIcon from '@material-ui/icons/FilterList';
import { Alert, TreeItem, TreeView } from '@material-ui/lab';
import _ from 'lodash';
import { useDispatch } from 'react-redux';

import { AuthContext } from '../../../_context/AuthContext';
import { useGlobalSeasonSelectors } from '../../../_store/slices/gff/seasons/globalSeasonSlice';
import { getReportsGrowersActions } from '../../../_store/slices/reports/getReportsGrowersSlice';
import { reportsAppliedFieldsSelectionActions } from '../../../_store/slices/reports/reportsAppliedFieldsSelectionSlice';
import { reportsFieldsSelectionActions } from '../../../_store/slices/reports/reportsFieldsSelectionSlice';
import {
  reportsFiltersActions,
  useReportsFiltersSelectors
} from '../../../_store/slices/reports/reportsFiltersSlice';
import {
  growersDrawerActions,
  useGrowersDrawerSelectors
} from '../../../_store/slices/ui/growersDrawerSlice';
import LoadingIndicator from '../../../Components/LoadingIndicator';

import { useReportsGrowersWithSelection } from './_hooks/useReportsGrowersWithSelection';
import { useReportsRegionsWithSelection } from './_hooks/useReportsRegionsWithSelection';
import { useSeasonSelectedFields } from './_hooks/useSeasonSelectedFields';

const useStyles = makeStyles({
  container: {
    padding: '0.5rem',
    color: '#D1D1D1'
  },
  root: {
    color: '#D1D1D1'
  },
  checkbox: {
    color: '#D1D1D1'
  },
  checkboxDisabled: {
    color: '#6E6E6EFF !important'
  },
  buttonLabel: {
    color: '#D1D1D1'
  },
  buttonDisabledLabel: {
    color: 'rgba(209,209,209,0.41)'
  },
  buttonsContainer: {
    marginBottom: '0.5rem'
  },
  regionsSelect: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  treeItemLabel: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  treeItemIcon: {
    marginRight: '0.5rem'
  },
  fieldsCount: {
    marginLeft: '0.25rem'
  },
  title: { textAlign: 'center', fontWeight: '500', marginBottom: '0.25rem' },
  filterContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '0.25rem',
    marginBottom: '0.25rem'
  },
  filterLabel: {
    marginLeft: '0.25rem'
  },
  filterName: {
    fontWeight: 'bold',
    color: '#FFFFFF',
    marginLeft: '0.5rem'
  }
});

function mapFieldsTree(fields, selected) {
  return _.chain(fields)
    .keyBy((field) => _.get(field, 'id'))
    .mapValues(() => selected)
    .value();
}

function mapFarmsTree(farms, selected) {
  return _.chain(farms)
    .keyBy((farm) => _.get(farm, 'id'))
    .mapValues((farm) => mapFieldsTree(_.get(farm, 'fields'), selected))
    .value();
}

function mapGrowersTree(growers, selected) {
  return _.chain(growers)
    .filter((grower) => !!_.get(grower, 'seasonId'))
    .keyBy((grower) => _.get(grower, 'id'))
    .mapValues((grower) => ({
      [_.get(grower, 'seasonId')]: mapFarmsTree(
        _.get(grower, 'farms'),
        selected
      )
    }))
    .value();
}

const GrowersTreeItem = ({
  item,
  icon,
  iconTooltip,
  onCheckboxClick = _.noop,
  children
}) => {
  const styles = useStyles();

  const disabled = _.get(item, 'disabled', false);
  const checked = _.get(item, 'checked', false);
  const indeterminate = _.get(item, 'indeterminate', false);
  const fieldsCount = _.get(item, 'fieldsCount');
  const checkedFieldsCount = _.get(item, 'checkedFieldsCount');

  const handleClick = useCallback(
    (e) => {
      e.preventDefault();
      onCheckboxClick();
    },
    [onCheckboxClick]
  );

  return (
    <TreeItem
      nodeId={_.get(item, 'id')}
      label={
        <Box className={styles.treeItemLabel}>
          <Checkbox
            classes={{
              root: styles.checkbox,
              disabled: styles.checkboxDisabled
            }}
            disabled={disabled}
            indeterminate={indeterminate}
            checked={checked}
            size="small"
            color="default"
            onClick={handleClick}
          />
          {icon && (
            <Tooltip title={iconTooltip}>
              <span>
                <FontAwesomeIcon icon={icon} className={styles.treeItemIcon} />
              </span>
            </Tooltip>
          )}
          <span>{`${_.get(item, 'name')} ${
            _.isFinite(fieldsCount) && _.isFinite(checkedFieldsCount)
              ? '(' + checkedFieldsCount + '/' + fieldsCount + ')'
              : ''
          }`}</span>
        </Box>
      }
    >
      {children}
    </TreeItem>
  );
};

const NoRegionGrowersTreeItem = ({ noRegionGrowers }) => {
  const dispatch = useDispatch();

  const { selectedSeason: seasonYear } = useGlobalSeasonSelectors();

  const growers = _.get(noRegionGrowers, 'growers');
  const checked = _.get(noRegionGrowers, 'checked', false);
  const indeterminate = _.get(noRegionGrowers, 'indeterminate', false);

  const handleClick = useCallback(() => {
    const tree = mapGrowersTree(growers, indeterminate ? true : !checked);

    dispatch(
      reportsFieldsSelectionActions.mergeStateTree({ tree, seasonYear })
    );
    dispatch(reportsFiltersActions.resetFilter());
  }, [checked, dispatch, growers, indeterminate, seasonYear]);

  return (
    <GrowersTreeItem item={noRegionGrowers} onCheckboxClick={handleClick}>
      {_.map(growers, (grower) => (
        <GrowerTreeItem key={_.get(grower, 'id')} grower={grower} />
      ))}
    </GrowersTreeItem>
  );
};

const RegionTreeItem = ({ region }) => {
  const dispatch = useDispatch();

  const { selectedSeason: seasonYear } = useGlobalSeasonSelectors();

  const districts = _.get(region, 'districts');
  const checked = _.get(region, 'checked', false);
  const indeterminate = _.get(region, 'indeterminate', false);

  const handleClick = useCallback(() => {
    const growers = _.flatMap(districts, (district) =>
      _.get(district, 'growers')
    );

    const tree = mapGrowersTree(growers, indeterminate ? true : !checked);

    dispatch(
      reportsFieldsSelectionActions.mergeStateTree({ tree, seasonYear })
    );
    dispatch(reportsFiltersActions.resetFilter());
  }, [checked, dispatch, districts, indeterminate, seasonYear]);

  return (
    <GrowersTreeItem item={region} onCheckboxClick={handleClick}>
      {_.map(districts, (district) => (
        <DistrictTreeItem key={_.get(district, 'id')} district={district} />
      ))}
    </GrowersTreeItem>
  );
};

const DistrictTreeItem = ({ district }) => {
  const dispatch = useDispatch();

  const { selectedSeason: seasonYear } = useGlobalSeasonSelectors();

  const growers = _.get(district, 'growers');
  const checked = _.get(district, 'checked', false);
  const indeterminate = _.get(district, 'indeterminate', false);

  const handleClick = useCallback(() => {
    const tree = mapGrowersTree(growers, indeterminate ? true : !checked);

    dispatch(
      reportsFieldsSelectionActions.mergeStateTree({ tree, seasonYear })
    );
    dispatch(reportsFiltersActions.resetFilter());
  }, [checked, dispatch, growers, indeterminate, seasonYear]);

  return (
    <GrowersTreeItem item={district} onCheckboxClick={handleClick}>
      {_.map(growers, (grower) => (
        <GrowerTreeItem key={_.get(grower, 'id')} grower={grower} />
      ))}
    </GrowersTreeItem>
  );
};

const GrowerTreeItem = ({ grower }) => {
  const dispatch = useDispatch();

  const { selectedSeason: seasonYear } = useGlobalSeasonSelectors();

  const growerId = _.get(grower, 'id');
  const seasonId = _.get(grower, 'seasonId');
  const farms = _.get(grower, 'farms');
  const checked = _.get(grower, 'checked', false);
  const indeterminate = _.get(grower, 'indeterminate', false);

  const handleClick = useCallback(() => {
    const tree = {
      [growerId]: {
        [seasonId]: mapFarmsTree(farms, indeterminate ? true : !checked)
      }
    };

    dispatch(
      reportsFieldsSelectionActions.mergeStateTree({ tree, seasonYear })
    );
    dispatch(reportsFiltersActions.resetFilter());
  }, [checked, dispatch, farms, growerId, indeterminate, seasonId, seasonYear]);

  return (
    <GrowersTreeItem
      item={grower}
      icon={faUserTie}
      iconTooltip="Grower"
      onCheckboxClick={handleClick}
    >
      {_.map(farms, (farm) => (
        <FarmTreeItem
          key={_.get(farm, 'id')}
          growerId={growerId}
          seasonId={seasonId}
          farm={farm}
        />
      ))}
    </GrowersTreeItem>
  );
};

const FarmTreeItem = ({ growerId, seasonId, farm }) => {
  const dispatch = useDispatch();

  const { selectedSeason: seasonYear } = useGlobalSeasonSelectors();

  const farmId = _.get(farm, 'id');
  const checked = _.get(farm, 'checked', false);
  const indeterminate = _.get(farm, 'indeterminate', false);
  const fields = _.get(farm, 'fields');

  const handleClick = useCallback(() => {
    const tree = {
      [growerId]: {
        [seasonId]: {
          [farmId]: mapFieldsTree(fields, indeterminate ? true : !checked)
        }
      }
    };

    dispatch(
      reportsFieldsSelectionActions.mergeStateTree({ tree, seasonYear })
    );
    dispatch(reportsFiltersActions.resetFilter());
  }, [
    checked,
    dispatch,
    farmId,
    fields,
    growerId,
    indeterminate,
    seasonId,
    seasonYear
  ]);

  return (
    <GrowersTreeItem
      item={farm}
      icon={faSeedling}
      iconTooltip="Farm"
      onCheckboxClick={handleClick}
    >
      {_.map(fields, (field) => (
        <FieldTreeItem
          key={_.get(field, 'id')}
          growerId={growerId}
          seasonId={seasonId}
          farmId={farmId}
          field={field}
        />
      ))}
    </GrowersTreeItem>
  );
};

const FieldTreeItem = ({ growerId, seasonId, farmId, field }) => {
  const dispatch = useDispatch();

  const { selectedSeason: seasonYear } = useGlobalSeasonSelectors();

  const fieldId = _.get(field, 'id');

  const handleClick = useCallback(() => {
    dispatch(
      reportsFieldsSelectionActions.toggleChecked({
        growerId,
        seasonId,
        farmId,
        fieldId,
        seasonYear
      })
    );
    dispatch(reportsFiltersActions.resetFilter());
  }, [dispatch, farmId, fieldId, growerId, seasonId, seasonYear]);

  return (
    <GrowersTreeItem
      item={field}
      iconTooltip="Field"
      icon={faDrawPolygon}
      onCheckboxClick={handleClick}
    />
  );
};

const SelectedFilter = ({ selectedFilter }) => {
  const styles = useStyles();

  return (
    <Box className={styles.filterContainer}>
      <FilterListIcon />
      <Typography className={styles.filterLabel}>Selected Filter:</Typography>
      <Typography className={styles.filterName}>
        {_.get(selectedFilter, 'name')}
      </Typography>
    </Box>
  );
};

const GrowersTreeView = ({ onShowSavedFilters }) => {
  const styles = useStyles();
  const dispatch = useDispatch();

  const { isAdmin } = useContext(AuthContext);

  const { showReportsRegions } = useGrowersDrawerSelectors();

  const { selectedFilter } = useReportsFiltersSelectors();

  const renderRegions = !!isAdmin && !!showReportsRegions;

  const { selectedSeason: seasonYear } = useGlobalSeasonSelectors();

  const { inProgress, errorMessage, success, growers, touched } =
    useReportsGrowersWithSelection();

  const { regions, noRegionGrowers } = useReportsRegionsWithSelection(growers);

  const { fieldsChecked: fieldsCheckedNotApplied } = useSeasonSelectedFields();

  useEffect(() => {
    dispatch(getReportsGrowersActions.submit({ seasonYear }));
  }, [dispatch, seasonYear]);

  const onSelectAllClick = useCallback(() => {
    const tree = mapGrowersTree(growers, true);

    dispatch(
      reportsFieldsSelectionActions.mergeStateTree({ tree, seasonYear })
    );
    dispatch(reportsFiltersActions.resetFilter());
  }, [dispatch, growers, seasonYear]);

  const onSelectNoneClick = useCallback(() => {
    const tree = mapGrowersTree(growers, false);

    dispatch(
      reportsFieldsSelectionActions.mergeStateTree({ tree, seasonYear })
    );
    dispatch(reportsFiltersActions.resetFilter());
  }, [dispatch, growers, seasonYear]);

  const onApplyClick = useCallback(() => {
    dispatch(
      reportsAppliedFieldsSelectionActions.replaceStateTree({
        tree: fieldsCheckedNotApplied,
        seasonYear
      })
    );
    dispatch(reportsFieldsSelectionActions.resetTouched());
  }, [dispatch, fieldsCheckedNotApplied, seasonYear]);

  const handleRegionsToggle = useCallback(() => {
    dispatch(growersDrawerActions.toggleShowReportsRegions());
  }, [dispatch]);

  return (
    <Box className={styles.container}>
      {inProgress && <LoadingIndicator />}
      {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
      {success && (
        <>
          <Grid container className={styles.buttonsContainer}>
            <Grid item xs={12}>
              <Typography className={styles.title}>
                Reports Hierarchy
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Button
                size="small"
                classes={{ label: styles.buttonLabel }}
                fullWidth
                onClick={onSelectAllClick}
              >
                All
              </Button>
            </Grid>
            <Grid item xs={4}>
              <Button
                size="small"
                classes={{ label: styles.buttonLabel }}
                fullWidth
                onClick={onSelectNoneClick}
              >
                None
              </Button>
            </Grid>
            <Grid item xs={4}>
              <Button
                size="small"
                classes={{
                  label: !touched
                    ? styles.buttonDisabledLabel
                    : styles.buttonLabel
                }}
                fullWidth
                disabled={!touched}
                onClick={onApplyClick}
              >
                Apply
              </Button>
            </Grid>
            {isAdmin && (
              <Grid item xs={6} className={styles.regionsSelect}>
                <FormControlLabel
                  className={styles.buttonLabel}
                  control={
                    <Checkbox
                      className={styles.checkbox}
                      checked={showReportsRegions}
                      onChange={handleRegionsToggle}
                      name="checkedB"
                      color="default"
                      size="small"
                    />
                  }
                  label=" Regions"
                />
              </Grid>
            )}
            <Grid item xs={isAdmin ? 6 : 12} className={styles.regionsSelect}>
              <Button
                size="small"
                classes={{
                  label: touched
                    ? styles.buttonDisabledLabel
                    : styles.buttonLabel
                }}
                fullWidth
                disabled={touched}
                onClick={onShowSavedFilters}
              >
                Saved Filters
              </Button>
            </Grid>
            {!!selectedFilter && (
              <Grid item xs={12}>
                <SelectedFilter selectedFilter={selectedFilter} />
              </Grid>
            )}
          </Grid>
          <TreeView
            className={styles.root}
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
            disableSelection
          >
            {!renderRegions &&
              _.map(growers, (grower) => (
                <GrowerTreeItem key={_.get(grower, 'id')} grower={grower} />
              ))}
            {renderRegions && (
              <>
                {_.map(regions, (region) => (
                  <RegionTreeItem key={_.get(region, 'id')} region={region} />
                ))}
                {!_.chain(noRegionGrowers).get('growers').isEmpty().value() && (
                  <NoRegionGrowersTreeItem noRegionGrowers={noRegionGrowers} />
                )}
              </>
            )}
          </TreeView>
        </>
      )}
    </Box>
  );
};

export default GrowersTreeView;
