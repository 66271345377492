import _ from 'lodash';
import moment from 'moment';

import { getErrorMessage } from '../../services/errors';
import {
  addFieldAccessibilityFeedbackActionTypes,
  getFieldAccessibilityActionTypes
} from '../actionTypes/fieldAccessibilityActionTypes';
import { requestStatus } from '../helpers/requestStatus';

export const fieldAccessibilityReducer = (state = {}, action) => {
  const fieldId = _.last(_.get(action, 'payload.apiArguments'));
  switch (action.type) {
    case getFieldAccessibilityActionTypes.REQUEST:
      return {
        ...state,
        fieldAccessibility: {
          ..._.get(state, 'fieldAccessibility'),
          [fieldId]: {
            ..._.get(state, ['fieldAccessibility', fieldId]),
            status: requestStatus.IN_PROGRESS
          }
        }
      };
    case getFieldAccessibilityActionTypes.SUCCEEDED:
      return {
        ...state,
        fieldAccessibility: {
          ..._.get(state, 'fieldAccessibility'),
          [fieldId]: {
            status: requestStatus.SUCCESS,
            accessibility: {
              ...action.payload.response
            },
            updatedAt: moment().toISOString()
          }
        }
      };
    case getFieldAccessibilityActionTypes.FAILED:
      return {
        ...state,
        fieldAccessibility: {
          ..._.get(state, 'fieldAccessibility'),
          [fieldId]: {
            status: requestStatus.FAILURE,
            error: {
              message: getErrorMessage(action.payload.apiError),
              status: _.get(action, 'payload.apiError.response.status')
            },
            updatedAt: moment().toISOString()
          }
        }
      };
    case addFieldAccessibilityFeedbackActionTypes.REQUEST:
      return {
        ...state,
        addFieldAccessibilityFeedback: {
          status: requestStatus.IN_PROGRESS
        }
      };
    case addFieldAccessibilityFeedbackActionTypes.SUCCEEDED:
      return {
        ...state,
        addFieldAccessibilityFeedback: {
          status: requestStatus.SUCCESS,
          updatedAt: moment().toISOString()
        }
      };
    case addFieldAccessibilityFeedbackActionTypes.FAILED:
      return {
        ...state,
        addFieldAccessibilityFeedback: {
          status: requestStatus.FAILURE,
          error: {
            message: getErrorMessage(action.payload.apiError)
          }
        }
      };
    case addFieldAccessibilityFeedbackActionTypes.CLEAR:
      return {
        ...state,
        addFieldAccessibilityFeedback: {}
      };
    default:
      return state;
  }
};
