import getHistoricalDeviceCommandsSlice from './getHistoricalDeviceCommands';
import sendDeviceCommandSlice from './sendDeviceCommand';
import stopAllCommandSlice from './stopAllCommandSlice';

const commandsSlice = [
  sendDeviceCommandSlice,
  getHistoricalDeviceCommandsSlice,
  stopAllCommandSlice
];

export default commandsSlice;
