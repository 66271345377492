import React, { useMemo } from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import _ from 'lodash';
import moment from 'moment';

import GrowthStageBadge from '../Growths/GrowthStageBadge';
import { momentFormat } from '../Growths/GrowthTimeline/_utils/growthTimelineUtils';

import {
  grainMoistureContentColor,
  grainMoistureContentLineWidth
} from './GrainMoistureChart';

const useStyles = makeStyles(() => ({
  header: {
    marginBottom: '0.5rem'
  },
  item: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'nowrap'
  },
  date: {
    fontWeight: '500'
  },
  itemName: {
    marginRight: '0.75rem',
    fontWeight: '500',
    fontSize: '0.85rem'
  },
  itemValue: {
    fontSize: '0.85rem'
  },
  lineLegendContainer: {
    width: '1.25rem',
    height: '1.25rem',
    marginRight: '0.5rem'
  },
  lineLegendBox: (props) => ({
    height: '50%',
    borderBottom: `${props.borderWidth}px solid ${props.borderColor}`
  }),
  growthStageItem: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end'
  }
}));

const getRoundedValue = (
  conditions,
  field,
  mutationFunction = (x) => x,
  precision = 0
) => {
  return !isNaN(_.get(conditions, field))
    ? mutationFunction(_.get(conditions, field)).toFixed(precision)
    : null;
};

const LineLegendItem = ({ color, lineWidth = 1 }) => {
  const styles = useStyles({ borderWidth: lineWidth, borderColor: color });

  return (
    <Box className={styles.lineLegendContainer}>
      <Box className={styles.lineLegendBox} />{' '}
    </Box>
  );
};

const ValueItem = ({ name, value, unit, legendItem = <></> }) => {
  const styles = useStyles();

  return (
    <Grid item xs={12} sm={6} lg={4} xl={2} className={styles.item}>
      {legendItem}
      <Typography className={styles.itemName} noWrap>
        {name}
      </Typography>
      <Typography className={styles.itemValue}>{`${value} ${unit}`}</Typography>
    </Grid>
  );
};

const HarvestAdvisorDailyDetails = ({
  growthStages,
  infieldDrydown,
  selectedDate
}) => {
  const styles = useStyles();

  const growthStagesDescending = useMemo(
    () => _.orderBy(growthStages, ['date'], ['desc']),
    [growthStages]
  );

  const growthStageType = useMemo(() => {
    const currentDate = moment(selectedDate);
    return _.chain(growthStagesDescending)
      .find((growthStage) => {
        return currentDate.isSameOrAfter(moment(_.get(growthStage, 'date')));
      })
      .get('growthStage')
      .value();
  }, [growthStagesDescending, selectedDate]);

  const dailyConditions = useMemo(
    () =>
      _.find(
        infieldDrydown,
        (item) =>
          _.get(item, 'date') === moment(selectedDate).format(momentFormat)
      ),
    [infieldDrydown, selectedDate]
  );

  const formattedDate = useMemo(
    () => (selectedDate ? moment(selectedDate).format('ll') : ''),
    [selectedDate]
  );

  const soilMoistureDepletion = getRoundedValue(
    dailyConditions,
    'grainMoistureContent',
    (x) => x,
    1
  );

  return (
    <>
      <Grid
        container
        spacing={1}
        justifyContent="space-between"
        className={styles.header}
      >
        <Grid item xs={12} sm={6} md={4} xl={2}>
          <Typography className={styles.date}>{formattedDate}</Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          xl={2}
          className={styles.growthStageItem}
        >
          <Box>
            <GrowthStageBadge growthStage={growthStageType} small />
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={1} justifyContent="space-between">
        <ValueItem
          name="Grain Moisture Content"
          value={soilMoistureDepletion}
          unit="%"
          legendItem={
            <LineLegendItem
              lineWidth={grainMoistureContentLineWidth}
              color={grainMoistureContentColor}
            />
          }
        />
      </Grid>
    </>
  );
};

export default HarvestAdvisorDailyDetails;
