import { useMemo } from 'react';

import _ from 'lodash';

import { deviceCommands } from '../../../../services/deviceCommands.service';
import { createApiSlice } from '../../utils/createApiSlice';

const getHistoricalDeviceCommandsSlice = createApiSlice({
  reducerPath: ['devices', 'commands', 'getHistoricalDeviceCommands'],
  apiFunction: deviceCommands.getCommandsV2,
  getStateUpdatePath: (action) => {
    const growerId = _.get(action, 'payload.apiArguments.0');
    const seasonId = _.get(action, 'payload.apiArguments.1');
    const deviceId = _.get(action, 'payload.apiArguments.2');
    return [growerId, seasonId, deviceId];
  },
  getSelectorPath: (growerId, seasonId, deviceId) => {
    return [growerId, seasonId, deviceId];
  },
  apiArgumentsFn: ({ growerId, seasonId, deviceId }) => [
    growerId,
    seasonId,
    deviceId
  ]
});

const { actions, selectors, useApiSelectors } =
  getHistoricalDeviceCommandsSlice;

export const getHistoricalDeviceCommandsActions = actions;

export const getHistoricalDeviceCommandsSelectors = {
  ...selectors
};

export const useGetHistoricalDeviceCommandsSelectors = (
  growerId,
  seasonId,
  deviceId
) => {
  const { inProgress, errorMessage, success, updatedAt, response } =
    useApiSelectors(growerId, seasonId, deviceId);

  return useMemo(
    () => ({
      inProgress,
      errorMessage,
      success,
      updatedAt,
      response
    }),
    [errorMessage, inProgress, response, success, updatedAt]
  );
};

export default getHistoricalDeviceCommandsSlice;
