import React from 'react';

import { useTimelineActivities } from './_hooks/useTimelineActivities';
import ActivitiesTimeline from './ActivitiesTimeline';

const ActivitiesTabContent = () => {
  const { inProgress, timelineActivities, minDate, maxDate } =
    useTimelineActivities();

  return (
    <ActivitiesTimeline
      inProgress={inProgress}
      timelineActivities={timelineActivities}
      minDate={minDate}
      maxDate={maxDate}
    />
  );
};

export default ActivitiesTabContent;
