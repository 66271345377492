import React, { useMemo } from 'react';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import _ from 'lodash';
import { Line } from 'react-chartjs-2';

import { generateLabels } from '../../IrrigationDashboard/Components/sensorChartsUtils';

import {
  commonLineDataSetParams,
  getDefaultOptions,
  getValue
} from './weatherChartUtils';

const TemperatureChart = ({
  chartRef,
  weatherData,
  timeUnit,
  startDate,
  endDate
}) => {
  const highChart = useMediaQuery((theme) => theme.breakpoints.down('xs'));

  const graphData = useMemo(() => {
    if (weatherData) {
      // Daily chart
      const maxTempValues = getValue(weatherData, 'airTempMax');
      const avgTempValues = getValue(weatherData, 'airTempAvg');
      const minTempValues = getValue(weatherData, 'airTempMin');
      const maxDewPointValues = getValue(weatherData, 'dewPointMax');
      const avgDewPointValues = getValue(weatherData, 'dewPointAvg');
      const minDewPointValues = getValue(weatherData, 'dewPointMin');

      // Hourly chart
      const tempValues = getValue(weatherData, 'airTemp');
      const dewPointValues = getValue(weatherData, 'dewPoint');

      const datasets = [
        {
          ...commonLineDataSetParams('#ef5350'),
          label: 'Max Temperature',
          data: maxTempValues
        },
        {
          ...commonLineDataSetParams('#e53935'),
          label: 'Average Temperature',
          data: avgTempValues
        },
        {
          ...commonLineDataSetParams('#ef9a9a'),
          label: 'Min Temperature',
          fill: maxTempValues && avgTempValues ? '-2' : undefined,
          data: minTempValues
        },
        {
          ...commonLineDataSetParams('#29b6f6'),
          label: 'Max Dew Point',
          hidden: true,
          data: maxDewPointValues
        },
        {
          ...commonLineDataSetParams('#039be5'),
          label: 'Average Dew Point',
          hidden: true,
          data: avgDewPointValues
        },
        {
          ...commonLineDataSetParams('#81d4fa'),
          label: 'Min Dew Point',
          fill: maxDewPointValues && avgDewPointValues ? '-2' : undefined,
          hidden: true,
          data: minDewPointValues
        },
        {
          ...commonLineDataSetParams('#e53935'),
          label: 'Temperature',
          data: tempValues
        },
        {
          ...commonLineDataSetParams('#039be5'),
          label: 'Dew Point',
          data: dewPointValues
        }
      ].filter((dataset) => !_.isEmpty(dataset.data));

      const labels = generateLabels(datasets, startDate, endDate);

      return {
        labels,
        datasets
      };
    }
  }, [endDate, startDate, weatherData]);

  return (
    <>
      {graphData && highChart && (
        <Line
          ref={chartRef}
          data={graphData}
          options={getDefaultOptions(timeUnit)}
          height={250}
          type="scatter"
        />
      )}
      {graphData && !highChart && (
        <Line
          ref={chartRef}
          data={graphData}
          options={getDefaultOptions(timeUnit)}
          height={120}
          type="scatter"
        />
      )}
    </>
  );
};

export default TemperatureChart;
