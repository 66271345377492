import { addToken, client } from './client';

export const devicesStateService = {
  getAllStateV2,
  getHourlyStateV2,
  getDailyStateV2,
  getCurrentStateV2
};

async function getAllStateV2(token, growerId, seasonId, deviceId) {
  return await getStateV2(token, growerId, seasonId, deviceId, 'all');
}

async function getHourlyStateV2(token, growerId, seasonId, deviceId) {
  return await getStateV2(token, growerId, seasonId, deviceId, 'hourly');
}

async function getDailyStateV2(token, growerId, seasonId, deviceId) {
  return await getStateV2(token, growerId, seasonId, deviceId, 'daily');
}

async function getCurrentStateV2(token, growerId, seasonId, deviceId) {
  return await getStateV2(token, growerId, seasonId, deviceId, 'current');
}

async function getStateV2(token, growerId, seasonId, deviceId, resolution) {
  const response = await client.get(
    `/v2/growers/${growerId}/seasons/${seasonId}/devices/${deviceId}/state?resolution=${resolution}`,
    addToken(token)
  );
  return response.data;
}
