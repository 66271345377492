import { addToken, client } from './client';

export const configService = {
  saveStageConfigV2,
  getCurrentTermsOfServiceV2,
  getEnvironmentV2
};

async function saveStageConfigV2(token, stageType, config) {
  const response = await client.post(
    `/v2/config/growth-model/growth-stages/${stageType}`,
    config,
    addToken(token)
  );
  return response.data;
}

async function getCurrentTermsOfServiceV2(token) {
  const response = await client.get(
    `/v2/config/terms-of-service/current`,
    addToken(token)
  );
  return response.data;
}

async function getEnvironmentV2(token) {
  const response = await client.get(`/v2/config/environment`, addToken(token));
  return response.data;
}
