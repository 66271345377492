import React from 'react';

import { Popover, Typography } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import _ from 'lodash';
import moment from 'moment';

import GrowthStageBadge from '../GrowthStageBadge';

const useStyles = makeStyles(() => ({
  tooltip: {
    padding: '1rem'
  },
  tooltipText: {
    marginTop: '0.5rem',
    whiteSpace: 'nowrap'
  },
  popover: {
    pointerEvents: 'none'
  },
  feedbackText: {
    marginTop: '0.5rem',
    fontSize: '0.875rem'
  },
  dateModifiedText: {
    marginTop: '0.5rem',
    fontSize: '0.75rem'
  }
}));

const GrowthStagePopover = ({ hoverParams, showModifiedDate = false }) => {
  const styles = useStyles();

  const anchorEl = _.get(hoverParams, 'element');
  const displayTooltip = !!anchorEl;

  const canEditFeedback = _.get(hoverParams, 'data.canEditFeedback');
  const observed = _.get(hoverParams, 'data.observed');
  const pending = _.get(hoverParams, 'data.pending');
  const dateModified = _.get(hoverParams, 'data.dateModified');

  return (
    <Popover
      className={styles.popover}
      classes={{ paper: styles.tooltip }}
      open={displayTooltip}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: _.get(hoverParams, 'data.visibleMidX', 0)
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
      disableAutoFocus
      disableRestoreFocus
    >
      <GrowthStageBadge
        growthStage={_.get(hoverParams, 'data.growthStage')}
        stageObserved={observed}
        stagePending={pending}
      />
      <Typography className={styles.tooltipText}>
        Start: {moment(_.get(hoverParams, 'data.startDate')).format('ll')}
      </Typography>
      <Typography className={styles.tooltipText}>
        End: {moment(_.get(hoverParams, 'data.endDate')).format('ll')}
      </Typography>
      {showModifiedDate && !!dateModified && (
        <Typography className={styles.dateModifiedText}>
          {`Modified: ${moment(dateModified).format('MM/DD/YYYY')}`}
        </Typography>
      )}
      {canEditFeedback && (
        <Typography className={styles.feedbackText}>
          Click to {observed ? 'modify' : 'add'} feedback
        </Typography>
      )}
    </Popover>
  );
};

export default GrowthStagePopover;
