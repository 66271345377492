import React from 'react';

import { useAddFieldAccessibilityFeedbackDialogSelectors } from '../../../_store/selectors/uiSelectors';
import ResponsiveDialog from '../../ResponsiveDialog';

import AddFieldAccessibilityForm from './AddFieldAccessibilityForm';

const AddFieldAccessibilityDialog = () => {
  const { growerId, seasonId, open } =
    useAddFieldAccessibilityFeedbackDialogSelectors();

  return (
    <ResponsiveDialog open={open} maxWidth="xs">
      {open && (
        <AddFieldAccessibilityForm growerId={growerId} seasonId={seasonId} />
      )}
    </ResponsiveDialog>
  );
};

export default AddFieldAccessibilityDialog;
