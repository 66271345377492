import React, { useCallback, useContext, useEffect } from 'react';

import DialogTitle from '@material-ui/core/DialogTitle';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import _ from 'lodash';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { fieldAccessibilityConditions } from '../../../_constants/fieldsConstants';
import { FarmStructureContext } from '../../../_context/FarmStructureContext';
import { addFieldAccessibilityFeedbackActions } from '../../../_store/actions/fieldAccessibilityActions';
import { closeAddFieldAccessibilityFeedbackDialog } from '../../../_store/actions/uiActions';
import {
  useAddFieldAccessibilityFeedbackSelectors,
  useAllGrowerFieldAccessibilitySelectors
} from '../../../_store/selectors/fieldAccessibilitySelectors';
import CreateUpdateDeleteDialogActions from '../../CreateUpdateDeleteDialogActions';
import ResponsiveDialogContent from '../../ResponsiveDialogContent';

const AddFieldAccessibilityForm = ({ growerId, seasonId }) => {
  const dispatch = useDispatch();

  const { fieldAccessibility } = useAllGrowerFieldAccessibilitySelectors();

  const { selectedFieldId: fieldId } = useContext(FarmStructureContext);

  const {
    inProgress,
    success,
    error: apiError
  } = useAddFieldAccessibilityFeedbackSelectors();

  const { register, handleSubmit, errors, watch, setValue } = useForm({
    defaultValues: {
      fieldId: !!fieldId
        ? fieldId
        : _.chain(fieldAccessibility).first().get('fieldId').value(),
      classification: fieldAccessibilityConditions[0].type
    }
  });

  const selectedFieldId = watch('fieldId');
  const selectedClassification = watch('classification');

  useEffect(() => {
    register({ name: 'fieldId' }, { required: 'Field is required' });
    register(
      { name: 'classification' },
      { required: 'Current Conditions are required' }
    );
  }, [register]);

  const handleClose = useCallback(() => {
    dispatch(addFieldAccessibilityFeedbackActions.clear());
    dispatch(closeAddFieldAccessibilityFeedbackDialog());
  }, [dispatch]);

  useEffect(() => {
    if (success) {
      handleClose();
    }
  }, [handleClose, success]);

  const handleFormSubmit = useCallback(
    (formData) => {
      const { fieldId, classification } = formData;

      const farmId = _.chain(fieldAccessibility)
        .find((accessibility) => accessibility.fieldId === fieldId)
        .get('farmId')
        .value();

      dispatch(
        addFieldAccessibilityFeedbackActions.submit({
          growerId,
          seasonId,
          farmId,
          fieldId,
          feedback: { classification }
        })
      );
    },
    [dispatch, fieldAccessibility, growerId, seasonId]
  );

  const handleFieldChange = useCallback(
    (e) => {
      setValue('fieldId', e.target.value);
    },
    [setValue]
  );

  const handleClassificationChange = useCallback(
    (e) => {
      setValue('classification', e.target.value);
    },
    [setValue]
  );

  return (
    <form noValidate onSubmit={handleSubmit(handleFormSubmit)}>
      <DialogTitle>Add Field Accessibility Feedback</DialogTitle>
      <ResponsiveDialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {selectedFieldId && (
              <TextField
                value={selectedFieldId}
                onChange={handleFieldChange}
                variant="outlined"
                fullWidth
                required
                select
                id="fieldId"
                label="Field"
                name="fieldId"
                error={!!_.get(errors, 'fieldId')}
                helperText={_.get(errors, 'fieldId.message')}
              >
                {_.map(fieldAccessibility, (accessibility) => (
                  <MenuItem
                    key={_.get(accessibility, 'fieldId')}
                    value={_.get(accessibility, 'fieldId')}
                  >
                    {_.get(accessibility, 'fieldName')}
                  </MenuItem>
                ))}
              </TextField>
            )}
          </Grid>
          <Grid item xs={12}>
            {selectedClassification && (
              <TextField
                value={selectedClassification}
                onChange={handleClassificationChange}
                variant="outlined"
                fullWidth
                required
                select
                id="classification"
                label="Current Conditions"
                name="classification"
                error={!!_.get(errors, 'classification')}
                helperText={_.get(errors, 'classification.message')}
              >
                {_.map(fieldAccessibilityConditions, (classification) => (
                  <MenuItem
                    key={_.get(classification, 'type')}
                    value={_.get(classification, 'type')}
                  >
                    {_.get(classification, 'name')}
                  </MenuItem>
                ))}
              </TextField>
            )}
          </Grid>
          <Grid item xs={12}>
            {apiError && <FormHelperText error>{apiError}</FormHelperText>}
          </Grid>
        </Grid>
      </ResponsiveDialogContent>
      <CreateUpdateDeleteDialogActions
        inProgress={inProgress}
        confirmText="Add"
        onCancel={handleClose}
      />
    </form>
  );
};

export default AddFieldAccessibilityForm;
