import { useCallback, useMemo } from 'react';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useDispatch } from 'react-redux';

import {
  growersDrawerActions,
  useGrowersDrawerSelectors
} from '../../_store/slices/ui/growersDrawerSlice';
import {
  menuActions,
  useMenuSelectors
} from '../../_store/slices/ui/menuSlice';

export const useGrowersDrawerState = () => {
  const dispatch = useDispatch();

  const { showMobileSideMenu } = useMenuSelectors();
  const { growersDrawerOpen } = useGrowersDrawerSelectors();

  const closeCollidingDrawer = useMediaQuery((theme) =>
    theme.breakpoints.down('xs')
  );

  const toggleGrowersDrawer = useCallback(() => {
    if (!growersDrawerOpen && showMobileSideMenu && closeCollidingDrawer) {
      dispatch(menuActions.toggleMobileSideMenu());
    }
    dispatch(growersDrawerActions.toggleGrowersDrawerOpen());
  }, [closeCollidingDrawer, dispatch, growersDrawerOpen, showMobileSideMenu]);

  return useMemo(
    () => ({ growersDrawerOpen, toggleGrowersDrawer }),
    [growersDrawerOpen, toggleGrowersDrawer]
  );
};
