import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import makeStyles from '@material-ui/core/styles/makeStyles';
import cx from 'classnames';

const useStyles = makeStyles(() => ({
  badge: (props) => ({
    backgroundColor: props.backgroundColor,
    height: '1.25rem',
    width: '12.5rem',
    borderRadius: '2px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }),
  label: (props) => ({
    fontSize: '0.875rem',
    fontWeight: '400',
    color: props.textColor,
    textAlign: 'center',
    flexGrow: 1,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    marginLeft: '0.25rem',
    marginRight: '0.25rem'
  }),
  icon: (props) => ({
    color: props.textColor,
    marginLeft: '0.25rem',
    fontSize: '0.75rem'
  }),
  iconContainer: {
    marginLeft: '0.25rem'
  }
}));

const CommonBadge = ({
  backgroundColor,
  textColor,
  text,
  faIcon,
  icon,
  className,
  iconTooltip = ''
}) => {
  const styles = useStyles({ backgroundColor, textColor });

  return (
    <>
      {text && (
        <Paper elevation={0} className={cx(styles.badge, className)}>
          {!!icon && <Box className={styles.iconContainer}>{icon}</Box>}
          {faIcon && (
            <Tooltip title={iconTooltip}>
              <Box>
                <FontAwesomeIcon className={styles.icon} icon={faIcon} />
              </Box>
            </Tooltip>
          )}
          <Typography className={styles.label}>{text.toUpperCase()}</Typography>
        </Paper>
      )}
    </>
  );
};
export default CommonBadge;
