import React, { useCallback, useContext } from 'react';

import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import AddIcon from '@material-ui/icons/Add';
import { useDispatch } from 'react-redux';

import { AuthContext } from '../../_context/AuthContext';
import { FarmStructureContext } from '../../_context/FarmStructureContext';
import { openAddFieldAccessibilityFeedbackDialog } from '../../_store/actions/uiActions';
import { useAllGrowerFieldAccessibilitySelectors } from '../../_store/selectors/fieldAccessibilitySelectors';
import { ResponsiveHeaderButtonsContainer } from '../../Layout/Content';

const useStyles = makeStyles(() => ({
  addButton: {
    '&:hover': {
      textDecoration: 'none',
      color: '#fff'
    },
    marginLeft: '0.25rem',
    marginRight: '0.25rem'
  }
}));

const AddButton = ({ onClick, text }) => {
  const styles = useStyles();

  return (
    <Button
      size="small"
      variant="contained"
      color="primary"
      className={styles.addButton}
      startIcon={<AddIcon />}
      onClick={onClick}
    >
      {text}
    </Button>
  );
};

const FieldAccessibilityHeaderButtons = () => {
  const dispatch = useDispatch();

  const { isUserView } = useContext(AuthContext);

  const { selectedGrowerId: growerId, selectedSeasonId: seasonId } =
    useContext(FarmStructureContext);

  const { inProgress, minDate, maxDate } =
    useAllGrowerFieldAccessibilitySelectors(growerId, seasonId);

  const noData = !minDate || !maxDate;

  const handleAddFeedbackClick = useCallback(() => {
    dispatch(openAddFieldAccessibilityFeedbackDialog(growerId, seasonId));
  }, [dispatch, growerId, seasonId]);

  return (
    <ResponsiveHeaderButtonsContainer>
      {!inProgress && !noData && !isUserView && (
        <AddButton onClick={handleAddFeedbackClick} text="Add Feedback" />
      )}
    </ResponsiveHeaderButtonsContainer>
  );
};

export default FieldAccessibilityHeaderButtons;
