import React, { useMemo } from 'react';

import { Chart } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import _ from 'lodash';
import moment from 'moment';

import 'chartjs-adapter-moment';

import GrowthModelChart, { commonLineDataSetParams } from './GrowthModelChart';

Chart.register(annotationPlugin);

const defaultOptions = {
  responsive: true,
  tooltips: {
    mode: 'label'
  },
  scales: {
    'x-axis': {
      id: 'x-axis',
      type: 'time',
      time: {
        minUnit: 'day',
        tooltipFormat: 'LL'
      },
      display: true,
      gridLines: {
        display: true
      },
      ticks: {
        autoSkip: true
      }
    },
    'y-axis-gdd': {
      type: 'linear',
      display: true,
      position: 'right',
      id: 'y-axis-gdd',
      gridLines: {
        display: false
      }
    },
    'y-axis-temp': {
      type: 'linear',
      display: true,
      position: 'left',
      id: 'y-axis-temp',
      gridLines: {
        display: true
      }
    }
  }
};

const GrowthModelGddChart = ({ chartRef, GDDData, growthStages }) => {
  const growthStagesGdd = useMemo(() => {
    const gddByStage = _.map(growthStages, (stage) => {
      const startDate = moment(_.get(stage, 'date'));
      const endDate = moment(_.get(stage, 'endDate'));
      const gdd = _.filter(GDDData, (item) => {
        const date = moment(_.get(item, 'date'));
        return date.isSameOrAfter(startDate) && date.isBefore(endDate);
      });
      const firstGddItem = _.first(gdd);
      const startGdd = _.get(firstGddItem, 'accumulatedGdd', 0);
      const stageGdd = _.map(gdd, (item) => ({
        date: _.get(item, 'date'),
        accumulatedGdd: _.get(item, 'accumulatedGdd') - startGdd
      }));

      // This is to break the line at the end of the growth stage
      _.set(_.first(stageGdd), 'accumulatedGdd', undefined);
      return { growthStage: _.get(stage, 'growthStage'), stageGdd };
    });

    return _.chain(gddByStage)
      .flatMap((stage) => _.get(stage, 'stageGdd'))
      .orderBy('date')
      .value();
  }, [GDDData, growthStages]);

  const chartData = useMemo(() => {
    if (GDDData) {
      const GDDValues = _.map(GDDData, (v) => v['gdd']);
      const minTempValues = _.map(GDDData, (v) => v['minTemperature']);
      const maxTempValues = _.map(GDDData, (v) => v['maxTemperature']);
      const accumulatedGDD = _.map(GDDData, (v) => v['accumulatedGdd']);
      const labels = _.map(GDDData, (data) => moment(data.date).format('L'));
      const growthStageAccumulatedGDD = _.map(growthStagesGdd, (item) =>
        _.get(item, 'accumulatedGdd')
      );

      const datasets = [
        {
          ...commonLineDataSetParams('#6d4c41'),
          label: 'Accumulated GDD',
          yAxisID: 'y-axis-gdd',
          data: accumulatedGDD
        },
        {
          ...commonLineDataSetParams('#8d6e63'),
          label: 'Growth Stage Accumulated GDD',
          yAxisID: 'y-axis-gdd',
          data: growthStageAccumulatedGDD
        },
        {
          label: 'GDD',
          yAxisID: 'y-axis-gdd',
          type: 'bar',
          backgroundColor: '#8d6e6366',
          borderColor: '#8d6e63',
          data: GDDValues
        },
        {
          ...commonLineDataSetParams('#ef5350'),
          label: 'Max Temperature',
          yAxisID: 'y-axis-temp',
          data: maxTempValues
        },
        {
          ...commonLineDataSetParams('#ef9a9a'),
          label: 'Min Temperature',
          yAxisID: 'y-axis-temp',
          fill: '-1',
          data: minTempValues
        }
      ].filter((dataset) => dataset.data);

      return {
        labels,
        datasets
      };
    }
  }, [GDDData, growthStagesGdd]);

  return (
    <GrowthModelChart
      chartRef={chartRef}
      defaultOptions={defaultOptions}
      chartData={chartData}
      growthStages={growthStages}
    />
  );
};

export default GrowthModelGddChart;
