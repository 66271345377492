import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';

import makeStyles from '@material-ui/core/styles/makeStyles';
import * as d3 from 'd3';
import _ from 'lodash';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { v4 as uuid } from 'uuid';

import { FarmStructureContext } from '../../_context/FarmStructureContext';
import { getGrowthStages } from '../../_helpers/Growths/growthModelHelpers';
import { openAddMoistureSampleDialog } from '../../_store/actions/uiActions';
import { useGrowthModelSelectors } from '../../_store/selectors/uiSelectors';
import ChartDateLine from '../Charts/ChartDateLine';
import ChartLeftAxis from '../Charts/ChartLeftAxis';
import ChartTimeAxis from '../Charts/ChartTimeAxis';
import ChartTodayLine from '../Charts/ChartTodayLine';
import MouseEventsOverlay from '../Charts/MouseEventsOverlay';
import { parseDate } from '../Growths/GrowthTimeline/_utils/growthTimelineUtils';

import GrainMoistureLineChart from './GrainMoistureLineChart';
import HarvestAdvisorChartControls from './HarvestAdvisorChartControls';
import HarvestAdvisorDailyDetails from './HarvestAdvisorDailyDetails';
import MoistureSamples from './MoistureSamples';

const topPadding = 10;
const leftPadding = 10;
const rightPadding = 20;
const bottomPadding = 0;
const xAxisHeight = 40;
const yAxisWidth = 65;
const minHeight = 300;
const maxHeight = 500;
const widthHeightRatio = 3;
const yAxisGap = 15;
const xAxisGap = 15;
const maxZoomDays = 5;
const moistureSamplesHeight = 35;

export const grainMoistureContentColor = '#43a047';
export const grainMoistureContentLineWidth = 2.5;
const targetGrainMoistureContentColor = '#424242';

const useStyles = makeStyles(() => ({
  targetGrainMoistureContentLabel: {
    dominantBaseline: 'text-before-edge',
    fontSize: '0.75rem',
    opacity: 0.5
  }
}));

const GrainMoistureChart = ({
  growth,
  containerWidth,
  infieldDrydown,
  targetGrainMoisture
}) => {
  const styles = useStyles();
  const dispatch = useDispatch();

  const {
    selectedGrowerId: growerId,
    selectedSeasonId: seasonId,
    selectedFarmId: farmId,
    selectedFieldId: fieldId
  } = useContext(FarmStructureContext);

  const [rangeSelection, setRangeSelection] = useState();
  const [selectedDate, setSelectedDate] = useState(moment().toISOString());

  const handleDateSelected = useMemo(() => _.debounce(setSelectedDate, 10), []);

  const [clipPathId] = useState(`grainMoistureClipPath-${uuid()}`);

  const { selectedGrowthModel } = useGrowthModelSelectors();

  const moistureSamples = useMemo(
    () => _.get(growth, 'moistureSamples'),
    [growth]
  );

  const growthStages = useMemo(() => {
    const stages = getGrowthStages(growth, selectedGrowthModel);
    const plantingDate = moment(_.get(growth, 'plantingDate')).format(
      'YYYY-MM-DD'
    );
    return [{ date: plantingDate, growthStage: 'Planting' }, ...stages];
  }, [growth, selectedGrowthModel]);

  const minDate = useMemo(
    () => _.chain(infieldDrydown).minBy('date').get('date').value(),
    [infieldDrydown]
  );
  const maxDate = useMemo(
    () => _.chain(infieldDrydown).maxBy('date').get('date').value(),
    [infieldDrydown]
  );

  useEffect(() => {
    const min = moment(minDate);
    const max = moment(maxDate);
    if (moment().isBefore(min) || moment().isAfter(max)) {
      setSelectedDate(min.toISOString());
    }
  }, [maxDate, minDate]);

  const minMoisture = useMemo(() => {
    const min = _.chain(infieldDrydown)
      .minBy('grainMoistureContent')
      .get('grainMoistureContent')
      .value();

    if (targetGrainMoisture) {
      return _.min([min, targetGrainMoisture]);
    }

    return min;
  }, [infieldDrydown, targetGrainMoisture]);

  const maxMoisture = useMemo(() => {
    const max = _.chain(infieldDrydown)
      .maxBy('grainMoistureContent')
      .get('grainMoistureContent')
      .value();

    if (targetGrainMoisture) {
      return _.max([max, targetGrainMoisture]);
    }

    return max;
  }, [infieldDrydown, targetGrainMoisture]);

  const timeScale = useMemo(
    () =>
      d3
        .scaleTime()
        .domain([parseDate(minDate), parseDate(maxDate)])
        .range([leftPadding + yAxisWidth, containerWidth - rightPadding]),
    [minDate, maxDate, containerWidth]
  );

  const selectedTimeScale = useMemo(() => {
    if (
      rangeSelection &&
      rangeSelection.length === 2 &&
      !isNaN(rangeSelection[0]) &&
      !isNaN(rangeSelection[1])
    ) {
      return timeScale.copy().domain(rangeSelection);
    }
    return timeScale;
  }, [rangeSelection, timeScale]);

  const height = useMemo(
    () =>
      containerWidth / widthHeightRatio > maxHeight
        ? maxHeight
        : containerWidth / widthHeightRatio < minHeight
        ? minHeight
        : containerWidth / widthHeightRatio,
    [containerWidth]
  );

  const chartHeight = useMemo(
    () =>
      height -
      topPadding -
      bottomPadding -
      xAxisHeight -
      xAxisGap -
      moistureSamplesHeight,
    [height]
  );

  const moistureScale = useMemo(
    () =>
      d3
        .scaleLinear()
        .domain([minMoisture, maxMoisture])
        .nice()
        .range([chartHeight + topPadding, topPadding]),
    [chartHeight, maxMoisture, minMoisture]
  );

  const targetGrainMoistureTextX = useMemo(
    () => moistureScale(targetGrainMoisture),
    [moistureScale, targetGrainMoisture]
  );

  const xAxisTop = useMemo(
    () => chartHeight + topPadding + xAxisGap + moistureSamplesHeight,
    [chartHeight]
  );

  const clipPathWidth = useMemo(
    () => containerWidth - leftPadding - rightPadding - yAxisWidth + 1,
    [containerWidth]
  );

  const grainMoistureContentSelector = useCallback(
    (item) => _.get(item, 'grainMoistureContent'),
    []
  );

  const targetGrainMoistureContentSelector = useCallback(
    (item) => _.get(item, 'targetGrainMoisture'),
    []
  );

  const handleClick = useCallback(
    (date) => {
      const selectedDate = moment(date).format('YYYY-MM-DD');
      const moistureSample = _.find(moistureSamples, (sample) =>
        _.get(sample, 'date', '').startsWith(selectedDate)
      );
      if (moistureSample) {
        dispatch(
          openAddMoistureSampleDialog(
            growerId,
            seasonId,
            farmId,
            fieldId,
            _.get(growth, 'id'),
            _.get(moistureSample, 'id')
          )
        );
      }
    },
    [dispatch, farmId, fieldId, growerId, growth, moistureSamples, seasonId]
  );

  const handleMouseOver = useCallback(
    (date) => {
      handleDateSelected(moment(date).toISOString());
    },
    [handleDateSelected]
  );

  const handleMouseOut = useCallback(() => {
    const min = moment(minDate);
    const max = moment(maxDate);
    if (moment().isBefore(min) || moment().isAfter(max)) {
      handleDateSelected(min.toISOString());
    } else {
      handleDateSelected(moment().toISOString());
    }
  }, [minDate, maxDate, handleDateSelected]);

  const handleRangeSelection = useCallback(
    (range) => {
      const endDate = moment(range[1]);
      const startDate = moment(range[0]);
      const rangeDays = endDate.diff(startDate, 'days');
      if (rangeDays >= maxZoomDays) {
        setRangeSelection(range);
      } else {
        const daysDiff = maxZoomDays - rangeDays;
        let newEndDate = endDate
          .clone()
          .add(daysDiff / 2, 'days')
          .startOf('day');
        const maxEndDate = moment(maxDate);
        const minStartDate = moment(minDate);

        if (newEndDate.isAfter(maxEndDate)) {
          newEndDate = maxEndDate;
        }

        let newStartDate = newEndDate.clone().subtract(maxZoomDays, 'days');

        if (newStartDate.isBefore(minStartDate)) {
          newStartDate = minStartDate;
          newEndDate = newStartDate.clone().add(maxZoomDays, 'days');
        }

        setRangeSelection([newStartDate.toDate(), newEndDate.toDate()]);
      }
    },
    [maxDate, minDate]
  );

  const handleZoomReset = useCallback(() => {
    setRangeSelection(null);
  }, []);

  return (
    <>
      <HarvestAdvisorDailyDetails
        growthStages={growthStages}
        infieldDrydown={infieldDrydown}
        selectedDate={selectedDate}
      />
      <HarvestAdvisorChartControls
        isZoomed={!!rangeSelection}
        onZoomReset={handleZoomReset}
      />
      <svg width={containerWidth} height={height}>
        <ChartTimeAxis
          paddingTop={xAxisTop}
          selectedTimeScale={selectedTimeScale}
        />
        <ChartLeftAxis
          yScale={moistureScale}
          paddingLeft={leftPadding}
          paddingRight={yAxisGap}
          axisWidth={yAxisWidth}
          label="Grain Moisture Content (%)"
        />
        <g clipPath={`url(#${clipPathId})`}>
          <GrainMoistureLineChart
            yScale={moistureScale}
            timeScale={selectedTimeScale}
            data={infieldDrydown}
            valueSelector={grainMoistureContentSelector}
            color={grainMoistureContentColor}
            lineWidth={grainMoistureContentLineWidth}
          />
          <GrainMoistureLineChart
            yScale={moistureScale}
            timeScale={selectedTimeScale}
            data={infieldDrydown}
            valueSelector={targetGrainMoistureContentSelector}
            color={targetGrainMoistureContentColor}
          />
          <ChartTodayLine
            paddingTop={topPadding}
            height={chartHeight + xAxisGap + moistureSamplesHeight}
            selectedTimeScale={selectedTimeScale}
            lineWidth={1}
          />
          {selectedDate && (
            <ChartDateLine
              paddingTop={topPadding}
              height={chartHeight + xAxisGap + moistureSamplesHeight}
              selectedTimeScale={selectedTimeScale}
              lineWidth={1}
              date={selectedDate}
              color="#AFAFAF"
            />
          )}
          <MoistureSamples
            height={moistureSamplesHeight}
            paddingLeft={leftPadding + yAxisWidth}
            paddingTop={chartHeight + topPadding}
            timeScale={selectedTimeScale}
            moistureSamples={moistureSamples}
          />
        </g>
        <clipPath id={clipPathId}>
          <rect
            x={leftPadding + yAxisWidth}
            y={topPadding}
            width={clipPathWidth}
            height={chartHeight + xAxisGap + moistureSamplesHeight}
          />
        </clipPath>
        {!!targetGrainMoisture && (
          <text
            className={styles.targetGrainMoistureContentLabel}
            y={targetGrainMoistureTextX}
            x={leftPadding + yAxisWidth}
            fill={targetGrainMoistureContentColor}
          >
            Target Grain Moisture Content
          </text>
        )}
        <MouseEventsOverlay
          timeScale={selectedTimeScale}
          paddingTop={topPadding}
          paddingBottom={bottomPadding + xAxisHeight}
          paddingLeft={leftPadding + yAxisWidth}
          paddingRight={rightPadding}
          containerWidth={containerWidth}
          containerHeight={height}
          onClick={handleClick}
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
          onRangeSelection={handleRangeSelection}
        />
      </svg>
    </>
  );
};

export default GrainMoistureChart;
