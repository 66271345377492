import { all, fork, put, takeEvery, takeLatest } from 'redux-saga/effects';

import { isUnauthorized } from '../../services/errors';
import { unauthorizedErrorAction } from '../actions/apiErrorsActions';
import { apiActionTypes } from '../actionTypes/apiActionTypes';
import { apiErrorsActionTypes } from '../actionTypes/apiErrorsActionTypes';
import { unauthorizedErrorActions } from '../slices/user/unauthorizedErrorSlice';
import { errorActionName } from '../slices/utils/createApiSlice';

function* handleUnauthorizedError() {
  yield put(unauthorizedErrorActions.setError());
}

function* watchUnauthorizedError() {
  yield takeLatest(
    apiErrorsActionTypes.API_UNAUTHORIZED_ERROR,
    handleUnauthorizedError
  );
}

function* handleApiError(action) {
  const { apiError } = action.payload;
  if (isUnauthorized(apiError)) {
    yield put(unauthorizedErrorAction());
  }
}

function* watchApiErrors() {
  yield takeEvery(
    (action) =>
      action.type.endsWith(apiActionTypes.FAILED) ||
      action.type.endsWith(errorActionName),
    handleApiError
  );
}

function* apiErrorsSaga() {
  yield all([fork(watchUnauthorizedError), fork(watchApiErrors)]);
}

export default apiErrorsSaga;
