import React, { useContext } from 'react';

import { FarmStructureContext } from '../../../_context/FarmStructureContext';
import { useFieldWithGrowthSelector } from '../../../_store/selectors/fieldsSelectors';
import { useCreateGrowthDialogSelectors } from '../../../_store/selectors/uiSelectors';
import ResponsiveDialog from '../../ResponsiveDialog';

import GrowthForm from './GrowthForm';

const CreateGrowthDialog = () => {
  const { open, growerId, seasonId, farmId, fieldId, growthId } =
    useCreateGrowthDialogSelectors();
  const { field } = useFieldWithGrowthSelector(
    growerId,
    seasonId,
    farmId,
    fieldId
  );

  const { selectedSeason } = useContext(FarmStructureContext);

  return (
    <ResponsiveDialog open={open}>
      {open && (
        <GrowthForm
          growerId={growerId}
          seasonId={seasonId}
          farmId={farmId}
          fieldId={fieldId}
          growthId={growthId}
          field={field}
          season={selectedSeason}
        />
      )}
    </ResponsiveDialog>
  );
};

export default CreateGrowthDialog;
