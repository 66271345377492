import React, { useCallback, useContext, useRef } from 'react';

import { Alert } from '@material-ui/lab';

import { FORECAST_TABS } from '../../../../_constants/weatherConstants';
import { WeatherContext } from '../../../../_context/WeatherContext';
import { useGlobalSeasonSelectors } from '../../../../_store/slices/gff/seasons/globalSeasonSlice';
import ChartsHeader from '../../../../Components/Charts/ChartsHeader';
import LoadingIndicator from '../../../../Components/LoadingIndicator';
import ResponsiveToggleButtons from '../../../../Components/ResponsiveToggleButtons';
import { ContentBody, ContentContainer } from '../../../../Layout/Content';
import ChartSelector from '../ChartSelector';
import WeatherCharts from '../WeatherCharts';

const forecastTabSelectorItems = [
  { id: FORECAST_TABS['3_DAYS'], name: '3 Days' },
  { id: FORECAST_TABS['7_DAYS'], name: '7 Days' }
];

const Forecast = () => {
  const chartRef = useRef(null);

  const {
    forecastTab,
    setForecastTab,
    forecast,
    forecastGDD,
    selectedChart,
    errorMessage,
    inProgress,
    success
  } = useContext(WeatherContext);

  const { isHistorical: isHistoricalSeason } = useGlobalSeasonSelectors();

  const handleForecastTabSelect = useCallback(
    (daysId) => {
      setForecastTab(daysId);
    },
    [setForecastTab]
  );

  return (
    <>
      {!isHistoricalSeason && (
        <>
          <ChartsHeader>
            <ChartSelector chartRef={chartRef} tabName="Forecast" />
            <ResponsiveToggleButtons
              items={forecastTabSelectorItems}
              onSelect={handleForecastTabSelect}
              selectedItemId={forecastTab}
              size="small"
              variant="contained"
              disableElevation
              dropdownBreakpoint="xs"
            />
          </ChartsHeader>
          <ContentContainer>
            <ContentBody>
              {success && (
                <WeatherCharts
                  chartRef={chartRef}
                  selectedChart={selectedChart}
                  weatherData={forecast}
                  GDDData={forecastGDD}
                  timeUnit={
                    forecastTab === FORECAST_TABS['3_DAYS'] ? 'hour' : 'day'
                  }
                />
              )}
              {inProgress && <LoadingIndicator />}
              {!!errorMessage && !inProgress && (
                <Alert color="danger">
                  Something went wrong. Please try again later.
                </Alert>
              )}
            </ContentBody>
          </ContentContainer>
        </>
      )}
      {isHistoricalSeason && (
        <ContentContainer>
          <ContentBody>
            <Alert severity="warning">
              Forecast is not available for historical seasons. Please select
              the current season.
            </Alert>
          </ContentBody>
        </ContentContainer>
      )}
    </>
  );
};

export default Forecast;
