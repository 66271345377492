import { addToken, client } from './client';

export const growerDevicesService = {
  getGrowerDevicesV2,
  createGrowerDevicesV2,
  updateGrowerDevicesV2,
  deleteGrowerDevicesV2
};

async function getGrowerDevicesV2(token, growerId) {
  const response = await client.get(
    `/v2/growers/${growerId}/devices`,
    addToken(token)
  );
  return response.data;
}

async function createGrowerDevicesV2(token, growerId, device) {
  const response = await client.post(
    `/v2/growers/${growerId}/devices`,
    device,
    addToken(token)
  );
  return response.data;
}

async function updateGrowerDevicesV2(token, growerId, deviceId, device) {
  const response = await client.put(
    `/v2/growers/${growerId}/devices/${deviceId}`,
    device,
    addToken(token)
  );
  return response.data;
}

async function deleteGrowerDevicesV2(token, growerId, deviceId) {
  const response = await client.delete(
    `/v2/growers/${growerId}/devices/${deviceId}`,
    addToken(token)
  );
  return response.data;
}
