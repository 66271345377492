import { useMemo } from 'react';

import { useCropDashboardSelectors } from '../../../_store/slices/ui/cropDashboardSlice';

const minFieldNamesWidth = 100;
const maxFieldNamesWidth = 200;

export function useTimelineFieldNamesWidth(
  containerWidth,
  disableFarmName = false
) {
  const { showAllForGrower } = useCropDashboardSelectors();

  const fieldNamesWidth = useMemo(() => {
    const w =
      showAllForGrower && !disableFarmName
        ? containerWidth * 0.2
        : containerWidth * 0.1;
    return w < minFieldNamesWidth
      ? minFieldNamesWidth
      : w > maxFieldNamesWidth
      ? maxFieldNamesWidth
      : w;
  }, [containerWidth, disableFarmName, showAllForGrower]);

  return useMemo(
    () => ({
      fieldNamesWidth
    }),
    [fieldNamesWidth]
  );
}
