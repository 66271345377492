import _ from 'lodash';
import moment from 'moment';

import { getErrorMessage } from '../../services/errors';
import {
  createFieldActionTypes,
  deleteFieldActionTypes,
  getConfigFieldsActionTypes,
  updateFieldActionTypes
} from '../actionTypes/fieldsActionTypes';
import { requestStatus } from '../helpers/requestStatus';

export const fieldsReducer = (state = {}, action) => {
  const growerId = _.first(_.get(action, 'payload.apiArguments'));
  const farmId = _.get(action, 'payload.apiArguments.1');
  switch (action.type) {
    case getConfigFieldsActionTypes.REQUEST:
      return {
        ...state,
        configFields: {
          ..._.get(state, 'configFields'),
          [growerId]: {
            ..._.get(state, ['configFields', growerId]),
            [farmId]: { status: requestStatus.IN_PROGRESS }
          }
        }
      };
    case getConfigFieldsActionTypes.SUCCEEDED:
      return {
        ...state,
        configFields: {
          ..._.get(state, 'configFields'),
          [growerId]: {
            ..._.get(state, ['configFields', growerId]),
            [farmId]: {
              ..._.get(state, ['configFields', growerId, farmId]),
              status: requestStatus.SUCCESS,
              fields: _.map(action.payload.response, (field) => ({
                ...field,
                growerId,
                farmId
              })),
              updatedAt: moment().toISOString()
            }
          }
        }
      };
    case getConfigFieldsActionTypes.FAILED:
      return {
        ...state,
        configFields: {
          ..._.get(state, 'configFields'),
          [growerId]: {
            ..._.get(state, ['configFields', growerId]),
            [farmId]: {
              status: requestStatus.FAILURE,
              error: {
                message: getErrorMessage(action.payload.apiError)
              },
              updatedAt: moment().toISOString()
            }
          }
        }
      };
    case createFieldActionTypes.REQUEST:
      return {
        ...state,
        createField: {
          status: requestStatus.IN_PROGRESS
        }
      };
    case createFieldActionTypes.SUCCEEDED:
      return {
        ...state,
        createField: {
          status: requestStatus.SUCCESS,
          field: action.payload.response,
          updatedAt: moment().toISOString()
        }
      };
    case createFieldActionTypes.FAILED:
      return {
        ...state,
        createField: {
          status: requestStatus.FAILURE,
          error: {
            message: getErrorMessage(action.payload.apiError)
          }
        }
      };
    case createFieldActionTypes.CLEAR:
      return {
        ...state,
        createField: {}
      };
    case updateFieldActionTypes.REQUEST:
      return {
        ...state,
        updateField: {
          status: requestStatus.IN_PROGRESS
        }
      };
    case updateFieldActionTypes.SUCCEEDED:
      return {
        ...state,
        updateField: {
          status: requestStatus.SUCCESS,
          field: action.payload.response,
          updatedAt: moment().toISOString()
        }
      };
    case updateFieldActionTypes.FAILED:
      return {
        ...state,
        updateField: {
          status: requestStatus.FAILURE,
          error: {
            message: getErrorMessage(action.payload.apiError)
          }
        }
      };
    case updateFieldActionTypes.CLEAR:
      return {
        ...state,
        updateField: {}
      };
    case deleteFieldActionTypes.REQUEST:
      return {
        ...state,
        deleteField: {
          status: requestStatus.IN_PROGRESS,
          field: _.get(state, 'deleteField.field')
        }
      };
    case deleteFieldActionTypes.SUCCEEDED:
      return {
        ...state,
        deleteField: {
          status: requestStatus.SUCCESS,
          field: _.get(state, 'deleteField.field'),
          updatedAt: moment().toISOString()
        }
      };
    case deleteFieldActionTypes.FAILED:
      return {
        ...state,
        deleteField: {
          status: requestStatus.FAILURE,
          field: _.get(state, 'deleteField.field'),
          error: {
            message: getErrorMessage(action.payload.apiError)
          }
        }
      };
    case deleteFieldActionTypes.CLEAR:
      return {
        ...state,
        deleteField: {}
      };
    case deleteFieldActionTypes.SELECT:
      return {
        ...state,
        deleteField: {
          field: _.find(
            _.get(state, [
              'configFields',
              action.payload.growerId,
              action.payload.farmId,
              'fields'
            ]),
            (field) => field.id === action.payload.fieldId
          )
        }
      };
    default:
      return state;
  }
};
