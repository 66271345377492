import { useMemo } from 'react';

import { useSelector } from 'react-redux';

import { createDataSlice } from '../utils/createDataSlice';

const growersDrawerSlice = createDataSlice({
  reducerPath: ['ui', 'growersDrawer'],
  initialState: { growersDrawerOpen: false, showReportsRegions: true },
  reducers: {
    toggleGrowersDrawerOpen(state) {
      state.growersDrawerOpen = !state.growersDrawerOpen;
    },
    closeGrowersDrawer(state) {
      state.growersDrawerOpen = false;
    },
    toggleShowReportsRegions(state) {
      state.showReportsRegions = !state.showReportsRegions;
    }
  }
});

const { actions, baseSelector: growersDrawerSelector } = growersDrawerSlice;

export const growersDrawerActions = actions;

export const useGrowersDrawerSelectors = () => {
  const { growersDrawerOpen, showReportsRegions } = useSelector(
    growersDrawerSelector
  );

  return useMemo(
    () => ({
      growersDrawerOpen,
      showReportsRegions
    }),
    [growersDrawerOpen, showReportsRegions]
  );
};

export default growersDrawerSlice;
