import { useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  unauthorizedErrorActions,
  useUnauthorizedErrorSelectors
} from '../../_store/slices/user/unauthorizedErrorSlice';

export function useUnauthorizedErrorHandler() {
  const history = useHistory();
  const dispatch = useDispatch();

  const { hasError } = useUnauthorizedErrorSelectors();

  useEffect(() => {
    if (hasError) {
      dispatch(unauthorizedErrorActions.clear());
      history.push('/auth');
    }
  }, [dispatch, hasError, history]);
}
