import { addToken, client } from './client';

export const aquariusService = {
  getSensorData,
  getCurrentSystemData,
  getHistoricalSystemData
};

async function getSensorData(token, id, channel, start, end) {
  const response = await client.get(
    `/aquarius/sensor?sensorId=${id}&channel=${channel}&start=${start}&end=${end}`,
    addToken(token)
  );
  return response.data;
}

async function getCurrentSystemData(token, id) {
  const response = await client.get(
    `/aquarius/pumpSystem/current?pumpSystemId=${id}`,
    addToken(token)
  );
  return response.data;
}

async function getHistoricalSystemData(token, id, start, end) {
  const response = await client.get(
    `/aquarius/pumpSystem?pumpSystemId=${id}&start=${start}&end=${end}`,
    addToken(token)
  );
  return response.data;
}
