import React from 'react';

import useMediaQuery from '@material-ui/core/useMediaQuery';

import ActivitiesAccordion from './ActivitiesAccordion';
import ActivitiesTable from './ActivitiesTable';

const ResponsiveActivities = () => {
  const showTable = useMediaQuery((theme) => theme.breakpoints.up('md'));

  return (
    <>
      {!showTable && <ActivitiesAccordion />}
      {showTable && <ActivitiesTable />}
    </>
  );
};

export default ResponsiveActivities;
