import React from 'react';

import AddFieldAccessibilityDialog from './AddFieldAccessibilityDialog';

const FieldAccessibilityDialogs = () => {
  return (
    <>
      <AddFieldAccessibilityDialog />
    </>
  );
};

export default FieldAccessibilityDialogs;
