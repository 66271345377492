import React from 'react';

import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

const ContentBreadcrumbs = ({ children }) => {
  return (
    <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
      {children}
    </Breadcrumbs>
  );
};

export default ContentBreadcrumbs;
