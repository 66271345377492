import React, { useContext, useEffect, useRef } from 'react';

import { Alert } from '@material-ui/lab';

import { growthModels } from '../../../../_constants/growthsConstants';
import { CHARTS } from '../../../../_constants/weatherConstants';
import { FarmStructureContext } from '../../../../_context/FarmStructureContext';
import { WeatherContext } from '../../../../_context/WeatherContext';
import { useGdd } from '../../../../_hooks/Growths/useGdd';
import { useGrowthPrecipitation } from '../../../../_hooks/Growths/useGrowthPrecipitation';
import { useGrowthStages } from '../../../../_hooks/Growths/useGrowthStages';
import { useActiveGrowthSelectors } from '../../../../_store/selectors/growthsSelectors';
import { useGrowthModelSelectors } from '../../../../_store/selectors/uiSelectors';
import ChartsHeader from '../../../../Components/Charts/ChartsHeader';
import GrowthModelGddChart from '../../../../Components/Growths/GrowthModelGddChart';
import GrowthModelSelector from '../../../../Components/Growths/GrowthModelSelector';
import GrowthModelPrecipitationChart from '../../../../Components/Growths/GrowthPrecipitationChart';
import LoadingIndicator from '../../../../Components/LoadingIndicator';
import { ContentBody, ContentContainer } from '../../../../Layout/Content';
import ChartSelector from '../ChartSelector';

const Growth = () => {
  const chartRef = useRef(null);

  const { selectedChart, setSelectedChart } = useContext(WeatherContext);

  const { selectedGrowthModel } = useGrowthModelSelectors();

  const { selectedFieldId, selectedSeasonId } =
    useContext(FarmStructureContext);

  const {
    growth,
    isLoading: inProgress,
    notFound,
    errorMessage
  } = useActiveGrowthSelectors(selectedSeasonId, selectedFieldId);

  const { isHistoricalSeason } = useContext(FarmStructureContext);

  const { growthStages } = useGrowthStages(growth);
  const { gdd: growthModelGdd } = useGdd(growth);
  const { precipitation: precipitationData } = useGrowthPrecipitation(growth);

  useEffect(() => {
    if (
      (selectedGrowthModel === growthModels.ClearAg.type &&
        selectedChart === CHARTS.PRECIPITATION) ||
      (selectedChart !== CHARTS.GDD && selectedChart !== CHARTS.PRECIPITATION)
    ) {
      setSelectedChart(CHARTS.GDD);
    }
  }, [selectedChart, selectedGrowthModel, setSelectedChart]);

  return (
    <>
      <>
        <ChartsHeader>
          {selectedGrowthModel === growthModels.RiceTec.type && (
            <ChartSelector
              chartRef={chartRef}
              tabName="Growth"
              availableCharts={[CHARTS.GDD, CHARTS.PRECIPITATION]}
            />
          )}
          {selectedGrowthModel !== growthModels.RiceTec.type && <div />}
          <GrowthModelSelector small />
        </ChartsHeader>
        <ContentContainer>
          <ContentBody>
            {!!growth && (
              <>
                {selectedChart === CHARTS.GDD && (
                  <GrowthModelGddChart
                    chartRef={chartRef}
                    GDDData={growthModelGdd}
                    growthStages={growthStages}
                  />
                )}
                {selectedChart === CHARTS.PRECIPITATION && (
                  <GrowthModelPrecipitationChart
                    chartRef={chartRef}
                    precipitationData={precipitationData}
                    growthStages={growthStages}
                  />
                )}
              </>
            )}
            {inProgress && <LoadingIndicator />}
            {!!errorMessage && !inProgress && (
              <Alert color="danger">
                Something went wrong. Please try again later.
              </Alert>
            )}
            {!inProgress &&
              (notFound || (!growth && !errorMessage)) &&
              isHistoricalSeason && (
                <Alert severity="info">
                  There {isHistoricalSeason ? 'were' : 'are'} no growths
                  configured for this field.
                </Alert>
              )}
          </ContentBody>
        </ContentContainer>
      </>
    </>
  );
};

export default Growth;
