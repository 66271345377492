import authSlice from './authSlice';
import clearUserDataSlice from './clearUserDataSlice';
import forgotPasswordSlice from './forgotPasswordSlice';
import getCurrentUserSlice from './getCurrentUserSlice';
import getUserActionsSlice from './getUserActionsSlice';
import pageViewActionSlice from './pageViewActionSlice';
import resetPasswordParamsSlice from './resetPasswordParamsSlice';
import resetPasswordSlice from './resetPasswordSlice';
import tokenRefreshSlice from './tokenRefreshSlice';
import unauthorizedErrorSlice from './unauthorizedErrorSlice';
import validateResetPasswordTokenSlice from './validateResetPasswordTokenSlice';

const userSlices = [
  authSlice,
  getCurrentUserSlice,
  forgotPasswordSlice,
  resetPasswordParamsSlice,
  validateResetPasswordTokenSlice,
  resetPasswordSlice,
  tokenRefreshSlice,
  clearUserDataSlice,
  unauthorizedErrorSlice,
  getUserActionsSlice,
  pageViewActionSlice
];

export default userSlices;
