import { useMemo } from 'react';

import { useSelector } from 'react-redux';

import { createDataSlice } from '../utils/createDataSlice';

const reportsFiltersSlice = createDataSlice({
  reducerPath: ['reports', 'reportsFilters'],
  initialState: { selectedFilter: undefined },
  reducers: {
    selectFilter(state, action) {
      state.selectedFilter = action.payload;
    },
    resetFilter(state) {
      state.selectedFilter = undefined;
    }
  }
});

const { actions, baseSelector: reportsFiltersSelector } = reportsFiltersSlice;

export const reportsFiltersActions = actions;

export const useReportsFiltersSelectors = () => {
  const { selectedFilter } = useSelector(reportsFiltersSelector);

  return useMemo(() => ({ selectedFilter }), [selectedFilter]);
};

export default reportsFiltersSlice;
