import { useMemo } from 'react';

import _ from 'lodash';
import { useSelector } from 'react-redux';

import { requestStatus } from '../helpers/requestStatus';

export const configFieldsStatusSelector = (growerId, farmId) => (state) =>
  _.get(state, ['fields', 'configFields', growerId, farmId, 'status']);

export const useConfigFieldsSelector = (growerId, farmId) => {
  const status = useSelector(configFieldsStatusSelector(growerId, farmId));
  const isLoading = status === requestStatus.IN_PROGRESS;
  const success = status === requestStatus.SUCCESS;
  const error = useSelector((state) =>
    _.get(state, [
      'fields',
      'configFields',
      growerId,
      farmId,
      'error',
      'message'
    ])
  );
  const fields = useSelector((state) =>
    _.get(state, ['fields', 'configFields', growerId, farmId, 'fields'])
  );

  return useMemo(
    () => ({
      fields,
      isLoading,
      success,
      error
    }),
    [error, fields, isLoading, success]
  );
};

export const useConfigFieldSelectors = (growerId, farmId, fieldId) => {
  const {
    fields,
    isLoading: inProgress,
    success,
    error
  } = useConfigFieldsSelector(growerId, farmId);
  const field = useMemo(
    () => _.find(fields, (field) => field.id === fieldId),
    [fieldId, fields]
  );

  return useMemo(
    () => ({ field, success, inProgress, error }),
    [error, field, inProgress, success]
  );
};

export const useCreateFieldSelectors = () => {
  const status = useSelector((state) =>
    _.get(state, 'fields.createField.status')
  );
  const isLoading = status === requestStatus.IN_PROGRESS;
  const success = status === requestStatus.SUCCESS;
  const error = useSelector((state) =>
    _.get(state, 'fields.createField.error.message')
  );

  return {
    isLoading,
    success,
    error
  };
};

export const useUpdateFieldSelectors = () => {
  const status = useSelector((state) =>
    _.get(state, 'fields.updateField.status')
  );
  const isLoading = status === requestStatus.IN_PROGRESS;
  const success = status === requestStatus.SUCCESS;
  const error = useSelector((state) =>
    _.get(state, 'fields.updateField.error.message')
  );

  return {
    isLoading,
    success,
    error
  };
};

export const useDeleteFieldSelectors = () => {
  const fieldToDelete = useSelector((state) =>
    _.get(state, 'fields.deleteField.field')
  );

  const status = useSelector((state) =>
    _.get(state, 'fields.deleteField.status')
  );
  const isLoading = status === requestStatus.IN_PROGRESS;
  const success = status === requestStatus.SUCCESS;
  const error = useSelector((state) =>
    _.get(state, 'fields.deleteField.error.message')
  );

  return {
    fieldToDelete,
    isLoading,
    success,
    error
  };
};
