import React from 'react';

import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';

const FormContainer = ({ maxWidth = 'md', children }) => {
  return (
    <Container maxWidth={maxWidth} disableGutters>
      <Paper>{children}</Paper>
    </Container>
  );
};

export default FormContainer;
