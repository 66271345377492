import fasXmlParser, { j2xParser } from 'fast-xml-parser';
import _ from 'lodash';
import * as shapefile from 'shapefile';

const togeojson = require('@mapbox/togeojson');

const supportedGeometries = {
  Polygon: 'Polygon',
  MultiPolygon: 'MultiPolygon'
};

function convertToMultiPolygonCoordinatesList(geojson) {
  const type = _.get(geojson, 'type');
  if (type === 'FeatureCollection') {
    return _.chain(geojson)
      .get('features')
      .map((feature) => convertToMultiPolygonCoordinatesList(feature))
      .flatten()
      .value();
  }

  if (type === 'Feature') {
    return convertToMultiPolygonCoordinatesList(_.get(geojson, 'geometry'));
  }

  if (type === 'GeometryCollection') {
    return _.chain(geojson)
      .get('geometries')
      .map((geometry) => convertToMultiPolygonCoordinatesList(geometry))
      .flatten()
      .value();
  }

  if (type === supportedGeometries.MultiPolygon) {
    const coordinates = _.get(geojson, 'coordinates');
    if (!_.isEmpty(coordinates)) {
      return coordinates;
    }
    return [];
  }

  if (type === supportedGeometries.Polygon) {
    const coordinates = _.get(geojson, 'coordinates');
    if (!_.isEmpty(coordinates)) {
      return [coordinates];
    }
    return [];
  }

  return [];
}

export function convertToMultipolygon(geojson) {
  const coordinates = convertToMultiPolygonCoordinatesList(geojson);
  if (!_.isEmpty(coordinates)) {
    return {
      type: 'MultiPolygon',
      coordinates
    };
  }

  return null;
}

export function sanitizeGeojson(geojson) {
  const type = _.get(geojson, 'type');

  if (type === 'FeatureCollection') {
    const features = _.chain(geojson)
      .get('features')
      .map((feature) => sanitizeGeojson(feature))
      .filter((feature) => !!feature)
      .value();
    if (_.isEmpty(features)) {
      return null;
    }
    return {
      type: 'FeatureCollection',
      features
    };
  }

  if (type === 'Feature') {
    const geometry = sanitizeGeojson(_.get(geojson, 'geometry'));

    if (!geometry) {
      return null;
    }

    return {
      type: 'Feature',
      geometry
    };
  }

  if (type === 'GeometryCollection') {
    const geometries = _.chain(geojson)
      .get('geometries')
      .map((geometry) => sanitizeGeojson(geometry))
      .filter((geometry) => !!geometry)
      .value();
    if (_.isEmpty(geometries)) {
      return null;
    }
    return {
      type: 'GeometryCollection',
      geometries
    };
  }

  if (_.has(supportedGeometries, type)) {
    return _.pick(geojson, ['type', 'coordinates']);
  }

  return null;
}

export async function shapefileToGeojson(shpSource) {
  const featureCollection = {
    type: 'FeatureCollection',
    features: []
  };
  try {
    const source = await shapefile.openShp(shpSource);
    let result = await source.read();

    while (!result.done) {
      featureCollection.features.push(result.value);
      result = await source.read();
    }
  } catch (e) {
    console.error(e);
  }
  return featureCollection;
}

export function kmlToGeojson(xmlString) {
  const valid = fasXmlParser.validate(xmlString);

  // validation function returns either true or error object
  if (valid !== true) {
    console.error('Error parsing KML', valid);
    throw new Error(
      `Error in line ${_.get(valid, 'err.line')}: ${_.get(valid, 'err.msg')}`
    );
  }

  // Conversion to js object and back to xml string fixes some errors
  // that DOMParser does not handle (eg. missing schemas)
  const jsonObj = fasXmlParser.parse(xmlString, {});
  const j2xParserInstance = new j2xParser({});
  const cleanXml = j2xParserInstance.parse(jsonObj);

  const kml = new DOMParser().parseFromString(cleanXml, 'application/xml');
  const geoJson = togeojson.kml(kml);

  return sanitizeGeojson(geoJson);
}
