import { buildApiActionTypes } from './apiActionTypes';

export const getConfigFieldsActionTypes =
  buildApiActionTypes('GET_CONFIG_FIELDS');

export const createFieldActionTypes = buildApiActionTypes('CREATE_FIELD');

export const updateFieldActionTypes = buildApiActionTypes('UPDATE_FIELD');

export const deleteFieldActionTypes = {
  ...buildApiActionTypes('DELETE_FIELD'),
  SELECT: 'DELETE_FIELD_SELECT'
};
