import React, { useCallback } from 'react';

import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { makeStyles } from '@material-ui/core/styles';
import cx from 'classnames';

import { useMenuState } from '../../../_hooks/ui/useMenuState';

const useStyles = makeStyles(() => ({
  externalIcon: {
    marginLeft: '0.5rem',
    verticalAlign: 'super',
    fontSize: '0.7rem'
  },
  item: {
    width: '100%'
  },
  link: {
    width: '100%',
    transition:
      'transform .2s, height 300ms, color 300ms, background-color 300ms',
    display: 'flex',
    alignItems: 'center',
    lineHeight: '2.5rem',
    height: '2.5rem',
    position: 'relative',
    borderRadius: '0.25rem',
    whiteSpace: 'nowrap',
    color: 'rgba(255, 255, 255, 0.7)',
    '&:hover': {
      color: 'white',
      background: 'rgba(255, 255, 255, 0.1)',
      textDecoration: 'none'
    }
  },
  activeLink: {
    background: 'rgba(255, 255, 255, 0.1)',
    fontWeight: 'bold'
  },
  icon: {
    textAlign: 'center',
    width: '34px',
    height: '34px',
    lineHeight: '34px',
    fontSize: '1.25rem',
    opacity: '0.5',
    transition: 'color 150ms'
  },
  iconContainer: {
    display: 'flex',
    padding: '0 3px',
    width: '40px',
    justifyContent: 'center',
    alignItems: 'center'
  },
  label: {
    marginLeft: '5px',
    transition: 'color 150ms, opacity 300ms',
    fontSize: '0.88rem'
  },
  hideLabel: {
    opacity: '0'
  }
}));

const MenuItem = ({ item, active, collapsedSideMenuHover }) => {
  const styles = useStyles();
  const { toggleMobileSideMenu, showMobileSideMenu, collapsedSideMenu } =
    useMenuState();

  const handleClick = useCallback(() => {
    // Close menu on phone layouts when a link is clicked
    if (showMobileSideMenu) {
      toggleMobileSideMenu();
    }
  }, [showMobileSideMenu, toggleMobileSideMenu]);

  return (
    <li className={styles.item}>
      <a
        onClick={handleClick}
        className={cx(styles.link, {
          [styles.activeLink]: active
        })}
        href={item.to}
        target={item.target || '_self'}
      >
        <div className={cx(styles.iconContainer)}>
          <FontAwesomeIcon icon={item.icon} className={styles.icon} />
        </div>
        <span
          className={cx(styles.label, {
            [styles.hideLabel]: collapsedSideMenu && !collapsedSideMenuHover
          })}
        >
          {item.label}
          {item.target === '_blank' && (
            <FontAwesomeIcon
              className={styles.externalIcon}
              icon={faExternalLinkAlt}
            />
          )}
        </span>
      </a>
    </li>
  );
};

export default MenuItem;
