import { useMemo } from 'react';

import _ from 'lodash';
import { useSelector } from 'react-redux';

import { requestStatus } from '../helpers/requestStatus';

import {
  useAllGrowerFieldsSelector,
  useFieldsSelector
} from './fieldsSelectors';

export const fieldAccessibilityStatusSelector = (fieldId) => (state) =>
  _.get(state, ['fieldAccessibility', 'fieldAccessibility', fieldId, 'status']);

export const fieldAccessibilityUpdatedAtSelector = (fieldId) => (state) =>
  _.get(state, [
    'fieldAccessibility',
    'fieldAccessibility',
    fieldId,
    'updatedAt'
  ]);

export const fieldAccessibilityErrorStatusSelector = (fieldId) => (state) =>
  _.get(state, [
    'fieldAccessibility',
    'fieldAccessibility',
    fieldId,
    'error',
    'status'
  ]);

export const useFieldAccessibilitySelectors = (fieldId) => {
  const status = useSelector(fieldAccessibilityStatusSelector(fieldId));
  const fieldAccessibility = useSelector((state) => {
    return _.get(state, [
      'fieldAccessibility',
      'fieldAccessibility',
      fieldId,
      'accessibility'
    ]);
  });
  const updatedAt = useSelector(fieldAccessibilityUpdatedAtSelector(fieldId));

  const isLoading = status === requestStatus.IN_PROGRESS;

  const error = useSelector((state) =>
    _.get(state, ['fieldAccessibility', 'fieldAccessibility', fieldId, 'error'])
  );

  const notFound =
    useSelector(fieldAccessibilityErrorStatusSelector(fieldId)) === 404;
  const errorMessage = _.get(error, 'message');

  const dates = useMemo(
    () =>
      _.chain(fieldAccessibility)
        .get('data')
        .map((item) => item.date)
        .value(),
    [fieldAccessibility]
  );

  const minDate = useMemo(() => _.min(dates), [dates]);

  const maxDate = useMemo(() => _.max(dates), [dates]);

  return useMemo(
    () => ({
      fieldAccessibility,
      minDate,
      maxDate,
      inProgress: isLoading,
      notFound,
      errorMessage,
      updatedAt
    }),
    [
      errorMessage,
      fieldAccessibility,
      isLoading,
      maxDate,
      minDate,
      notFound,
      updatedAt
    ]
  );
};

const useFieldAccessibility = (fields) => {
  const allFieldAccessibility = useSelector((state) =>
    _.get(state, ['fieldAccessibility', 'fieldAccessibility'])
  );

  const fieldAccessibilityDescriptors = useMemo(() => {
    return _.chain(fields)
      .map((field) => {
        const fieldId = _.get(field, 'id');
        const fieldAccessibility = _.get(allFieldAccessibility, [fieldId]);
        return {
          ...fieldAccessibility,
          accessibility: _.get(fieldAccessibility, 'accessibility')
            ? {
                ..._.get(fieldAccessibility, 'accessibility', {}),
                fieldName: _.get(field, 'name'),
                farmName: _.get(field, 'farmName'),
                fieldId: _.get(field, 'id'),
                farmId: _.get(field, 'farmId')
              }
            : _.get(fieldAccessibility, 'accessibility'),
          minDate: _.chain(fieldAccessibility)
            .get('accessibility.data')
            .minBy((item) => _.get(item, 'date'))
            .get('date')
            .value(),
          maxDate: _.chain(fieldAccessibility)
            .get('accessibility.data')
            .maxBy((item) => _.get(item, 'date'))
            .get('date')
            .value()
        };
      })
      .filter((fieldAccessibility) => !!fieldAccessibility)
      .value();
  }, [allFieldAccessibility, fields]);

  const inProgress = useMemo(
    () =>
      _.some(
        fieldAccessibilityDescriptors,
        (fieldAccessibility) =>
          _.get(fieldAccessibility, 'status') === requestStatus.IN_PROGRESS
      ),
    [fieldAccessibilityDescriptors]
  );

  const fieldAccessibility = useMemo(
    () =>
      _.chain(fieldAccessibilityDescriptors)
        .map((fieldAccessibility) => _.get(fieldAccessibility, 'accessibility'))
        .filter((fieldAccessibility) => !!fieldAccessibility)
        .value(),
    [fieldAccessibilityDescriptors]
  );

  const minDate = useMemo(
    () =>
      _.chain(fieldAccessibilityDescriptors)
        .map((fieldAccessibility) => _.get(fieldAccessibility, 'minDate'))
        .min()
        .value(),
    [fieldAccessibilityDescriptors]
  );

  const maxDate = useMemo(
    () =>
      _.chain(fieldAccessibilityDescriptors)
        .map((fieldAccessibility) => _.get(fieldAccessibility, 'maxDate'))
        .max()
        .value(),
    [fieldAccessibilityDescriptors]
  );

  return useMemo(
    () => ({ inProgress, fieldAccessibility, minDate, maxDate }),
    [fieldAccessibility, inProgress, maxDate, minDate]
  );
};

export const useAllGrowerFieldAccessibilitySelectors = () => {
  const { fields } = useAllGrowerFieldsSelector();

  const { inProgress, fieldAccessibility, minDate, maxDate } =
    useFieldAccessibility(fields);

  return useMemo(
    () => ({ inProgress, fieldAccessibility, minDate, maxDate }),
    [fieldAccessibility, inProgress, maxDate, minDate]
  );
};

export const useFarmFieldAccessibilitySelectors = (
  growerId,
  seasonId,
  farmId
) => {
  const { fields } = useFieldsSelector(growerId, seasonId, farmId);

  const { inProgress, fieldAccessibility, minDate, maxDate } =
    useFieldAccessibility(fields);

  return useMemo(
    () => ({ inProgress, fieldAccessibility, minDate, maxDate }),
    [fieldAccessibility, inProgress, maxDate, minDate]
  );
};

export const useAddFieldAccessibilityFeedbackSelectors = () => {
  const status = useSelector((state) =>
    _.get(state, 'fieldAccessibility.addFieldAccessibilityFeedback.status')
  );
  const inProgress = status === requestStatus.IN_PROGRESS;
  const success = status === requestStatus.SUCCESS;
  const error = useSelector((state) =>
    _.get(
      state,
      'fieldAccessibility.addFieldAccessibilityFeedback.error.message'
    )
  );

  return useMemo(
    () => ({
      inProgress,
      success,
      error
    }),
    [error, inProgress, success]
  );
};
