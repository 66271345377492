import { useCallback, useMemo } from 'react';

import { useDispatch } from 'react-redux';

import {
  reportsChartsActions,
  useReportsChartsSelectors
} from '../../../../_store/slices/reports/reportsChartsSlice';

export const DataTypes = {
  Acres: 'acres',
  Number: 'count'
};

export function useChartDataType() {
  const dispatch = useDispatch();

  const { chartDataType: dataType } = useReportsChartsSelectors();

  const showAcres = dataType === DataTypes.Acres;
  const showNumber = dataType === DataTypes.Number;

  const setShowAcres = useCallback(() => {
    dispatch(
      reportsChartsActions.changeDataType({ dataType: DataTypes.Acres })
    );
  }, [dispatch]);

  const setShowNumber = useCallback(() => {
    dispatch(
      reportsChartsActions.changeDataType({ dataType: DataTypes.Number })
    );
  }, [dispatch]);

  return useMemo(
    () => ({
      showAcres,
      showNumber,
      dataType,
      setShowAcres,
      setShowNumber
    }),
    [dataType, setShowAcres, setShowNumber, showAcres, showNumber]
  );
}
