import _ from 'lodash';

export const growthModels = {
  RiceTec: {
    type: 'RiceTec',
    name: 'RiceTec',
    order: 0
  },
  ClearAg: {
    type: 'ClearAg',
    name: 'ClearAg',
    order: 1
  }
};

export const orderedGrowthModels = _.chain(growthModels)
  .values()
  .orderBy('order')
  .value();

export const defaultGrowthModel = _.chain(growthModels)
  .values()
  .minBy('order')
  .value();

export const growthFeedbackType = {
  GrowthStage: {
    type: 'GrowthStage',
    name: 'Growth Stage',
    order: 0
  }
};

export const growthStagesTypes = {
  Unknown: 'Unknown',
  NotPlanted: 'NotPlanted',
  Preplanting: 'Preplanting',
  Planting: 'Planting',
  Emergence: 'Emergence',
  Leaf2: 'Leaf2',
  Leaf4: 'Leaf4',
  GreenRing: 'GreenRingPi',
  Pd: 'Pd',
  EarlyBoot: 'EarlyBoot',
  MidBoot: 'MidBoot',
  LateBoot: 'LateBoot',
  Heading50X: 'Heading50X',
  PhysiologicalMaturity: 'PhysiologicalMaturity',
  Harvest: 'Harvest',
  Historical: 'Historical'
};

export const growthFeedbackTypesList = _.chain(growthFeedbackType)
  .values()
  .orderBy((type) => type.order)
  .value();
