import { buildApiActionTypes } from './apiActionTypes';

export const growthsActionTypes = {
  UPDATE_ACTIVE_GROWTH: 'UPDATE_ACTIVE_GROWTH',
  UPDATE_ALL_ACTIVE_GROWTH_FOR_GROWER: 'UPDATE_ALL_ACTIVE_GROWTH_FOR_GROWER',
  UPDATE_ALL_ACTIVE_GROWTH_FOR_FARM: 'UPDATE_ALL_ACTIVE_GROWTH_FOR_FARM',
  FORCE_UPDATE_ACTIVE_GROWTH: 'FORCE_UPDATE_ACTIVE_GROWTH'
};

export const getActiveGrowthActionTypes =
  buildApiActionTypes('GET_ACTIVE_GROWTH');

export const createGrowthActionTypes = buildApiActionTypes('CREATE_GROWTH');

export const modifyGrowthActionTypes = buildApiActionTypes('MODIFY_GROWTH');

export const deleteGrowthActionTypes = buildApiActionTypes('DELETE_GROWTH');

export const setTargetMoistureActionTypes = buildApiActionTypes(
  'SET_TARGET_MOISTURE'
);
