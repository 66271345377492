import { addToken, client } from './client';

export const statisticsService = {
  getGrowersStatistics,
  getFarmsStatistics,
  getFieldsStatistics,
  getSeasonFieldsStatistics,
  getSeasonFeedbackStatistics,
  getUsersPageViewsStatistics,
  getPageViewsStatistics
};

async function getGrowersStatistics(token) {
  const response = await client.get(
    `/v2/statistics/gff/growers`,
    addToken(token)
  );
  return response.data;
}

async function getFarmsStatistics(token) {
  const response = await client.get(
    `/v2/statistics/gff/farms`,
    addToken(token)
  );
  return response.data;
}

async function getFieldsStatistics(token) {
  const response = await client.get(
    `/v2/statistics/gff/fields`,
    addToken(token)
  );
  return response.data;
}

async function getSeasonFieldsStatistics(token, seasonYear) {
  const response = await client.get(
    `/v2/statistics/season/fields?seasonYear=${seasonYear}`,
    addToken(token)
  );
  return response.data;
}

async function getSeasonFeedbackStatistics(token, seasonYear) {
  const response = await client.get(
    `/v2/statistics/season/feedback?seasonYear=${seasonYear}`,
    addToken(token)
  );
  return response.data;
}

async function getUsersPageViewsStatistics(token, seasonYear) {
  const response = await client.get(
    `/v2/statistics/users/pageViews?seasonYear=${seasonYear}`,
    addToken(token)
  );
  return response.data;
}

async function getPageViewsStatistics(token, seasonYear) {
  const response = await client.get(
    `/v2/statistics/usage/pageViews?seasonYear=${seasonYear}`,
    addToken(token)
  );
  return response.data;
}
