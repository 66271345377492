export const CHARTS = {
  TEMPERATURE: 'TEMPERATURE',
  PRECIPITATION: 'PRECIPITATION',
  HUMIDITY: 'HUMIDITY',
  WIND_SPEED: 'WIND_SPEED',
  GDD: 'GDD'
};

export const FORECAST_TABS = {
  '3_DAYS': '3_DAYS',
  '7_DAYS': '7_DAYS'
};
