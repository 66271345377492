import { useMemo } from 'react';

import _ from 'lodash';

import { growerDevicesService } from '../../../../services/growerDevices.service';
import { createApiSlice } from '../../utils/createApiSlice';

const getGrowerDevicesSlice = createApiSlice({
  reducerPath: ['devices', 'growerDevices', 'getGrowerDevices'],
  apiFunction: growerDevicesService.getGrowerDevicesV2,
  getStateUpdatePath: (action) => {
    const growerId = _.first(_.get(action, 'payload.apiArguments'));
    return [growerId];
  },
  getSelectorPath: (growerId) => {
    return [growerId];
  }
});

const { actions, useApiSelectors } = getGrowerDevicesSlice;

export const getGrowerDevicesActions = actions;

export const useGetGrowerDevicesSelectors = (growerId) => {
  const { inProgress, success, errorMessage, response, updatedAt } =
    useApiSelectors(growerId);

  return useMemo(
    () => ({ inProgress, success, errorMessage, response, updatedAt }),
    [errorMessage, inProgress, response, success, updatedAt]
  );
};

export default getGrowerDevicesSlice;
