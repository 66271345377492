import React from 'react';

import Link from '@material-ui/core/Link';

import { BreadcrumbsText } from './index';

const BreadcrumbsLink = ({ children, ...props }) => {
  return (
    <Link {...props}>
      <BreadcrumbsText>{children}</BreadcrumbsText>
    </Link>
  );
};

export default BreadcrumbsLink;
