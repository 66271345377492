import { useMemo } from 'react';

import { useSelector } from 'react-redux';

import { DataTypes } from '../../../Pages/Reports/Components/_hooks/useChartDataType';
import { createDataSlice } from '../utils/createDataSlice';

const reportsChartsSlice = createDataSlice({
  reducerPath: ['reports', 'reportsCharts'],
  initialState: { showChart: false, chartDataType: DataTypes.Acres },
  reducers: {
    toggleShowChart(state) {
      state.showChart = !state.showChart;
    },
    changeDataType(state, action) {
      const { dataType } = action.payload;
      state.chartDataType = dataType;
    }
  }
});

const { actions, baseSelector: reportsChartsSelector } = reportsChartsSlice;

export const reportsChartsActions = actions;

export const useReportsChartsSelectors = () => {
  const { showChart, chartDataType } = useSelector(reportsChartsSelector);

  return useMemo(
    () => ({ showChart, chartDataType }),
    [showChart, chartDataType]
  );
};

export default reportsChartsSlice;
