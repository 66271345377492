import React, { useMemo } from 'react';

import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import _ from 'lodash';

import { growthStagesTypes } from '../../_constants/growthsConstants';
import { useGrowthStagesConfig } from '../../_store/selectors/configSelectors';

import GrowthStageBadge from './GrowthStageBadge';

const useStyles = makeStyles(() => ({
  legend: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    flexGrow: 1,
    justifyContent: 'center'
  },
  item: {
    padding: '0.25rem'
  }
}));

const LegendItem = ({ growthStage }) => {
  const styles = useStyles();

  return (
    <Box className={styles.item}>
      <GrowthStageBadge growthStage={_.get(growthStage, 'type')} small />{' '}
    </Box>
  );
};

const GrowthStagesLegend = () => {
  const styles = useStyles();

  const { growthStagesConfig } = useGrowthStagesConfig();

  const growthStages = useMemo(
    () =>
      _.chain(growthStagesConfig)
        .values()
        .filter(
          (stage) =>
            stage.type !== growthStagesTypes.Unknown &&
            stage.type !== growthStagesTypes.NotPlanted
        )
        .orderBy('order')
        .value(),
    [growthStagesConfig]
  );

  return (
    <Box className={styles.legend}>
      {_.map(growthStages, (growthStage) => (
        <LegendItem
          key={_.get(growthStage, 'type')}
          growthStage={growthStage}
        />
      ))}
    </Box>
  );
};

export default GrowthStagesLegend;
