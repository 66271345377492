import moment from 'moment';

import { getErrorMessage } from '../../services/errors';
import {
  addGrowthFeedbackActionTypes,
  deleteGrowthFeedbackActionTypes,
  updateGrowthFeedbackActionTypes
} from '../actionTypes/growthsFeedbackActionTypes';
import { requestStatus } from '../helpers/requestStatus';

export const growthsFeedbackReducer = (state = {}, action) => {
  switch (action.type) {
    case addGrowthFeedbackActionTypes.REQUEST:
      return {
        ...state,
        addGrowthFeedback: {
          status: requestStatus.IN_PROGRESS
        }
      };
    case addGrowthFeedbackActionTypes.SUCCEEDED:
      return {
        ...state,
        addGrowthFeedback: {
          status: requestStatus.SUCCESS,
          feedback: action.payload.response,
          updatedAt: moment().toISOString()
        }
      };
    case addGrowthFeedbackActionTypes.FAILED:
      return {
        ...state,
        addGrowthFeedback: {
          status: requestStatus.FAILURE,
          error: {
            message: getErrorMessage(action.payload.apiError)
          }
        }
      };
    case addGrowthFeedbackActionTypes.CLEAR:
      return {
        ...state,
        addGrowthFeedback: {}
      };
    case updateGrowthFeedbackActionTypes.REQUEST:
      return {
        ...state,
        updateGrowthFeedback: {
          status: requestStatus.IN_PROGRESS
        }
      };
    case updateGrowthFeedbackActionTypes.SUCCEEDED:
      return {
        ...state,
        updateGrowthFeedback: {
          status: requestStatus.SUCCESS,
          feedback: action.payload.response,
          updatedAt: moment().toISOString()
        }
      };
    case updateGrowthFeedbackActionTypes.FAILED:
      return {
        ...state,
        updateGrowthFeedback: {
          status: requestStatus.FAILURE,
          error: {
            message: getErrorMessage(action.payload.apiError)
          }
        }
      };
    case updateGrowthFeedbackActionTypes.CLEAR:
      return {
        ...state,
        updateGrowthFeedback: {}
      };
    case deleteGrowthFeedbackActionTypes.REQUEST:
      return {
        ...state,
        deleteGrowthFeedback: {
          status: requestStatus.IN_PROGRESS
        }
      };
    case deleteGrowthFeedbackActionTypes.SUCCEEDED:
      return {
        ...state,
        deleteGrowthFeedback: {
          status: requestStatus.SUCCESS,
          updatedAt: moment().toISOString()
        }
      };
    case deleteGrowthFeedbackActionTypes.FAILED:
      return {
        ...state,
        deleteGrowthFeedback: {
          status: requestStatus.FAILURE,
          error: {
            message: getErrorMessage(action.payload.apiError)
          }
        }
      };
    case deleteGrowthFeedbackActionTypes.CLEAR:
      return {
        ...state,
        deleteGrowthFeedback: {}
      };
    default:
      return state;
  }
};
