import { useMemo } from 'react';

import { growerDevicesService } from '../../../../services/growerDevices.service';
import { createApiSlice } from '../../utils/createApiSlice';

const createGrowerDeviceSlice = createApiSlice({
  reducerPath: ['devices', 'growerDevices', 'createGrowerDevice'],
  apiFunction: growerDevicesService.createGrowerDevicesV2
});

const { actions, useApiSelectors } = createGrowerDeviceSlice;

export const createGrowerDeviceActions = actions;

export const useCreateGrowerDeviceSelectors = () => {
  const { inProgress, success, errorMessage, response, updatedAt } =
    useApiSelectors();

  return useMemo(
    () => ({ inProgress, success, errorMessage, response, updatedAt }),
    [errorMessage, inProgress, response, success, updatedAt]
  );
};

export default createGrowerDeviceSlice;
