import React, { useContext, useEffect, useMemo, useState } from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Alert from '@material-ui/lab/Alert';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import { useResizeDetector } from 'react-resize-detector';

import { FarmStructureContext } from '../../_context/FarmStructureContext';
import { updateFieldAccessibility } from '../../_store/actions/fieldAccessibilityActions';
import { useAllGrowerFieldAccessibilitySelectors } from '../../_store/selectors/fieldAccessibilitySelectors';
import { useCropDashboardSelectors } from '../../_store/slices/ui/cropDashboardSlice';

import FieldAccessibilityTimelineChart from './FieldAccessibilityTimelineChart';
import FieldAccessibilityTimelineSelector from './FieldAccessibilityTimelineSelector';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%'
  },
  timelineContainer: {
    position: 'relative',
    width: '100%'
  },
  svg: {
    position: 'absolute'
  }
}));

const FieldAccessibility = () => {
  const styles = useStyles();
  const dispatch = useDispatch();

  const { width, ref: containerRef } = useResizeDetector();

  const {
    selectedGrowerId,
    selectedSeasonId,
    selectedFarmId,
    isActiveSeason,
    farms
  } = useContext(FarmStructureContext);

  const { showAllForGrower } = useCropDashboardSelectors();

  const [rangeSelection, setRangeSelection] = useState();
  const handleRangeSelected = useMemo(
    () => _.debounce(setRangeSelection, 10),
    []
  );

  const { inProgress, fieldAccessibility, minDate, maxDate } =
    useAllGrowerFieldAccessibilitySelectors();

  const noData = !minDate || !maxDate;

  useEffect(() => {
    if (
      selectedGrowerId &&
      selectedSeasonId &&
      isActiveSeason &&
      !_.isEmpty(farms)
    ) {
      if (showAllForGrower) {
        dispatch(updateFieldAccessibility(selectedGrowerId, selectedSeasonId));
      }
      if (!showAllForGrower && selectedFarmId) {
        dispatch(
          updateFieldAccessibility(
            selectedGrowerId,
            selectedSeasonId,
            selectedFarmId
          )
        );
      }
    }
  }, [
    dispatch,
    selectedGrowerId,
    farms,
    selectedSeasonId,
    isActiveSeason,
    showAllForGrower,
    selectedFarmId
  ]);

  return (
    <div className={styles.container}>
      <div className={styles.timelineContainer} ref={containerRef}>
        {!inProgress && !noData && !_.isNaN(width) && _.isNumber(width) && (
          <>
            <FieldAccessibilityTimelineChart
              containerWidth={width}
              fieldAccessibility={fieldAccessibility}
              minDate={minDate}
              maxDate={maxDate}
              rangeSelection={rangeSelection}
            />
            <FieldAccessibilityTimelineSelector
              fieldAccessibility={fieldAccessibility}
              containerWidth={width}
              minDate={minDate}
              maxDate={maxDate}
              onRangeSelected={handleRangeSelected}
            />
          </>
        )}
      </div>
      {!inProgress && noData && (
        <Alert severity="info">
          There is no Field Accessibility data currently available for the
          selected Grower. Please try again later.
        </Alert>
      )}
      {inProgress && <CircularProgress />}
    </div>
  );
};

export default FieldAccessibility;
