export const DefaultPumpStateColor = '#bdbdbd';
export const DefaultPumpStateTextColor = '#000000';

export const PumpStates = {
  0: { name: 'Idle', color: '#8bc34a', textColor: '#000000' },
  1: { name: 'Running', color: '#1e88e5', textColor: '#ffffff' },
  2: { name: 'Startup', color: '#aed581', textColor: '#000000' },
  3: { name: 'Warmup', color: '#aed581', textColor: '#000000' },
  4: { name: 'Cooldown', color: '#aed581', textColor: '#000000' },
  5: { name: 'Lockout', color: '#aed581', textColor: '#000000' },
  6: {
    name: 'Emergency Switch Activated',
    color: '#9c27b0',
    textColor: '#ffffff'
  },
  7: { name: 'Charging', color: '#aed581', textColor: '#000000' },
  8: { name: 'VFD Comms Timeout', color: '#b71c1c', textColor: '#ffffff' },
  9: { name: 'Ignition Off' },
  10: { name: 'Solar Drip Enabled' },
  100: { name: 'Unit Timed Out', color: '#d32f2f', textColor: '#ffffff' },
  1000: { name: 'No Data Available' },
  2000: { name: 'Communication Error', color: '#d32f2f', textColor: '#ffffff' }
};

export const PumpStateTypes = {
  Idle: 0,
  Running: 1
};

export const PumpCommands = {
  Start: {
    type: 0,
    name: 'Start',
    verb: 'Starting',
    color: '#1e88e5',
    textColor: '#ffffff'
  },
  Stop: {
    type: 1,
    name: 'Stop',
    verb: 'Stopping',
    color: '#8bc34a',
    textColor: '#000000'
  },
  SetRpm: {
    type: 2,
    name: 'Set RPMs',
    verb: 'Setting RPMs',
    color: '#aed581',
    textColor: '#000000'
  }
};

export const CommandStatus = {
  0: {
    name: 'New',
    color: '#d4d4d4',
    textColor: '#000000'
  },
  1: {
    name: 'Rejected',
    color: '#d32f2f',
    textColor: '#ffffff'
  },
  2: {
    name: 'Accepted',
    color: '#b5d78f',
    textColor: '#000000'
  },
  3: {
    name: 'Verification',
    color: '#b5d78f',
    textColor: '#000000'
  },
  4: {
    name: 'Success',
    color: '#8bc34a',
    textColor: '#000000'
  },
  5: {
    name: 'Failure',
    color: '#d32f2f',
    textColor: '#ffffff'
  },
  6: {
    name: 'Terminated',
    color: '#606060',
    textColor: '#ffffff'
  }
};

export const PumpType = {
  Unknown: 0,
  Electric: 1,
  Diesel: 2
};
