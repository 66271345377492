import { useContext, useEffect, useMemo } from 'react';

import _ from 'lodash';
import { useDispatch } from 'react-redux';

import { FarmStructureContext } from '../../../_context/FarmStructureContext';
import { useActiveGrowthSelectors } from '../../../_store/selectors/growthsSelectors';
import {
  getSnapshotActions,
  useGetSnapshotSelectors
} from '../../../_store/slices/growths/snapshots/getSnapshotSlice';
import { useGrowthsTimelineActivities } from '../../Activities/_hooks/useGrowthsTimelineActivities';

function useSnapshot({ activeGrowth, selectedSnapshot }) {
  const dispatch = useDispatch();

  const growerId = _.get(activeGrowth, 'growerId');
  const seasonId = _.get(activeGrowth, 'seasonId');
  const farmId = _.get(activeGrowth, 'farmId');
  const fieldId = _.get(activeGrowth, 'fieldId');
  const growthId = _.get(activeGrowth, 'id');
  const snapshotId = _.get(selectedSnapshot, 'snapshotId');
  const snapshotName = _.get(selectedSnapshot, 'name');

  const { snapshot, inProgress, errorMessage, success } =
    useGetSnapshotSelectors(snapshotId);

  useEffect(() => {
    if (
      growerId &&
      seasonId &&
      farmId &&
      fieldId &&
      growthId &&
      snapshotId &&
      !success
    ) {
      dispatch(
        getSnapshotActions.submit({
          growerId,
          seasonId,
          farmId,
          fieldId,
          growthId,
          snapshotId
        })
      );
    }
  }, [
    dispatch,
    farmId,
    fieldId,
    growerId,
    growthId,
    seasonId,
    snapshotId,
    success
  ]);

  const growth = useMemo(() => {
    if (!success || !snapshot) {
      return undefined;
    }

    return {
      ...activeGrowth,
      feedback: _.get(snapshot, 'growthFeedback'),
      ...snapshot,
      fieldName: snapshotName,
      id: snapshotId
    };
  }, [activeGrowth, snapshot, snapshotId, snapshotName, success]);

  return useMemo(
    () => ({ growth, inProgress, success, errorMessage }),
    [errorMessage, growth, inProgress, success]
  );
}

export function useGrowthModelSnapshots({
  firstSelectedSnapshot,
  secondSelectedSnapshot
}) {
  const { selectedSeasonId, selectedFieldId } =
    useContext(FarmStructureContext);

  const {
    isLoading: activeGrowthInProgress,
    growth: activeGrowth,
    errorMessage: activeGrowthErrorMessage
  } = useActiveGrowthSelectors(selectedSeasonId, selectedFieldId);

  const {
    growth: firstGrowth,
    inProgress: firstGrowthInProgress,
    errorMessage: firstGrowthErrorMessage
  } = useSnapshot({ activeGrowth, selectedSnapshot: firstSelectedSnapshot });

  const {
    growth: secondGrowth,
    inProgress: secondGrowthInProgress,
    errorMessage: secondGrowthErrorMessage
  } = useSnapshot({ activeGrowth, selectedSnapshot: secondSelectedSnapshot });

  const inProgress =
    activeGrowthInProgress || firstGrowthInProgress || secondGrowthInProgress;

  const errorMessage =
    activeGrowthErrorMessage ||
    firstGrowthErrorMessage ||
    secondGrowthErrorMessage;

  const growths = useMemo(
    () => _.filter([firstGrowth, secondGrowth], (item) => !!item),
    [firstGrowth, secondGrowth]
  );

  const { timelineActivities, minDate, maxDate } = useGrowthsTimelineActivities(
    { inProgress, growths }
  );

  return useMemo(
    () => ({
      activeGrowth,
      activeGrowthInProgress,
      timelineActivities,
      inProgress,
      minDate,
      maxDate,
      errorMessage
    }),
    [
      activeGrowth,
      activeGrowthInProgress,
      errorMessage,
      inProgress,
      maxDate,
      minDate,
      timelineActivities
    ]
  );
}
