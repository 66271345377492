import React, { PureComponent } from 'react';

const iconStyle = {
  cursor: 'pointer',
  stroke: 'none'
};

export default class MoistureIcon extends PureComponent {
  render() {
    const { size = 20, onClick, style } = this.props;

    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        id="svg"
        width={size}
        height={size}
        viewBox="0 0 100 100"
        style={{ ...iconStyle, ...style }}
        onClick={onClick}
      >
        <polygon points="40,17 42,5 44,17" fill="gold" />
        <circle cx="42" cy="6" r="2" fill="gold" />
        <rect x="35" y="17" rx="5" ry="5" width="31" height="20" fill="black" />
        <rect x="32.5" y="23" width="36" height="62" fill="#009688" />
        {/* <!--  green: #009688; blue: #0097A7, top cap: #607D8B  --> */}
        <rect
          x="28"
          y="23"
          rx="3"
          ry="3"
          width="45"
          height="12"
          fill="#00BCD4"
        />
        <rect x="32.5" y="59" width="36" height="1" fill="springgreen" />
        <rect
          x="32.5"
          y="78"
          rx="3"
          ry="3"
          width="36"
          height="12"
          fill="springgreen"
        />
        <rect
          x="32.5"
          y="60"
          width="36"
          height="26"
          fill="#5C6BC0"
          fillOpacity="1.0"
        />{' '}
        {/*<!-- water -->*/}
        {/* <!-- row #1 --> */}
        <circle id="circle" cx="40" cy="64" r="2" fill="white" />
        <use href="#circle" transform="translate(7)" />
        <use href="#circle" transform="translate(14)" />
        <use href="#circle" transform="translate(21)" />
        {/* <!-- row #2 --> */}
        <use href="#circle" transform="translate(3 6)" />
        <use href="#circle" transform="translate(10 6)" />
        <use href="#circle" transform="translate(17 6)" />
        {/* <!-- row #3 --> */}
        <use href="#circle" transform="translate(0 12)" />
        <use href="#circle" transform="translate(7 12)" />
        <use href="#circle" transform="translate(14 12)" />
        <use href="#circle" transform="translate(21 12)" />
        {/* <!-- row #4 --> */}
        <use href="#circle" transform="translate(3 18)" />
        <use href="#circle" transform="translate(10 18)" />
        <use href="#circle" transform="translate(17 18)" />
      </svg>
    );
  }
}
