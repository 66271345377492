import React, { useCallback } from 'react';

import Box from '@material-ui/core/Box';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import _ from 'lodash';
import { useDispatch } from 'react-redux';

import { useSeasonsConfig } from '../../_store/selectors/configSelectors';
import {
  globalSeasonActions,
  useGlobalSeasonSelectors
} from '../../_store/slices/gff/seasons/globalSeasonSlice';

const useStyles = makeStyles(() => ({
  drawerTitle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    minHeight: '3rem',
    paddingLeft: '1.5rem',
    paddingRight: '1.5rem',
    backgroundColor: '#2E4057',
    borderBottom: '#D1D1D180 1px solid',
    '&:hover': {
      backgroundColor: '#445468',
      color: '#F8F8F8'
    }
  },
  title: {
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: '#D1D1D1',
    marginLeft: '0.25rem'
  },
  arrow: { color: '#F8F8F8' }
}));

const DrawerSeasonMenu = () => {
  const styles = useStyles();
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const { seasons } = useSeasonsConfig();
  const { selectedSeason } = useGlobalSeasonSelectors();

  const open = !!anchorEl;

  const handleOpen = useCallback((e) => {
    setAnchorEl(e.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleSelect = useCallback(
    (year) => {
      dispatch(globalSeasonActions.selectSeason({ season: year }));
      handleClose();
    },
    [dispatch, handleClose]
  );

  return (
    <>
      <Box className={styles.drawerTitle} onClick={handleOpen}>
        <Typography className={styles.title}>
          Season {selectedSeason}
        </Typography>
        {open && <ExpandLessIcon className={styles.arrow} />}
        {!open && <ExpandMoreIcon className={styles.arrow} />}
      </Box>

      <Menu
        open={open}
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        onClose={handleClose}
        transformOrigin={{ horizontal: 'center', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
      >
        {_.map(seasons, (season) => (
          <MenuItem
            key={_.get(season, 'seasonYear')}
            onClick={() => handleSelect(_.get(season, 'seasonYear'))}
          >
            {_.get(season, 'seasonYear')}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default DrawerSeasonMenu;
