import React, { useMemo } from 'react';

import makeStyles from '@material-ui/core/styles/makeStyles';
import moment from 'moment';

const useStyles = makeStyles(() => ({
  line: {
    opacity: 0.875
  }
}));

const ChartDateLine = ({
  paddingTop,
  height,
  selectedTimeScale,
  lineWidth,
  date,
  color = 'black'
}) => {
  const styles = useStyles();

  const x = useMemo(
    () => selectedTimeScale(moment(date).toDate()),
    [date, selectedTimeScale]
  );

  return (
    <rect
      fill={color}
      height={height}
      width={lineWidth}
      x={x}
      y={paddingTop}
      className={styles.line}
    />
  );
};

export default ChartDateLine;
