import { useContext, useMemo } from 'react';

import _ from 'lodash';

import { AuthContext } from '../../_context/AuthContext';
import { FarmStructureContext } from '../../_context/FarmStructureContext';

export function canModifyFeedback(stage, isUserView, isHistoricalSeason) {
  const addFeedbackAllowed = _.get(stage, 'addFeedbackAllowed');
  const editFeedbackAllowed = _.get(stage, 'editFeedbackAllowed');

  return (
    (addFeedbackAllowed || editFeedbackAllowed) &&
    !isUserView &&
    !isHistoricalSeason
  );
}

export function useCanModifyFeedback(stage) {
  const { isUserView } = useContext(AuthContext);

  const { isHistoricalSeason } = useContext(FarmStructureContext);

  const isFeedbackModificationAllowed = canModifyFeedback(
    stage,
    isUserView,
    isHistoricalSeason
  );

  return useMemo(
    () => ({
      isFeedbackModificationAllowed
    }),
    [isFeedbackModificationAllowed]
  );
}
