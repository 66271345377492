import { useMemo } from 'react';

import { reportsService } from '../../../../services/reports.service';
import { createApiSlice } from '../../utils/createApiSlice';

const updateSavedFilterSlice = createApiSlice({
  reducerPath: ['reports', 'savedFilters', 'updateSavedFilter'],
  apiFunction: reportsService.updateSavedFilter,
  apiArgumentsFn: ({ seasonYear, filterId, name }) => [
    seasonYear,
    filterId,
    name
  ]
});

const { actions, useApiSelectors } = updateSavedFilterSlice;

export const updateSavedFilterActions = actions;

export const useUpdateSavedFilterSelectors = () => {
  const { inProgress, errorMessage, success, response } = useApiSelectors();

  return useMemo(
    () => ({
      inProgress,
      errorMessage,
      success,
      response
    }),
    [errorMessage, inProgress, response, success]
  );
};

export default updateSavedFilterSlice;
