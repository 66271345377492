import React, { useMemo } from 'react';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Line } from 'react-chartjs-2';

import { generateLabels } from '../../IrrigationDashboard/Components/sensorChartsUtils';

import {
  commonLineDataSetParams,
  getDefaultOptions,
  getValue
} from './weatherChartUtils';

const HumidityChart = ({
  chartRef,
  weatherData,
  timeUnit,
  startDate,
  endDate
}) => {
  const highChart = useMediaQuery((theme) => theme.breakpoints.down('xs'));

  const graphData = useMemo(() => {
    if (weatherData) {
      // Daily chart
      const maxHumidityValues = getValue(weatherData, 'relativeHumidityMax');
      const avgHumidityValues = getValue(weatherData, 'relativeHumidityAvg');
      const minHumidityValues = getValue(weatherData, 'relativeHumidityMin');

      // Hourly chart
      const humidityValues = getValue(weatherData, 'relativeHumidity');

      const datasets = [
        {
          ...commonLineDataSetParams('#ab47bc'),
          label: 'Max Humidity',
          type: 'line',
          data: maxHumidityValues
        },
        {
          ...commonLineDataSetParams('#8e24aa'),
          label: 'Average Humidity',
          type: 'line',
          data: avgHumidityValues
        },
        {
          ...commonLineDataSetParams('#ce93d8'),
          label: 'Min Humidity',
          fill: maxHumidityValues && minHumidityValues ? '-2' : undefined,
          type: 'line',
          data: minHumidityValues
        },
        {
          ...commonLineDataSetParams('#8e24aa'),
          label: 'Humidity',
          type: 'line',
          data: humidityValues
        }
      ].filter((dataset) => dataset.data);

      const labels = generateLabels(datasets, startDate, endDate);

      return {
        labels,
        datasets
      };
    }
  }, [endDate, startDate, weatherData]);

  return (
    <>
      {graphData && highChart && (
        <Line
          ref={chartRef}
          data={graphData}
          options={getDefaultOptions(timeUnit)}
          height={250}
          type="scatter"
        />
      )}
      {graphData && !highChart && (
        <Line
          ref={chartRef}
          data={graphData}
          options={getDefaultOptions(timeUnit)}
          height={120}
          type="scatter"
        />
      )}
    </>
  );
};

export default HumidityChart;
