import React, { useCallback, useState } from 'react';

import { DialogActions } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import BackupIcon from '@material-ui/icons/Backup';
import _ from 'lodash';
import { useDropzone } from 'react-dropzone';
import { useDispatch } from 'react-redux';

import { saveUploadedFieldShapeActions } from '../../../_store/slices/gff/fields/saveUploadedFieldShapeSlice';
import { shapefileUploadDialogActions } from '../../../_store/slices/ui/dialogs/shapefileUploadDialogSlice';
import {
  kmlToGeojson,
  sanitizeGeojson,
  shapefileToGeojson
} from '../../../_utilities/geojson/geojsonConversionTools';
import ResponsiveDialogContent from '../../ResponsiveDialogContent';

const useStyles = makeStyles(() => ({
  errorMessage: {
    marginTop: '0.5rem',
    color: 'red'
  },
  dropzone: {
    cursor: 'pointer',
    padding: '2rem',
    border: '2px dashed #808080',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  title: {
    fontWeight: 'bold',
    marginBottom: '0.5rem'
  },
  actions: {
    marginTop: '1rem'
  },
  icon: {
    fontSize: '3rem',
    marginTop: '1rem',
    marginBottom: '1rem'
  }
}));

const UploadShapefile = () => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const [readError, setReadError] = useState();

  const onDrop = useCallback(() => {
    setReadError(null);
  }, []);

  const { acceptedFiles, fileRejections, getRootProps, getInputProps } =
    useDropzone({
      onDrop,
      multiple: false,
      accept: '.kml,.geojson,.shp'
    });

  const file = _.first(acceptedFiles);
  const fileName = _.get(file, 'name');
  const isKml = fileName && fileName.toLowerCase().endsWith('.kml');
  const isShp = fileName && fileName.toLowerCase().endsWith('.shp');
  const isGeojson = fileName && fileName.toLowerCase().endsWith('.geojson');

  const error = _.first(fileRejections) || readError;
  const errorMessage = _.get(error, 'errors.0.message') || readError;

  const handleCancel = useCallback(() => {
    dispatch(shapefileUploadDialogActions.toggle());
  }, [dispatch]);

  const handleSave = useCallback(() => {
    const reader = new FileReader();

    reader.onabort = () => console.error('File reading was aborted');
    reader.onerror = () => setReadError('Error reading uploaded file');

    reader.onload = async () => {
      try {
        let geoJson = null;
        if (isShp) {
          geoJson = await shapefileToGeojson(reader.result);
        } else if (isGeojson) {
          geoJson = sanitizeGeojson(JSON.parse(reader.result));
        } else if (isKml) {
          geoJson = kmlToGeojson(reader.result);
        }

        if (!geoJson) {
          setReadError('Error parsing uploaded file');
          return;
        }
        dispatch(
          saveUploadedFieldShapeActions.saveGeojson({ geojson: geoJson })
        );
        dispatch(shapefileUploadDialogActions.toggle());
      } catch (e) {
        console.error('Error parsing uploaded file', e);
        setReadError(e.message);
      }
    };

    if (isKml || isGeojson) {
      reader.readAsText(file);
    } else if (isShp) {
      reader.readAsArrayBuffer(file);
    }
  }, [dispatch, file, isGeojson, isKml, isShp]);

  return (
    <>
      <DialogTitle>Upload Shapefile</DialogTitle>
      <ResponsiveDialogContent>
        <Box className={styles.dropzone} {...getRootProps()}>
          <input {...getInputProps()} />
          <>
            <Typography className={styles.title}>
              Drag and drop some files here, or click to select files
            </Typography>
            <Typography>
              Only .kml, .geojson and .shp files are supported
            </Typography>
            <BackupIcon className={styles.icon} />
          </>
          {fileName && (
            <Typography className={styles.title}>{fileName}</Typography>
          )}
        </Box>
        {error && (
          <Typography className={styles.errorMessage}>
            {errorMessage}
          </Typography>
        )}
      </ResponsiveDialogContent>
      <DialogActions className={styles.actions}>
        <Button onClick={handleCancel} variant="contained" color="default">
          Cancel
        </Button>
        <Button
          onClick={handleSave}
          disabled={!file}
          variant="contained"
          color="primary"
        >
          Save
        </Button>
      </DialogActions>
    </>
  );
};

export default UploadShapefile;
