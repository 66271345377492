import React, { useMemo } from 'react';

import _ from 'lodash';
import moment from 'moment';

import CsvDownloadButton, { csvDateFormat } from '../CsvDownloadButton';

const header = [
  'Farm',
  'Field',
  'Technology',
  'Seed Product',
  'Acres',
  'Planting Date',
  'Physiological Maturity Date',
  'Estimated Harvest Date'
];

const GrowerHarvestAdvisorCsvDownload = ({ grower, fields }) => {
  const filename = `Harvest Schedule - ${_.get(grower, 'name')}`;

  const csvData = useMemo(
    () =>
      _.map(fields, (field) => {
        const plantingDate = _.get(field, 'plantingDate');
        const physiologicalMaturityDate = _.get(
          field,
          'physiologicalMaturityDate'
        );
        const harvestDate = _.get(field, 'harvestDate');

        return [
          _.get(field, 'farmName'),
          _.get(field, 'name'),
          _.get(field, 'technologyName'),
          _.get(field, 'varietyName'),
          _.get(field, 'acres'),
          plantingDate ? moment(plantingDate).format(csvDateFormat) : '',
          physiologicalMaturityDate
            ? moment(physiologicalMaturityDate).format(csvDateFormat)
            : '',
          harvestDate ? moment(harvestDate).format(csvDateFormat) : ''
        ];
      }),
    [fields]
  );

  return (
    <>
      <CsvDownloadButton
        filename={filename}
        header={header}
        dataArray={csvData}
      />
    </>
  );
};

export default GrowerHarvestAdvisorCsvDownload;
