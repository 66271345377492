import commandsSlice from './commands';
import growerDevicesSlices from './growerDevices';
import seasonDevicesSlices from './seasonDevices';
import seasonFieldDevicesSlices from './seasonFieldDevices';
import devicesStateSlices from './state';

const devicesSlices = [
  growerDevicesSlices,
  seasonFieldDevicesSlices,
  seasonDevicesSlices,
  devicesStateSlices,
  commandsSlice
];

export default devicesSlices;
