import React, { useEffect, useState } from 'react';

import makeStyles from '@material-ui/core/styles/makeStyles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import _ from 'lodash';
import { Bar } from 'react-chartjs-2';

import ContentBody from '../../../Layout/Content/ContentBody';
import ContentContainer from '../../../Layout/Content/ContentContainer';

import { commonLineDataSetParams, getValue } from './weatherChartUtils';

const options = {
  responsive: true,
  tooltips: {
    mode: 'label'
  },
  legend: {
    display: false
  },
  scales: {
    'x-axis': {
      type: 'time',
      time: {
        unit: 'day',
        displayFormats: {
          day: 'ddd'
        },
        tooltipFormat: 'LL'
      },
      display: true,
      gridLines: {
        display: true
      },
      ticks: {
        autoSkip: true
      }
    },
    'y-axis-temp': {
      type: 'linear',
      display: true,
      position: 'left',
      id: 'y-axis-temp',
      gridLines: {
        display: true
      }
    },
    'y-axis-precip': {
      type: 'linear',
      display: true,
      position: 'right',
      id: 'y-axis-precip',
      gridLines: {
        display: false
      }
    }
  }
};

const useStyles = makeStyles(() => ({
  icons: {
    display: 'flex',
    justifyContent: 'space-between',
    marginLeft: '8%',
    marginRight: '8%'
  },
  descriptorImage: {
    width: '2rem',
    height: '2rem'
  }
}));

const ForecastWidget = ({ dailyForecast }) => {
  const styles = useStyles();

  const [graphData, setGraphData] = useState();
  const highChart = useMediaQuery((theme) => theme.breakpoints.down('xs'));

  useEffect(() => {
    if (dailyForecast) {
      const precipitationValues = getValue(dailyForecast, 'precipAccPeriod');
      const maxTempValues = getValue(dailyForecast, 'airTempMax');
      const minTempValues = getValue(dailyForecast, 'airTempMin');

      const datasets = [
        {
          ...commonLineDataSetParams('#ef5350'),
          label: 'Max Temperature',
          yAxisID: 'y-axis-temp',
          data: maxTempValues
        },
        {
          ...commonLineDataSetParams('#ef9a9a'),
          label: 'Min Temperature',
          yAxisID: 'y-axis-temp',
          fill: '-1',
          data: minTempValues
        },
        {
          label: 'Precipitation',
          yAxisID: 'y-axis-precip',
          type: 'bar',
          backgroundColor: '#66bb6a66',
          borderColor: '#66bb6a',
          data: precipitationValues
        }
      ].filter((dataset) => dataset.data);

      const data = {
        labels: _.map(dailyForecast, (forecast) => _.get(forecast, 'date')),
        datasets
      };
      setGraphData(data);
    }
  }, [dailyForecast]);

  return (
    <>
      {graphData && (
        <ContentContainer>
          <ContentBody>
            <div className={styles.icons}>
              {_.map(dailyForecast, (forecast, idx) => (
                <img
                  key={idx}
                  className={styles.descriptorImage}
                  alt="Forecast weather icon"
                  src={_.get(forecast, 'iconUrl')}
                />
              ))}
            </div>
            {highChart && (
              <Bar data={graphData} options={options} height={125} type="bar" />
            )}
            {!highChart && (
              <Bar data={graphData} options={options} height={50} type="bar" />
            )}
          </ContentBody>
        </ContentContainer>
      )}
    </>
  );
};

export default ForecastWidget;
