import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';

export const createDataSlice = ({ reducerPath, name, ...options }) => {
  const dataSlice = createSlice({ name: _.join(reducerPath, '/'), ...options });

  const { actions, reducer } = dataSlice;

  const stateBasePath = _.join(reducerPath, '.');
  const baseSelector = (state) => _.get(state, stateBasePath);

  return {
    actions,
    baseSelector,
    reducerDescriptor: {
      reducer,
      path: reducerPath
    }
  };
};
