import { useContext, useMemo } from 'react';

import _ from 'lodash';
import tinycolor from 'tinycolor2';

import { inactiveBackgroundColor } from '../../../../_constants/defaultColors';
import { growthStagesTypes } from '../../../../_constants/growthsConstants';
import { FarmStructureContext } from '../../../../_context/FarmStructureContext';
import { getActiveGrowth } from '../../../../_hooks/Growths/useActiveGrowth';
import {
  useGrowthStagesConfig,
  useTechnologiesConfig,
  useVarietiesConfig
} from '../../../../_store/selectors/configSelectors';
import { useAllGrowerFieldsSelector } from '../../../../_store/selectors/fieldsSelectors';
import { useGrowthModelSelectors } from '../../../../_store/selectors/uiSelectors';
import { getFieldAccessibilityColor } from '../../../../_utilities/FieldAccessibility/fieldAccessibilityColors';

const defaultFillColor = 'rgba(200, 200, 200, 0.5)';
const defaultOutlineColor = 'rgba(255, 255, 255, 0.5)';

const weatherOutlineColor = '#76ff03';
const weatherFillColor = tinycolor(weatherOutlineColor)
  .setAlpha(0.6)
  .toString();

export const defaultTechnologyColor = '#D5D5D5';

export const useFieldsFeatureCollection = (zoomToField) => {
  const { growthStagesConfig } = useGrowthStagesConfig();
  const { selectedGrowthModel } = useGrowthModelSelectors();

  const { selectedField, selectedFieldId } = useContext(FarmStructureContext);

  const { varieties } = useVarietiesConfig();
  const { technologies } = useTechnologiesConfig();

  const varietiesById = useMemo(
    () => _.keyBy(varieties, (variety) => _.get(variety, 'id')),
    [varieties]
  );

  const technologiesById = useMemo(
    () => _.keyBy(technologies, (technology) => _.get(technology, 'id')),
    [technologies]
  );

  const { fields: growerFields } = useAllGrowerFieldsSelector(true);

  const fieldsFeatureCollection = useMemo(() => {
    const features = _.map(growerFields, (field) => {
      const isSelected = _.get(field, 'id') === selectedFieldId;

      const activeGrowth = getActiveGrowth(field, selectedGrowthModel);

      const growthStage = _.get(
        activeGrowth,
        'estimatedCurrentGrowthStage',
        growthStagesTypes.Unknown
      );

      const growthStageFillColor = _.get(growthStagesConfig, [
        growthStage,
        'fillColor'
      ]);
      const growthStageOutlineColor = _.get(growthStagesConfig, [
        growthStage,
        'outlineColor'
      ]);

      const fieldAccessibilityValue = _.get(
        field,
        'currentFieldAccessibility.value'
      );

      const fieldAccessibilityOutlineColor =
        !!fieldAccessibilityValue || fieldAccessibilityValue === 0
          ? getFieldAccessibilityColor(fieldAccessibilityValue)
          : inactiveBackgroundColor;

      const fieldAccessibilityFillColor = tinycolor(
        fieldAccessibilityOutlineColor
      )
        .setAlpha(0.5)
        .toString();

      const varietyId = _.get(field, 'activeGrowth.varietyId');
      const variety = _.get(varietiesById, [varietyId]);
      const technologyId = _.get(variety, 'technologyId');
      const technology = _.get(technologiesById, [technologyId]);
      const cropOutlineColor = _.get(
        technology,
        'color',
        defaultTechnologyColor
      );
      const cropFillColor = tinycolor(cropOutlineColor)
        .setAlpha(activeGrowth ? 0.5 : 0.25)
        .toString();

      if (zoomToField && !isSelected) {
        return {
          type: 'Feature',
          properties: {
            selected: field.id === _.get(selectedField, 'id'),
            growerId: _.get(field, 'growerId'),
            farmId: _.get(field, 'farmId'),
            fieldId: _.get(field, 'id'),
            growthStageFillColor: defaultFillColor,
            growthStageOutlineColor: defaultOutlineColor,
            weatherOutlineColor: defaultOutlineColor,
            weatherFillColor: defaultFillColor,
            fieldAccessibilityOutlineColor: defaultOutlineColor,
            fieldAccessibilityFillColor: defaultFillColor,
            cropOutlineColor: defaultOutlineColor,
            cropFillColor: defaultFillColor,
            activitiesOutlineColor: defaultOutlineColor,
            activitiesFillColor: defaultFillColor
          },
          geometry: _.get(field, 'shape')
        };
      }

      return {
        type: 'Feature',
        properties: {
          selected: field.id === _.get(selectedField, 'id'),
          growerId: _.get(field, 'growerId'),
          farmId: _.get(field, 'farmId'),
          fieldId: _.get(field, 'id'),
          growthStageFillColor,
          growthStageOutlineColor,
          weatherOutlineColor,
          weatherFillColor,
          fieldAccessibilityOutlineColor,
          fieldAccessibilityFillColor,
          cropOutlineColor,
          cropFillColor,
          activitiesOutlineColor: weatherOutlineColor,
          activitiesFillColor: weatherFillColor
        },
        geometry: _.get(field, 'shape')
      };
    });
    return { type: 'FeatureCollection', features };
  }, [
    growerFields,
    growthStagesConfig,
    selectedField,
    selectedFieldId,
    selectedGrowthModel,
    technologiesById,
    varietiesById,
    zoomToField
  ]);

  return useMemo(
    () => ({
      fieldsFeatureCollection,
      growerFields
    }),
    [fieldsFeatureCollection, growerFields]
  );
};
