import React, { useCallback } from 'react';

import IconButton from '@material-ui/core/IconButton';
import makeStyles from '@material-ui/core/styles/makeStyles';
import RefreshIcon from '@material-ui/icons/Refresh';
import moment from 'moment';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  updatedAt: {
    display: 'flex',
    alignItems: 'center'
  },
  refreshButton: {
    marginLeft: '0.5rem'
  }
}));

const LastUpdatedHeaderDetails = ({ updatedAt, inProgress, onRefresh }) => {
  const styles = useStyles();

  const handleRefresh = useCallback(async () => {
    await onRefresh();
  }, [onRefresh]);

  return (
    <div className={styles.container}>
      <div className={styles.updatedAt}>
        {updatedAt && (
          <>
            <span>Last updated: {moment(updatedAt).format('LLL')}</span>
            <IconButton
              className={styles.refreshButton}
              size="small"
              color="primary"
              variant="contained"
              onClick={handleRefresh}
              disabled={inProgress}
            >
              <RefreshIcon />
            </IconButton>
          </>
        )}
      </div>
    </div>
  );
};

export default LastUpdatedHeaderDetails;
