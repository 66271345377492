import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { DialogTitle, Input, MenuItem, Select } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import _ from 'lodash';
import moment from 'moment';
import DatePicker from 'react-datepicker';

import CreateUpdateDeleteDialogActions from '../CreateUpdateDeleteDialogActions';
import ResponsiveDialog from '../ResponsiveDialog';
import ResponsiveDialogContent from '../ResponsiveDialogContent';

import 'react-datepicker/dist/react-datepicker.css';

const TIMESPANS = [
  {
    key: '7_DAYS',
    label: '7 days',
    days: 7
  },
  {
    key: '14_DAYS',
    label: '14 days',
    days: 14
  },
  {
    key: '1_MONTH',
    label: '1 month',
    months: 1
  },
  {
    key: '2_MONTHS',
    label: '2 months',
    months: 2
  },
  {
    key: '3_MONTHS',
    label: '3 months',
    months: 3
  },
  {
    key: 'CUSTOM',
    label: 'custom'
  }
];

const useStyles = makeStyles(() => ({
  header: {
    width: '100%',
    textAlign: 'left',
    fontSize: '1rem',
    fontWeight: 'bold'
  },
  calendar: {
    marginBottom: '1rem'
  },
  customDays: {
    marginTop: '1rem'
  }
}));

const SelectDatesModal = ({
  startDate,
  setStartDate,
  setEndDate,
  firstAvailableDate,
  lastAvailableDate,
  timespan,
  setTimespan,
  customDays,
  setCustomDays,
  isOpen,
  toggle
}) => {
  const styles = useStyles();

  const [startDateCandidate, setStartDateCandidate] = useState(
    startDate ? new Date(startDate) : new Date(firstAvailableDate)
  );

  useEffect(() => {
    setStartDateCandidate(
      startDate ? new Date(startDate) : new Date(firstAvailableDate)
    );
  }, [firstAvailableDate, startDate]);

  const includeDates = useMemo(() => {
    const dates = [];
    const current = moment(firstAvailableDate).startOf('day');
    const last = moment(lastAvailableDate);
    while (current.isSameOrBefore(last)) {
      dates.push(new Date(current.toISOString()));
      current.add(1, 'days');
    }
    return dates;
  }, [firstAvailableDate, lastAvailableDate]);

  const handleDateChange = useCallback((date) => {
    setStartDateCandidate(date);
  }, []);

  const handleSpanChange = useCallback(
    (e) => {
      setTimespan(e.target.value);
    },
    [setTimespan]
  );

  const handleCustomDaysChange = useCallback(
    (e) => {
      setCustomDays(e.target.value);
    },
    [setCustomDays]
  );

  const handleSelect = useCallback(
    (e) => {
      e.preventDefault();
      const start = moment(startDateCandidate);
      let end = start.clone();
      const maxEnd = moment(lastAvailableDate);
      if (timespan === 'CUSTOM') {
        end.add(Number.parseInt(customDays), 'days');
      } else {
        const cfg = _.find(TIMESPANS, (t) => t.key === timespan);
        if (cfg.days) {
          end.add(cfg.days, 'days');
        }
        if (cfg.months) {
          end.add(cfg.months, 'months');
        }
      }
      if (end.isAfter(maxEnd)) {
        end = maxEnd;
      }
      setStartDate(start.format('YYYY-MM-DD'));
      setEndDate(end.format('YYYY-MM-DD'));
      toggle();
    },
    [
      customDays,
      setEndDate,
      setStartDate,
      startDateCandidate,
      toggle,
      timespan,
      lastAvailableDate
    ]
  );

  return (
    <ResponsiveDialog open={isOpen} onClose={toggle} maxWidth="xs">
      <DialogTitle>Select date range</DialogTitle>
      <form onSubmit={handleSelect}>
        <ResponsiveDialogContent>
          <div className={styles.header}>Start date:</div>
          <DatePicker
            calendarClassName={styles.calendar}
            selected={startDateCandidate}
            onChange={handleDateChange}
            inline
            includeDates={includeDates}
          />
          <div className={styles.header}>Time span:</div>
          <Select fullWidth value={timespan} onChange={handleSpanChange}>
            {_.map(TIMESPANS, (timespan) => (
              <MenuItem
                key={_.get(timespan, 'key')}
                value={_.get(timespan, 'key')}
              >
                {_.get(timespan, 'label')}
              </MenuItem>
            ))}
          </Select>
          {timespan === 'CUSTOM' && (
            <div className={styles.customDays}>
              <Input
                type="number"
                min={1}
                max={150}
                required
                value={customDays}
                onChange={handleCustomDaysChange}
                fullWidth
                endAdornment="days"
              />
            </div>
          )}
        </ResponsiveDialogContent>
        <CreateUpdateDeleteDialogActions
          confirmText="Select"
          onCancel={toggle}
          size="small"
        />
      </form>
    </ResponsiveDialog>
  );
};

export default SelectDatesModal;
