import React, { useContext } from 'react';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import cx from 'classnames';
import _ from 'lodash';
import moment from 'moment';

import { FarmStructureContext } from '../../_context/FarmStructureContext';
import { useCanModifyFeedback } from '../../_hooks/Growths/useCanModifyFeedback';
import { useGrowthStages } from '../../_hooks/Growths/useGrowthStages';

import FeedbackButton from './FeedbackButton';
import GrowthStageBadge from './GrowthStageBadge';

const useStyles = makeStyles(() => ({
  table: {
    maxWidth: '45rem'
  },
  stageObserved: {
    fontWeight: 'bold'
  }
}));

const GrowthModelRow = ({ stage, growerId, seasonId, farmId, fieldId }) => {
  const styles = useStyles();

  const { isHistoricalSeason } = useContext(FarmStructureContext);

  const growthStage = _.get(stage, 'growthStage');
  const growthModelDate = _.get(stage, 'date');
  const accumulatedGdd = _.get(stage, 'accumulatedGdd');

  const stageObserved = _.get(stage, 'observed');
  const stagePending = _.get(stage, 'pending');

  const { isFeedbackModificationAllowed } = useCanModifyFeedback(stage);

  return (
    <TableRow key={growthStage}>
      <TableCell>
        <GrowthStageBadge
          growthStage={growthStage}
          stageObserved={stageObserved}
          stagePending={stagePending}
        />
      </TableCell>
      <TableCell
        align="right"
        className={cx({ [styles.stageObserved]: !!stageObserved })}
      >
        {growthModelDate && moment(growthModelDate).format('ll')}
      </TableCell>
      <TableCell
        align="right"
        className={cx({ [styles.stageObserved]: !!stageObserved })}
      >
        {accumulatedGdd}
      </TableCell>
      {!isHistoricalSeason && (
        <TableCell width="30rem">
          {isFeedbackModificationAllowed && (
            <FeedbackButton
              growerId={growerId}
              seasonId={seasonId}
              farmId={farmId}
              fieldId={fieldId}
              stage={stage}
            />
          )}
        </TableCell>
      )}
    </TableRow>
  );
};

const GrowthModelTable = ({ growth, growerId, farmId, seasonId, fieldId }) => {
  const styles = useStyles();

  const { isHistoricalSeason } = useContext(FarmStructureContext);

  const { growthStages } = useGrowthStages(growth);

  return (
    <Table className={styles.table}>
      <TableHead>
        <TableRow>
          <TableCell align="center">Growth Stage</TableCell>
          <TableCell align="center">Estimated Date</TableCell>
          <TableCell align="center">Accumulated GDD</TableCell>
          {!isHistoricalSeason && <TableCell />}
        </TableRow>
      </TableHead>
      <TableBody>
        {_.map(growthStages, (stage) => (
          <GrowthModelRow
            key={_.get(stage, 'growthStage')}
            stage={stage}
            growerId={growerId}
            seasonId={seasonId}
            farmId={farmId}
            fieldId={fieldId}
          />
        ))}
      </TableBody>
    </Table>
  );
};

export default GrowthModelTable;
