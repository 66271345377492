import React, { useMemo } from 'react';

import { Accordion } from '@material-ui/core';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import _ from 'lodash';

import { growthStagesTypes } from '../../_constants/growthsConstants';
import { useActiveGrowth } from '../../_hooks/Growths/useActiveGrowth';
import { useAllGrowerFieldsSelector } from '../../_store/selectors/fieldsSelectors';
import GrowthStageBadge from '../../Components/Growths/GrowthStageBadge';
import SmallGrowthStageBadge from '../Growths/SmallGrowthStageBadge';

import FieldActivitiesDetails from './FieldActivitiesDetails';

const AccordionContent = ({ field }) => {
  return <FieldActivitiesDetails field={field} />;
};

const useHeaderStyles = makeStyles(() => ({
  header: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  headerBadge: {
    marginLeft: '0.25rem'
  }
}));

const AccordionHeader = ({ field }) => {
  const styles = useHeaderStyles();

  const showLargeBadge = useMediaQuery((theme) => theme.breakpoints.up('sm'));

  const { activeGrowth } = useActiveGrowth(field);

  const growthStage = useMemo(
    () =>
      _.get(
        activeGrowth,
        'estimatedCurrentGrowthStage',
        growthStagesTypes.Unknown
      ),
    [activeGrowth]
  );

  const stageObserved = useMemo(
    () => _.get(activeGrowth, 'stageObserved', false),
    [activeGrowth]
  );

  const stagePending = useMemo(
    () => _.get(activeGrowth, 'stagePending', false),
    [activeGrowth]
  );

  return (
    <Box className={styles.header}>
      <Typography>{_.get(field, 'name')}</Typography>
      <Box className={styles.headerBadge}>
        {showLargeBadge && (
          <GrowthStageBadge
            growthStage={growthStage}
            stageObserved={stageObserved}
          />
        )}
        {!showLargeBadge && (
          <SmallGrowthStageBadge
            growthStage={growthStage}
            stageObserved={stageObserved}
            stagePending={stagePending}
          />
        )}
      </Box>
    </Box>
  );
};

const ActivitiesAccordion = () => {
  const { fields } = useAllGrowerFieldsSelector();

  return (
    <>
      {_.map(fields, (field) => (
        <Accordion key={_.get(field, 'id')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            id={`${_.get(field, 'id')}-header`}
          >
            <AccordionHeader field={field} />
          </AccordionSummary>
          <AccordionDetails>
            <AccordionContent field={field} />
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  );
};

export default ActivitiesAccordion;
