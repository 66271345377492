import React, { useContext } from 'react';

import makeStyles from '@material-ui/core/styles/makeStyles';

import { AuthContext } from '../../../../../_context/AuthContext';
import EnvironmentNameBadge from '../../EnvironmentNameBadge';
import RightHeaderMenu from '../../RightHeaderMenu';
import StopAllButton from '../../StopAllButton';

const useStyles = makeStyles(() => ({
  headerContent: {
    display: 'flex',
    flex: '1 1',
    justifyContent: 'space-between',
    minWidth: 0
  },
  environmentName: {
    alignSelf: 'center'
  }
}));

const HeaderContent = () => {
  const styles = useStyles();

  const { isActive, tosAccepted } = useContext(AuthContext);

  return (
    <div className={styles.headerContent}>
      <EnvironmentNameBadge className={styles.environmentName} />
      {isActive && tosAccepted && <StopAllButton />}
      {(!isActive || !tosAccepted) && <div />}
      <RightHeaderMenu />
    </div>
  );
};

export default HeaderContent;
