import { useMemo } from 'react';

import { useSelector } from 'react-redux';

import { createDataSlice } from '../../utils/createDataSlice';

const saveUploadedFieldShapeSlice = createDataSlice({
  reducerPath: ['gff', 'fields', 'uploadedGeojson'],
  initialState: { geojson: null },
  reducers: {
    saveGeojson(state, action) {
      state.geojson = action.payload.geojson;
    },
    clearGeojson(state) {
      state.geojson = null;
    }
  }
});

const { actions, baseSelector: saveUploadedFieldShapeSelector } =
  saveUploadedFieldShapeSlice;

export const saveUploadedFieldShapeActions = actions;

export const useSaveUploadedFieldShapeSelectors = () => {
  const { geojson } = useSelector(saveUploadedFieldShapeSelector);

  return useMemo(
    () => ({
      geojson
    }),
    [geojson]
  );
};

export default saveUploadedFieldShapeSlice;
