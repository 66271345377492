import { addToken, client } from './client';

export const reportsService = {
  getReportsGrowers,
  getGrowthModelReport,
  getSavedFilters,
  postSavedFilter,
  deleteSavedFilter,
  updateSavedFilter
};

async function getReportsGrowers(token, seasonYear) {
  const response = await client.get(
    `/v2/reports/growers?seasonYear=${seasonYear}`,
    addToken(token)
  );
  return response.data;
}

async function getGrowthModelReport(token, fields) {
  const response = await client.post(
    `/v2/reports/growth-model`,
    { fields },
    addToken(token)
  );
  return response.data;
}

async function getSavedFilters(token) {
  const response = await client.get(`v2/reports/filters`, addToken(token));
  return response.data;
}

async function postSavedFilter(token, seasonYear, filter) {
  const response = await client.post(
    `v2/reports/filters/${seasonYear}`,
    filter,
    addToken(token)
  );
  return response.data;
}

async function deleteSavedFilter(token, seasonYear, filterId) {
  const response = await client.delete(
    `v2/reports/filters/${seasonYear}/${filterId}`,
    addToken(token)
  );
  return response.data;
}

async function updateSavedFilter(token, seasonYear, filterId, name) {
  const response = await client.patch(
    `v2/reports/filters/${seasonYear}/${filterId}`,
    { name },
    addToken(token)
  );
  return response.data;
}
