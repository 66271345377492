import React from 'react';

import Popover from '@material-ui/core/Popover';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import _ from 'lodash';
import moment from 'moment';

const useStyles = makeStyles(() => ({
  tooltip: {
    padding: '1rem'
  },
  tooltipText: {
    marginTop: '0.5rem',
    whiteSpace: 'nowrap'
  },
  popover: {
    pointerEvents: 'none'
  }
}));

const FieldAccessibilityPopover = ({ hoverParams }) => {
  const styles = useStyles();

  const anchorEl = _.get(hoverParams, 'element');
  const displayTooltip = !!anchorEl;

  return (
    <Popover
      className={styles.popover}
      classes={{ paper: styles.tooltip }}
      open={displayTooltip}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: _.get(hoverParams, 'data.visibleMidX', 0)
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
      disableAutoFocus
      disableRestoreFocus
    >
      <Typography className={styles.tooltipText}>
        Start: {moment(_.get(hoverParams, 'data.startDate')).format('llll')}
      </Typography>
      <Typography className={styles.tooltipText}>
        End: {moment(_.get(hoverParams, 'data.endDate')).format('llll')}
      </Typography>
      <Typography className={styles.tooltipText}>
        Field Conditions:{' '}
        {`${_.get(hoverParams, 'data.classification')} (${(
          (_.get(hoverParams, 'data.classificationValue') + 1) *
          50
        ).toFixed(0)}%)`}
      </Typography>
    </Popover>
  );
};

export default FieldAccessibilityPopover;
