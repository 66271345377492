import { fieldAccessibilityService } from '../../services';
import {
  addFieldAccessibilityFeedbackActionTypes,
  fieldAccessibilityActionTypes,
  getFieldAccessibilityActionTypes
} from '../actionTypes/fieldAccessibilityActionTypes';

import { buildApiActions } from './apiActions';

export const updateFieldAccessibility = (
  growerId,
  seasonId,
  farmId = null
) => ({
  type: fieldAccessibilityActionTypes.UPDATE_FIELD_ACCESSIBILITY,
  payload: {
    growerId,
    seasonId,
    farmId
  }
});

export const getFieldAccessibilityActions = buildApiActions(
  getFieldAccessibilityActionTypes,
  fieldAccessibilityService.getFieldAccessibilityV2
);

export const addFieldAccessibilityFeedbackActions = buildApiActions(
  addFieldAccessibilityFeedbackActionTypes,
  fieldAccessibilityService.addFieldAccessibilityFeedbackV2
);
