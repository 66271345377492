import React, { useCallback, useContext, useMemo } from 'react';

import { FormControlLabel, Switch } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import _ from 'lodash';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useDispatch } from 'react-redux';

import { FarmStructureContext } from '../../_context/FarmStructureContext';
import { useGrowersDrawerState } from '../../_hooks/ui/useGrowersDrawerState';
import {
  cropDashboardActions,
  useCropDashboardSelectors
} from '../../_store/slices/ui/cropDashboardSlice';
import LoadingIndicator from '../../Components/LoadingIndicator';

import DrawerError from './DrawerError';
import DrawerItem from './DrawerItem';
import DrawerTitle from './DrawerTitle';

const useStyles = makeStyles((theme) => ({
  drawer: {},
  drawerPaper: {
    width: theme.dimensions.gffDrawerWidth,
    paddingTop: theme.dimensions.headerHeight,
    backgroundColor: 'rgb(46, 64, 87)'
  },
  scrollbar: {
    '& > .ps__rail-y > .ps__thumb-y': {
      backgroundColor: 'rgb(21, 28, 43, 1)'
    }
  },
  buttonLabel: {
    color: '#D1D1D1',
    marginTop: '0.25rem',
    marginBottom: '0.25rem'
  },
  switch: {
    marginLeft: '1.5rem',
    marginRight: '0.25rem'
  },
  farmName: {
    textAlign: 'center',
    color: '#e7e7e7',
    fontWeight: 'bold',
    marginTop: '0.5rem'
  }
}));

const GrowersDrawerContent = () => {
  const styles = useStyles();
  const dispatch = useDispatch();

  const { toggleGrowersDrawer } = useGrowersDrawerState();
  const { showAllForGrower } = useCropDashboardSelectors();

  const {
    growers,
    farms,
    fields,
    loading,
    selectedGrower,
    selectedSeason,
    selectedFarm,
    selectedField,
    growersError,
    farmsError,
    fieldsError,
    selectGrower,
    selectFarm,
    selectField,
    clearGrower,
    clearFarm,
    clearField
  } = useContext(FarmStructureContext);

  const theme = useTheme();
  const closeDrawerOnFieldSelect = !useMediaQuery(theme.breakpoints.up('md'));

  const showGrowerList = !loading && !selectedGrower && !selectedFarm;
  const showSeasonNotConfigured =
    !showGrowerList && !selectedSeason && !loading;
  const showFarmsList =
    !loading &&
    !showAllForGrower &&
    selectedGrower &&
    selectedSeason &&
    !selectedFarm;
  const showFieldsList =
    !loading &&
    !showAllForGrower &&
    selectedGrower &&
    selectedSeason &&
    selectedFarm;
  const showFieldsCheckbox = !loading && selectedGrower && selectedSeason;
  const showAllFarmsFieldsList =
    !loading && showAllForGrower && selectedGrower && selectedSeason;

  const growersCount = _.get(_.values(growers), 'length', 0);
  const farmsCount = _.get(_.values(farms), 'length', 0);

  const farmsWithFields = useMemo(
    () =>
      _.chain(farms)
        .values()
        .filter((farm) => !_.isEmpty(_.get(farm, 'fields')))
        .value(),
    [farms]
  );

  const title =
    !showAllForGrower && selectedFarm
      ? _.get(selectedFarm, 'name')
      : selectedGrower
      ? _.get(selectedGrower, 'name')
      : 'Growers';
  const showBackButton =
    (selectedGrower && growersCount > 1) ||
    (selectedFarm && (growersCount > 1 || farmsCount > 1)) ||
    selectedField;

  const handleGrowerSelect = useCallback(
    (item) => {
      selectGrower(_.get(item, 'id'));
    },
    [selectGrower]
  );

  const handleFarmSelect = useCallback(
    (item) => {
      selectFarm(
        _.get(item, 'growerId'),
        _.get(item, 'seasonId'),
        _.get(item, 'id')
      );
    },
    [selectFarm]
  );

  const handleFieldSelect = useCallback(
    (item) => {
      const fieldId = _.get(item, 'id');
      if (fieldId === _.get(selectedField, 'id')) {
        clearField();
        if (showAllForGrower) {
          clearFarm();
        }
      } else {
        selectField(
          _.get(item, 'growerId'),
          _.get(item, 'seasonId'),
          _.get(item, 'farmId'),
          fieldId
        );
        if (closeDrawerOnFieldSelect) {
          toggleGrowersDrawer();
        }
      }
    },
    [
      clearFarm,
      clearField,
      closeDrawerOnFieldSelect,
      selectField,
      selectedField,
      showAllForGrower,
      toggleGrowersDrawer
    ]
  );

  const handleBackButtonClick = useCallback(() => {
    if (showAllForGrower) {
      clearField();
      clearFarm();
      clearGrower();
      return;
    }
    if (showFarmsList || showSeasonNotConfigured) {
      clearGrower();
    }
    if (showFieldsList) {
      clearFarm();
    }
    if (!!selectedField) {
      clearField();
    }
  }, [
    clearFarm,
    clearField,
    clearGrower,
    selectedField,
    showAllForGrower,
    showFarmsList,
    showFieldsList,
    showSeasonNotConfigured
  ]);

  const handleShowAllFieldsToggle = useCallback(() => {
    dispatch(cropDashboardActions.toggleShowAllForGrower());
  }, [dispatch]);

  return (
    <>
      <DrawerTitle
        title={title}
        onBackClick={handleBackButtonClick}
        showBackButton={showBackButton}
      />
      <PerfectScrollbar className={styles.scrollbar}>
        {loading && <LoadingIndicator />}
        {showGrowerList && (
          <>
            {growersError && <DrawerError message={growersError} />}
            {!growersError && _.isEmpty(growers) && (
              <DrawerError message="No Growers" />
            )}
            {_.map(growers, (grower, idx) => {
              const farmsCount = _.get(grower, 'farmsCount');
              return (
                <DrawerItem
                  key={_.get(grower, 'id')}
                  item={grower}
                  title={_.get(grower, 'name')}
                  subtitle={`${farmsCount} Farm${farmsCount === 1 ? '' : 's'}`}
                  even={idx % 2 === 0}
                  onClick={handleGrowerSelect}
                />
              );
            })}
          </>
        )}
        {showSeasonNotConfigured && (
          <DrawerError message="Season is not configured" />
        )}
        {showFieldsCheckbox && (
          <FormControlLabel
            className={styles.buttonLabel}
            control={
              <Switch
                className={styles.switch}
                checked={showAllForGrower}
                onChange={handleShowAllFieldsToggle}
                name="showAllFieldsSwitch"
                color="default"
                size="small"
              />
            }
            label="Show all Fields"
          />
        )}
        {showFarmsList && (
          <>
            {farmsError && <DrawerError message={farmsError} />}
            {!farmsError && _.isEmpty(farms) && (
              <DrawerError message="No Farms" />
            )}
            {_.map(farms, (farm, idx) => {
              const fieldsCount = _.get(farm, 'fieldsCount');
              return (
                <DrawerItem
                  key={_.get(farm, 'id')}
                  item={farm}
                  title={_.get(farm, 'name')}
                  subtitle={`${fieldsCount} Field${
                    fieldsCount === 1 ? '' : 's'
                  }`}
                  even={idx % 2 === 0}
                  onClick={handleFarmSelect}
                />
              );
            })}
          </>
        )}
        {showFieldsList && (
          <>
            {fieldsError && <DrawerError message={fieldsError} />}
            {!fieldsError && _.isEmpty(fields) && (
              <DrawerError message="No Fields" />
            )}
            {_.map(fields, (field, idx) => {
              const acres = _.get(field, 'acres');
              return (
                <DrawerItem
                  key={_.get(field, 'id')}
                  item={field}
                  title={_.get(field, 'name')}
                  subtitle={`${acres} Acre${field === 1 ? '' : 's'}`}
                  even={idx % 2 === 0}
                  onClick={handleFieldSelect}
                  selected={_.get(field, 'id') === _.get(selectedField, 'id')}
                  shape={_.get(field, 'shape')}
                />
              );
            })}
          </>
        )}
        {showAllFarmsFieldsList && (
          <>
            {farmsError && <DrawerError message={farmsError} />}
            {!farmsError && _.isEmpty(farms) && (
              <DrawerError message="No Farms" />
            )}
            {!!_.isEmpty(farmsWithFields) && (
              <DrawerError message="No Fields" />
            )}
            {!farmsError &&
              !_.isEmpty(farmsWithFields) &&
              _.map(farmsWithFields, (farm) => (
                <>
                  <Typography className={styles.farmName}>
                    {_.get(farm, 'name')}
                  </Typography>
                  {_.chain(farm)
                    .get('fields')
                    .values()
                    .map((field, idx) => {
                      const acres = _.get(field, 'acres');
                      return (
                        <DrawerItem
                          key={_.get(field, 'id')}
                          item={field}
                          title={_.get(field, 'name')}
                          subtitle={`${acres} Acre${field === 1 ? '' : 's'}`}
                          even={idx % 2 === 0}
                          onClick={handleFieldSelect}
                          selected={
                            _.get(field, 'id') === _.get(selectedField, 'id')
                          }
                          shape={_.get(field, 'shape')}
                        />
                      );
                    })
                    .value()}
                </>
              ))}
          </>
        )}
      </PerfectScrollbar>
    </>
  );
};

export default GrowersDrawerContent;
