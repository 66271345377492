import React, { useMemo } from 'react';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import _ from 'lodash';
import { Line } from 'react-chartjs-2';

import { generateLabels } from '../../IrrigationDashboard/Components/sensorChartsUtils';

import {
  commonLineDataSetParams,
  getAggregatedValues,
  getDefaultOptions,
  getValue
} from './weatherChartUtils';

const PrecipitationChart = ({
  chartRef,
  weatherData,
  timeUnit,
  startDate,
  endDate
}) => {
  const highChart = useMediaQuery((theme) => theme.breakpoints.down('xs'));

  const graphData = useMemo(() => {
    if (weatherData) {
      const precipitationValues = _.filter(
        getValue(weatherData, 'precipAccPeriod'),
        (item) => _.isNumber(item.y) && !_.isNaN(item.y)
      );

      const accumulatedPrecipitation = getAggregatedValues(
        weatherData,
        'precipAccPeriod'
      );

      const datasets = [
        {
          ...commonLineDataSetParams('#43a047'),
          label: 'Accumulated Precipitation',
          data: accumulatedPrecipitation
        },
        {
          label: 'Precipitation',
          type: 'bar',
          backgroundColor: '#66bb6a66',
          borderColor: '#66bb6a',
          data: precipitationValues
        }
      ].filter((dataset) => dataset.data);

      const labels = generateLabels(datasets, startDate, endDate);

      return {
        labels,
        datasets
      };
    }
  }, [endDate, startDate, weatherData]);

  return (
    <>
      {graphData && highChart && (
        <Line
          ref={chartRef}
          data={graphData}
          options={getDefaultOptions(timeUnit)}
          height={250}
          type="scatter"
        />
      )}
      {graphData && !highChart && (
        <Line
          ref={chartRef}
          data={graphData}
          options={getDefaultOptions(timeUnit)}
          height={120}
          type="scatter"
        />
      )}
    </>
  );
};

export default PrecipitationChart;
