import React, { useCallback, useEffect } from 'react';

import DialogTitle from '@material-ui/core/DialogTitle';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import _ from 'lodash';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { setTargetMoistureActions } from '../../../_store/actions/growthsActions';
import { useSetTargetMoistureSelectors } from '../../../_store/selectors/growthsSelectors';
import CreateUpdateDeleteDialogActions from '../../CreateUpdateDeleteDialogActions';
import ResponsiveDialogContent from '../../ResponsiveDialogContent';

const SetTargetMoistureForm = ({
  growth,
  onClose,
  growerId,
  seasonId,
  farmId,
  fieldId,
  growthId
}) => {
  const dispatch = useDispatch();

  const { register, handleSubmit, errors } = useForm({
    defaultValues: { targetGrainMoisture: _.get(growth, 'targetGrainMoisture') }
  });

  const {
    inProgress,
    success,
    error: apiError
  } = useSetTargetMoistureSelectors();

  const handleClose = useCallback(() => {
    dispatch(setTargetMoistureActions.clear());
    onClose();
  }, [dispatch, onClose]);

  useEffect(() => {
    if (success) {
      handleClose();
    }
  }, [handleClose, success]);

  const handleFormSubmit = useCallback(
    (formData) => {
      dispatch(
        setTargetMoistureActions.request(
          growerId,
          seasonId,
          farmId,
          fieldId,
          growthId,
          {
            targetGrainMoisture: _.get(formData, 'targetGrainMoisture')
          }
        )
      );
    },
    [dispatch, farmId, fieldId, growerId, growthId, seasonId]
  );

  return (
    <form noValidate onSubmit={handleSubmit(handleFormSubmit)}>
      <DialogTitle>Set Target Grain Moisture Content</DialogTitle>
      <ResponsiveDialogContent>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextField
              inputRef={register({
                min: {
                  value: 0.00000001,
                  message:
                    'Target Grain Moisture Content must be greater than 0'
                },
                max: {
                  value: 100,
                  message: 'Target Grain Moisture Content must be less than 100'
                },
                valueAsNumber: true,
                required: 'Target Grain Moisture is required'
              })}
              inputProps={{ min: 0, max: 100 }}
              required
              fullWidth
              variant="standard"
              type="number"
              id="targetGrainMoisture"
              label="Target Grain Moisture Content"
              name="targetGrainMoisture"
              error={!!_.get(errors, 'targetGrainMoisture')}
              helperText={_.get(errors, 'targetGrainMoisture.message')}
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>
              }}
            />
          </Grid>
          <Grid item xs={12}>
            {apiError && <FormHelperText error>{apiError}</FormHelperText>}
          </Grid>
        </Grid>
      </ResponsiveDialogContent>
      <CreateUpdateDeleteDialogActions
        inProgress={inProgress}
        confirmText="Save"
        onCancel={handleClose}
      />
    </form>
  );
};

export default SetTargetMoistureForm;
