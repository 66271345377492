import React, { useContext } from 'react';

import makeStyles from '@material-ui/core/styles/makeStyles';

import { AuthContext } from '../../_context/AuthContext';
import { useMenuState } from '../../_hooks/ui/useMenuState';

import DesktopHeader from './Components/headers/DesktopHeader';
import MobileHeader from './Components/headers/MobileHeader';
import UnauthorizedHeader from './Components/headers/UnauthorizedHeader';

const useStyles = makeStyles((theme) => ({
  header: {
    height: theme.dimensions.headerHeight,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    alignContent: 'center',
    color: 'white',
    flex: '1 1',
    minWidth: 0,
    position: 'fixed',
    width: '100%',
    top: 0,
    zIndex: 10,
    transition: 'all 0.2s',
    boxShadow:
      '0 0.46875rem 2.1875rem rgba(0, 0, 0, 0.03), 0 0.9375rem 1.40625rem rgba(0, 0, 0, 0.03), 0 0.25rem 0.53125rem rgba(0, 0, 0, 0.05), 0 0.125rem 0.1875rem rgba(0, 0, 0, 0.03)',
    background: `#fafbfc linear-gradient(to top, ${theme.palette.brand.primary.light} 0%, ${theme.palette.brand.primary.lighter} 100%)`
  }
}));

const AppHeader = ({ empty = false }) => {
  const styles = useStyles();
  const { user } = useContext(AuthContext);
  const { showMobileMenu } = useMenuState();

  return (
    <div className={styles.header}>
      <>
        {(!user || empty) && <UnauthorizedHeader />}
        {!!user && !empty && (
          <>
            {showMobileMenu && <MobileHeader />}
            {!showMobileMenu && <DesktopHeader />}
          </>
        )}
      </>
    </div>
  );
};

export default AppHeader;
