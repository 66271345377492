import { useMemo } from 'react';

import { useSelector } from 'react-redux';

import { createDataSlice } from '../utils/createDataSlice';

const cropDashboardSlice = createDataSlice({
  reducerPath: ['ui', 'cropDashboard'],
  initialState: { showAllForGrower: false },
  reducers: {
    toggleShowAllForGrower(state) {
      state.showAllForGrower = !state.showAllForGrower;
    }
  }
});

const { actions, baseSelector: cropDashboardSelector } = cropDashboardSlice;

export const cropDashboardActions = actions;

export const useCropDashboardSelectors = () => {
  const { showAllForGrower } = useSelector(cropDashboardSelector);

  return useMemo(
    () => ({
      showAllForGrower
    }),
    [showAllForGrower]
  );
};

export default cropDashboardSlice;
