import { useMemo } from 'react';

import _ from 'lodash';

import { useGlobalSeasonSelectors } from '../../../../_store/slices/gff/seasons/globalSeasonSlice';
import { useGetReportsGrowersSelectors } from '../../../../_store/slices/reports/getReportsGrowersSlice';

import { getReportsGffTreeParams } from './useReportsGrowersWithSelection';

export function useReportsRegionsWithSelection(growers) {
  const { selectedSeason: seasonYear } = useGlobalSeasonSelectors();

  const { response } = useGetReportsGrowersSelectors(seasonYear);

  const regions = _.get(response, 'regions');

  const growersByDistrict = useMemo(
    () => _.chain(growers).groupBy('districtId').value(),
    [growers]
  );

  const regionsWithSelection = useMemo(() => {
    return _.map(regions, (region) => {
      const districts = _.chain(region)
        .get('districts')
        .map((district) => {
          const districtGrowers = _.get(
            growersByDistrict,
            [_.get(district, 'id')],
            []
          );

          const {
            fieldsCount,
            checkedFieldsCount,
            checked,
            disabled,
            indeterminate
          } = getReportsGffTreeParams(districtGrowers);

          return {
            ...district,
            growers: districtGrowers,
            fieldsCount,
            checkedFieldsCount,
            checked,
            disabled,
            indeterminate
          };
        })
        .value();

      const {
        fieldsCount,
        checkedFieldsCount,
        checked,
        disabled,
        indeterminate
      } = getReportsGffTreeParams(districts);

      return {
        ...region,
        districts,
        fieldsCount,
        checkedFieldsCount,
        checked,
        disabled,
        indeterminate
      };
    });
  }, [growersByDistrict, regions]);

  const noRegionGrowers = useMemo(() => {
    const growersWithNoDistrictId = _.get(growersByDistrict, [undefined]);

    const {
      fieldsCount,
      checkedFieldsCount,
      checked,
      disabled,
      indeterminate
    } = getReportsGffTreeParams(growersWithNoDistrictId);

    return {
      id: 'no-region',
      name: 'No Region',
      growers: growersWithNoDistrictId,
      fieldsCount,
      checkedFieldsCount,
      checked,
      disabled,
      indeterminate
    };
  }, [growersByDistrict]);

  return useMemo(
    () => ({ regions: regionsWithSelection, noRegionGrowers }),
    [noRegionGrowers, regionsWithSelection]
  );
}
