import { useMemo } from 'react';

import { reportsService } from '../../../../services/reports.service';
import { createApiSlice } from '../../utils/createApiSlice';

const postSavedFilterSlice = createApiSlice({
  reducerPath: ['reports', 'savedFilters', 'postSavedFilter'],
  apiFunction: reportsService.postSavedFilter,
  apiArgumentsFn: ({ seasonYear, filter }) => [seasonYear, filter]
});

const { actions, useApiSelectors } = postSavedFilterSlice;

export const postSavedFilterActions = actions;

export const usePostSavedFilterSelectors = () => {
  const { inProgress, errorMessage, success, response } = useApiSelectors();

  return useMemo(
    () => ({
      inProgress,
      errorMessage,
      success,
      response
    }),
    [errorMessage, inProgress, response, success]
  );
};

export default postSavedFilterSlice;
