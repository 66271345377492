import React from 'react';

import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import AddIcon from '@material-ui/icons/Add';
import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  addButton: {
    '&:hover': {
      textDecoration: 'none',
      color: '#fff'
    }
  }
}));

const AddButton = ({ linkTo, text }) => {
  const styles = useStyles();

  return (
    <Button
      size="small"
      variant="contained"
      color="primary"
      className={styles.addButton}
      startIcon={<AddIcon />}
      component={RouterLink}
      to={linkTo}
    >
      {text}
    </Button>
  );
};

export default AddButton;
