import React from 'react';

import { Box } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  body: {
    padding: theme.spacing(2)
  }
}));

const ContentBody = ({ children }) => {
  const styles = useStyles();

  return <Box className={styles.body}>{children}</Box>;
};

export default ContentBody;
