import React, { createContext, useCallback, useContext, useMemo } from 'react';

import { userService } from '../services/';

import { ApiContext } from './ApiContext';
import { AuthContext } from './AuthContext';

export const UserContext = createContext({});

export const UserProvider = ({ children }) => {
  const { callApi } = useContext(ApiContext);
  const { user } = useContext(AuthContext);

  const getUsers = useCallback(async () => {
    return await callApi((token) => userService.getUsers(token));
  }, [callApi]);

  const saveUser = useCallback(
    async (user) => {
      return await callApi((token) => userService.saveUser(token, user));
    },
    [callApi]
  );

  const changePassword = useCallback(
    async (data) => {
      const requestData = {
        ...data,
        uId: user.userId
      };
      await callApi((token) => userService.changePassword(token, requestData));
    },
    [user, callApi]
  );

  const context = useMemo(
    () => ({ getUsers, saveUser, changePassword }),
    [getUsers, saveUser, changePassword]
  );

  return (
    <UserContext.Provider value={context}>{children}</UserContext.Provider>
  );
};
