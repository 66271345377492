import { useMemo } from 'react';

import { useSelector } from 'react-redux';

import { createDataSlice } from '../utils/createDataSlice';

const resetPasswordParamsSlice = createDataSlice({
  reducerPath: ['newUser', 'resetPasswordParams'],
  initialState: {},
  reducers: {
    setParams: {
      reducer: (state, action) => action.payload,
      prepare: (userId, token = {}) => ({ payload: { userId, token } })
    },
    clear: () => ({})
  }
});

const { actions, baseSelector: resetPasswordParamsSelector } =
  resetPasswordParamsSlice;

export const resetPasswordParamsActions = actions;

export const useResetPasswordParamsSelectors = () => {
  const { userId, token } = useSelector(resetPasswordParamsSelector);

  return useMemo(() => ({ userId, token }), [userId, token]);
};

export default resetPasswordParamsSlice;
