import React, { createContext, useCallback, useMemo } from 'react';

import _ from 'lodash';
import { useDispatch } from 'react-redux';

import {
  selectFarmAction,
  selectFieldAction,
  selectGrowerAction,
  selectSeasonAction
} from '../_store/actions/farmStructureActions';
import {
  useFarmSelectors,
  useFarmsSelector
} from '../_store/selectors/farmsSelectors';
import { useFarmStructureSelector } from '../_store/selectors/farmStructureSelectors';
import {
  useFieldSelectors,
  useFieldsSelector
} from '../_store/selectors/fieldsSelectors';
import {
  useGrowerSelectors,
  useGrowersSelectors
} from '../_store/selectors/growersSelectors';
import { useSeasonSelector } from '../_store/selectors/seasonsSelectors';
import { useGetSeasonsSelectors } from '../_store/slices/gff/seasons/getSeasonsSlice';

export const FarmStructureContext = createContext({});

export const FarmStructureProvider = ({ children }) => {
  const dispatch = useDispatch();
  const { growerId, seasonId, farmId, fieldId } = useFarmStructureSelector();

  const {
    growers,
    inProgress: loadingGrowers,
    growersFetchError: growersError
  } = useGrowersSelectors();
  const { farms } = useFarmsSelector(growerId, seasonId);
  const { fields } = useFieldsSelector(growerId, seasonId, farmId);
  const { grower: selectedGrower } = useGrowerSelectors(growerId);
  const { season: selectedSeason } = useSeasonSelector(growerId, seasonId);
  const { farm: selectedFarm } = useFarmSelectors(growerId, seasonId, farmId);
  const { field: selectedField } = useFieldSelectors(
    growerId,
    seasonId,
    farmId,
    fieldId
  );

  const { inProgress: seasonsInProgress, errorMessage: seasonsErrorMessage } =
    useGetSeasonsSelectors(_.get(selectedGrower, 'id'));

  const isActiveSeason = !!_.get(selectedSeason, 'isActive');
  const isHistoricalSeason = !!_.get(selectedSeason, 'isHistorical');

  const farmsError = seasonsErrorMessage;
  const fieldsError = farmsError;

  const loading = loadingGrowers || seasonsInProgress;

  const selectGrower = useCallback(
    (growerId) => {
      dispatch(selectGrowerAction(growerId));
    },
    [dispatch]
  );

  const selectSeason = useCallback(
    (growerId, seasonId) => {
      dispatch(selectSeasonAction(growerId, seasonId));
    },
    [dispatch]
  );

  const selectFarm = useCallback(
    (growerId, seasonId, farmId) => {
      dispatch(selectFarmAction(growerId, seasonId, farmId));
    },
    [dispatch]
  );

  const selectField = useCallback(
    (growerId, seasonId, farmId, fieldId) => {
      dispatch(selectFieldAction(growerId, seasonId, farmId, fieldId));
    },
    [dispatch]
  );

  const clearGrower = useCallback(() => {
    selectGrower(null);
  }, [selectGrower]);

  const clearFarm = useCallback(() => {
    selectFarm(growerId, seasonId, null);
  }, [growerId, seasonId, selectFarm]);

  const clearField = useCallback(() => {
    selectField(growerId, seasonId, farmId, null);
  }, [farmId, growerId, seasonId, selectField]);

  const context = useMemo(
    () => ({
      growers,
      farms,
      fields,
      loading,
      selectedGrowerId: growerId,
      selectedSeasonId: seasonId,
      selectedFarmId: farmId,
      selectedFieldId: fieldId,
      selectedGrower,
      selectedSeason,
      selectedFarm,
      selectedField,
      growersError,
      farmsError,
      fieldsError,
      isActiveSeason,
      isHistoricalSeason,
      selectGrower,
      selectSeason,
      selectFarm,
      selectField,
      clearGrower,
      clearFarm,
      clearField
    }),
    [
      growers,
      farms,
      fields,
      loading,
      growerId,
      seasonId,
      farmId,
      fieldId,
      selectedGrower,
      selectedSeason,
      selectedFarm,
      selectedField,
      growersError,
      farmsError,
      fieldsError,
      isActiveSeason,
      isHistoricalSeason,
      selectGrower,
      selectSeason,
      selectFarm,
      selectField,
      clearGrower,
      clearFarm,
      clearField
    ]
  );

  return (
    <FarmStructureContext.Provider value={context}>
      {children}
    </FarmStructureContext.Provider>
  );
};
