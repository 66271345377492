import { addToken, client } from './client';

export const feedbackService = {
  addUserFeedback,
  getUsersFeedback,
  postUsersFeedbackStatus
};

async function addUserFeedback(token, feedback) {
  const response = await client.post(`/feedback`, feedback, addToken(token));
  return response.data;
}

async function getUsersFeedback(token) {
  const response = await client.get(`/feedback`, addToken(token));
  return response.data;
}

async function postUsersFeedbackStatus(token, feedbackId, newStatus) {
  const response = await client.post(
    `/feedback/${feedbackId}/change-status`,
    { newStatus },
    addToken(token)
  );
  return response.data;
}
