import React from 'react';

import makeStyles from '@material-ui/core/styles/makeStyles';

import { ContentHeader } from './index';

const useStyles = makeStyles((theme) => ({
  responsiveHeader: {
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: '2.375rem',
      textAlign: 'center',
      '& > :not(:first-child)': {
        marginTop: '0.5rem'
      }
    }
  }
}));

const ResponsiveContentHeader = ({ headerRef, children, className }) => {
  const styles = useStyles();

  return (
    <ContentHeader className={styles.responsiveHeader}>
      {children}
    </ContentHeader>
  );
};

export default ResponsiveContentHeader;
