import { useMemo } from 'react';

import _ from 'lodash';

import { statisticsService } from '../../../services/statistics.service';
import { createApiSlice } from '../utils/createApiSlice';

const getPageViewsStatisticsSlice = createApiSlice({
  reducerPath: ['statistics', 'getPageViewsStatistics'],
  apiFunction: statisticsService.getPageViewsStatistics,
  getStateUpdatePath: (action) => {
    const seasonYear = _.get(action, 'payload.apiArguments.0');
    return [seasonYear];
  },
  getSelectorPath: (seasonYear) => {
    return [seasonYear];
  },
  apiArgumentsFn: ({ seasonYear }) => [seasonYear]
});

const { actions, useApiSelectors } = getPageViewsStatisticsSlice;

export const getPageViewsStatisticsActions = actions;

export const useGetPageViewsStatisticsSelectors = (seasonYear) => {
  const { inProgress, errorMessage, success, response } =
    useApiSelectors(seasonYear);

  return useMemo(
    () => ({
      inProgress,
      errorMessage,
      success,
      response
    }),
    [errorMessage, inProgress, response, success]
  );
};

export default getPageViewsStatisticsSlice;
