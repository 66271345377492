import { useMemo } from 'react';

import { userService } from '../../../services';
import { createApiSlice } from '../utils/createApiSlice';

const tokenRefreshSlice = createApiSlice({
  reducerPath: ['newUser', 'tokenRefresh'],
  apiFunction: userService.refreshToken,
  authenticate: false,
  apiArgumentsFn: ({ refreshToken }) => [refreshToken]
});

const { actions, useApiSelectors } = tokenRefreshSlice;

export const tokenRefreshActions = actions;

export const useTokenRefreshSelectors = () => {
  const { inProgress, errorMessage, success } = useApiSelectors();

  return useMemo(
    () => ({
      inProgress,
      errorMessage,
      success
    }),
    [errorMessage, inProgress, success]
  );
};

export default tokenRefreshSlice;
