import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import makeStyles from '@material-ui/core/styles/makeStyles';
import cx from 'classnames';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },
  badge: (props) => ({
    backgroundColor: props.backgroundColor,
    paddingLeft: '0.5rem',
    paddingRight: '0.5rem',
    width: '100%',
    maxWidth: '20rem',
    borderRadius: '1rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }),
  label: (props) => ({
    fontSize: props.small ? '0.75rem' : '1rem',
    color: props.textColor,
    textAlign: 'center'
  }),
  icon: (props) => ({
    color: props.textColor,
    marginRight: '0.5rem',
    fontSize: '0.75rem'
  })
}));

const CustomBadge = ({
  className,
  backgroundColor,
  textColor,
  title,
  small = false,
  faIcon,
  iconTooltip = ''
}) => {
  const styles = useStyles({ backgroundColor, textColor, small });

  return (
    <Box className={cx(styles.container, className)}>
      <Paper elevation={3} className={styles.badge}>
        {faIcon && (
          <Tooltip title={iconTooltip}>
            <Box>
              <FontAwesomeIcon className={styles.icon} icon={faIcon} />
            </Box>
          </Tooltip>
        )}
        <Typography className={styles.label} noWrap>
          {title}
        </Typography>
      </Paper>
    </Box>
  );
};

export default CustomBadge;
