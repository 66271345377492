import React, { useContext, useMemo } from 'react';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import _ from 'lodash';

import { growthStagesTypes } from '../../_constants/growthsConstants';
import { FarmStructureContext } from '../../_context/FarmStructureContext';
import { getActiveGrowth } from '../../_hooks/Growths/useActiveGrowth';
import { getTechnology } from '../../_hooks/useCropTechnology';
import {
  useTechnologiesConfig,
  useVarietiesConfig
} from '../../_store/selectors/configSelectors';
import { useAllGrowerFieldsSelector } from '../../_store/selectors/fieldsSelectors';
import { useGrowthModelSelectors } from '../../_store/selectors/uiSelectors';
import {
  ContentBody,
  ContentContainer,
  ResponsiveContentHeader
} from '../../Layout/Content';
import { useTimelineGrowths } from '../Growths/GrowthTimeline/_hooks/useTimelineGrowths';
import LoadingIndicator from '../LoadingIndicator';

import GrowerHarvestAdvisorAccordion from './GrowerHarvestAdvisorAccordion';
import GrowerHarvestAdvisorCsvDownload from './GrowerHarvestAdvisorCsvDownload';
import GrowerHarvestAdvisorTable from './GrowerHarvestAdvisorTable';

const GrowerHarvestAdvisor = () => {
  const showTable = useMediaQuery((theme) => theme.breakpoints.up('md'), {
    noSsr: true
  });

  const { varieties } = useVarietiesConfig();
  const { technologies } = useTechnologiesConfig();

  const { selectedGrower } = useContext(FarmStructureContext);

  const { selectedGrowthModel } = useGrowthModelSelectors();

  const { fields } = useAllGrowerFieldsSelector();

  const { inProgress: growthsInProgress, timelineGrowths } =
    useTimelineGrowths();

  const growthsByFieldId = useMemo(
    () => _.groupBy(timelineGrowths, 'fieldId'),
    [timelineGrowths]
  );

  const fieldsByHarvestDates = useMemo(
    () =>
      _.chain(fields)
        .map((field) => {
          const activeGrowth = getActiveGrowth(field, selectedGrowthModel);
          const varietyId = _.get(activeGrowth, 'varietyId');
          const isReference = _.get(activeGrowth, 'isReference');

          const growth = _.get(growthsByFieldId, [_.get(field, 'id'), 0]);

          const { varietyName, technologyName, technologyId } = getTechnology(
            varieties,
            technologies,
            varietyId
          );

          const id = _.get(field, 'id');
          const growerId = _.get(field, 'growerId');
          const farmId = _.get(field, 'farmId');
          const seasonId = _.get(field, 'seasonId');
          const farmName = _.get(field, 'farmName');
          const name = _.get(field, 'name');
          const acres = _.get(field, 'acres');

          const plantingDate = _.get(activeGrowth, 'plantingDate');
          const harvestDate = _.get(
            getActiveGrowth(field, selectedGrowthModel),
            'harvestDate'
          );
          const physiologicalMaturity = _.chain(growth)
            .get('stages')
            .find(
              (stage) =>
                _.get(stage, 'growthStage') ===
                growthStagesTypes.PhysiologicalMaturity
            )
            .value();
          const physiologicalMaturityDate = _.get(
            physiologicalMaturity,
            'startDate'
          );
          return {
            id,
            growerId,
            seasonId,
            farmId,
            name,
            farmName,
            acres,
            isReference,
            varietyName,
            varietyId,
            technologyName,
            technologyId,
            plantingDate,
            harvestDate,
            physiologicalMaturityDate
          };
        })
        .filter((field) => !!_.get(field, 'harvestDate'))
        .orderBy('harvestDate')
        .value(),
    [fields, growthsByFieldId, selectedGrowthModel, technologies, varieties]
  );

  return (
    <>
      <ContentContainer>
        <ResponsiveContentHeader>
          <span>Harvest Schedule</span>
          {!growthsInProgress && (
            <GrowerHarvestAdvisorCsvDownload
              grower={selectedGrower}
              fields={fieldsByHarvestDates}
            />
          )}
        </ResponsiveContentHeader>
        <ContentBody>
          {!growthsInProgress && showTable && (
            <GrowerHarvestAdvisorTable fields={fieldsByHarvestDates} />
          )}
          {!growthsInProgress && !showTable && (
            <GrowerHarvestAdvisorAccordion fields={fieldsByHarvestDates} />
          )}
          {growthsInProgress && <LoadingIndicator />}
        </ContentBody>
      </ContentContainer>
    </>
  );
};

export default GrowerHarvestAdvisor;
