import { useMemo } from 'react';

import { userService } from '../../../services';
import { createApiSlice } from '../utils/createApiSlice';

const resetPasswordSlice = createApiSlice({
  reducerPath: ['newUser', 'resetPassword'],
  apiFunction: userService.resetPassword,
  authenticate: false,
  apiArgumentsFn: ({ userId, token, password, confirmPassword }) => [
    { userId, token, password, confirmPassword }
  ]
});

const { actions, useApiSelectors } = resetPasswordSlice;

export const resetPasswordActions = actions;

export const useResetPasswordSelectors = () => {
  const { inProgress, errorMessage, success } = useApiSelectors();

  return useMemo(
    () => ({
      inProgress,
      errorMessage,
      success
    }),
    [errorMessage, inProgress, success]
  );
};

export default resetPasswordSlice;
