import React from 'react';

import makeStyles from '@material-ui/core/styles/makeStyles';
import _ from 'lodash';
import { useResizeDetector } from 'react-resize-detector';

import GrainMoistureChart from './GrainMoistureChart';

const useStyles = makeStyles(() => ({
  chartContainer: {
    position: 'relative',
    width: '100%'
  }
}));

const FieldHarvestAdvisorChart = ({ growth, infieldDrydown }) => {
  const styles = useStyles();

  const { width, ref: containerRef } = useResizeDetector();

  const targetGrainMoisture = _.get(growth, 'targetGrainMoisture');

  return (
    <div className={styles.chartContainer} ref={containerRef}>
      {!_.isNaN(width) && _.isNumber(width) && (
        <GrainMoistureChart
          growth={growth}
          containerWidth={width}
          infieldDrydown={infieldDrydown}
          targetGrainMoisture={targetGrainMoisture}
        />
      )}
    </div>
  );
};

export default FieldHarvestAdvisorChart;
