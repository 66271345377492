import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';

import _ from 'lodash';
import moment from 'moment';
import { useDispatch } from 'react-redux';

import { FORECAST_TABS } from '../_constants/weatherConstants';
import { useWeatherStations } from '../_hooks/Devices/useWeatherStations';
import { useWeatherStationWeather } from '../_hooks/Devices/useWeatherStationWeather';
import { setWeatherParameters } from '../_store/actions/uiActions';
import {
  forceUpdateFieldWeatherAction,
  forceUpdateLocationWeatherAction,
  forceUpdateWeatherStationWeatherAction,
  updateFieldWeatherAction,
  updateLocationWeatherAction,
  updateWeatherStationWeatherAction
} from '../_store/actions/weatherActions';
import { useWeatherParametersSelectors } from '../_store/selectors/uiSelectors';
import {
  useFieldWeatherSelectors,
  useLocationWeatherSelectors
} from '../_store/selectors/weatherSelectors';
import { useGlobalSeasonSelectors } from '../_store/slices/gff/seasons/globalSeasonSlice';

import { FarmStructureContext } from './FarmStructureContext';

const locationPollingIntervalSeconds = 10;

export const LOCATION_SOURCES = {
  FIELD: 'PUMP_SYSTEM',
  BROWSER_LOCATION: 'BROWSER_LOCATION',
  WEATHER_STATION: 'WEATHER_STATION'
};

export const DASHBOARD_TABS = {
  CURRENT_CONDITIONS: 'CURRENT_CONDITIONS',
  FORECAST: 'FORECAST',
  HISTORICAL: 'HISTORICAL',
  GROWTH: 'GROWTH'
};

export const WeatherContext = createContext({});

export const WeatherProvider = ({ children }) => {
  const [locationSource, setLocationSource] = useState(LOCATION_SOURCES.FIELD);
  const [selectedWeatherStation, setSelectedWeatherStation] = useState();
  const [locationError, setLocationError] = useState();
  const [browserLocation, setBrowserLocation] = useState();
  const dispatch = useDispatch();
  const { weatherParameters } = useWeatherParametersSelectors();

  const {
    selectedField,
    selectedGrowerId,
    selectedSeasonId,
    selectedFarmId,
    selectedFieldId
  } = useContext(FarmStructureContext);

  const { weatherStations } = useWeatherStations(
    selectedGrowerId,
    selectedSeasonId
  );

  const { selectedSeason: seasonYear } = useGlobalSeasonSelectors();

  const weatherStationId = _.get(selectedWeatherStation, 'id');

  const fieldWeather = useFieldWeatherSelectors(
    selectedSeasonId,
    selectedFieldId
  );
  const locationWeather = useLocationWeatherSelectors(seasonYear);
  const weatherStationWeather = useWeatherStationWeather(
    selectedGrowerId,
    selectedSeasonId,
    weatherStationId
  );

  const { inProgress, updatedAt, errorMessage, weather, success } =
    locationSource === LOCATION_SOURCES.BROWSER_LOCATION
      ? locationWeather
      : locationSource === LOCATION_SOURCES.FIELD
      ? fieldWeather
      : weatherStationWeather;

  const setDashboardState = useCallback(
    (parameters) => {
      dispatch(setWeatherParameters(parameters));
    },
    [dispatch]
  );

  const setDashboardTab = useCallback(
    (dashboardTab) => {
      setDashboardState({ dashboardTab });
    },
    [setDashboardState]
  );

  const setForecastTab = useCallback(
    (forecastTab) => {
      setDashboardState({ forecastTab });
    },
    [setDashboardState]
  );

  const setHistoricalStartDate = useCallback(
    (historicalStartDate) => {
      setDashboardState({ historicalStartDate });
    },
    [setDashboardState]
  );

  const setHistoricalEndDate = useCallback(
    (historicalEndDate) => {
      setDashboardState({ historicalEndDate });
    },
    [setDashboardState]
  );

  const setSelectedChart = useCallback(
    (selectedChart) => {
      setDashboardState({ selectedChart });
    },
    [setDashboardState]
  );

  const setTimespan = useCallback(
    (timespan) => {
      setDashboardState({ timespan });
    },
    [setDashboardState]
  );

  const setCustomDays = useCallback(
    (customDays) => {
      setDashboardState({ customDays });
    },
    [setDashboardState]
  );

  const dashboardTab = _.get(weatherParameters, 'dashboardTab');
  const forecastTab = _.get(weatherParameters, 'forecastTab');
  const historicalStartDate = _.get(weatherParameters, 'historicalStartDate');
  const historicalEndDate = _.get(weatherParameters, 'historicalEndDate');
  const selectedChart = _.get(weatherParameters, 'selectedChart');
  const timespan = _.get(weatherParameters, 'timespan');
  const customDays = _.get(weatherParameters, 'customDays');

  const getData = (key) => {
    return _.get(weather, [key, 'items']);
  };

  const currentConditions = _.get(weather, 'currentConditions');
  const dailyGdd = getData('dailyGdd');
  const dailyForecast = getData('dailyForecast');
  const hourlyForecast = getData('hourlyForecast');
  const forecastGDD =
    forecastTab === FORECAST_TABS['3_DAYS']
      ? _.chain(dailyGdd)
          .filter((item) => moment(_.get(item, 'date')).isAfter())
          .take(3)
          .value()
      : _.filter(dailyGdd, (item) => moment(_.get(item, 'date')).isAfter());
  const dailyHistorical = getData('dailyHistorical');
  const hourlyHistorical = getData('hourlyHistorical');
  const historicalGDD = _.filter(dailyGdd, (item) =>
    moment(_.get(item, 'date')).isBefore(moment().startOf('day'))
  );

  const forecast =
    forecastTab === FORECAST_TABS['3_DAYS'] ? hourlyForecast : dailyForecast;

  useEffect(() => {
    if (
      dashboardTab === DASHBOARD_TABS.FORECAST &&
      locationSource === LOCATION_SOURCES.WEATHER_STATION
    ) {
      setDashboardTab(DASHBOARD_TABS.CURRENT_CONDITIONS);
    }
  }, [dashboardTab, locationSource, setDashboardTab]);

  useEffect(() => {
    if (!_.isEmpty(weatherStations)) {
      setSelectedWeatherStation(_.first(weatherStations));
    } else {
      setSelectedWeatherStation(undefined);
    }
  }, [weatherStations]);

  useEffect(() => {
    if (!seasonYear) {
      return;
    }
    if (
      !historicalStartDate ||
      !historicalEndDate ||
      !_.startsWith(historicalStartDate, seasonYear) ||
      !_.startsWith(historicalEndDate, seasonYear)
    ) {
      if (moment().year() === seasonYear) {
        setHistoricalEndDate(moment().format('YYYY-MM-DD'));
        if (moment().startOf('year').isAfter(moment().subtract(3, 'months'))) {
          setHistoricalStartDate(moment().startOf('year').format('YYYY-MM-DD'));
        } else {
          setHistoricalStartDate(
            moment().subtract(3, 'months').format('YYYY-MM-DD')
          );
        }
      } else {
        setHistoricalStartDate(`${seasonYear}-01-01`);
        setHistoricalEndDate(`${seasonYear}-12-31`);
      }
    }
  }, [
    historicalEndDate,
    historicalStartDate,
    seasonYear,
    setHistoricalEndDate,
    setHistoricalStartDate
  ]);

  const showHourlyHistorical = useMemo(() => {
    if (!historicalStartDate || !historicalEndDate || !hourlyHistorical) {
      return undefined;
    }
    const start = moment(historicalStartDate).startOf('day');
    const end = moment(historicalEndDate).endOf('day');
    const firstHourlyHistorical = _.first(hourlyHistorical);
    const lastHourlyHistorical = _.last(hourlyHistorical);
    const firstHourlyDate = firstHourlyHistorical
      ? moment(_.get(firstHourlyHistorical, 'date')).startOf('day')
      : undefined;
    const lastHourlyDate = lastHourlyHistorical
      ? moment(_.get(lastHourlyHistorical, 'date')).endOf('day')
      : undefined;
    return (
      start.isSameOrAfter(firstHourlyDate) && end.isSameOrBefore(lastHourlyDate)
    );
  }, [historicalStartDate, historicalEndDate, hourlyHistorical]);

  const historical = useMemo(() => {
    if (
      !historicalStartDate ||
      !historicalEndDate ||
      (!showHourlyHistorical && !dailyHistorical) ||
      (showHourlyHistorical && !hourlyHistorical)
    ) {
      return undefined;
    }
    const start = moment(historicalStartDate).startOf('day');
    const end = moment(historicalEndDate).endOf('day');
    if (showHourlyHistorical) {
      return _.filter(hourlyHistorical, (historical) => {
        const timestamp = moment(_.get(historical, 'date'));
        return timestamp.isSameOrAfter(start) && timestamp.isSameOrBefore(end);
      });
    }
    return _.filter(dailyHistorical, (historical) => {
      const timestamp = moment(_.get(historical, 'date'));
      return timestamp.isSameOrAfter(start) && timestamp.isSameOrBefore(end);
    });
  }, [
    showHourlyHistorical,
    historicalStartDate,
    historicalEndDate,
    hourlyHistorical,
    dailyHistorical
  ]);

  const filteredHistoricalGDD = useMemo(() => {
    if (!historicalStartDate || !historicalEndDate || !historicalGDD) {
      return undefined;
    }
    const start = moment(historicalStartDate).startOf('day');
    const end = moment(historicalEndDate).endOf('day');
    return _.filter(historicalGDD, (historical) => {
      const timestamp = moment(_.get(historical, 'date'));
      return timestamp.isSameOrAfter(start) && timestamp.isSameOrBefore(end);
    });
  }, [historicalStartDate, historicalEndDate, historicalGDD]);

  const forceUpdate = useCallback(async () => {
    if (
      locationSource === LOCATION_SOURCES.FIELD &&
      selectedGrowerId &&
      selectedSeasonId &&
      selectedFarmId &&
      selectedFieldId
    ) {
      dispatch(
        forceUpdateFieldWeatherAction(
          selectedGrowerId,
          selectedSeasonId,
          selectedFarmId,
          selectedFieldId
        )
      );
    }
    if (
      locationSource === LOCATION_SOURCES.BROWSER_LOCATION &&
      browserLocation
    ) {
      dispatch(
        forceUpdateLocationWeatherAction(
          seasonYear,
          _.get(browserLocation, 'lat'),
          _.get(browserLocation, 'lng')
        )
      );
    }

    if (
      locationSource === LOCATION_SOURCES.WEATHER_STATION &&
      weatherStationId
    ) {
      dispatch(
        forceUpdateWeatherStationWeatherAction(
          selectedGrowerId,
          selectedSeasonId,
          weatherStationId
        )
      );
    }
  }, [
    browserLocation,
    dispatch,
    locationSource,
    seasonYear,
    selectedFarmId,
    selectedFieldId,
    selectedGrowerId,
    selectedSeasonId,
    weatherStationId
  ]);

  useEffect(() => {
    if (
      locationSource === LOCATION_SOURCES.FIELD &&
      selectedGrowerId &&
      selectedSeasonId &&
      selectedFarmId &&
      selectedFieldId
    ) {
      dispatch(
        updateFieldWeatherAction(
          selectedGrowerId,
          selectedSeasonId,
          selectedFarmId,
          selectedFieldId
        )
      );
    }
  }, [
    dispatch,
    locationSource,
    selectedFarmId,
    selectedFieldId,
    selectedGrowerId,
    selectedSeasonId
  ]);

  useEffect(() => {
    if (
      locationSource === LOCATION_SOURCES.BROWSER_LOCATION &&
      browserLocation
    ) {
      dispatch(
        updateLocationWeatherAction(
          seasonYear,
          _.get(browserLocation, 'lat'),
          _.get(browserLocation, 'lng')
        )
      );
    }
  }, [browserLocation, dispatch, locationSource, seasonYear]);

  useEffect(() => {
    if (
      locationSource === LOCATION_SOURCES.WEATHER_STATION &&
      weatherStationId
    ) {
      dispatch(
        updateWeatherStationWeatherAction(
          selectedGrowerId,
          selectedSeasonId,
          weatherStationId
        )
      );
    }
  }, [
    dispatch,
    locationSource,
    selectedGrowerId,
    selectedSeasonId,
    weatherStationId
  ]);

  useEffect(() => {
    if (selectedField || !navigator.geolocation) {
      setLocationSource(LOCATION_SOURCES.FIELD);
    }
  }, [selectedField]);

  useEffect(() => {
    if (
      !weatherStationId &&
      locationSource === LOCATION_SOURCES.WEATHER_STATION
    ) {
      setLocationSource(LOCATION_SOURCES.FIELD);
    }
  }, [locationSource, weatherStationId]);

  const updateLocation = useCallback(() => {
    navigator.geolocation.getCurrentPosition(
      (pos) => {
        const lat = Math.round(pos.coords.latitude * 100) / 100;
        const lng = Math.round(pos.coords.longitude * 100) / 100;
        setBrowserLocation((existingLocation) => {
          if (
            _.get(existingLocation, 'lat') !== lat ||
            _.get(existingLocation, 'lng') !== lng
          ) {
            return {
              lat,
              lng
            };
          } else {
            return existingLocation;
          }
        });
      },
      (err) => {
        if (err.code === 1) {
          setLocationError(
            'In order to use Current Location please allow the browser to access the location.'
          );
        } else {
          setLocationError(
            'Something went wrong when acquiring location. Please try again later.'
          );
        }
      }
    );
  }, []);

  useEffect(() => {
    if (locationSource === LOCATION_SOURCES.BROWSER_LOCATION) {
      updateLocation();
      const interval = setInterval(
        () => updateLocation(),
        locationPollingIntervalSeconds * 1000
      );
      return () => clearInterval(interval);
    }
  }, [locationSource, updateLocation]);

  const selectWeatherStation = useCallback(
    (stationId) => {
      const selectedStation = _.find(
        weatherStations,
        (station) => station.id === stationId
      );
      if (selectedStation) {
        setSelectedWeatherStation(selectedStation);
      }
    },
    [weatherStations]
  );

  const weatherContext = useMemo(
    () => ({
      dashboardTab,
      forecastTab,
      forecast,
      locationSource,
      dailyForecast,
      forecastGDD,
      dailyHistorical,
      historical,
      historicalGDD: filteredHistoricalGDD,
      currentConditions,
      updatedAt,
      errorMessage,
      inProgress,
      success,
      location: browserLocation,
      locationError,
      historicalStartDate,
      historicalEndDate,
      selectedChart,
      showHourlyHistorical,
      weatherStations,
      selectedWeatherStation,
      setHistoricalStartDate,
      setHistoricalEndDate,
      setDashboardTab,
      setForecastTab,
      setLocationSource,
      setSelectedChart,
      forceUpdate,
      timespan,
      setTimespan,
      customDays,
      setCustomDays,
      selectWeatherStation
    }),
    [
      dashboardTab,
      forecastTab,
      forecast,
      locationSource,
      dailyForecast,
      forecastGDD,
      dailyHistorical,
      historical,
      filteredHistoricalGDD,
      currentConditions,
      updatedAt,
      errorMessage,
      inProgress,
      success,
      browserLocation,
      locationError,
      historicalStartDate,
      historicalEndDate,
      selectedChart,
      showHourlyHistorical,
      weatherStations,
      selectedWeatherStation,
      setHistoricalStartDate,
      setHistoricalEndDate,
      setDashboardTab,
      setForecastTab,
      setSelectedChart,
      forceUpdate,
      timespan,
      setTimespan,
      customDays,
      setCustomDays,
      selectWeatherStation
    ]
  );

  return (
    <WeatherContext.Provider value={weatherContext}>
      {children}
    </WeatherContext.Provider>
  );
};
