import { useMemo } from 'react';

import _ from 'lodash';

import { growthModelApiService } from '../../../services/growthModelApi.service';
import { createApiSlice } from '../utils/createApiSlice';

const getGrowthModelApiFieldsSlice = createApiSlice({
  reducerPath: ['growthModelApiAdmin', 'getGrowthModelApiFields'],
  apiFunction: growthModelApiService.getFields,
  getStateUpdatePath: (action) => {
    const season = _.get(action, 'payload.apiArguments.0');
    return [season];
  },
  getSelectorPath: (season) => {
    return [season];
  },
  apiArgumentsFn: ({ season }) => [season]
});

const { actions, useApiSelectors } = getGrowthModelApiFieldsSlice;

export const getGrowthModelApiFieldsActions = actions;

export const useGetGrowthModelApiFieldsSelectors = (season) => {
  const { inProgress, errorMessage, success, updatedAt, response } =
    useApiSelectors(season);

  return useMemo(
    () => ({
      inProgress,
      errorMessage,
      success,
      updatedAt,
      response
    }),
    [errorMessage, inProgress, response, success, updatedAt]
  );
};

export default getGrowthModelApiFieldsSlice;
