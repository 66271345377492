import React from 'react';

import { Box } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import cx from 'classnames';

const useStyles = makeStyles((theme) => ({
  header: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    minHeight: '3.5rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    textTransform: 'uppercase',
    color: 'rgba(0, 0, 0, 0.7)',
    fontWeight: 'bold',
    fontSize: '0.88rem',
    borderBottom: '1px solid rgba(0, 0, 0, 0.125)'
  }
}));

const ContentHeader = ({ headerRef, children, className }) => {
  const styles = useStyles();

  return (
    <Box ref={headerRef} className={cx(styles.header, className)}>
      {children}
    </Box>
  );
};

export default ContentHeader;
