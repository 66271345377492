export const userRoles = {
  UserView: {
    type: 'UserView',
    name: 'User View',
    order: 0
  },
  User: {
    type: 'User',
    name: 'User',
    order: 1
  },
  Consultant: {
    type: 'Consultant',
    name: 'Consultant',
    order: 2
  },
  Admin: {
    type: 'Admin',
    name: 'Admin',
    order: 3
  }
};

export const UserActions = {
  LogIn: { type: 'LogIn', name: 'Log In' },
  TokenRefresh: { type: 'TokenRefresh', name: 'Token Refresh' },
  PageView: { type: 'PageView', name: 'Page View' }
};
