export const apiUrl = process.env.REACT_APP_API_URL;

export const mapboxToken = process.env.REACT_APP_MAPBOX_TOKEN;
export const mapTransitionDuration =
  process.env.REACT_APP_MAP_TRANSITION_DURATION || 1500;

export const weatherConfig = {
  currentConditionsUpdateInterval:
    process.env.REACT_APP_CURRENT_CONDITIONS_UPDATE_INTERVAL || 120,
  forecastAndHistoricalUpdateInterval:
    process.env.REACT_APP_FORECAST_AND_HISTORICAL_UPDATE_INTERVAL || 600,
  seasonStart: process.env.REACT_APP_HISTORICAL_SEASON_START || '02-15',
  seasonEnd: process.env.REACT_APP_HISTORICAL_SEASON_END || '10-15'
};

export const apiUpdateIntervals = {
  growthUpdateIntervalSeconds:
    process.env.REACT_APP_GROWTH_UPDATE_INTERVAL_SECONDS || 600,
  growersUpdateIntervalSeconds:
    process.env.REACT_APP_GROWERS_UPDATE_INTERVAL_SECONDS || 600,
  fieldAccessibilityUpdateIntervalSeconds:
    process.env.REACT_APP_FIELD_ACCESSIBILITY_UPDATE_INTERVAL_SECONDS || 3600,
  weatherUpdateIntervalSeconds:
    process.env.REACT_APP_WEATHER_UPDATE_INTERVAL_SECONDS || 600,
  historicalDeviceStateUpdateInterval:
    process.env.REACT_APP_HISTORICAL_DEVICE_STATE_UPDATE_INTERVAL_SECONDS || 600
};

export const growthConfig = {
  defaultTargetGrainMoisture:
    process.env.REACT_APP_DEFAULT_TARGET_GRAIN_MOISTURE || 20
};

export const buildNumber = process.env.REACT_APP_BUILD_NUMBER;
