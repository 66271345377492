import getGrowthModelApiFieldsSlice from './getGrowthModelApiFieldsSlice';
import getGrowthModelApiGrowthFeedbackSliceSlice from './getGrowthModelApiGrowthFeedbackSlice';
import getGrowthModelApiGrowthsSlice from './getGrowthModelApiGrowthsSlice';
import getGrowthModelApiGrowthStagesSlice from './getGrowthModelApiGrowthStagesSlice';
import getGrowthModelApiHistoricalGrowthStagesCsvSlice from './getGrowthModelApiHistoricalGrowthStagesCsvSlice';
import getGrowthModelApiProductionGrowthStagesCsvSlice from './getGrowthModelApiProductionGrowthStagesCsvSlice';

const growthModelApiAdminSlices = [
  getGrowthModelApiFieldsSlice,
  getGrowthModelApiGrowthsSlice,
  getGrowthModelApiGrowthStagesSlice,
  getGrowthModelApiGrowthFeedbackSliceSlice,
  getGrowthModelApiHistoricalGrowthStagesCsvSlice,
  getGrowthModelApiProductionGrowthStagesCsvSlice
];

export default growthModelApiAdminSlices;
