import { useMemo } from 'react';

import moment from 'moment';
import { useSelector } from 'react-redux';

import { createDataSlice } from '../../utils/createDataSlice';

const globalSeasonSlice = createDataSlice({
  reducerPath: ['gff', 'seasons', 'globalSeason'],
  initialState: { selectedSeason: null },
  reducers: {
    selectSeason(state, action) {
      state.selectedSeason = action.payload.season;
    }
  }
});

const { actions, baseSelector } = globalSeasonSlice;

export const globalSeasonSelector = baseSelector;
export const globalSeasonActions = actions;

export const useGlobalSeasonSelectors = () => {
  const { selectedSeason } = useSelector(globalSeasonSelector);

  const isHistorical = selectedSeason < moment().year();

  return useMemo(
    () => ({
      selectedSeason,
      isHistorical
    }),
    [selectedSeason, isHistorical]
  );
};

export default globalSeasonSlice;
