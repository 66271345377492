import React, { useContext, useMemo } from 'react';

import _ from 'lodash';
import { Redirect, Route } from 'react-router-dom';

import { AuthContext } from '../../../_context/AuthContext';

const RestrictedRoute = ({ component, allowedRoles, ...props }) => {
  const { hasRole, isActive, tosAccepted } = useContext(AuthContext);

  const isAllowed = useMemo(
    () =>
      _.some(allowedRoles, (role) => hasRole(role)) && isActive && tosAccepted,
    [allowedRoles, hasRole, isActive, tosAccepted]
  );

  return isAllowed ? (
    <Route component={component} {...props} />
  ) : (
    <Route {...props}>
      <Redirect to={{ pathname: '/' }} />
    </Route>
  );
};

export default RestrictedRoute;
