import { getErrorMessage } from '../../services/errors';
import { acceptTermsOfServiceActionTypes } from '../actionTypes/userActionTypes';
import { requestStatus } from '../helpers/requestStatus';

export const userReducer = (state = {}, action) => {
  switch (action.type) {
    case acceptTermsOfServiceActionTypes.REQUEST:
      return {
        ...state,
        acceptTermsOfService: {
          status: requestStatus.IN_PROGRESS
        }
      };
    case acceptTermsOfServiceActionTypes.SUCCEEDED:
      return {
        ...state,
        acceptTermsOfService: {
          status: requestStatus.SUCCESS
        }
      };
    case acceptTermsOfServiceActionTypes.FAILED:
      return {
        ...state,
        acceptTermsOfService: {
          status: requestStatus.FAILURE,
          error: {
            message: getErrorMessage(action.payload.apiError)
          }
        }
      };
    case acceptTermsOfServiceActionTypes.CLEAR:
      return { ...state, acceptTermsOfService: null };
    default:
      return state;
  }
};
