import { userService } from '../../../services';
import { createApiSlice } from '../utils/createApiSlice';

const getCurrentUserSlice = createApiSlice({
  reducerPath: ['newUser', 'getCurrentUser'],
  apiFunction: userService.getCurrentUser
});

const { actions, useApiSelectors } = getCurrentUserSlice;

export const getCurrentUserActions = actions;

export const useGetCurrentUserSelectors = useApiSelectors;

export default getCurrentUserSlice;
