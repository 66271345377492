import React, { useMemo } from 'react';

import { ListSubheader } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import _ from 'lodash';

import {
  useTechnologiesConfig,
  useVarietiesConfig
} from '../../_store/selectors/configSelectors';

const VarietyDropdown = ({ ...props }) => {
  const { varieties } = useVarietiesConfig();
  const { technologies } = useTechnologiesConfig();

  const varietiesByTechnology = useMemo(
    () => _.groupBy(varieties, 'technologyId'),
    [varieties]
  );

  return (
    <TextField {...props}>
      {_.flatMap(technologies, (technology) => [
        <ListSubheader key={_.get(technology, 'id')}>
          {_.get(technology, 'name')}
        </ListSubheader>,
        ..._.map(
          _.get(varietiesByTechnology, [_.get(technology, 'id')]),
          (variety) => (
            <MenuItem key={_.get(variety, 'id')} value={_.get(variety, 'id')}>
              {_.get(variety, 'name')}
            </MenuItem>
          )
        )
      ])}
    </TextField>
  );
};

export default VarietyDropdown;
