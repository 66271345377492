import React from 'react';

import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import makeStyles from '@material-ui/core/styles/makeStyles';
import _ from 'lodash';

import { useGrowthStagesConfig } from '../../_store/selectors/configSelectors';

const useStyles = makeStyles(() => ({
  badge: (props) => ({
    backgroundColor: props.backgroundColor,
    width: '1.5rem',
    height: '1.5rem',
    borderRadius: '1rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }),
  icon: (props) => ({
    color: props.textColor,
    fontSize: '0.75rem'
  })
}));

const SmallGrowthStageBadge = ({ growthStage, stageObserved }) => {
  const { growthStagesConfig } = useGrowthStagesConfig();

  const backgroundColor = _.get(growthStagesConfig, [
    growthStage,
    'outlineColor'
  ]);

  const textColor = _.get(growthStagesConfig, [growthStage, 'textColor']);

  const stageName = _.get(growthStagesConfig, [growthStage, 'name']);

  const styles = useStyles({ backgroundColor, textColor });

  return (
    <Tooltip title={stageObserved ? `Observed ${stageName}` : stageName}>
      <Paper elevation={3} className={styles.badge}>
        {!!stageObserved && (
          <FontAwesomeIcon className={styles.icon} icon={faCheck} />
        )}
      </Paper>
    </Tooltip>
  );
};

export default SmallGrowthStageBadge;
