import React, { useCallback } from 'react';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import _ from 'lodash';

const useStyles = makeStyles((theme) => ({
  tabs: {
    marginBottom: '1rem',
    marginTop: '-10px',
    [theme.breakpoints.up('md')]: { marginTop: '-20px' }
  }
}));

const PageTabs = ({ tabs, onTabChange, activeTabId }) => {
  const styles = useStyles();

  const handleTabChange = useCallback(
    (event, tabId) => {
      onTabChange(tabId);
    },
    [onTabChange]
  );

  return (
    <>
      {activeTabId && (
        <Tabs
          value={activeTabId}
          classes={{ root: styles.tabs }}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="on"
        >
          {_.map(tabs, (tab) => (
            <Tab
              key={_.get(tab, 'tabId')}
              value={_.get(tab, 'tabId')}
              label={_.get(tab, 'name')}
            />
          ))}
        </Tabs>
      )}
    </>
  );
};

export default PageTabs;
