import _ from 'lodash';
import { combineReducers } from 'redux';

const buildReducersTree = (descriptors) => {
  const reducersTree = {};

  for (let descriptor of descriptors) {
    const path = _.get(descriptor, 'path');
    if (_.get(reducersTree, path)) {
      throw new Error(`Duplicated reducer path: ${path}`);
    }
    _.set(
      reducersTree,
      _.get(descriptor, 'path'),
      _.get(descriptor, 'reducer')
    );
  }

  return reducersTree;
};

const combineReducersTree = (node) => {
  if (_.isFunction(node)) {
    // This is a leaf (a reducer)
    return node;
  }

  return _.mapValues(node, (childNode) => {
    const children = combineReducersTree(childNode);
    return _.isFunction(children) ? children : combineReducers(children);
  });
};

export const combineDescriptors = (descriptors) => {
  const reducerDescriptors = _.map(descriptors, 'reducerDescriptor');
  const reducersTree = buildReducersTree(reducerDescriptors);
  return combineReducersTree(reducersTree);
};
