import React, { useCallback, useContext, useState } from 'react';

import _ from 'lodash';

import { FarmStructureContext } from '../../_context/FarmStructureContext';
import { useGetSnapshotsDatesSelectors } from '../../_store/slices/growths/snapshots/getSnapshotsDatesSlice';
import {
  ContentBody,
  ContentContainer,
  ResponsiveContentHeader
} from '../../Layout/Content';
import ActivitiesHeaderButtons from '../Activities/ActivitiesHeaderButtons';
import ActivitiesTimeline from '../Activities/ActivitiesTimeline';
import LoadingIndicator from '../LoadingIndicator';

import { useGrowthModelSnapshots } from './_hooks/useGrowthModelSnapshots';
import GrowthModelSnapshotsDatesSelector from './GrowthModelSnapshotsDatesSelector';
import GrowthModelSnapshotsTable from './GrowthModelSnapshotsTable';

const GrowthModelSnapshots = ({ mobileLayout }) => {
  const [firstSnapshot, setFirstSnapshot] = useState();
  const [secondSnapshot, setSecondSnapshot] = useState();

  const {
    selectedGrowerId: growerId,
    selectedSeasonId: seasonId,
    selectedFarmId: farmId,
    selectedFieldId: fieldId
  } = useContext(FarmStructureContext);

  const {
    inProgress: snapshotsInProgress,
    activeGrowthInProgress,
    timelineActivities,
    activeGrowth,
    minDate,
    maxDate
  } = useGrowthModelSnapshots({
    firstSelectedSnapshot: firstSnapshot,
    secondSelectedSnapshot: secondSnapshot
  });

  const growthId = _.get(activeGrowth, 'id');

  const { inProgress: datesInProgress, errorMessage: datesErrorMessage } =
    useGetSnapshotsDatesSelectors(growthId);

  const handleFirstSnapshotChange = useCallback((snapshot) => {
    setFirstSnapshot(snapshot);
  }, []);

  const handleSecondSnapshotChange = useCallback((snapshot) => {
    setSecondSnapshot(snapshot);
  }, []);

  return (
    <>
      <ContentContainer>
        <ResponsiveContentHeader>
          <span>Select Dates</span>
        </ResponsiveContentHeader>
        <ContentBody>
          <GrowthModelSnapshotsDatesSelector
            growerId={growerId}
            seasonId={seasonId}
            farmId={farmId}
            fieldId={fieldId}
            growthId={growthId}
            activeGrowthInProgress={activeGrowthInProgress}
            firstSnapshot={firstSnapshot}
            secondSnapshot={secondSnapshot}
            onFirstSnapshotChange={handleFirstSnapshotChange}
            onSecondSnapshotChange={handleSecondSnapshotChange}
          />
        </ContentBody>
      </ContentContainer>
      {fieldId &&
        !mobileLayout &&
        !datesInProgress &&
        !activeGrowthInProgress &&
        !datesErrorMessage &&
        activeGrowth && (
          <ContentContainer>
            <ResponsiveContentHeader>
              <span>Timeline</span>
              <ActivitiesHeaderButtons />
            </ResponsiveContentHeader>
            <ContentBody>
              <ActivitiesTimeline
                inProgress={snapshotsInProgress}
                timelineActivities={timelineActivities}
                minDate={minDate}
                maxDate={maxDate}
                showModifiedDate
                disableFarmName
              />
            </ContentBody>
          </ContentContainer>
        )}
      {fieldId &&
        !datesInProgress &&
        !activeGrowthInProgress &&
        !datesErrorMessage &&
        activeGrowth && (
          <ContentContainer>
            <ResponsiveContentHeader>
              <span>Growth Models Comparison</span>
            </ResponsiveContentHeader>
            {snapshotsInProgress && <LoadingIndicator />}
            <ContentBody>
              {!snapshotsInProgress && (
                <GrowthModelSnapshotsTable
                  timelineActivities={timelineActivities}
                  firstSnapshot={firstSnapshot}
                  secondSnapshot={secondSnapshot}
                />
              )}
            </ContentBody>
          </ContentContainer>
        )}
    </>
  );
};

export default GrowthModelSnapshots;
