import React, { useCallback, useMemo, useState } from 'react';

import * as d3 from 'd3';
import _ from 'lodash';
import { v4 as uuid } from 'uuid';

import { useTimelineFieldNamesWidth } from '../Charts/_hooks/useTimelineFieldNamesWidth';
import ChartTimeAxis from '../Charts/ChartTimeAxis';
import TimelineFieldNames from '../Charts/TimelineFieldNames';

import { parseDate } from './_utils/fieldAccessibilityUtils';
import FieldAccessibilityPopover from './FieldAccessibilityPopover';
import FieldAccessibilityTimeline from './FieldAccessibilityTimeline';

const timelineBarHeight = 25;
const timelineGap = 6;
const scaleMargin = 20;
const scaleHeight = 40;
const todayLineExcess = 10;

const FieldAccessibilityTimelineChart = ({
  fieldAccessibility,
  containerWidth,
  minDate,
  maxDate,
  rangeSelection
}) => {
  const [clipPathId] = useState(`fieldAccessibilityClipPath-${uuid()}`);

  const [hoverParams, setHoverParams] = useState();

  const { fieldNamesWidth } = useTimelineFieldNamesWidth(containerWidth);

  const debouncedSetHoverParams = useMemo(
    () => _.debounce(setHoverParams, 200),
    []
  );

  const fieldAccessibilityCount = _.get(fieldAccessibility, 'length', 0);

  const fieldNames = useMemo(
    () =>
      _.map(fieldAccessibility, (item) => ({
        id: _.get(item, 'fieldId'),
        fieldName: _.get(item, 'fieldName'),
        farmName: _.get(item, 'farmName')
      })),
    [fieldAccessibility]
  );

  const timelinesHeight = useMemo(() => {
    const h =
      fieldAccessibilityCount * (timelineBarHeight + timelineGap) - timelineGap;
    return h > 0 ? h : 0;
  }, [fieldAccessibilityCount]);

  const height = useMemo(
    () => timelinesHeight + 2 * todayLineExcess + scaleMargin + scaleHeight,
    [timelinesHeight]
  );

  const timeScale = useMemo(
    () =>
      d3
        .scaleTime()
        .domain([parseDate(minDate), parseDate(maxDate)])
        .range([fieldNamesWidth, containerWidth]),
    [minDate, maxDate, fieldNamesWidth, containerWidth]
  );

  const selectedTimeScale = useMemo(() => {
    if (
      rangeSelection &&
      rangeSelection.length === 2 &&
      !isNaN(rangeSelection[0]) &&
      !isNaN(rangeSelection[1])
    ) {
      return timeScale.copy().domain(rangeSelection);
    }
    return timeScale;
  }, [rangeSelection, timeScale]);

  const handleMouseOver = useCallback(
    (params) => {
      debouncedSetHoverParams(params);
    },
    [debouncedSetHoverParams]
  );

  const handleMouseOut = useCallback(() => {
    debouncedSetHoverParams((params) => ({ ...params, element: null }));
  }, [debouncedSetHoverParams]);

  return (
    <>
      <svg width={containerWidth} height={height}>
        <TimelineFieldNames
          fieldNames={fieldNames}
          paddingTop={todayLineExcess}
          barHeight={timelineBarHeight}
          barGap={timelineGap}
          fieldNamesWidth={fieldNamesWidth}
        />
        <ChartTimeAxis
          paddingTop={height - scaleHeight}
          selectedTimeScale={selectedTimeScale}
        />
        <g clipPath={`url(#${clipPathId})`}>
          <FieldAccessibilityTimeline
            svgWidth={containerWidth}
            paddingTop={todayLineExcess}
            barHeight={timelineBarHeight}
            barGap={timelineGap}
            rectangleRadius={0}
            fieldAccessibility={fieldAccessibility}
            selectedTimeScale={selectedTimeScale}
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
          />
        </g>
        <clipPath id={clipPathId}>
          <rect
            x={fieldNamesWidth}
            y={todayLineExcess}
            width={containerWidth - fieldNamesWidth}
            height={timelinesHeight}
          />
        </clipPath>
      </svg>
      <FieldAccessibilityPopover hoverParams={hoverParams} />
    </>
  );
};

export default FieldAccessibilityTimelineChart;
