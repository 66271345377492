import React, { useCallback, useContext, useState } from 'react';

import { IconButton } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import makeStyles from '@material-ui/core/styles/makeStyles';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { useHistory } from 'react-router-dom';

import { AuthContext } from '../../../_context/AuthContext';

import ChangePasswordDialog from './ChangePasswordDialog';

const useStyles = makeStyles(() => ({
  container: { borderLeft: '1px solid #ffffff50' },
  userButton: {
    color: '#ffffff'
  }
}));

export default function UserDropdown() {
  const styles = useStyles();
  const history = useHistory();
  const { user, logOut, isActive } = useContext(AuthContext);
  const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] =
    useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleLogoutClick = useCallback(() => {
    logOut();
  }, [logOut]);

  const handleToggleChangePasswordModal = useCallback(() => {
    setIsChangePasswordModalOpen(!isChangePasswordModalOpen);
  }, [isChangePasswordModalOpen, setIsChangePasswordModalOpen]);

  const handleOpenMenu = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);
  const handleCloseMenu = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleShareFeedbackClick = useCallback(() => {
    history.push('/share-feedback');
  }, [history]);

  return (
    <>
      {user && (
        <Box className={styles.container}>
          <IconButton className={styles.userButton} onClick={handleOpenMenu}>
            <AccountCircleIcon fontSize="large" />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={!!anchorEl}
            onClose={handleCloseMenu}
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
          >
            <MenuItem disabled>{`${user.firstName} ${user.lastName}`}</MenuItem>
            {isActive && (
              <MenuItem onClick={handleToggleChangePasswordModal}>
                Change password
              </MenuItem>
            )}
            <MenuItem onClick={handleShareFeedbackClick}>
              Share Feedback
            </MenuItem>
            <MenuItem onClick={handleLogoutClick}>Logout</MenuItem>
          </Menu>
          <ChangePasswordDialog
            isOpen={isChangePasswordModalOpen}
            toggle={handleToggleChangePasswordModal}
          />
        </Box>
      )}
    </>
  );
}
