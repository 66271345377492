import React, { useEffect, useMemo, useRef } from 'react';

import makeStyles from '@material-ui/core/styles/makeStyles';
import * as d3 from 'd3';

const useStyles = makeStyles(() => ({
  axis: (props) => ({
    '& > .tick': {
      stroke: 'lightgray',
      opacity: props.ticksOpacity,
      shapeRendering: 'crispEdges'
    },
    '& > path': {
      strokeWidth: 0
    }
  })
}));

const xAxis = (g, x, width, paddingTop, textColor) =>
  g
    .attr('transform', `translate(0, ${paddingTop})`)
    .call(d3.axisBottom(x).ticks(width / 100))
    .selectAll('text')
    .style('text-anchor', 'middle')
    .attr('fill', textColor)
    .attr('stroke', 'none')
    .attr('font-size', 12)
    .attr('dy', '1em');

const ChartTimeAxis = ({
  paddingTop,
  selectedTimeScale,
  textColor = '#000000',
  opacity = 0.5
}) => {
  const styles = useStyles({ ticksOpacity: opacity });
  const xAxisRef = useRef();

  const width = useMemo(() => {
    return selectedTimeScale.range()[1] - selectedTimeScale.range()[0];
  }, [selectedTimeScale]);

  useEffect(() => {
    if (!xAxisRef.current) {
      return;
    }
    const gx = d3.select(xAxisRef.current);
    gx.call(xAxis, selectedTimeScale, width, paddingTop, textColor);
  }, [paddingTop, selectedTimeScale, textColor, width]);

  return <g ref={xAxisRef} className={styles.axis} />;
};

export default ChartTimeAxis;
