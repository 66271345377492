import React, { useContext } from 'react';

import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { AuthContext } from '../../../_context/AuthContext';

import GrowerFarmFieldSelector from './GrowerFarmFieldSelector';
import UserDropdown from './UserDropdown';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    flexBasis: '19.75rem'
  }
}));

export default function RightHeaderMenu() {
  const styles = useStyles();

  const { isActive, tosAccepted } = useContext(AuthContext);

  return (
    <Box className={styles.container}>
      {isActive && tosAccepted && <GrowerFarmFieldSelector />}
      <UserDropdown />
    </Box>
  );
}
