import React, { useCallback, useMemo } from 'react';

import _ from 'lodash';
import { useDispatch } from 'react-redux';

import {
  growthFeedbackType,
  growthFeedbackTypesList,
  growthStagesTypes
} from '../../../_constants/growthsConstants';
import { closeGrowthFeedbackDialog } from '../../../_store/actions/uiActions';
import { useGrowthStagesConfig } from '../../../_store/selectors/configSelectors';
import { useActiveGrowthSelectors } from '../../../_store/selectors/growthsSelectors';
import { useGrowthFeedbackDialogSelectors } from '../../../_store/selectors/uiSelectors';
import ResponsiveDialog from '../../ResponsiveDialog';

import GrowthFeedbackForm from './GrowthFeedbackForm';

const GrowthFeedbackDialog = () => {
  const dispatch = useDispatch();

  const { growthStagesConfig } = useGrowthStagesConfig();

  const { open, growerId, seasonId, farmId, fieldId, growthStage } =
    useGrowthFeedbackDialogSelectors();

  const { growth } = useActiveGrowthSelectors(seasonId, fieldId);

  const selectedFeedback = useMemo(
    () =>
      _.chain(growth)
        .get('growthFeedback')
        .find((item) => item.stage === growthStage)
        .value(),
    [growthStage, growth]
  );

  const growthStagesList = useMemo(
    () =>
      _.chain(growthStagesConfig)
        .values()
        .filter(
          (stage) =>
            stage.type !== growthStagesTypes.Unknown &&
            stage.type !== growthStagesTypes.NotPlanted
        )
        .orderBy((stage) => stage.order)
        .value(),
    [growthStagesConfig]
  );

  const availableGrowthStages = useMemo(() => {
    if (selectedFeedback) {
      const stage = _.get(growthStagesConfig, [
        _.get(selectedFeedback, 'stage')
      ]);
      return [stage];
    }
    const stagesFeedback = _.chain(growth)
      .get('growthFeedback')
      .filter((item) => item.type === growthFeedbackType.GrowthStage.type)
      .keyBy((item) => item.stage)
      .value();

    const latestStageFeedbackOrder = _.chain(stagesFeedback)
      .keys()
      .map((type) => _.find(growthStagesList, (stage) => stage.type === type))
      .filter((item) => !!item)
      .orderBy((stage) => _.get(stage, 'order'))
      .last()
      .get('order', 0)
      .value();

    return _.chain(growthStagesList)
      .filter(
        (stage) =>
          stage.type === _.get(selectedFeedback, 'stage') ||
          latestStageFeedbackOrder < stage.order
      )
      .map((stage) =>
        stage.type === 'Emergence'
          ? { ...stage, name: 'Vegetative - Emergence' }
          : stage
      )
      .value();
  }, [growth, growthStagesConfig, growthStagesList, selectedFeedback]);

  const availableFeedbackTypes = useMemo(() => {
    return _.filter(
      growthFeedbackTypesList,
      (item) =>
        item.type === _.get(selectedFeedback, 'type') ||
        (item.type === growthFeedbackType.GrowthStage.type &&
          !_.isEmpty(availableGrowthStages)) ||
        item.type === growthFeedbackType.Custom.type
    );
  }, [availableGrowthStages, selectedFeedback]);

  const handleClose = useCallback(() => {
    dispatch(closeGrowthFeedbackDialog());
  }, [dispatch]);

  return (
    <>
      <ResponsiveDialog open={open}>
        {open && (
          <GrowthFeedbackForm
            availableFeedbackTypes={availableFeedbackTypes}
            availableGrowthStages={availableGrowthStages}
            selectedFeedback={selectedFeedback}
            onClose={handleClose}
            growth={growth}
            growerId={growerId}
            seasonId={seasonId}
            farmId={farmId}
            fieldId={fieldId}
            selectedGrowthStage={growthStage}
            growthStagesConfig={growthStagesConfig}
          />
        )}
      </ResponsiveDialog>
    </>
  );
};

export default GrowthFeedbackDialog;
