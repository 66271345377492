import React, { useCallback } from 'react';

import Drawer from '@material-ui/core/Drawer';
import { makeStyles } from '@material-ui/core/styles';
import cx from 'classnames';

import { useMenuState } from '../../_hooks/ui/useMenuState';

import MenuContent from './MenuContent';

const useStyles = makeStyles((theme) => ({
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: theme.dimensions.menuDrawerWidth,
      flexShrink: 0
    },
    overflow: 'hidden'
  },
  drawerPaper: {
    width: theme.dimensions.menuDrawerWidth,
    paddingTop: theme.dimensions.headerHeight,
    backgroundColor: 'rgb(46, 64, 87)',
    overflow: 'hidden'
  },
  closeMenuButton: {
    marginRight: 'auto',
    marginLeft: 0
  },
  collapsedDrawerOpen: {
    width: theme.dimensions.menuDrawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  collapsedDrawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: 'hidden',
    width: theme.dimensions.collapsedMenuDrawerWidth
  }
}));

function MenuDrawer() {
  const classes = useStyles();

  const {
    toggleMobileSideMenu,
    showMobileSideMenu,
    collapsedSideMenu,
    showMobileMenu
  } = useMenuState();

  const handleClick = useCallback(() => {
    // Close menu on phone layouts when a link is clicked
    if (showMobileSideMenu) {
      toggleMobileSideMenu();
    }
  }, [showMobileSideMenu, toggleMobileSideMenu]);

  return (
    <nav>
      {showMobileMenu && (
        <Drawer
          variant="persistent"
          anchor="left"
          open={showMobileSideMenu}
          onClose={handleClick}
          classes={{
            paper: classes.drawerPaper
          }}
          ModalProps={{
            keepMounted: true // Better open performance on mobile.
          }}
        >
          <MenuContent onCLick={handleClick} />
        </Drawer>
      )}
      {!showMobileMenu && (
        <Drawer
          className={cx(classes.drawer, {
            [classes.collapsedDrawerOpen]: !collapsedSideMenu,
            [classes.collapsedDrawerClose]: collapsedSideMenu
          })}
          variant="permanent"
          classes={{
            paper: cx(classes.drawerPaper, {
              [classes.collapsedDrawerOpen]: !collapsedSideMenu,
              [classes.collapsedDrawerClose]: collapsedSideMenu
            })
          }}
        >
          <MenuContent onCLick={handleClick} />
        </Drawer>
      )}
    </nav>
  );
}

export default MenuDrawer;
