import React, { useMemo } from 'react';

import { Chart } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import _ from 'lodash';
import moment from 'moment';

import 'chartjs-adapter-moment';

import GrowthModelChart, { commonLineDataSetParams } from './GrowthModelChart';

Chart.register(annotationPlugin);

const defaultOptions = {
  responsive: true,
  tooltips: {
    mode: 'label'
  },
  scales: {
    'x-axis': {
      id: 'x-axis',
      type: 'time',
      time: {
        minUnit: 'day',
        tooltipFormat: 'LL'
      },
      display: true,
      gridLines: {
        display: true
      },
      ticks: {
        autoSkip: true
      }
    },
    'y-axis-precipitation': {
      type: 'linear',
      display: true,
      position: 'left',
      id: 'y-axis-precipitation',
      gridLines: {
        display: true
      }
    }
  }
};

const GrowthModelPrecipitationChart = ({
  chartRef,
  precipitationData,
  growthStages
}) => {
  const growthStagesPrecipitation = useMemo(() => {
    const gddByStage = _.map(growthStages, (stage) => {
      const startDate = moment(_.get(stage, 'date'));
      const endDate = moment(_.get(stage, 'endDate'));
      const precipitation = _.filter(precipitationData, (item) => {
        const date = moment(_.get(item, 'timestamp'));
        return date.isSameOrAfter(startDate) && date.isBefore(endDate);
      });
      const firstPrecipitationItem = _.first(precipitation);
      const startPrecipitation = _.get(
        firstPrecipitationItem,
        'accumulatedPrecipitationIn',
        0
      );
      const stagePrecipitation = _.map(precipitation, (item) => ({
        timestamp: _.get(item, 'timestamp'),
        accumulatedPrecipitationIn:
          _.get(item, 'accumulatedPrecipitationIn') - startPrecipitation
      }));

      // This is to break the line at the end of the growth stage
      _.set(
        _.first(stagePrecipitation),
        'accumulatedPrecipitationIn',
        undefined
      );
      return {
        growthStage: _.get(stage, 'growthStage'),
        stagePrecipitation: stagePrecipitation
      };
    });

    return _.chain(gddByStage)
      .flatMap((stage) => _.get(stage, 'stagePrecipitation'))
      .orderBy('date')
      .value();
  }, [precipitationData, growthStages]);

  const chartData = useMemo(() => {
    if (precipitationData) {
      const dailyPrecipitationValues = _.map(
        precipitationData,
        (v) => v['accumulatedDailyPrecipitationIn']
      );
      const accumulatedPrecipitationIn = _.map(
        precipitationData,
        (v) => v['accumulatedPrecipitationIn']
      );
      const labels = _.map(precipitationData, (data) =>
        moment(data.timestamp).format('L')
      );
      const growthStageAccumulatedPrecipitationIn = _.map(
        growthStagesPrecipitation,
        (item) => _.get(item, 'accumulatedPrecipitationIn')
      );

      const datasets = [
        {
          ...commonLineDataSetParams('#43a047'),
          label: 'Accumulated Precipitation',
          yAxisID: 'y-axis-precipitation',
          data: accumulatedPrecipitationIn
        },
        {
          ...commonLineDataSetParams('#6fc573'),
          label: 'Growth Stage Accumulated Precipitation',
          yAxisID: 'y-axis-precipitation',
          data: growthStageAccumulatedPrecipitationIn
        },
        {
          label: 'Precipitation',
          yAxisID: 'y-axis-precipitation',
          type: 'bar',
          backgroundColor: '#66bb6a66',
          borderColor: '#66bb6a',
          data: dailyPrecipitationValues
        }
      ].filter((dataset) => dataset.data);

      return {
        labels,
        datasets
      };
    }
  }, [precipitationData, growthStagesPrecipitation]);

  return (
    <GrowthModelChart
      chartRef={chartRef}
      defaultOptions={defaultOptions}
      chartData={chartData}
      growthStages={growthStages}
    />
  );
};

export default GrowthModelPrecipitationChart;
