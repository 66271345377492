import React, { useMemo } from 'react';

import makeStyles from '@material-ui/core/styles/makeStyles';
import _ from 'lodash';

const useStyles = makeStyles(() => ({
  text: {
    opacity: 0.875,
    fontWeight: '500'
  }
}));
const ActivitiesGrowthStagesTimelineLabels = ({
  svgWidth,
  barHeight,
  paddingTop,
  timelineGrowths,
  fieldNamesWidth
}) => {
  const styles = useStyles();

  const labels = useMemo(() => {
    return _.map(timelineGrowths, (growth) => {
      const startY = _.get(growth, 'startY');
      const y = startY + paddingTop + barHeight / 2;
      return {
        y,
        growthId: _.get(growth, 'id')
      };
    });
  }, [barHeight, paddingTop, timelineGrowths]);

  return (
    <g>
      {svgWidth > 0 && (
        <>
          {_.map(labels, (label) => (
            <text
              key={_.get(label, 'growthId')}
              className={styles.text}
              x={fieldNamesWidth + 10}
              y={_.get(label, 'y')}
              fontSize={14}
              textAnchor="start"
              dominantBaseline="middle"
              fill="#000000"
            >
              Growth Stages
            </text>
          ))}
        </>
      )}
    </g>
  );
};

export default ActivitiesGrowthStagesTimelineLabels;
