import React, { useCallback } from 'react';

import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const useStyles = makeStyles(() => ({
  drawerTitle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    minHeight: '3rem',
    paddingLeft: '1.5rem',
    paddingRight: '1.5rem',
    backgroundColor: '#1F2737'
  },
  title: {
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: '#D1D1D1',
    marginLeft: '0.25rem'
  },
  backArrow: {
    color: '#D1D1D1',
    paddingLeft: '0'
  }
}));

const DrawerTitle = ({ onBackClick, title, showBackButton }) => {
  const styles = useStyles();

  const handleBackClick = useCallback(() => {
    onBackClick();
  }, [onBackClick]);

  return (
    <Box className={styles.drawerTitle}>
      {showBackButton && (
        <IconButton
          onClick={handleBackClick}
          className={styles.backArrow}
          size="small"
        >
          <ArrowBackIcon />
        </IconButton>
      )}
      <Typography className={styles.title}>{title}</Typography>
    </Box>
  );
};

export default DrawerTitle;
