import { useMemo } from 'react';

import _ from 'lodash';

import { apiUpdateIntervals } from '../../../../config';
import { devicesStateService } from '../../../../services/devicesState.service';
import { createApiSlice } from '../../utils/createApiSlice';

const getHourlyDeviceStateSlice = createApiSlice({
  reducerPath: ['devices', 'state', 'getHourlyDeviceState'],
  apiFunction: devicesStateService.getHourlyStateV2,
  getStateUpdatePath: (action) => {
    const growerId = _.get(action, 'payload.apiArguments.0');
    const seasonId = _.get(action, 'payload.apiArguments.1');
    const deviceId = _.get(action, 'payload.apiArguments.2');
    return [growerId, seasonId, deviceId];
  },
  getSelectorPath: (growerId, seasonId, deviceId) => {
    return [growerId, seasonId, deviceId];
  },
  apiArgumentsFn: ({ growerId, seasonId, deviceId }) => [
    growerId,
    seasonId,
    deviceId
  ],
  updateIntervalSeconds: apiUpdateIntervals.historicalDeviceStateUpdateInterval
});

const { actions, useApiSelectors } = getHourlyDeviceStateSlice;

export const getHourlyDeviceStateActions = actions;

export const useGetHourlyDeviceStateSelectors = (
  growerId,
  seasonId,
  deviceId
) => {
  const {
    inProgress,
    errorMessage,
    success,
    response: state,
    updatedAt
  } = useApiSelectors(growerId, seasonId, deviceId);

  return useMemo(
    () => ({
      state,
      inProgress,
      errorMessage,
      success,
      updatedAt
    }),
    [errorMessage, inProgress, state, success, updatedAt]
  );
};

export default getHourlyDeviceStateSlice;
