import _ from 'lodash';
import moment from 'moment';
import { all, fork, put, select, takeEvery } from 'redux-saga/effects';

import { globalSeasonActions } from '../slices/gff/seasons/globalSeasonSlice';
import { authActions, authSelectors } from '../slices/user/authSlice';
import { clearUserDataActions } from '../slices/user/clearUserDataSlice';
import { getCurrentUserActions } from '../slices/user/getCurrentUserSlice';
import { tokenRefreshActions } from '../slices/user/tokenRefreshSlice';

function* handleUpdateUserSuccess(action) {
  const currentUser = yield select(authSelectors.getCurrentUser);
  const response = _.get(action, 'payload.response');
  if (currentUser && _.get(response, 'id') === _.get(currentUser, 'id')) {
    yield put(authActions.updateCurrentUser({ response }));
  } else {
    yield put(clearUserDataActions.clear());
  }
}

function* handleTokenRefreshSuccess(action) {
  yield handleUpdateUserSuccess(action);
  yield put(tokenRefreshActions.clear());
}

function* handleAuthSuccess(action) {
  const response = _.get(action, 'payload.response');
  const seasons = _.get(response, 'config.seasons.seasons');
  const activeSeason = _.find(
    seasons,
    (season) => _.get(season, 'seasonYear') === moment().year()
  );
  const season = activeSeason || _.last(seasons);
  if (season) {
    yield put(
      globalSeasonActions.selectSeason({ season: _.get(season, 'seasonYear') })
    );
  }
}

function* handleTokenRefreshError() {
  yield put(clearUserDataActions.clear());
}

function* watchGetCurrentUserSuccess() {
  yield takeEvery(getCurrentUserActions.success.type, handleUpdateUserSuccess);
}

function* watchAuthSuccess() {
  yield takeEvery(authActions.success.type, handleAuthSuccess);
}

function* watchTokenRefreshSuccess() {
  yield takeEvery(tokenRefreshActions.success.type, handleTokenRefreshSuccess);
}

function* watchTokenRefreshError() {
  yield takeEvery(tokenRefreshActions.error.type, handleTokenRefreshError);
}

function* currentUserSaga() {
  yield all([
    fork(watchGetCurrentUserSuccess),
    fork(watchAuthSuccess),
    fork(watchTokenRefreshSuccess),
    fork(watchTokenRefreshError)
  ]);
}

export default currentUserSaga;
