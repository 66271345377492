import React from 'react';

import { Popover, Typography } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import _ from 'lodash';

import CommonBadge from '../../../Pages/Map/Components/CommonBadge';

const useStyles = makeStyles(() => ({
  tooltip: {
    padding: '1rem'
  },
  tooltipText: {
    marginTop: '0.5rem',
    whiteSpace: 'nowrap'
  },
  popover: {
    pointerEvents: 'none'
  },
  varietyName: {
    textAlign: 'center',
    width: '100%',
    marginTop: '0.5rem'
  }
}));

const TechnologyPopover = ({ hoverParams }) => {
  const styles = useStyles();

  const anchorEl = _.get(hoverParams, 'element');
  const displayTooltip = !!anchorEl;

  const technologyColor = _.get(hoverParams, 'data.technologyColor');
  const technologyTextColor = _.get(hoverParams, 'data.technologyTextColor');
  const technologyName = _.get(hoverParams, 'data.technologyName');
  const varietyName = _.get(hoverParams, 'data.varietyName');

  return (
    <Popover
      className={styles.popover}
      classes={{ paper: styles.tooltip }}
      open={displayTooltip}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
      disableAutoFocus
      disableRestoreFocus
    >
      <CommonBadge
        backgroundColor={technologyColor}
        textColor={technologyTextColor}
        text={technologyName}
      />
      <Typography className={styles.varietyName}>{varietyName}</Typography>
    </Popover>
  );
};

export default TechnologyPopover;
