import { useMemo } from 'react';

import _ from 'lodash';
import { useSelector } from 'react-redux';

import { requestStatus } from '../helpers/requestStatus';

export const growersSelector = (state) =>
  _.get(state, 'growers.growers.growers');
export const growersStatusSelector = (state) =>
  _.get(state, 'growers.growers.status');
export const growersUpdatedAtSelector = (state) =>
  _.get(state, 'growers.growers.updatedAt');

export const configGrowersStatusSelector = (state) =>
  _.get(state, 'growers.configGrowers.status');

export const useGrowersSelectors = () => {
  const growers = useSelector(growersSelector);
  const growersFetchError = useSelector((state) =>
    _.get(state, 'growers.growers.error.message')
  );
  const inProgress =
    useSelector(growersStatusSelector) === requestStatus.IN_PROGRESS;

  return useMemo(
    () => ({ growers, growersFetchError, inProgress }),
    [growers, growersFetchError, inProgress]
  );
};

export const useGrowerSelectors = (growerId) => {
  const { growers } = useGrowersSelectors();
  const grower = useMemo(
    () => _.find(growers, (grower) => grower.id === growerId),
    [growerId, growers]
  );

  return {
    grower
  };
};

export const useConfigGrowersSelectors = () => {
  const configGrowers = useSelector((state) =>
    _.get(state, 'growers.configGrowers.response')
  );
  const error = useSelector((state) =>
    _.get(state, 'growers.configGrowers.error.message')
  );
  const status = useSelector((state) =>
    _.get(state, 'growers.configGrowers.status')
  );

  const success = status === requestStatus.SUCCESS;

  const inProgress = status === requestStatus.IN_PROGRESS;

  return useMemo(
    () => ({
      configGrowers,
      error,
      inProgress,
      success
    }),
    [configGrowers, error, inProgress, success]
  );
};

export const useConfigGrowerSelectors = (growerId) => {
  const { configGrowers, inProgress, success, error } =
    useConfigGrowersSelectors();
  const grower = useMemo(
    () => _.find(configGrowers, (grower) => grower.id === growerId),
    [growerId, configGrowers]
  );

  return useMemo(
    () => ({
      grower,
      inProgress,
      success,
      error
    }),
    [grower, inProgress, success, error]
  );
};

export const useCreateGrowerSelectors = () => {
  const status = useSelector((state) =>
    _.get(state, 'growers.createGrower.status')
  );
  const isLoading = status === requestStatus.IN_PROGRESS;
  const success = status === requestStatus.SUCCESS;
  const error = useSelector((state) =>
    _.get(state, 'growers.createGrower.error.message')
  );

  return {
    isLoading,
    success,
    error
  };
};

export const useUpdateGrowerSelectors = () => {
  const status = useSelector((state) =>
    _.get(state, 'growers.updateGrower.status')
  );
  const isLoading = status === requestStatus.IN_PROGRESS;
  const success = status === requestStatus.SUCCESS;
  const error = useSelector((state) =>
    _.get(state, 'growers.updateGrower.error.message')
  );

  return {
    isLoading,
    success,
    error
  };
};

export const useDeleteGrowerSelectors = () => {
  const growerId = useSelector((state) =>
    _.get(state, 'growers.deleteGrower.growerId')
  );

  const { grower: growerToDelete } = useConfigGrowerSelectors(growerId);
  const status = useSelector((state) =>
    _.get(state, 'growers.deleteGrower.status')
  );
  const isLoading = status === requestStatus.IN_PROGRESS;
  const success = status === requestStatus.SUCCESS;
  const error = useSelector((state) =>
    _.get(state, 'growers.deleteGrower.error.message')
  );

  return {
    growerToDelete,
    isLoading,
    success,
    error
  };
};
