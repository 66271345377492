import React, { useCallback } from 'react';

import { useDispatch } from 'react-redux';

import { closeSetTargetMoistureDialog } from '../../../_store/actions/uiActions';
import { useActiveGrowthSelectors } from '../../../_store/selectors/growthsSelectors';
import { useSetTargetMoistureDialogSelectors } from '../../../_store/selectors/uiSelectors';
import ResponsiveDialog from '../../ResponsiveDialog';

import SetTargetMoistureForm from './SetTargetMoistureForm';

const SetTargetMoistureDialog = () => {
  const dispatch = useDispatch();

  const { growerId, seasonId, farmId, fieldId, growthId, open } =
    useSetTargetMoistureDialogSelectors();

  const { growth } = useActiveGrowthSelectors(seasonId, fieldId);

  const handleClose = useCallback(() => {
    dispatch(closeSetTargetMoistureDialog());
  }, [dispatch]);

  return (
    <ResponsiveDialog open={open} maxWidth="xs">
      {open && (
        <SetTargetMoistureForm
          growth={growth}
          onClose={handleClose}
          growerId={growerId}
          seasonId={seasonId}
          farmId={farmId}
          fieldId={fieldId}
          growthId={growthId}
        />
      )}
    </ResponsiveDialog>
  );
};

export default SetTargetMoistureDialog;
