import React, { useCallback } from 'react';

import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import _ from 'lodash';
import { useDispatch } from 'react-redux';

import { openGrowthFeedbackDialog } from '../../_store/actions/uiActions';

const FeedbackButton = ({
  growerId,
  seasonId,
  farmId,
  fieldId,
  stage,
  rootClasses
}) => {
  const dispatch = useDispatch();
  const isEdit = !!_.get(stage, 'observed');

  const handleClick = useCallback(() => {
    const growthStage = _.get(stage, 'growthStage');
    dispatch(
      openGrowthFeedbackDialog(growerId, seasonId, farmId, fieldId, growthStage)
    );
  }, [dispatch, farmId, fieldId, growerId, seasonId, stage]);

  return (
    <Tooltip title={isEdit ? 'Edit Growth Feedback' : 'Add Growth Feedback'}>
      <IconButton
        size="small"
        onClick={handleClick}
        classes={{ root: rootClasses }}
      >
        {isEdit ? <EditIcon /> : <AddIcon />}
      </IconButton>
    </Tooltip>
  );
};

export default FeedbackButton;
