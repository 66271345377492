import { useMemo } from 'react';

import _ from 'lodash';
import moment from 'moment';
import { useSelector } from 'react-redux';

import {
  growthFeedbackType,
  growthStagesTypes
} from '../../_constants/growthsConstants';
import { requestStatus } from '../helpers/requestStatus';

import { useGrowthStagesConfig } from './configSelectors';
import { useAllGrowerFieldsSelector } from './fieldsSelectors';

export const growthStatusSelector = (seasonId, fieldId) => (state) =>
  _.get(state, ['growths', 'activeGrowths', seasonId, fieldId, 'status']);

export const growthUpdatedAtSelector = (seasonId, fieldId) => (state) =>
  _.get(state, ['growths', 'activeGrowths', seasonId, fieldId, 'updatedAt']);

export const growthErrorStatusSelector = (seasonId, fieldId) => (state) =>
  _.get(state, [
    'growths',
    'activeGrowths',
    seasonId,
    fieldId,
    'error',
    'status'
  ]);

export const activeGrowthIdSelector = (seasonId, fieldId) => (state) =>
  _.get(state, ['growths', 'activeGrowths', seasonId, fieldId, 'growth', 'id']);

function getFeedbackWithNames(growth, growthStagesConfig) {
  return _.chain(growth)
    .get('growthFeedback')
    .map((feedback) => {
      const stageConfig = _.get(growthStagesConfig, [feedback.stage]);
      return {
        ...feedback,
        name:
          feedback.type === growthFeedbackType.GrowthStage.type
            ? _.get(stageConfig, 'name', 'Unknown Stage')
            : feedback.name || 'Unknown Event'
      };
    })
    .value();
}

function getFeedbackByStage(feedbackWithNames) {
  return _.chain(feedbackWithNames).keyBy('stage').value();
}

function getGrowthWithAllowedFeedback(
  growth,
  feedbackByStage,
  growthStagesConfig
) {
  if (!growth) {
    return growth;
  }

  const plantingDate = _.get(growth, 'plantingDate')
    ? moment(_.get(growth, 'plantingDate'))
    : null;
  const isPlanted = !!plantingDate && moment().isAfter(plantingDate);

  const latestFeedbackOrder =
    _.chain(feedbackByStage)
      .keys()
      .map((key) => _.get(growthStagesConfig, [key, 'order']))
      .max()
      .value() || 0;

  const growthModelsWithAllowedFeedback = _.chain(growth)
    .get('growthModels')
    .map((growthModel) => {
      const growthStages = _.get(growthModel, 'growthStages');

      const growthStagesWithAllowedFeedback = _.map(growthStages, (stage) => {
        const growthStage = _.get(stage, 'growthStage');

        const isStageFeedbackAllowed =
          growthStage !== growthStagesTypes.Unknown &&
          growthStage !== growthStagesTypes.NotPlanted &&
          growthStage !== growthStagesTypes.Preplanting &&
          growthStage !== growthStagesTypes.Planting &&
          growthStage !== growthStagesTypes.Historical;

        const feedback = _.get(feedbackByStage, [growthStage]);

        return {
          ...stage,
          addFeedbackAllowed:
            isPlanted &&
            isStageFeedbackAllowed &&
            _.get(growthStagesConfig, [growthStage, 'order']) >
              latestFeedbackOrder,
          editFeedbackAllowed: isPlanted && !!feedback
        };
      });
      return {
        ...growthModel,
        growthStages: growthStagesWithAllowedFeedback
      };
    })
    .value();

  return {
    ...growth,
    growthModels: growthModelsWithAllowedFeedback
  };
}

export const useActiveGrowthSelectors = (seasonId, fieldId) => {
  const { growthStagesConfig } = useGrowthStagesConfig();

  const status = useSelector(growthStatusSelector(seasonId, fieldId));
  const growth = useSelector((state) =>
    _.get(state, ['growths', 'activeGrowths', seasonId, fieldId, 'growth'])
  );
  const updatedAt = useSelector(growthUpdatedAtSelector(seasonId, fieldId));

  const isLoading = status === requestStatus.IN_PROGRESS;

  const error = useSelector((state) =>
    _.get(state, ['growths', 'activeGrowths', seasonId, fieldId, 'error'])
  );

  const feedbackWithNames = useMemo(
    () => getFeedbackWithNames(growth, growthStagesConfig),
    [growth, growthStagesConfig]
  );

  const feedbackByStage = useMemo(
    () => getFeedbackByStage(feedbackWithNames),
    [feedbackWithNames]
  );

  const growthWithAllowedFeedback = useMemo(
    () =>
      getGrowthWithAllowedFeedback(growth, feedbackByStage, growthStagesConfig),
    [feedbackByStage, growth, growthStagesConfig]
  );

  const growthWithFeedbackNames = useMemo(() => {
    if (!growthWithAllowedFeedback) {
      return growthWithAllowedFeedback;
    }

    return {
      ...growthWithAllowedFeedback,
      feedback: feedbackWithNames
    };
  }, [feedbackWithNames, growthWithAllowedFeedback]);

  const notFound =
    useSelector(growthErrorStatusSelector(seasonId, fieldId)) === 404;
  const errorMessage = _.get(error, 'message');

  return useMemo(
    () => ({
      growth: growthWithFeedbackNames,
      isLoading,
      notFound,
      errorMessage,
      updatedAt
    }),
    [errorMessage, growthWithFeedbackNames, isLoading, notFound, updatedAt]
  );
};

export const useAllGrowerActiveGrowthsSelectors = (growerId, seasonId) => {
  const { fields } = useAllGrowerFieldsSelector();

  const { growthStagesConfig } = useGrowthStagesConfig();

  const activeGrowths = useSelector((state) =>
    _.get(state, ['growths', 'activeGrowths', seasonId])
  );

  const growthDescriptors = useMemo(() => {
    return _.chain(fields)
      .map((field) => {
        const fieldId = _.get(field, 'id');
        const growthDescriptor = _.get(activeGrowths, [fieldId]);
        const growth = _.get(growthDescriptor, 'growth');
        if (!growth) {
          return growthDescriptor;
        }

        const feedbackWithNames = getFeedbackWithNames(
          growth,
          growthStagesConfig
        );
        const feedbackByStage = getFeedbackByStage(feedbackWithNames);

        return {
          ...growthDescriptor,
          growth: {
            ...getGrowthWithAllowedFeedback(
              growth,
              feedbackByStage,
              growthStagesConfig
            ),
            feedbackByStage,
            feedback: feedbackWithNames,
            fieldName: _.get(field, 'name'),
            farmName: _.get(field, 'farmName')
          }
        };
      })
      .filter((growth) => !!growth)
      .value();
  }, [activeGrowths, fields, growthStagesConfig]);

  const inProgress = useMemo(
    () =>
      _.some(
        growthDescriptors,
        (growth) => _.get(growth, 'status') === requestStatus.IN_PROGRESS
      ),
    [growthDescriptors]
  );

  const growths = useMemo(
    () =>
      _.chain(growthDescriptors)
        .map((growth) => _.get(growth, 'growth'))
        .filter((growth) => !!growth)
        .value(),
    [growthDescriptors]
  );

  return useMemo(() => ({ inProgress, growths }), [growths, inProgress]);
};

export const useCreateGrowthSelectors = () => {
  const status = useSelector((state) =>
    _.get(state, 'growths.createGrowth.status')
  );
  const inProgress = status === requestStatus.IN_PROGRESS;
  const success = status === requestStatus.SUCCESS;
  const error = useSelector((state) =>
    _.get(state, 'growths.createGrowth.error.message')
  );

  return useMemo(
    () => ({
      inProgress,
      success,
      error
    }),
    [error, inProgress, success]
  );
};

export const useModifyGrowthSelectors = () => {
  const status = useSelector((state) =>
    _.get(state, 'growths.modifyGrowth.status')
  );
  const inProgress = status === requestStatus.IN_PROGRESS;
  const success = status === requestStatus.SUCCESS;
  const error = useSelector((state) =>
    _.get(state, 'growths.modifyGrowth.error.message')
  );

  return useMemo(
    () => ({
      inProgress,
      success,
      error
    }),
    [error, inProgress, success]
  );
};

export const useDeleteGrowthSelectors = () => {
  const status = useSelector((state) =>
    _.get(state, 'growths.deleteGrowth.status')
  );
  const inProgress = status === requestStatus.IN_PROGRESS;
  const success = status === requestStatus.SUCCESS;
  const error = useSelector((state) =>
    _.get(state, 'growths.deleteGrowth.error.message')
  );

  return useMemo(
    () => ({
      inProgress,
      success,
      error
    }),
    [error, inProgress, success]
  );
};

export const useSetTargetMoistureSelectors = () => {
  const status = useSelector((state) =>
    _.get(state, 'growths.setTargetMoisture.status')
  );
  const inProgress = status === requestStatus.IN_PROGRESS;
  const success = status === requestStatus.SUCCESS;
  const error = useSelector((state) =>
    _.get(state, 'growths.setTargetMoisture.error.message')
  );

  return useMemo(
    () => ({
      inProgress,
      success,
      error
    }),
    [error, inProgress, success]
  );
};
