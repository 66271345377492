import { userService } from '../../../services';
import { createApiSlice } from '../utils/createApiSlice';

const pageViewActionSlice = createApiSlice({
  reducerPath: ['newUser', 'pageViewAction'],
  apiFunction: userService.pageViewAction
});

const { actions } = pageViewActionSlice;

export const pageViewActionActions = actions;

export default pageViewActionSlice;
