import { useMemo } from 'react';

import { seasonService } from '../../../../services';
import { createApiSlice } from '../../utils/createApiSlice';

const removeFieldFromSeasonSlice = createApiSlice({
  reducerPath: ['gff', 'seasons', 'removeFieldFromSeason'],
  apiFunction: seasonService.removeFieldV2
});

const { actions, useApiSelectors } = removeFieldFromSeasonSlice;

export const removeFieldFromSeasonActions = actions;

export const useRemoveFieldFromSeasonSelectors = () => {
  const { inProgress, errorMessage, response } = useApiSelectors();

  return useMemo(
    () => ({
      inProgress,
      errorMessage,
      response
    }),
    [inProgress, errorMessage, response]
  );
};

export default removeFieldFromSeasonSlice;
