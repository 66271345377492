import React, { useCallback, useContext, useMemo, useState } from 'react';

import { faCheckDouble } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import cx from 'classnames';
import _ from 'lodash';
import moment from 'moment';

import { AuthContext } from '../../_context/AuthContext';
import { FarmStructureContext } from '../../_context/FarmStructureContext';
import Technology from '../../Pages/Dashboard/Components/Technology';
const useStyles = makeStyles(() => ({
  selectedFieldCell: { fontWeight: 'bold' },
  selectedFieldRow: { backgroundColor: '#FAFAFA' },
  table: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    cursor: 'pointer'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    borderBottom: '1px solid rgba(224, 224, 224, 1)'
  },
  header: {
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 500,
    lineHeight: '1.5rem'
  },
  headerContent: {
    marginRight: '0.25rem'
  },
  cell: {
    display: 'flex',
    minWidth: 0,
    flexBasis: 0,
    padding: '1rem',
    fontSize: '0.875rem',
    alignItems: 'center'
  },
  farmCell: {
    flexGrow: 1
  },
  fieldCell: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  seedProductCell: {
    flexGrow: 1.25
  },
  plantingCell: {
    flexGrow: 1,
    justifyContent: 'flex-end'
  },
  physiologicalMaturityCell: {
    flexGrow: 1.25,
    justifyContent: 'flex-end'
  },
  acresCell: {
    flexGrow: 1,
    justifyContent: 'flex-end'
  },
  estimatedHarvestCell: {
    flexGrow: 1.25,
    justifyContent: 'flex-end',
    fontWeight: '500'
  },
  referenceIcon: {
    fontSize: '0.75rem',
    marginRight: '0.5rem'
  }
}));

const SortingOrder = {
  Asc: 'asc',
  Desc: 'desc'
};

const headers = {
  field: 'name',
  farmName: 'farmName',
  technology: 'technologyName',
  seedProduct: 'varietyName',
  acres: 'acres',
  plantingDate: 'plantingDate',
  physiologicalMaturityDate: 'physiologicalMaturityDate',
  harvestDate: 'harvestDate'
};

const FieldRow = ({ field }) => {
  const styles = useStyles();

  const { isAdmin } = useContext(AuthContext);

  const { selectedFieldId, selectField } = useContext(FarmStructureContext);

  const isSelected = _.get(field, 'id') === selectedFieldId;

  const varietyId = _.get(field, 'varietyId');
  const isReference = _.get(field, 'isReference');
  const acres = _.get(field, 'acres');
  const varietyName = _.get(field, 'varietyName');

  const plantingDate = useMemo(() => {
    const date = _.get(field, 'plantingDate');
    return !!date ? moment(date) : null;
  }, [field]);

  const harvestDate = _.get(field, 'harvestDate');

  const physiologicalMaturityDate = useMemo(() => {
    return _.get(field, 'physiologicalMaturityDate');
  }, [field]);

  const handleRowClick = useCallback(() => {
    selectField(
      _.get(field, 'growerId'),
      _.get(field, 'seasonId'),
      _.get(field, 'farmId'),
      _.get(field, 'id')
    );
  }, [field, selectField]);

  return (
    <>
      <Box className={styles.row} onClick={handleRowClick}>
        <Box
          className={cx(styles.cell, styles.farmCell, {
            [styles.selectedFieldCell]: isSelected
          })}
        >
          {_.get(field, 'farmName')}
        </Box>
        <Box
          className={cx(styles.cell, styles.fieldCell, {
            [styles.selectedFieldCell]: isSelected
          })}
        >
          {isAdmin && isReference && (
            <Tooltip title="Reference Growth">
              <Box>
                <FontAwesomeIcon
                  icon={faCheckDouble}
                  className={styles.referenceIcon}
                />
              </Box>
            </Tooltip>
          )}
          <span>{_.get(field, 'name')}</span>
        </Box>
        <Box className={cx(styles.cell, styles.seedProductCell)}>
          {!!varietyId && <Technology varietyId={varietyId} />}
        </Box>
        <Box className={cx(styles.cell, styles.seedProductCell)}>
          {varietyName}
        </Box>
        <Box className={cx(styles.cell, styles.acresCell)}>
          {!!acres && acres.toFixed(1)}
        </Box>
        <Box className={cx(styles.cell, styles.plantingCell)}>
          {!!plantingDate && plantingDate.format('ll')}
        </Box>
        <Box className={cx(styles.cell, styles.physiologicalMaturityCell)}>
          {!!physiologicalMaturityDate &&
            moment(physiologicalMaturityDate).format('ll')}
        </Box>
        <Box className={cx(styles.cell, styles.estimatedHarvestCell)}>
          {!!harvestDate && moment(harvestDate).format('ll')}
        </Box>
      </Box>
    </>
  );
};

const HeaderItem = ({ className, variable, order, onClick, children }) => {
  const styles = useStyles();

  const isSelected = variable === _.get(order, 'variable');
  const sortOrder = _.get(order, 'order');

  const handleClick = useCallback(() => {
    onClick(variable);
  }, [onClick, variable]);

  return (
    <Box className={className} onClick={handleClick}>
      <Box className={styles.headerContent}>{children}</Box>
      {isSelected &&
        (sortOrder === SortingOrder.Asc ? (
          <ArrowDownwardIcon />
        ) : (
          <ArrowUpwardIcon />
        ))}
    </Box>
  );
};

const GrowerHarvestAdvisorTable = ({ fields }) => {
  const styles = useStyles();

  const [order, setOrder] = useState({
    variable: headers.harvestDate,
    order: SortingOrder.Asc
  });

  const orderedFields = useMemo(
    () => _.orderBy(fields, [_.get(order, 'variable')], _.get(order, 'order')),
    [fields, order]
  );

  const handleHeaderClick = useCallback(
    (variable) => {
      if (
        _.get(order, 'variable') === variable &&
        _.get(order, 'order') === SortingOrder.Asc
      ) {
        setOrder({ variable, order: SortingOrder.Desc });
      } else {
        setOrder({ variable, order: SortingOrder.Asc });
      }
    },
    [order]
  );

  return (
    <Box className={styles.table}>
      <Box className={styles.row}>
        <HeaderItem
          className={cx(styles.cell, styles.header, styles.farmCell)}
          onClick={handleHeaderClick}
          variable={headers.farmName}
          order={order}
        >
          Farm
        </HeaderItem>
        <HeaderItem
          className={cx(styles.cell, styles.header, styles.fieldCell)}
          onClick={handleHeaderClick}
          variable={headers.field}
          order={order}
        >
          Field
        </HeaderItem>
        <HeaderItem
          className={cx(styles.cell, styles.header, styles.seedProductCell)}
          onClick={handleHeaderClick}
          variable={headers.technology}
          order={order}
        >
          Technology
        </HeaderItem>
        <HeaderItem
          className={cx(styles.cell, styles.header, styles.seedProductCell)}
          onClick={handleHeaderClick}
          variable={headers.seedProduct}
          order={order}
        >
          Seed Product
        </HeaderItem>
        <HeaderItem
          className={cx(styles.cell, styles.header, styles.acresCell)}
          onClick={handleHeaderClick}
          variable={headers.acres}
          order={order}
        >
          Acres
        </HeaderItem>
        <HeaderItem
          className={cx(styles.cell, styles.header, styles.plantingCell)}
          align="center"
          onClick={handleHeaderClick}
          variable={headers.plantingDate}
          order={order}
        >
          Planting Date
        </HeaderItem>
        <HeaderItem
          className={cx(
            styles.cell,
            styles.header,
            styles.physiologicalMaturityCell
          )}
          align="center"
          onClick={handleHeaderClick}
          variable={headers.physiologicalMaturityDate}
          order={order}
        >
          Phys.Maturity Date
        </HeaderItem>
        <HeaderItem
          className={cx(
            styles.cell,
            styles.header,
            styles.estimatedHarvestCell
          )}
          align="center"
          onClick={handleHeaderClick}
          variable={headers.harvestDate}
          order={order}
        >
          Estimated Harvest Date
        </HeaderItem>
      </Box>

      {_.map(orderedFields, (field) => (
        <FieldRow key={_.get(field, 'id')} field={field} />
      ))}
    </Box>
  );
};

export default GrowerHarvestAdvisorTable;
