import React from 'react';

import _ from 'lodash';

const FieldsDivider = ({ svgWidth, fields, fieldGap }) => {
  return (
    <g>
      {_.map(_.tail(fields), (field) => {
        const y = _.get(field, 'startY') - fieldGap;
        return (
          <line
            x1={0}
            x2={svgWidth}
            y1={y}
            y2={y}
            stroke="#9e9e9e"
            key={_.get(field, 'id')}
          />
        );
      })}
    </g>
  );
};

export default FieldsDivider;
