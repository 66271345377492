import _ from 'lodash';

export function brushHandles(g, selection, brushHeight, handlesWidth) {
  g.selectAll('.handle--custom')
    .data([{ type: 'w' }, { type: 'e' }])
    .join((enter) =>
      enter
        .append('rect')
        .attr('class', 'handle--custom')
        .attr('fill', 'rgb(46, 64, 87)')
        .attr('fill-opacity', 0.8)
        .attr('stroke-width', 0)
        .attr('cursor', 'ew-resize')
        .attr('height', brushHeight)
        .attr('width', handlesWidth)
    )
    .attr('display', selection === null ? 'none' : null)
    .attr(
      'transform',
      selection === null
        ? null
        : (d, i) =>
            `translate(${
              _.get(d, 'type') === 'w'
                ? selection[i]
                : selection[i] - handlesWidth
            },${0})`
    );
}
