import { useMemo } from 'react';

import _ from 'lodash';

import { deviceCommands } from '../../../../services/deviceCommands.service';
import { createApiSlice } from '../../utils/createApiSlice';

const sendDeviceCommandSlice = createApiSlice({
  reducerPath: ['devices', 'commands', 'sendDeviceCommand'],
  apiFunction: deviceCommands.postCommandV2,
  getStateUpdatePath: (action) => {
    const growerId = _.get(action, 'payload.apiArguments.0');
    const seasonId = _.get(action, 'payload.apiArguments.1');
    const deviceId = _.get(action, 'payload.apiArguments.2');
    return [growerId, seasonId, deviceId];
  },
  getSelectorPath: (growerId, seasonId, deviceId) => {
    return [growerId, seasonId, deviceId];
  },
  apiArgumentsFn: ({ growerId, seasonId, deviceId, command }) => [
    growerId,
    seasonId,
    deviceId,
    command
  ]
});

const { actions, selectors, useApiSelectors } = sendDeviceCommandSlice;

export const sendDeviceCommandActions = actions;

export const sendDeviceCommandSelectors = {
  ...selectors
};

export const useSendDeviceCommandSelectors = (growerId, seasonId, deviceId) => {
  const { inProgress, errorMessage, success, updatedAt } = useApiSelectors(
    growerId,
    seasonId,
    deviceId
  );

  return useMemo(
    () => ({
      inProgress,
      errorMessage,
      success,
      updatedAt
    }),
    [errorMessage, inProgress, success, updatedAt]
  );
};

export default sendDeviceCommandSlice;
