import { useMemo } from 'react';

import { getPrecipitation } from '../../_helpers/Growths/growthModelHelpers';
import { useGrowthModelSelectors } from '../../_store/selectors/uiSelectors';

export function useGrowthPrecipitation(growth) {
  const { selectedGrowthModel } = useGrowthModelSelectors();

  return useMemo(
    () => ({ precipitation: getPrecipitation(growth, selectedGrowthModel) }),
    [growth, selectedGrowthModel]
  );
}
