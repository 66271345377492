import React from 'react';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(() => ({
  breadcrumbsText: {
    padding: 0,
    textTransform: 'uppercase',
    color: 'rgba(0, 0, 0, 0.7)',
    fontWeight: 'bold',
    fontSize: '0.88rem'
  }
}));

const BreadcrumbsText = ({ children, ...props }) => {
  const styles = useStyles();

  return (
    <Typography {...props} className={styles.breadcrumbsText}>
      {children}
    </Typography>
  );
};

export default BreadcrumbsText;
