import { useMemo } from 'react';

import _ from 'lodash';
import { useSelector } from 'react-redux';

import { useGlobalSeasonSelectors } from '../gff/seasons/globalSeasonSlice';
import { createDataSlice } from '../utils/createDataSlice';

const reportsAppliedFieldsSelectionSlice = createDataSlice({
  reducerPath: ['reports', 'reportsAppliedFieldsSelection'],
  initialState: { fieldsChecked: {} },
  reducers: {
    replaceStateTree(state, action) {
      const { tree, seasonYear } = action.payload;
      _.set(state, ['fieldsChecked', seasonYear], tree);
    }
  }
});

const { actions, baseSelector: reportsAppliedFieldsSelectionSelector } =
  reportsAppliedFieldsSelectionSlice;

export const reportsAppliedFieldsSelectionActions = actions;

export const useReportsAppliedFieldsSelectionSelectors = () => {
  const { selectedSeason: seasonYear } = useGlobalSeasonSelectors();

  const { fieldsChecked } = useSelector(reportsAppliedFieldsSelectionSelector);
  const seasonFieldsChecked = _.get(fieldsChecked, [seasonYear]);

  return useMemo(
    () => ({
      fieldsChecked: seasonFieldsChecked
    }),
    [seasonFieldsChecked]
  );
};

export default reportsAppliedFieldsSelectionSlice;
