import { all, fork, takeLatest, select, put } from 'redux-saga/effects';

import { getConfigFarmsActions } from '../actions/farmsAction';
import { getConfigFieldsActions } from '../actions/fieldsAction';
import { getConfigGrowersActions } from '../actions/growersAction';
import { gffConfigActionTypes } from '../actionTypes/gffConfigActionTypes';
import { requestStatus } from '../helpers/requestStatus';
import { configFarmsStatusSelector } from '../selectors/configFarmsSelectors';
import { configFieldsStatusSelector } from '../selectors/configFieldsSelectors';
import { configGrowersStatusSelector } from '../selectors/growersSelectors';

function* handleUpdateGffConfig(action) {
  const { growerId, farmId } = action.payload;

  const growersStatus = yield select(configGrowersStatusSelector);

  if (growersStatus !== requestStatus.IN_PROGRESS) {
    yield put(getConfigGrowersActions.request());
  }

  if (!growerId) {
    return;
  }

  const farmsStatus = yield select(configFarmsStatusSelector(growerId));
  if (farmsStatus !== requestStatus.IN_PROGRESS) {
    yield put(getConfigFarmsActions.request(growerId));
  }

  if (!farmId) {
    return;
  }

  const fieldsStatus = yield select(
    configFieldsStatusSelector(growerId, farmId)
  );
  if (fieldsStatus !== requestStatus.IN_PROGRESS) {
    yield put(getConfigFieldsActions.request(growerId, farmId));
  }
}

function* watchUpdateGffConfig() {
  yield takeLatest(
    gffConfigActionTypes.UPDATE_GFF_CONFIG,
    handleUpdateGffConfig
  );
}

function* gffConfigSaga() {
  yield all([fork(watchUpdateGffConfig)]);
}

export default gffConfigSaga;
