import React, { useCallback, useState } from 'react';

import { CircularProgress } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import makeStyles from '@material-ui/core/styles/makeStyles';
import cx from 'classnames';

import ConfirmDeleteButton from './ConfirmDeleteButton';

const useStyles = makeStyles(() => ({
  editButtonsContainer: {
    justifyContent: 'space-between'
  },
  commonButtons: {
    display: 'flex',
    alignItems: 'center',
    '& > :not(:first-child)': {
      marginLeft: '0.5rem'
    }
  }
}));

const CreateUpdateDeleteDialogActions = ({
  isEdit,
  inProgress,
  confirmText,
  confirmDisabled,
  onDelete,
  onCancel,
  size
}) => {
  const styles = useStyles();

  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);

  const handleClose = useCallback(() => {
    onCancel();
  }, [onCancel]);

  const handleDelete = useCallback(() => {
    setConfirmDeleteOpen(true);
  }, []);

  const handleCancelDelete = useCallback(() => {
    setConfirmDeleteOpen(false);
  }, []);

  const handleConfirmDelete = useCallback(() => {
    setConfirmDeleteOpen(false);
    onDelete();
  }, [onDelete]);

  return (
    <DialogActions className={cx({ [styles.editButtonsContainer]: isEdit })}>
      {isEdit && (
        <ConfirmDeleteButton
          open={confirmDeleteOpen}
          disabled={inProgress}
          onDelete={handleDelete}
          onCancel={handleCancelDelete}
          onConfirmDelete={handleConfirmDelete}
        >
          Delete
        </ConfirmDeleteButton>
      )}
      {!confirmDeleteOpen && (
        <Box className={styles.commonButtons}>
          {inProgress && <CircularProgress size={30} />}
          <Button
            disabled={inProgress}
            onClick={handleClose}
            variant="contained"
            color="default"
            size={size}
          >
            Cancel
          </Button>
          <Button
            disabled={inProgress || confirmDisabled}
            type="submit"
            variant="contained"
            color="primary"
            size={size}
          >
            {confirmText}
            {!confirmText && (isEdit ? 'Update' : 'Create')}
          </Button>
        </Box>
      )}
    </DialogActions>
  );
};

export default CreateUpdateDeleteDialogActions;
