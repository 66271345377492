import { useMemo } from 'react';

import { useSelector } from 'react-redux';

import { createDataSlice } from '../utils/createDataSlice';

const menuSlice = createDataSlice({
  reducerPath: ['ui', 'menu'],
  initialState: { openSideMenu: true, showMobileSideMenu: false },
  reducers: {
    toggleMobileSideMenu(state) {
      state.showMobileSideMenu = !state.showMobileSideMenu;
    },
    toggleSideMenu(state) {
      state.openSideMenu = !state.openSideMenu;
    }
  }
});

const { actions, baseSelector: menuSelector } = menuSlice;

export const menuActions = actions;

export const useMenuSelectors = () => {
  const { openSideMenu, showMobileSideMenu } = useSelector(menuSelector);

  return useMemo(
    () => ({
      openSideMenu,
      showMobileSideMenu
    }),
    [openSideMenu, showMobileSideMenu]
  );
};

export default menuSlice;
